import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import './style.scss';
import uuid from 'uuid'
import {
  hash,
  // constructArray,
} from 'scripts/helpers';
import renderable from 'types/renderable';
// import Braille from 'components/ASCIIanimation/Braille';
import AnimatedUnderline from 'components/AnimatedUnderline'
import Expander from 'components/Expander';

export type InfoItem = {
  label: React.ReactNode,
  value: React.ReactNode,
  hide?: boolean
}

export type Info = InfoItem[][]

const
  key_seed = uuid(),
  Block = (props: { children: ReactNode }) =>
    // <Expander isOpen={props.show} className='info-block-wrapper'>
    <div className='info-block'>
      {props.children}
    </div>
  // </Expander>
  ,
  Row = (props: { children: ReactNode, show: boolean }) =>
    <Expander isOpen={props.show} className='info-row-wrapper'>
      <div className='info-row'>
        {props.children}
      </div>
    </Expander>,
  Item = (props: { children: ReactNode }) =>
    <div className='info-item'>
      {props.children}
    </div>

function InfoElement({ info }: { info: Info }) {
  const
    show_moreInfo_button = info.some(block => block.some(row => row.hide)),
    [show_moreInfo, setShow_moreInfo] = React.useState(false)

  // console.log({ info })

  return <div className='Component-InfoElement'>
    {info.map((block, block_key) =>
      block.every(item => item.value === null) ? null :
        <Block key={hash(key_seed, block_key)}>{
          block.map((item, item_key) =>
            item.value === null ? null :
              <Row key={hash(key_seed, block_key, item_key)} show={!item.hide || show_moreInfo}>
                <Item>
                  {item.label}
                </Item>
                <Item>
                  {item.value}
                </Item>
              </Row>
          )
        }</Block>
    )}
    {show_moreInfo_button ?
      <span className='more-info-wrapper' onClick={() => setShow_moreInfo(!show_moreInfo)}>
        <AnimatedUnderline>
          {show_moreInfo ? 'Skrýt' : 'Zobrazit'} více info
        </AnimatedUnderline>
      </span> : null}
  </div>
}

InfoElement.propTypes = {
  info: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: renderable,
        hide: PropTypes.bool,
      })
    )
  )
}

export default InfoElement