import React from 'react';
import PropTypes from 'prop-types'
import url from 'url-prop-type'
import './style.scss';

function OfferBox(props) {
  return <div className={`${props.className || ''} Component-OfferBox`}>
    <header>
      <div style={{ backgroundImage: `url("${props.header.background}")` }} className='background-wrapper' />
      <div className='left-wrapper'>
        {props.header.left}
      </div>
      <div className='right-wrapper'>
        {props.header.right}
      </div>
    </header>
    <main>{props.main}</main>
    <footer>{props.footer}</footer>
  </div>;
}

OfferBox.propTypes = {
  header: PropTypes.shape({
    background: url,
    right: PropTypes.node.isRequired,
    left: PropTypes.node.isRequired,
  }).isRequired,
  main: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
}

export default OfferBox