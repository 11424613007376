import React from 'react'

import PropTypes from 'prop-types'

import {
  FaInfo as MoreInfoIcon,
  FaArrowCircleRight as ContinueIcon
} from 'react-icons/fa'

import offerPT from 'types/offer'
import './style.scss'
import FullOffer from './FullOffer'
import CompactOffer from './CompactOffer'
import AnimatedUnderline from 'components/AnimatedUnderline'
import SCButton from 'components/SCButton'
// import OfferInfoModal from './InfoModal'
import Img from 'react-image'
import LoadingIcon from 'components/LoadingIcon';

export function getBoon(offer, boon) {
  return offer.boons.find(
    ({ type }) => type === boon
  )
}

export function getVIPBoon(offer) {
  return getBoon(offer, 'vipPartner')
}

export function getField(offer, field) {
  return offer.fields.find(
    ({ name }) => name === field
  )
}

export const logoUnavailable = <span className='no-logo'>Logo tohoto<br />poskytovatele<br />není dostupné</span>

function Offer(props) {
  const
    {
      offer,
      className,
    } = props,
    // { warnings: notes, provider } = props.offer,
    compact = props.compact,
    buttons = props.noButtons ? {} : {
      continueButton: <div className='continue-wrapper button-wrapper'>
        <SCButton icon={ContinueIcon} iconPos='after' animation='bounce-right'
          onClick={props.onContinue}>
          Mám zájem
      </SCButton>
      </div>,
      moreInfoButton: <div className='more-info-wrapper button-wrapper' onClick={() => props.openModal(offer.id)}>
        <AnimatedUnderline className='more-info'>
          <div className='more-info-icon-wrapper'>
            <MoreInfoIcon className='more-info-icon' />
          </div>
          Více info
    </AnimatedUnderline>
      </div>
    },
    Offer = compact ? CompactOffer : FullOffer

  return <Offer
    className={['Component-Offer', className].toClass()}
    logo={<Img
      className='provider-logo'
      src={offer.provider.logoUrl}
      loader={<LoadingIcon className='logo-loading' />}
      unloader={logoUnavailable}
    />}
    noButtons={props.noButtons}
    openModal={props.openModal}
    {...{ ...buttons, offer }}
  />
}

Offer.propTypes = {
  offer: offerPT.isRequired,
  compact: PropTypes.bool,
  noButtons: PropTypes.bool,
  onInterest: PropTypes.func,
  onContinue: PropTypes.func,
}
Offer.defaultProps = {
  compact: false,
  noButtons: false,
  onInterest: () => { },
  onContinue: () => { },
  openModal: () => { }
}

export default Offer