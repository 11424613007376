/* eslint-disable no-extend-native */
import React from 'react'
// import toPx from 'to-px'
import { encode, decode } from 'base-64'
import objectHash from 'object-hash'
import serializeJavascript from 'serialize-javascript'
import cloneDeep from 'lodash/cloneDeep'
import Indexable from 'types/Indexable'
import { Arg1, Arg2 } from 'tsargs'
import makeCancellable from 'make-cancellable-promise'
import { isArray } from 'util'

/**
 * Clamps given value to the given range
 * 
 * @arg  {number} min minimum to clamp val to.
 * @arg  {number} val value to clamp.
 * @arg  {number} max maximum to clamp val to.
 */
export function clamp(min: number, val: number, max: number) {
  return Math.max(min, Math.min(val, max));
}

export function gcd(x: number, y?: number, ...rest: number[]): number {
  if (typeof x !== 'number')
    return NaN

  if (y === undefined)
    return x

  x = Math.abs(x)
  y = Math.abs(y)
  while (y) {
    let t = y
    y = x % y
    x = t
  }

  return gcd(x, ...rest)
}

/**
 * Rounds the given value to the nearest given multiple, or nearest whole number.
 * @param {Number} val Value to round.
 * @param {Number} [multiple=1] Rounds to closest multiple of this number.
 * @returns {Number} Rounded number
 */
export function roundToNearest(val: number, multiple = 1) {
  return Math.round(val / multiple) * multiple
}

/**
 * Gets the distance squared between two coordinates (for comparisons)
 * 
 * @arg {number} a
 * @arg {number} b
 */
export function dist_sq(a: number, b: number) {
  return a * a + b * b;
}

export function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

/**
 * Adds gaps between every 3 digits fromend or decimal (e.g. 1234567.89 -> 1 234 567.89)
 * @param {String} val String representation of a number
 * @returns {String}
 */
export function addHundredsGap(val: number) {
  let returnVal = String(val).replace(/ /g, '')

  // filter out non-numbers
  if (isNaN(parseFloat(returnVal)))
    return returnVal

  let startIndex = returnVal.indexOf('.')
  if (startIndex === -1)
    startIndex = returnVal.length

  for (let i = startIndex - 3; i > 0; i -= 3) {
    // console.log(`${returnVal}[${i}] = ${returnVal[i]}`)
    returnVal = returnVal.splice(i, 0, ' ')
    // console.log({ spacelessVal: returnVal })
  }

  return returnVal
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Checks whether two arrays contain the same elements, optionally in the same order
 * 
 * @arg {array} _arr1               First array to compare.
 * @arg {array} _arr2               Second array to compare.
 * @arg {boolean}  [check_order=false] Should order matter?
 */
export function arraysEqual(_arr1: string | any[], _arr2: string | any[], check_order = false) {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  )
    return false;

  var arr1 = _arr1.concat()
  var arr2 = _arr2.concat()

  if (!check_order) {
    arr1.sort()
    arr2.sort()
  }

  for (var i = 0; i < arr1.length; i++)
    if (arr1[i] !== arr2[i]) return false;

  return true;
}

/**
 * Removes from the array all items matching the discriminator and returns those items as new array.
 * @param {Array} arr 
 * @param {Function} callBackFn
 * @returns {Array}
 */
export function arrayExtract(arr: any[], callBackFn: { (item: any, index: number): boolean; (arg0: any, arg1: number): any; }) {
  let
    out_arr = [],
    i = 0

  while (i < arr.length)
    if (callBackFn(arr[i], i))
      out_arr.push(...arr.splice(i, 1))
    else
      i++

  return out_arr
}

export function arrayEvery<T>(
  array: T[],
  callBackFn: (value: T, index: number, array: T[]) => unknown = value => !!value,
  thisArg?: any
) {
  return array.every(callBackFn, thisArg)
}

export function arraySome<T>(
  array: T[],
  callBackFn: (value: T, index: number, array: T[]) => unknown = value => !!value,
  thisArg?: any
) {
  return array.some(callBackFn, thisArg)
}

/**
 * Converts a JSON (object) variable to Form Data.
 */
export function JSONtoFormData(json: Indexable) {
  let form_data = new FormData()

  for (var prop in json)
    if (json[prop] !== undefined &&
      json[prop] !== null)
      form_data.append(prop, json[prop])

  return form_data
}

/**
 * Converts FormData object to JSON.
 */
export function FormDataToJSON(FormData: [string, string][]) {
  let json: Indexable = {}

  for (const [key, val] of Object.values(FormData))
    try {
      json[key] = JSON.parse(val)
    } catch {
      json[key] = val
    }

  return json
}

export function fileToBase64(file: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })
}

export function encodeBase64(string?: string | null) {
  // console.log('encoding', string)

  if (!string)
    return null

  return encode(encodeURI(string))
}

export function decodeBase64(b64string?: string | null) {
  // console.log({ b64string })

  if (!b64string)
    return null

  return decodeURI(decode(b64string))
}

export function blockPageLoad(description: string) {
  const
    loading_prefix = 'loading_',
    attribute = loading_prefix + description,
    html = document.documentElement

  html.setAttribute(attribute, '')
  html.setAttribute('loading', '')
  // console.log('setting loading')

  return function () {
    html.removeAttribute(attribute)
    if (!Array.from(html.attributes).some(attr => /^loading_/.test(attr.name))) {
      html.removeAttribute('loading')
      // console.log('finished loading')
    }
  }
}

const encodeStorage = (!reactDev()) //|| true //force encode
function getStorageName(name: string) {
  return encodeStorage ? hash(name) : name
}
function encodeStorageData(data: string | null) {
  return encodeStorage ? encodeBase64(data) : data
}
function decodeStorageData(data: string | null) {
  return encodeStorage ? decodeBase64(data) : data
}
function setStorageItem(name: string, data: any, method: Storage) {

  // console.log('setting storage item', { name })

  const dataAsStr = (typeof data === 'object') ? serialise(data) : data

  // console.log({ dataAsStr })

  const encodedItem = encodeStorage ? encodeStorageData(dataAsStr) : dataAsStr

  // console.log({ encodedItem })

  method.setItem(getStorageName(name), encodedItem)
}
function getStorageItem(name: string, type: string, method: Storage) {

  // console.log('getting storage item', { name })

  const storageName = getStorageName(name)

  // console.log({ storageName })

  const gottenItem = method.getItem(storageName)

  // console.log({ gottenItem })

  const decodedItem = encodeStorage ?
    decodeStorageData(gottenItem) :
    gottenItem

  // console.log({ decodedItem })

  switch (type) {
    case 'string':
      return decodedItem
    case 'json':
      return deserialise(decodedItem)
    default:
      console.error('Invalid session item type:', type)
  }
}
function removeItem(name: string, method: Storage) {
  method.removeItem(getStorageName(name))
}
/**
 * @param {string} name Name of item to retrieve.
 * @param {any} data Data to store (string or json).
 */
export function setSessionItem(name: string, data: string | boolean) {
  return setStorageItem(name, data, sessionStorage)
}
/**
 * @param {string} name Name of item to retrieve.
 * @param {string} [type='string'] Type of item (string or json).
 */
export function getSessionItem(name: string, type = 'string') {
  return getStorageItem(name, type, sessionStorage)
}
export function removeSessionItem(name: string) {
  return removeItem(name, sessionStorage)
}
/**
 * @param {string} name Name of item to retrieve.
 * @param {any} data Data to store (string or json).
 */
export function setLocalItem(name: string, data: string) {
  return setStorageItem(name, data, localStorage)
}
/**
 * @param {string} name Name of item to retrieve.
 * @param {string} [type='string'] Type of item (string or json).
 */
export function getLocalItem(name: string, type = 'string') {
  return getStorageItem(name, type, localStorage)
}
export function removeLocalItem(name: string) {
  return removeItem(name, localStorage)
}

/**
 * Checks if email is valid
 * 
 * @arg {string} email Email to validate.
 * 
 * @returns {boolean} True if valid.
 */
export function validateEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/**
 * Checks if phone number is valid
 * 
 * @arg {string} phone Phone number to validate.
 * 
 * @returns {boolean} True if valid.
 */
export function validatePhone(phone: string | number) {
  var re = /^[+]?[()/0-9. -]{9,}$/;
  return re.test(String(phone).toLowerCase());
}

/**
 * Checks if postcode is valid
 * 
 * @arg {string} psc postcode to validate.
 * 
 * @returns {boolean} True if valid.
 */
export function validatePSC(psc: string) {
  var re = /^[0-9]{3}[ ]?[0-9]{2}$/;
  return re.test(String(psc).toLowerCase());
}

/**
 * Checks if URL is valid
 * 
 * @arg {string} url URL to validate.
 * @arg {boolean} [mustHaveProtocol = true] Whether the url must be prefixed with a protocol
 * 
 * @returns {boolean} True if valid.
 */
export function validateURL(url: string, mustHaveProtocol = false) {
  var re
  if (mustHaveProtocol)
    re = /^http(s)?:\/\/[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/igm
  else
    re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/igm
  return re.test(String(url).toLowerCase());
}

/**
   * Validates an ICO number
   * 
   * @arg {number} ico ICO number to verify.
   * @arg {boolean} [verbose=false] print validation error to console.
   * 
   * @returns {boolean} True if ICO is valid.
   */
export function validateICO(ico: string, verbose = false) {
  if (ico === undefined || !isFunction(ico.toString))
    return false

  ico = ico.toString()

  if (ico.length !== 8) {
    verbose && console.log(`ICO not right length, expected 8, got ${ico.length}`)
    return false
  }

  let sum = 0;
  //Loop over first 7 numbers and take index-product-sum
  for (let i = 0; i < 7; ++i)
    sum += Number(ico[i]) * (8 - i)

  //checksum number
  const s = sum % 11
  var n

  switch (s) {
    case 0:
      n = 1
      break
    case 1:
      n = 0
      break
    default:
      n = 11 - s
  }

  if (Number(ico[7]) === n)
    return true;
  verbose && console.log(`ICO should have ended with ${n}, instead got ${ico[7]}`)
  return false
}

/**
 * Validates a DIC number
 * 
 * @arg {string} dic DIC number to verify.
 * 
 * @returns {boolean} True if DIC may be valid (can't tell for sure without some API call).
 */
export function validateDIC(dic: string) {
  var re = /^[A-Z]{2}.*[a-zA-Z0-9].*$/;
  return re.test(dic);
}

export function hasClass(ele: Element, cls: string) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

export function addClass(ele: Element, cls: string) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

export function removeClass(ele: Element, cls: string) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

export function toClass(item: any): string {
  switch (typeof (item)) {
    case 'string':
      return item.toClass()
    case 'object':
      if (isArray(item))
        return item.toClass()
    // eslint-disable-next-line no-fallthrough
    default:
      return toClass(String(item))
  }
}

/**
 * Indexes into an object or array by a dot delimitered string.
 * For example: index({a: {b: 1}}, 'a.b') returns 1.
 * Can also set values using third argument.
 * 
 * @arg {Object|Array} obj Object or array to index into.
 * @arg {String} path Dot delimitered string to index by.
 * @arg {*} [value] Value to set.
 * @arg {Object} [options] Settings to index by.
 * @arg {Boolean} [options.force=false] Create missing containing objects along path of index.
 * @arg {Boolean} [options.verbose=false] Debug logging.
 * 
 * @returns {*} Value at the tail of the index.
 */
export const index_eraseValue = Symbol('Index erase value')
export function index(
  obj: Indexable,
  path: string | (string | number)[],
  value: typeof index_eraseValue | any | undefined,
  options = {}
): any {
  const opts = {
    force: false,
    verbose: false,
    ...options
  }

  opts.verbose && console.log('index', { obj, path, opts })

  if (typeof path === 'string') {
    opts.verbose && console.log(path, 'is string, splitting')
    return index(obj, path.split('.'), value, opts)
  }

  if (path.length === 1 && value !== undefined) {

    if (value === index_eraseValue) {
      opts.verbose && console.log('erasing', path)
      return delete obj[path[0]]
    }

    opts.verbose && console.log(path, '<-', value)
    return obj[path[0]] = value
  }

  if (path.length === 0) {
    opts.verbose && console.log('no path')
    return obj
  }

  if (opts.force && obj[path[0]] === undefined) {
    opts.verbose && console.log('forcing dead end in', obj)
    if (path.length === 1) {
      opts.verbose && console.log('creating property', { [path[0]]: value })
      obj[path[0]] = value
    }
    else if (isNumber(path[1])) {
      opts.verbose && console.log('creating array')
      obj[path[0]] = []
    }
    else {
      opts.verbose && console.log('creating object')
      obj[path[0]] = {}
    }
  }

  return index(obj[path[0]], path.slice(1), value, opts);
}

export function serialiseError(something: any) {
  let error = null

  if (something instanceof Error)
    error = something
  else if (isObject(something))
    error = new Error(serialise(something))
  else
    error = new Error(String(something))

  return error.stack
}

/**
 * Copies only the given keys of the supplied object
 */
export function someKeys(keys: string[], obj: Indexable) {
  return Object.entries(obj)
    .filter(([key]) => keys.includes(key))
    .reduce((newObj, [key, val]) => ({ ...newObj, [key]: val }), {} as Indexable)
}

export function isVoid(item: any) {
  return item === null || item === undefined
}

export function isObject(item: any) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function isPureObject(item: any) {
  if (!isObject(item))
    return false
  return Function.prototype.call.bind(Object.prototype.toString)(item) === '[object Object]'
}

// export function isArray(item: any) {
//   return (item && typeof item === 'object' && Array.isArray(item));
// }

export function isSymbol(item: any) {
  return typeof item === 'symbol'
}

export function isRegExp(item: any) {
  return item instanceof RegExp
}

export function isSyncFunction(functionToCheck: any, verbose = false) {
  if (!functionToCheck) {
    verbose && console.log(functionToCheck, 'coerces to false')
    return false
  }
  else if ({}.toString.call(functionToCheck) !== '[object Function]') {
    verbose && console.log('expected', {}.toString.call(functionToCheck), 'to be', {}.toString.call(() => { }))
    return false
  }

  return true
}

export function isAsyncFunction(functionToCheck: any, verbose = false) {
  if (!functionToCheck) {
    verbose && console.log(functionToCheck, 'coerces to false')
    return false
  }
  else if ({}.toString.call(functionToCheck) !== '[object AsyncFunction]') {
    verbose && console.log('expected', {}.toString.call(functionToCheck), 'to be', {}.toString.call(async () => { }))
    return false
  }

  return true
}

export function isFunction(functionToCheck: any, verbose = false) {
  return isSyncFunction(functionToCheck, verbose) || isAsyncFunction(functionToCheck, verbose)
}

export function isClassComponent(component: any) {
  // console.log({ component })
  return (
    typeof component === 'function' &&
    !!Object.getPrototypeOf(component).isReactComponent
  ) ? true : false
}

// export function isFunctionComponent(component) {
//   // console.log({ isFunctionComponent: component })

//   const
//     validFunction = isFunction(component),
//     createsElement =
//       /(=>|return)[\s\n]*([a-zA-Z0-9_]+\.)*createElement/
//         .test(String(component)),
//     result = !!(validFunction && createsElement)

//   // console.log({ validFunction, createsElement, result })

//   return result
// }

export function isForwardRefComponent(component: any) {
  if (!component)
    return false

  const { $$typeof: type } = component

  if (isSymbol(type))
    return type.description === Symbol('react.forward_ref').description

  return false
}

export function isReactComponent(component: any) {
  // console.log({ isReactComponent: component })

  const
    isReactClass = isClassComponent(component),
    isReactFunc = isFunction(component),
    isReactForwardRefFunc = isForwardRefComponent(component),
    result = !!(isReactClass || isReactFunc || isReactForwardRefFunc)

  // console.log({ isReactClass, isReactFunc, isReactForwardRefFunc, result })

  return result
}

export function isElement(element: any) {
  return isAtomicElement(element) || React.isValidElement(element);
}

export function isAtomicElement(element: any) {
  return (typeof element === 'string' || typeof element === 'number')
}

export function isDOMTypeElement(element: any) {
  return isElement(element) && typeof element.type === 'string';
}

export function isCompositeTypeElement(element: any) {
  return isElement(element) && typeof element.type === 'function';
}

// export function isFuncStr(maybeFuncStr, context = {}) {

//   const vm = require('vm')
//   const VMcontext = vm.createContext({
//     ...context,
//     __isFunction: isFunction
//   }),
//     VMscript = vm.Script(`__isFunction(${maybeFuncStr})`)

//   let result
//   try {
//     result = VMscript.runInContext(VMcontext)
//   } catch (err) {
//     result = false
//   }

//   return result
// }

export function reactDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}

// export function clone(source) {
//   let out // the cloned object
//   if (isArray(source)) {
//     out = [] // create an empty array
//     for (const i in source)
//       out[i] = clone(source[i]) // recursively clone the elements
//   }
//   else if (isPureObject(source)) {
//     out = {} // create an empty object
//     for (const k in source)
//       if (source.hasOwnProperty(k)) // filter out another array's index
//         out[k] = clone(source[k]) // recursively clone the value
//   }
//   else
//     return source
//   return out
// }

/**
 * WARNING: self-refering objects won't work, may crash
 * @param {Boolean} [options.overwrite_undefined = false] If source is undefined, erase target
 * @param {Boolean} [options.immutable_target = true] Whether the target should be mutated.
 * @param {Boolean} [options.verbose = false] Debug logging.
 * 
 * @returns {Object|Array} New, merged, object
 */
export function mergeDeep(target: Indexable, source: Indexable, options = {}) {
  const opts = {
    overwrite_undefined: false,
    immutable_target: true,
    verbose: false,
    ...options,
  }

  let output = opts.immutable_target ? cloneDeep(target) : target

  if ((isPureObject(source) || isArray(source)) && source !== null &&
    (isPureObject(target) || isArray(target)) && target !== null)
    for (const key in source)
      output[key] = mergeDeep(target[key], source[key], opts)

  else if (source !== undefined || opts.overwrite_undefined)
    output = source

  opts.verbose && console.log({ source: source, target: target, output: output })

  return output
}

export function compareDeep(lhs: Indexable, rhs: Indexable, depth = 0, MAX_DEPTH = Infinity) {
  // console.log({ lhs, rhs, depth })

  if (depth >= MAX_DEPTH) {
    // console.log({ lhs, rhs })
    return lhs === rhs
  }

  // automatic acceptance
  if (lhs === rhs) {
    // console.log(lhs, '===', rhs)
    return true
  }

  const tests = [
    isObject, isArray, isPureObject, isFunction, isSyncFunction, isAsyncFunction
  ]

  // automatic rejections
  if (
    typeof lhs !== typeof rhs ||
    tests.some(test => test(lhs) !== test(rhs))
  ) {
    // console.log('By tests', lhs, '!==', rhs)
    return false
  }

  // recursing
  if (typeof lhs === 'object') {
    if (lhs.constructor.name !== rhs.constructor.name) {
      // console.log('By constructor', lhs, '!==', rhs)
      return false
    }

    const
      lhsKeys = Object.keys(lhs),
      rhsKeys = Object.keys(rhs)

    if (!arraysEqual(lhsKeys, rhsKeys, true)) {
      // console.log('By arraysEqual', lhs, '!==', rhs)
      return false
    }

    if (React.isValidElement(lhs) && (lhs.key || rhs.key)) {
      // console.log(lhs.key, '=?=', rhs.key)
      return lhs.key === rhs.key
    }

    if (lhsKeys.some(key =>
      /^[_$]/.test(key) ? false :
        !compareDeep(lhs[key], rhs[key], depth + 1)
    )) {
      // console.log(lhs, '!==', rhs)
      return false
    }

    // passed all object checks -> equal
    // console.log(lhs, '===', rhs)
    return true
  }

  if (isFunction(lhs))
    return (lhs as Function).toString() === rhs.toString()

  // not an object and not strictly equal -> unequal
  // console.log(lhs, '!==', rhs)
  return false
}

export function constructArray<T>(length: number, constructor: (index: number) => T): T[] {
  // if (!length)
  //   return []
  return Array(length).fill(undefined).map((_, i) => constructor(i))
}

/**
 * Creates an array of random numbers
 * @param {boolean} [options.repeat = true] Whether numbers can repeat.
 * @param {boolean} [options.round = false] If to generate integers only.
 */
export function arrayOfRandom(min: number, max: number, length: number, options: Indexable | null = null) {
  const opts = {
    repeat: true,
    round: false,
    ...options
  }
  return constructArray(length, () => {
    const n = min + Math.random() * (max - min)
    if (opts.round)
      return Math.floor(n)
    else
      return n
  })
}

// export function processArray(items: string | any[], process: (arg0: any) => void) {
//   var todo = items.concat();

//   setImmediate(function processLoop() {
//     process(todo.shift());
//     if (todo.length > 0)
//       setImmediate(processLoop);
//   });
// }

export async function awaitAll(promises: Promise<any>[]) {
  for (const promise of promises)
    await promise
}

export function isIterable(obj: any) {
  // checks for null and undefined
  if (obj == null)
    return false

  return typeof obj[Symbol.iterator] === 'function';
}

/**
 * Verifies whether an object is empty.
 * 
 * object -> has no keys
 * string -> has zero length
 * else -> is always empty
 */
export function isEmpty(obj: Indexable) {
  // console.log(obj)
  switch (typeof obj) {
    case 'object':
      // if (obj === null)
      //   return true

      // console.log(obj, 'is object')

      // if (
      //   obj instanceof Set ||
      //   obj instanceof Map
      // )
      //   return obj.entries().length === 0
      if (isIterable(obj))
        return [...(obj as any)].length === 0

      return Object.entries({ ...obj }).length === 0

    case 'string':
      return (obj as string).length === 0

    default:
      return true
  }
}

export function getImgSz(imgUrl: string) {
  return new Promise(resolve => {
    let img = document.createElement('img')
    img.src = imgUrl
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height
      })
  })
}

// const MAX_DEPTH = Infinity

// function DEPRECATED__hash(depth, hasher, data, ...other_data) {
//   if (data === undefined) return

//   const finalHash = (...data) =>
//     _hash(depth + 1,
//       hasher,
//       ...data,
//       _hash(depth + 1,
//         hasher,
//         ...other_data
//       )
//     )

//   if (isObject(data)) {
//     return finalHash(
//       Object.keys(data)
//         .map(key =>
//           (/^[_$]/.test(key) || depth >= MAX_DEPTH) ?
//             key :
//             _hash(depth + 1, hasher, key, data[key])
//         )
//     )
//   }

//   if (isArray(data))
//     return finalHash(
//       ...(depth < MAX_DEPTH ?
//         data :
//         Object.keys(data))
//     )

//   const
//     dataAsStr = isFunction(data) ?
//       data.toString() :
//       JSON.stringify(data)
//   // console.log({ data, dataAsStr })

//   hasher.update(dataAsStr)
// }

type Path = {
  pathname: string,
  hash?: string,
}
type PathString = Path | string

export function pathToStr(path: PathString) {
  // console.log({ path })
  if (typeof (path) === 'string')
    return path

  let pathStr = path.pathname
  if (path.hash)
    pathStr += (path.hash[0] === '#') ? path.hash : `#${path.hash}`

  // console.log({ path, pathStr })
  return pathStr
}

export function strToPath(pathStr: PathString): Path {
  if (isPureObject(pathStr))
    return strToPath(pathToStr(pathStr as Path))

  const
    split = (pathStr as string).split('#'),
    path: Path = {
      pathname: split[0]
    }

  if (split.length)
    path.hash = split.slice(1).join('#')

  return path
}

export function pathCompare(lhs: PathString, rhs: PathString) {
  const
    lhsPath = pathToStr(lhs),
    rhsPath = pathToStr(rhs)

  // console.log({ lhsPath, rhsPath })
  return lhsPath === rhsPath
}

export function hash(...data: any[]) {
  const base26 = parseInt(objectHash(data, {
    algorithm: 'md5',
    encoding: 'hex',
  }), 16)
    .toString(26)
    .replace(/0*$/, '')

  let result = ''

  for (const char of base26)
    result += (Number(parseInt(char, 26)) + 10).toString(36)

  return result
}

export function makeid(length: number) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * Joins an array into a single string
 * @arg {String} [before='"'] String to place before each element
 * @arg {String} [after='"'] String to place after each element
 */
export function joinArrayCZ(array: any[], before = '"', after = '"') {
  let out_str = "";
  array.forEach((f, i, a) => {
    if (i !== 0) {
      if (i === a.length - 1)
        out_str += ' a '
      else
        out_str += ', '
    }
    out_str += `${before}${f}${after}`
  })
  return out_str
}

/**
 * Returns day string with correct czech word form.
 * @returns {String} Day string.
 */
export function daySuffixCZ(num: number) {
  if (typeof (num) !== 'number')
    throw new Error(`"${num}" is not a number, can't add cz day suffix.`)

  let suffix = 'dní'

  if (0 < num && num < 4)
    if (num === 1)
      suffix = 'den'
    else
      suffix = 'dny'

  return `${num} ${suffix}`
}

export function monthSuffixCZ(num: number) {
  let suffix = 'měsíc'

  if (num !== 1) {
    if (0 < num && num < 5)
      suffix += 'e'
    else
      suffix += 'ů'
  }

  return `${num} ${suffix}`
}

export function currencyCZ(value: number) {
  return `${addHundredsGap(Math.ceil(value))} Kč`
}

export function daysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

export function monthsInYear(locale: string) {
  switch (locale) {
    case 'cs':
      return [
        'leden',
        'únor',
        'březen',
        'duben',
        'květen',
        'červen',
        'červenec',
        'srpen',
        'září',
        'říjen',
        'listopad',
        'prosinec'
      ]
    case 'en':
      return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    default: throw Error(`invalid locale: ${locale}`)
  }
}

export function toDate(maybe_date: string | number | Date) {
  if (maybe_date instanceof Date)
    return maybe_date
  else if (typeof maybe_date === 'string')
    return require('chrono-node').parseDate(maybe_date)
  else
    return new Date(maybe_date)
}

export function toNumber(maybe_mumber: any) {
  return parseFloat(String(maybe_mumber).replace(/ /g, ''))
}

export function isNumber(maybe_number: any) {
  return (
    typeof maybe_number === 'number' ||
    typeof maybe_number === 'bigint'
  ) && !isNaN(Number(maybe_number))
}

export function hasScrollbar(element: { scrollHeight: number; clientHeight: number; scrollWidth: number; clientWidth: number; }) {
  // console.log({ element, scrollHeight: element.scrollHeight, clientHeight: element.clientHeight })

  console.log({
    clientHeight: document.documentElement.clientHeight,
    scrollHeight: document.documentElement.scrollHeight,
  })

  setTimeout(() => console.log({
    clientHeight: document.documentElement.clientHeight,
    scrollHeight: document.documentElement.scrollHeight,
  }), 1)

  return {
    vertical: element.scrollHeight > element.clientHeight,
    horizontal: element.scrollWidth > element.clientWidth
  }
}

export function calcDimension(dimension: string) {
  if (typeof dimension === 'string')
    return dimension
  return dimension + 'px'
}

export function getDocHeight() {
  const {
    documentElement: html,
    body
  } = document

  return Math.max(
    body.scrollHeight, html.scrollHeight,
    body.offsetHeight, html.offsetHeight,
    body.clientHeight, html.clientHeight
  );
}

export function clearSelection() {
  if (window === null)
    throw new Error('Window is `null`')
  if (window.getSelection && isFunction(window.getSelection))
    (window.getSelection as Function)().removeAllRanges()
  else if ((document as any).selection)
    (document as any).selection.empty()
}

export function cancellablePromise<T>(
  promise: Promise<T>
): [
    ReturnType<typeof makeCancellable>['promise'],
    ReturnType<typeof makeCancellable>['cancel']
  ] {
  const { promise: originalPromise, cancel } = makeCancellable(promise)
  return [originalPromise, cancel]
}

/**
 * Gets mouse coordinated relative to DOM element.
 * 
 * @param {object} mousePos Mouse screen coordinates.
 * @param {element} element DOM element to be relative to.
 * 
 * @returns {Object} Object containing x and y coordinates relative to element, as well as a percentage object containing percentqage x and y.
 */
export function relativeMousePos(mousePos: { x: number; y: number; }, element: Element) {
  const {
    x: rectPosX,
    y: rectPosY,
    width: rectW,
    height: rectH,
  } = element.getBoundingClientRect(),
    out = {
      x: mousePos.x - rectPosX,
      y: mousePos.y - rectPosY,
    }

  return {
    ...out,
    percentage: {
      x: out.x / rectW,
      y: out.y / rectH,
    }
  }
}

const scrollLock_attr = 'scroll-lock'

export function isScrollEnabled() {
  return !document.documentElement.getAttribute(scrollLock_attr)
}

type AddEventListenerWithScrollOverridde = { isScrollOverridden: boolean } & typeof window.addEventListener
type RemoveEventListenerWithScrollOverridde = { isScrollOverridden: boolean } & typeof window.removeEventListener
type WithIgnoreScrollLock = { ignoreScrollLock: boolean } & AddEventListenerOptions
type WithIsScrollOverridden = { isScrollOverridden?: boolean }

// declare global {
//   interface window {
//     addEventListener: typeof new_addEventListener
//   }
// }

let
  new_addEventListener: Function & WithIsScrollOverridden & {
    overridenScrollFunctions?: Indexable
  },
  new_removeEventListener: Function & WithIsScrollOverridden

if (!(window.addEventListener as AddEventListenerWithScrollOverridde).isScrollOverridden) {

  const
    old_addEventListener = window.addEventListener

  new_addEventListener = (
    event: Arg1<typeof old_addEventListener>,
    callback: Arg2<typeof old_addEventListener>,
    options_or_useCapture?: boolean | WithIgnoreScrollLock
  ) => {
    const ignoreScrollLock =
      isObject(options_or_useCapture) && (options_or_useCapture as WithIgnoreScrollLock).ignoreScrollLock

    //-- Override if scroll
    if (event === 'scroll') {
      const overriddenCallback = (...args: any[]) => {
        if (!ignoreScrollLock && !isScrollEnabled()) return

        if (isFunction(callback))
          return (callback as Function)(...args)
      }

      // console.log('adding overridden scroll listener', callback)
      if (!new_addEventListener.overridenScrollFunctions)
        throw new Error(`\`overridenScrollFunctions\` is undefined`);

      new_addEventListener.overridenScrollFunctions[callback as any] = overriddenCallback
      return old_addEventListener(event, overriddenCallback, options_or_useCapture)
    }

    //-- Don't override if not scroll
    return old_addEventListener(event, callback, options_or_useCapture)
  }

  //-- Mark addEventListener as overridden
  new_addEventListener.isScrollOverridden = true
  new_addEventListener.overridenScrollFunctions = {};
  (window.addEventListener as typeof new_addEventListener) = new_addEventListener
}

if (!(window.removeEventListener as RemoveEventListenerWithScrollOverridde).isScrollOverridden) {
  const old_removeEventListener = window.removeEventListener
  new_removeEventListener = (
    event: Arg1<typeof old_removeEventListener>,
    callback: Arg2<typeof old_removeEventListener>,
    ...args: any[]
  ) => {

    if (!(window.addEventListener as typeof new_addEventListener).overridenScrollFunctions)
      throw new Error('`overridenScrollFuncs` on `window.addEventListener` is `undefined`')

    //-- Override if event scroll and addEventListener has overridden the scroll function
    if (event === 'scroll' &&
      (window.addEventListener as typeof new_addEventListener).isScrollOverridden &&
      (window.addEventListener as typeof new_addEventListener).overridenScrollFunctions![callback as any]) {

      const overriddenCallback =
        (window.addEventListener as typeof new_addEventListener)
          .overridenScrollFunctions![callback as any]
      delete (window.addEventListener as typeof new_addEventListener)
        .overridenScrollFunctions![callback as any]

      // console.log('removing overridden scroll listener', callback)
      return old_removeEventListener(event, overriddenCallback, ...args)
    }

    //-- Don't override if not scroll
    return old_removeEventListener(event, callback, ...args)
  }

  //-- Mark removeEventListener as overridden
  new_removeEventListener.isScrollOverridden = true;
  (window.removeEventListener as typeof new_removeEventListener) = new_removeEventListener
}

class ScrollBlocker {
  pageScroll = window.pageYOffset

  blockScroll = (event: { preventDefault: () => void; }) => {
    event.preventDefault()
    // console.log(this)
    const oldScrollBehavior = document.documentElement.style.scrollBehavior
    document.documentElement.style.scrollBehavior = 'auto'
    window.scroll({
      top: this.pageScroll,
      behavior: 'auto',
    })
    document.documentElement.style.scrollBehavior = oldScrollBehavior
  }
}
let scrollBlocker: ScrollBlocker | null = null

export function disableBodyScroll() {
  const {
    documentElement: html,
  } = document

  const scrollLocks = Number(html.getAttribute(scrollLock_attr))
  if (!scrollLocks) {
    // console.log('disabling body scroll')
    scrollBlocker = new ScrollBlocker();
    (window.addEventListener as typeof new_addEventListener)('scroll', scrollBlocker.blockScroll, { ignoreScrollLock: true })
  }

  html.setAttribute(scrollLock_attr, String(scrollLocks + 1))
}

export function enableBodyScroll() {

  const {
    documentElement: html
  } = document,
    scrollLocks = Number(html.getAttribute(scrollLock_attr)),
    newScrollLocks = Math.max(0, scrollLocks - 1)

  if (!newScrollLocks) {
    html.removeAttribute(scrollLock_attr)

    if (scrollBlocker instanceof ScrollBlocker) {
      window.removeEventListener('scroll', scrollBlocker.blockScroll)
      scrollBlocker = null
    }
  }
  else
    html.setAttribute(scrollLock_attr, String(newScrollLocks))
}

export function scrollTo(offset: number, callback: () => unknown, scrollBehavior?: ScrollBehavior | undefined) {
  const onScroll = function () {
    if (window.pageYOffset === offset) {
      window.removeEventListener('scroll', onScroll)
      callback()
    }
  }
  window.addEventListener('scroll', onScroll)
  onScroll()
  window.scrollTo({
    top: offset,
    behavior: scrollBehavior
  })
}

// // left: 37, up: 38, right: 39, down: 40,
// // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
// var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

// export function preventDefault(e) {
//   e = e || window.event;
//   if (e.preventDefault)
//     e.preventDefault();
//   e.returnValue = false;
// }

// export function preventDefaultForScrollKeys(e) {
//   if (keys[e.keyCode]) {
//     preventDefault(e);
//     return false;
//   }
// }

// export function disableScroll() {
//   if (window.addEventListener) // older FF
//     window.addEventListener('DOMMouseScroll', preventDefault, false);
//   document.addEventListener('wheel', preventDefault, { passive: false }); // Disable scrolling in Chrome
//   // window.onwheel = preventDefault; // modern standard
//   // window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
//   window.ontouchmove = preventDefault; // mobile
//   document.onkeydown = preventDefaultForScrollKeys;
// }

// export function enableScroll() {
//   if (window.removeEventListener)
//     window.removeEventListener('DOMMouseScroll', preventDefault, false);
//   document.removeEventListener('wheel', preventDefault, { passive: false }); // Enable scrolling in Chrome
//   window.onmousewheel = document.onmousewheel = null;
//   window.onwheel = null;
//   window.ontouchmove = null;
//   document.onkeydown = null;
// }

// export function strToRegExp(str: string) {
//   // console.log(str)
//   // console.log(str.substring(1, str.length - 1).raw())

//   if (/^\/.*\/$/.test(str))
//     return RegExp(str.substring(1, str.length - 1).replace(/[^\\]\\[^\\]/, '//'))
// }

// export function sassThemeToJSON(sass: string) {
//   if (typeof sass != 'string') {
//     console.error('Invalid SASS arg:', sass)
//     throw Error(`SASS arg isn't a string, more in console`)
//   }

//   let out = {}

//   sass.split('\n').forEach(line => {
//     if (!/^\s*\$[a-zA-Z0-9\-_]+:.*;$/.test(line)) {
//       if (!/^\s*$/.test(line) && !/^\s*\/\//.test(line))
//         console.error('not a valid SASS variabel declaration:', line)
//     }
//     else {
//       const a = line.split(':')
//       const
//         key = a[0].trim().substr(1),
//         value = a.slice(1).join(':').trim()

//       out[key] = value.substr(0, value.length - 1) //trim simicolon
//     }
//   })

//   // console.log(out)
//   return out
// }

// const syntheticRender_cache = {}
export function syntheticRender(
  RenderMe: any = null,
  passProps: Indexable | null = null
) {

  // //-- Assign key
  // if (RenderMe && !(
  //   //-- RenderMe contains key
  //   RenderMe.key ||
  //   //-- PassProp contains key
  //   (passProps && passProps.key)
  // )) {
  //   if (!(RenderMe in syntheticRender_cache))
  //     syntheticRender_cache[RenderMe] = hash(uuid())
  //   passProps = { ...passProps, key: syntheticRender_cache[RenderMe] }
  // }

  if (isReactComponent(RenderMe) && !isElement(RenderMe)) {
    // console.log('rendering', RenderMe, 'as unrendered component')
    return <RenderMe {...passProps} />
  }

  if (isCompositeTypeElement(RenderMe)) {
    // console.log('rendering', RenderMe, 'as post-render component')

    return {
      ...RenderMe, props: {
        ...RenderMe.props,
        ...passProps
      }
    }
  }

  if (isElement(RenderMe) || RenderMe === null) {
    // console.log('rendering', RenderMe, 'as element')
    return RenderMe
  }

  console.error(RenderMe, 'is not renderable')
  return null
}

/**
 * Creates a setState function which acts on a key nested within an actual state object.
 * @param {string} [options.callbackKey = null] State key to set to callback function for functional components.
 * @param {object} [options.props = undefined] Props to pass to functional setState.
 * @param {boolean} [options.verbose = false] Debug logging.
 * 
 * @returns {function} Synthesised setState function.
 */
export function nestedSetState(
  key: string,
  getState: { (): any; (): any; (): any; },
  setState: {
    (value: any): void; //-- newstate
    (newState: any, callback: any): any; //-- callback
  },
  options: Indexable | null = null) {
  const opts = {
    callbackKey: null,
    props: undefined,
    verbose: false,
    ...options
  }

  // opts.verbose && console.log('Generated synthetic setState for key', key)

  return (newState: (arg0: any, arg1: undefined) => any, callback: any) => {
    const
      state = getState(),
      mergedState = {
        ...(state[key] || {}),
        ...(isFunction(newState) ?
          newState(state[key], opts.props) :
          newState)
      }

    opts.verbose && console.log(`setState[${key}](`, isFunction(newState) ? '<func>' : newState, ')', { newState: mergedState, oldState: state[key], callback })

    if (isFunction(callback)) {
      if (opts.callbackKey === null)
        setState({ [key]: mergedState }, callback)
      else
        setState({ [key]: mergedState, [opts.callbackKey!]: callback })
    }
    else
      setState({ [key]: mergedState })
  }
}

export function RenderAfterDelay(props: { children: any; delay?: number; }) {
  const
    [shouldRender, setRender]: [boolean | 'timeout', Function] = React.useState(false),
    renderTimeout = React.useRef(undefined as ReturnType<typeof setTimeout> | undefined)

  React.useEffect(() =>
    () => {
      if (renderTimeout.current)
        clearTimeout(renderTimeout.current)
    },
    [])

  if (shouldRender === false) {
    renderTimeout.current = setTimeout(() => {
      setRender(true)
      renderTimeout.current = undefined
    },
      props.delay || 1
    )
    setRender('timeout')
  }
  else if (shouldRender === true) {
    setRender(false)
    return props.children
  }

  return null
}

/**
 * Converts an object to a string representation.
 * @param {Object} object Object to serialise.
 */
export function serialise(object: any) {
  return serializeJavascript(object, { unsafe: true })
}

export function deserialise(dataAsString: string | null) {
  // console.log('deserialising:', { dataAsString })
  // eslint-disable-next-line no-eval
  const parsedValue = eval(`(${dataAsString})`)

  if (typeof parsedValue === 'object')
    return parsedValue

  console.error({ parsedValue })
  throw new Error('Item deserialised to non-object value')
}

interface FunctionWithProperties extends Indexable, Function { }
export function makeCallable(object: Indexable, func: FunctionWithProperties) {
  for (var prop in object)
    if (object.hasOwnProperty(prop))
      func[prop] = object[prop]

  return object
}

// function makeCancelable(promise) {
//   let isCanceled = false;
//   const wrappedPromise =
//     new Promise((resolve, reject) => {
//       promise
//         .then(
//           val => (isCanceled
//             ? reject(new Error({ isCanceled }))
//             : resolve(val))
//         )
//         .catch(
//           error => (isCanceled
//             ? reject(new Error({ isCanceled }))
//             : reject(error))
//         );
//     });
//   return {
//     promise: wrappedPromise,
//     cancel() {
//       isCanceled = true;
//     },
//   };
// }

// export function useCancellablePromise() {
//   // think of useRef as member variables inside a hook
//   // you cannot define promises here as an array because
//   // they will get initialized at every render refresh
//   const promises = useRef();
//   // useEffect initializes the promises array
//   // and cleans up by calling cancel on every stored
//   // promise.
//   // Empty array as input to useEffect ensures that the hook is
//   // called once during mount and the cancel() function called
//   // once during unmount
//   useEffect(
//     () => {
//       promises.current = promises.current || [];
//       return function cancel() {
//         promises.current.forEach(p => p.cancel());
//         promises.current = [];
//       };
//     }, []
//   );

//   // cancelablePromise remembers the promises that you
//   // have called so far. It returns a wrapped cancelable
//   // promise
//   function cancellablePromise(p) {
//     const cPromise = makeCancelable(p);
//     promises.current.push(cPromise);
//     return cPromise.promise;
//   }
//   return { cancellablePromise };
// }

// /**
//  * 
//  * @param {function, string} _toExecute Function to run. Can be an actual function, or a string representation of one.
//  * @param {object} _context Environment variables to set in the function's sandbox.
//  * @param {...any} _args Arguments to pass onto the executed function.
//  */
// export function execAsPureFunc(_toExecute, context = {}) {
//   // const secret = '__' + hash(Math.random().toString())

//   let code
//   if (isFunction(_toExecute))
//     code = _toExecute.toString()
//   else if (isFuncStr(_toExecute, context))
//     code = _toExecute
//   else
//     code = `function() {${_toExecute}}`

//   // code = `async ${code}`

//   // const { VM } = 
//   // const vm = new require('vm2').VM({ sandbox: context })

//   const args = Array.prototype.slice.call(arguments, 2)
//     .map(arg => {
//       if (isObject(arg))
//         return JSON.stringify(arg)
//       if (isFunction(arg))
//         return arg.toString()
//       if (typeof arg === 'string')
//         return `"${arg}"`
//       return arg
//     }).join(',')

//   const vm = require('vm')
//   const
//     VMscript = new vm.Script(`(${code})(${args})`),
//     VMcontext = new vm.createContext(context)

//   // console.log(VMscript.code)

//   return VMscript.runInContext(VMcontext)
// }

// === POLLYFILLS ===

//-- Strings
declare global {
  interface String {
    splice(start: number, delCount: number, newSubStr: string): string;
    toClass(): string;
  }
}

if (!String.prototype.splice) {
  /**
   * The splice() method changes the content of a string by removing a range of
   * characters and/or adding new characters.
   */
  String.prototype.splice = function (start, delCount, newSubStr) {
    return this.slice(0, start) + newSubStr + this.slice(start + Math.abs(delCount));
  };
}

if (!String.prototype.toClass) {
  String.prototype.toClass = function () {
    return this.toString()
  }
}

//-- Arrays
declare global {
  interface Array<T> {
    toClass(this: T[]): string,
    pureJoin(
      this: T[],
      delimiter?: Arg1<typeof Array.prototype.join>,
      mapper?: Arg1<typeof Array.prototype.map>
    ): string,
    purge(this: T[]): T[],
    extract(this: T[], discriminator: (item: T, index: number) => boolean): T[],
  }
}

if (!Array.prototype.purge) {
  Array.prototype.purge = function () {
    return this.filter(_ => _)
  }
}

if (!Array.prototype.pureJoin) {
  const pureJoin: typeof Array.prototype.pureJoin =
    function (delimiter, mapper) {
      const
        purged = this.purge(),
        mapped = mapper ? purged.map(mapper) : purged

      return mapped.join(delimiter)
    }
  Array.prototype.pureJoin = pureJoin
}

if (!Array.prototype.toClass) {
  Array.prototype.toClass = function () {
    return this.pureJoin(' ', toClass)
  }
}

if (!Array.prototype.extract) {
  Array.prototype.extract = function (discriminator) {
    return arrayExtract(this, discriminator)
  }
}
