import {
  oneOf,
  any
} from 'prop-types'
import boons from 'pages/Offers/boons'

export default {
  params: any.isRequired,
  source: oneOf([
    'productProvider',
    'productGroup',
  ]).isRequired,
  type: oneOf(boons.map(boon => boon.type)).isRequired,
  label: oneOf(boons.map(boon => boon.label)).isRequired,
  description: oneOf(boons.map(boon => boon.description)).isRequired,
}