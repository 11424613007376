import React from 'react';

import { useLocation, useHistory } from 'react-router-dom'
import FitText from 'react-fittext'
import './style.scss'
import AnimatedUnderline from 'components/AnimatedUnderline';
import withLSPageAPI from 'HOCs/withLSPageAPI';
import {
  FaCopy as CopyIcon,
  FaTimes as CloseIcon,
  FaSadTear as SadIcon,
} from 'react-icons/fa';
import { clearSelection } from 'scripts/helpers'
import NotificationSystem from 'react-notification-system'
import withResponsivity from 'HOCs/withResponsivity';
import useTitle from 'hooks/useTitle';

function Error(props) {
  const
    history = useHistory(),
    location = useLocation(),
    Title = useTitle(),
    errorArea = React.useRef(),
    notifications = React.createRef()

  // console.log('rendering error', location)
  if (!location.state || !location.state.error) {
    // console.error('location error not set on error page', location)
    history.replace('/')
    return null
  }

  // console.error(location.from, location.error.stack)

  return <>
    <Title>Chyba</Title>
    <div className={['Component-Error', props.className].filter(_ => _).join(' ')}>
      {props.isLessThan('medium') ? null :
        <FitText compressor={.1}>
          <div className='section left-section sad-icon-wrapper'>
            {/* <SadIcon className='sad-icon' /> */}
            {/* <span role='img' aria-label='sad face'>
            😿
          </span> */}
            :&nbsp;(
          </div>
        </FitText>}
      <div className='section right-section'>
        <div className='title-wrapper'>
          Něco se pokazilo!
          {props.isLessThan('medium') ? <SadIcon className='sad-icon' /> : null}
        </div>
        <div className='text-wrapper'>
          Pokud potřebujete vyřídit požadavek, neváhejte nás&nbsp;
          <AnimatedUnderline className={'contact-button' + (props.pageAPI ? ' active' : ' disabled')} onClick={() =>
            props.pageAPI.setActionMenuActive(true)
          }>
            kontaktovat
          </AnimatedUnderline>
          .
        </div>
        <div className='error-readout-wrapper'>
          <div className='error-readout'>
            <div className='error-readout-title'>
              {location.state.from}
              <div className='buttons-wrapper'>
                <CopyIcon className='copy-icon' onClick={({ target }) => {
                  errorArea.current.select()
                  document.execCommand('copy')
                  clearSelection()
                  target.focus()
                  notifications.current.addNotification({
                    message: 'Chybový záznam zkopítován',
                    level: 'info',
                    position: 'bc',
                  })
                }} />
                <CloseIcon className='close-icon' onClick={() => history.replace('/')} />
              </div>
            </div>
            <textarea readOnly ref={errorArea} className='error-readout-content'
              value={location.state.error} />
          </div>
        </div>
      </div>
    </div>
    <NotificationSystem ref={notifications} />
  </>
}

export default withResponsivity(withLSPageAPI(Error, true))

