import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Multifield from '../Multifield'
import { FaChevronDown as DropdownIcon } from 'react-icons/fa'

import './style.scss'

function InputWrapper(props) {

  const
    [opened, setOpened] = useState(false),
    classes = props.options.wrap_type +
      (opened ? ' opened ' : ' ') +
      'Component-InputWrapper'

  // console.log({ props })

  return <div className={classes}>
    {props.options.wrap_type === 'unwrap' ? <>
      <div className='dropdown form-row'
        onClick={() => setOpened(!opened)}>
        {props.placeholder || ''}
        <DropdownIcon className='dropdown-icon' />
      </div>
      {opened ?
        <Multifield {...props} />
        : null}
    </> :
      <Multifield {...props} />
    }
  </div>
}

InputWrapper.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.shape({
    wrap_type: PropTypes.oneOf([
      'horizontal',
      'inline',
      'vertical',
      'unwrap',
      'none'
    ])
  })
}
InputWrapper.defaultProps = {
  options: { wrap_type: 'none' }
}

export default InputWrapper