import React from 'react';
import {
  FaTimes as CloseIconIcon
} from 'react-icons/fa'
import './style.scss';

function CloseIcon(props) {
  const {
    className,
    ...passProps
  } = props

  return <CloseIconIcon
    className={['CloseIcon-Component', className].toClass()}
    {...passProps} />
}

export default CloseIcon