import React from 'react'

import PropTypes from 'prop-types'

import { syntheticRender } from 'scripts/helpers'
// import {
//   FaTimes as CloseIcon
// } from 'react-icons/fa'
// import Modal from 'react-modal'
import ModalBox from 'components/ModalBox'

import './style.scss'

import info from 'types/offer_info'
import SCButton from 'components/SCButton'
// import getPageAPI from 'scripts/LS_PageAPI'

function OfferInfoModal(props) {

  return <ModalBox isOpen={props.open} close={props.onClose} className='Component-OfferInfoModal'>
    {/* <div className='heading'>Informace o produktu</div> */}
    {syntheticRender(props.info)}
    <SCButton liveSupp className='interest-buttons'>
      Chci vědět víc
    </SCButton>
  </ModalBox>
}

OfferInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMoreInfo: PropTypes.func,
  info: info,
}
OfferInfoModal.defaultProps = {
  contents: [],
  onMoreInfo: () => { },
}

export default OfferInfoModal