import React from 'react';
import { syntheticRender, isFunction, nestedSetState } from 'scripts/helpers';
import { Route } from 'react-router-dom'
import NestedSwitch from 'components/NestedSwitch'
import uuid from 'uuid'
import { hash } from 'scripts/helpers'
const
  key_seed = uuid(),
  landingPages = [
    'digitv',
    'energielp',
    'energie',
  ].reduce((pages, page_name) => ({
    ...pages,
    [page_name]: require(`./${page_name}`)
  }),
    {})

function LandingPage(props) {
  const [state, setState] = React.useState(
    JSON.parse(localStorage.getItem('landingPageState')) ||
    {}
  )

  React.useEffect(() => {
    if (isFunction(state[key_seed])) {
      state[key_seed](state[props.page])
      delete state[key_seed]
    }
    localStorage.setItem('landingPageState', JSON.stringify(state))
  })

  return <NestedSwitch basepath='/landing-page'>
    {Object.keys(landingPages).map((page_name, key_itterator) => {

      const page = landingPages[page_name]
      // console.log({ page })

      return <Route exact
        key={hash(key_seed, key_itterator)}
        path={`/${page_name}`}>
        {syntheticRender(page.default, {
          state: Object.freeze({
            ...(page.initialState || {}),
            ...state[page_name],
          }),
          setState: nestedSetState(
            page_name,
            () => state, setState,
            { callbackKey: key_seed, })
        })}
      </Route>
    })}
  </NestedSwitch>
}

export default LandingPage