import React from 'react'

import Offer from '..'
import OfferLoading from '../Loading'
import createOffer from 'pages/Offers/createOffer';
import ProductOrigin from 'pages/Offers/ProductOrigin';
import Indexable from 'types/Indexable';
import Unpromise from 'types/Unpromise';
import { cancellablePromise } from 'scripts/helpers';

function StaticOffer(props: {
  origin: ProductOrigin,
  product: Indexable,
  compact: boolean,
}) {

  type offerType = Unpromise<ReturnType<typeof createOffer>>
  type offerState = offerType | null | undefined

  const {
    origin,
    product,
    ...passProps
  } = props,
    [offer, setOffer] = React.useState(null as offerState),
    loading = offer === null

  React.useEffect(() => {
    // console.log({ origin, product })
    const [promise, cancel] = cancellablePromise(createOffer(origin, product))
    promise.then(setOffer)
    return cancel
  }, [origin, product])

  if (loading)
    return <OfferLoading
      index={Math.floor(Math.random() * 10)}
      {...passProps}
    />

  return <Offer offer={offer as any} {...passProps} />;
}

export default StaticOffer