import SCH_API from "./SCH_API"
// import ProductType from 'types/ProductType'

type VATs_type = {
  electricity: number,
  gas: number,
  mobile: number,
  internet: number,
  tv: number,
  insurance: number,
  loan: number,
  account: number,
}

let VATs: VATs_type | Promise<VATs_type> | null = null

// const possible_products = [
//   'electricity',
//   'gas',
//   'mobile',
//   'internet',
//   'tv',
//   'insurance',
//   'loan',
//   'account'
// ]

/**
 * Returns the Value Added Tax for the supplied product
 * @param {string} type Product type to get VAT for
 * 
 * @returns {number}
 */
export default async function () {
  // console.log('getting VATs for', { product_type })

  // if (!possible_products.includes(type)) {
  //   // console.error({ product_type }, 'not contained in', { possible_products })
  //   throw new Error(`${type} in not a valid product type`)
  // }

  // console.log()
  if (VATs === null) {
    // console.log('VATs not yet fetched, getting')
    VATs = SCH_API('/tax/vat')
      .then(({ data }) => VATs = data as VATs_type)
  }

  return Promise.resolve(VATs)
}