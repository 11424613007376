import React from 'react';

import PropTypes from 'prop-types';
import { FaQuestionCircle as HelpIcon } from 'react-icons/fa';
import './style.scss'
import Tooltip from 'components/Tooltip'

function HelpTooltip(props) {
  const {
    className,
  } = props

  return <Tooltip className={['HelpTooltip-Component', className]} {...props}>
    <HelpIcon className='help-icon' />
  </Tooltip>
}

HelpTooltip.propTypes = {
  text: PropTypes.node
}

export default HelpTooltip