import UtilityProduct, { BriefProduct } from '../Product.d.ts'
import { BriefProduct as BriefProductBase } from 'types/Product'

export interface BriefProduct extends BriefProductBase {
  costs: {
    costPerMWh: number,
    fixedCostPerMonth: number,
    costPerM3: number,
  }
}

type usageCostPerUnit = {
  distribution: number,
  product: number,
  tax: number,
}

export interface FullProduct extends UtilityProduct {
  costs: {
    totalCostPerMonth: number,
    fixedCostPerMonth: number,
    usageCostPerMonth: number,
  },
  costCalculation: {
    fixedCostPerMonth: {
      baseProduct: number,
      distribution: number,
    },
    usageCostPerMWh: usageCostPerUnit,
    usageCostPerM3: usageCostPerUnit,
  }
}

type Product = FullProduct | BriefProduct

export default Product