import React, { ReactNode, CSSProperties } from 'react'
import './style.scss';
import useCombinedRefs from 'hooks/useCombinedRefs';
import { isNumber } from 'util';

interface dimensions {
  width: number,
  height: number,
}

const ResizeObserver = React.forwardRef((props: {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  handleHeight?: boolean;
  handleWidth?: boolean;
  onResize: (dimensions: dimensions) => any;
  tolerance?: number;
  frequency?: number;
  verbose?: boolean;
}, ref: React.Ref<HTMLElement>) => {
  const {
    className,
    children,
    handleHeight,
    handleWidth,
    onResize,
    tolerance,
    frequency,
    verbose,
    ...passProps
  } = {
    handleHeight: false,
    handleWidth: false,
    tolerance: 0,
    frequency: 2,
    ...props
  },
    // [contentHeight, setContentHeight] = React.useState(null),
    contentHeight_ref = React.useRef<number>(),
    contentWidth_ref = React.useRef<number>(),
    self_ref = React.useRef<HTMLElement>(),
    all_refs = useCombinedRefs(self_ref, ref),
    measureContent = React.useCallback(
      function <T>(
        self: HTMLElement,
        callback: () => T | void = () => { }
      ): T | void {

        const
          dimensions: dimensions = {
            height: self.scrollHeight,
            width: self.scrollWidth,
          },
          { current: contentHeight } = contentHeight_ref,
          { current: contentWidth } = contentWidth_ref

        let changed = false
        if (handleHeight &&
          (!isNumber(contentHeight) || Math.abs(dimensions.height - contentHeight) > tolerance)) {
          changed = true
          verbose && console.log('ResizeObserver', { oldHeight: contentHeight, newHeight: dimensions.height })
          contentHeight_ref.current = dimensions.height
        }
        if (handleWidth &&
          (!isNumber(contentWidth) || Math.abs(dimensions.width - contentWidth) > tolerance)) {
          changed = true
          verbose && console.log('ResizeObserver', { oldWidth: contentWidth, newWidth: dimensions.width })
          contentWidth_ref.current = dimensions.width
        }

        if (changed) {
          verbose && console.log('ResizeObserver new', { dimensions })
          onResize(dimensions)
        }

        return callback()
      }, [handleHeight, handleWidth, onResize, tolerance, verbose])

  React.useEffect(() => {
    const { current: self } = self_ref

    if (self === undefined) {
      console.error('ResizeObserver failed to bind reference to self', { self })
      return
    }

    // //-- Timeout based size check
    // var
    //   nextFrameTimeout: ReturnType<typeof setTimeout>,
    //   animationRequest: ReturnType<typeof requestAnimationFrame>,
    //   last_time = 0

    // const measure_content = (time: number) => {

    //   measureContent(self, () => {
    //     cancelAnimationFrame(animationRequest)

    //     const delay_next_check = 1000 / frequency - time + last_time
    //     last_time = time

    //     nextFrameTimeout = setTimeout(() => {
    //       animationRequest = requestAnimationFrame(measure_content)
    //     }, Math.max(delay_next_check, 0))
    //   })
    // }

    // animationRequest = requestAnimationFrame(measure_content)

    //-- Mutation observer based size check
    const observer = new MutationObserver(() => { measureContent(self) })
    observer.observe(self, {
      attributes: true,
      childList: true,
      subtree: true,
    })

    //-- Call initial measure
    measureContent(self)

    return () => {
      // cancelAnimationFrame(animationRequest)
      // clearTimeout(nextFrameTimeout)
      observer.disconnect()
    }
  }, [frequency, handleHeight, handleWidth, measureContent, onResize, tolerance, verbose])

  return <div className={['ResizeObserver-Component', className].toClass()}
    {...passProps} ref={all_refs}>
    {children}
  </div>
})

export default ResizeObserver