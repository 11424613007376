import React from 'react'
import PropTypes from 'prop-types'
import url from 'url-prop-type'
import Logo from 'components/Logo'
import styled from 'styled-components'
import Colour from 'color'
import {
  FaTimes as CloseIcon,
  FaComments as ChatIcon,
  FaPhone as VoiceIcon,
  FaVideo as VideoIcon,
  FaUndoAlt as OfflineIcon,
} from 'react-icons/fa'
import {
  hash,
  reactDev,
} from 'scripts/helpers'
import uuid from 'uuid'
import './style.scss'
import JumpingEllipsis from 'components/JumpingEllipsis'

const key_seed = uuid()

function AZbanner(props) {
  const
    resolve = (e, action) => {
      // document.pushAPI.resolvePush(action)
      // e.stopPropagation()
    },
    can = action => props.actions[action],//'pushAPI' in document && document.pushAPI.props.displayedActions.includes(action),
    buttons = [
      {
        type: 'offline',
        Icon: OfflineIcon,
        action: e => document.pushAPI.resolvePush('offline', e),
      },
      {
        type: 'chat',
        Icon: ChatIcon,
      },
      {
        type: 'voice',
        Icon: VoiceIcon,
      },
      {
        type: 'video',
        Icon: VideoIcon,
      },
    ]

  const notext = <JumpingEllipsis style={{ fontSize: '200%', letterSpacing: '.2em' }} />
  return <div className={`${props.className} Component-AZbanner${reactDev() ? ' dev' : ''}`} >
    <section className='bannerBody'
      style={props.bgUrl === undefined ?
        {
          background: 'white repeat url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABg2lDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9bpSoVBzOIdMhQnSyIijhqFYpQIdQKrTqYXPoFTRqSFBdHwbXg4Mdi1cHFWVcHV0EQ/ABxcXVSdJES/5cUWsR4cNyPd/ced++AYKPCNKtrHNB020wnE2I2tyqGXxFBFAJ6EZeZZcxJUgq+4+seAb7exXmW/7k/R7+atxgQEIlnmWHaxBvE05u2wXmfWGAlWSU+Jx4z6YLEj1xXPH7jXHQ5yDMFM5OeJxaIxWIHKx3MSqZGPEUcUzWd8oNZj1XOW5y1So217slfGMnrK8tcpxlFEotYggQRCmooowIbcVp1UiykaT/h4x92/RK5FHKVwcixgCo0yK4f/A9+d2sVJie8pEgC6H5xnI8RILwLNOuO833sOM0TIPQMXOltf7UBzHySXm9rsSNgYBu4uG5ryh5wuQMMPRmyKbtSiGawUADez+ibcsDgLdC35vXW2sfpA5ChrlI3wMEhMFqk7HWfd/d09vbvmVZ/P4Sscq5EnPKeAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH4wwcFAsKmzX90wAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAtSURBVDjLY2RgYGhgwAOKZq3CK8/EQCEYNWAwGMBYNGsVXgV9aWGj6WD4GwAA08MGVddobuUAAAAASUVORK5CYII=")',
        } :
        {
          backgroundImage: `url(${props.bgUrl})`
        }}
    >
      <div className='background' />
      <header>
        <div className='logo-wrapper'>
          <Logo />
        </div>
        <div className='closeIcon-wrapper'>
          {can('deny') ?
            <CloseIcon className='closeIcon' onClick={e => resolve(e, 'deny')} />
            : null}
        </div>
      </header>
      <main>
        <div className='text-container'>
          {props.texts.map((text, key_iterator) =>
            <div className='text-wrapper' key={hash(key_seed, key_iterator)}
              style={{
                ...Object.keys(text)
                  .filter(propName => typeof (text[propName]) === 'number')
                  .reduce((all, propName) => ({
                    ...all,
                    [propName]: text[propName] + 'px'
                  }),
                    {}),
                textAlign: text.alignment,
              }}
            >
              {text.content || notext}
            </div>
          )}
        </div>
      </main>
      <footer>
        <div className='button-container'>
          {buttons.filter(({ type }) => can(type)).map(({ type, Icon }, key_iterator) =>
            <div className='button-wrapper' key={hash(key_seed, key_iterator)}>
              <Icon
                className={`comm-button ${type}`}
                onClick={e => resolve(e, type)}
              />
            </div>
          )}
        </div>
        {can('deny') ?
          <div className='dismiss-wrapper'>
            <span className='dismiss' onClick={e => resolve(e, 'deny')}>
              Zavřít
          </span>
          </div> : null}
      </footer>
    </section>
  </div>;
}

AZbanner.propTypes = {
  bgUrl: url,
  colour: PropTypes.exact({
    r: PropTypes.number.isRequired,
    g: PropTypes.number.isRequired,
    b: PropTypes.number.isRequired,
  }).isRequired,
  bgOpacity: PropTypes.number.isRequired,
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      marginTop: PropTypes.number,
      marginBot: PropTypes.number,
      content: PropTypes.string,
      size: PropTypes.number,
      alignment: PropTypes.string.isRequired,
    })
  ).isRequired,
  actions: PropTypes.objectOf(PropTypes.bool).isRequired
}
AZbanner.defaultProps = {
  colour: 'transparent',
}

export default styled(AZbanner)`
  .bannerBody {
    .background {
      background-color: ${({ colour, bgOpacity }) => Colour(colour).fade(1 - bgOpacity).string()};
    }

    header {
      .logo-wrapper, .closeIcon {
        color: ${({ colour }) => Colour(colour).string()};
      }
    }
  }
`