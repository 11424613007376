import {
  validateICO,
} from 'scripts/helpers'
import address from './address'
import person from './person'

// console.log({ address })

const formFields = [

  //-- Household / Business
  {
    name: 'type',
    type: 'switch',
    options: {},
    data: [
      {
        label: 'Domácnost',
        value: 'household'
      },
      {
        label: 'Podnikatel',
        value: 'business'
      }
    ]
  },

  //-- Household person
  {
    type: 'wrapper',
    options: {
      wrap_type: 'none',
      mandatory: true,
      conditional: [
        [
          {
            key: 'type',
            value: 'household'
          }
        ]
      ]
    },
    data: person.layout
  },

  //-- Company basic info
  {
    name: 'company-name',
    type: 'text',
    options: {
      placeholder: 'Název firmy',
      mandatory: true,
      conditional: [
        [
          {
            key: 'type',
            value: 'business'
          }
        ]
      ]
    }
  },
  {
    name: 'company-ico',
    type: 'text',
    options: {
      placeholder: 'IČO',
      // mandatory: (...args) => {
      //   console.log(...args)
      //   return validateICO(...args)
      // },
      mandatory: ico => validateICO(ico),
      conditional: [
        [
          {
            key: 'type',
            value: 'business'
          }
        ]
      ]
    }
  },

  //-- Household address
  {
    name: 'address-delimeter',
    label: 'Adresa trvalého bydliště',
    type: 'delimeter',
    options: {
      conditional: [
        [
          {
            key: 'type',
            value: 'household'
          }
        ]
      ]
    }
  },
  {
    type: 'wrapper',
    options: {
      wrap_type: 'none',
      mandatory: true,
      conditional: [
        [
          {
            key: 'type',
            value: 'household'
          }
        ]
      ]
    },
    data: address.layout
  },

  //-- Company address
  {
    name: 'address-delimeter',
    label: 'Adresa sídla firmy',
    type: 'delimeter',
    options: {
      conditional: [
        [
          {
            key: 'type',
            value: 'business'
          }
        ],
      ]
    }
  },
  {
    type: 'wrapper',
    options: {
      wrap_type: 'none',
      mandatory: true,
      conditional: [
        [
          {
            key: 'type',
            value: 'business'
          }
        ]
      ]
    },
    data: address.layout
  },

  //-- Company insider
  {
    name: '',
    label: 'Kontaktní osoba',
    type: 'delimeter',
    options: {
      conditional: [
        [
          {
            key: 'type',
            value: 'business'
          }
        ]
      ]
    }
  },
  {
    type: 'wrapper',
    options: {
      wrap_type: 'none',
      mandatory: true,
      conditional: [
        [
          {
            key: 'type',
            value: 'business'
          }
        ]
      ]
    },
    data: (() => {
      person.layout.splice(2, 0, {
        name: 'positionName',
        type: 'text',
        options: {
          placeholder: 'Pozice ve firmě',
          mandatory: true,
          conditional: [
            [
              {
                key: 'type',
                value: 'business'
              }
            ]
          ]
        }
      })
      return person.layout
    })()
  },
]

// console.log(JSON.parse(JSON.stringify(formFields)))

// console.log({ exportedContactForm: { layout: formFields } })
export default Object.freeze({
  layout: formFields,
})