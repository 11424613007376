import React from 'react'
import PropTypes from 'prop-types'
import { currencyCZ } from 'scripts/helpers'

function DigiTVOfferPrice(props: { price: number }) {
  return <>
    <div className='Component-DigiTVOfferPrice'>
      <div className='price'>
        {currencyCZ(props.price)}
      </div>
      <div className='priceText'>
        měsíčně
    </div>
      <div className='saleText'>
        + nyní na 3 měsíce<br />
        <span style={{
          fontSize: '120%',
          textTransform: 'uppercase',
        }}>zdarma</span>
      </div>
    </div>
  </>
}
DigiTVOfferPrice.propTypes = {
  price: PropTypes.number.isRequired
}

export default [
  {
    header: {
      background: 'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/bg/balicek-sport-plus.jpg',
      image: 'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/front/balicek-sport-plus.png',
      content: <DigiTVOfferPrice price={349} />,
    },
    text: {
      title: 'Sport Plus',
      paragraph: 'Žijete sportem. Hltáte góly, koše i míčky. Každý knockout vás fyzicky bolí. Vidíte se v tom? Pak je tenhle balíček přesně pro vás!',
      subtitle: '20 TV kanálů, 19 v HD',
    },
    logos: [
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/premier-sport-long-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/digi-sport-1-long-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/arena-sport-1-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/fightbox-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/golf-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/nova-sport-1-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/eurosport-1-long-60.png',
    ],
    colour: '#67af0e',
    channels: [
      "ČT1 HD",
      "ČT2 HD",
      "Premier Sport HD",
      "Digi Sport 1 HD",
      "Digi Sport 2 HD",
      "Digi Sport 3 HD",
      "Digi Sport 6 HD",
      "Digi Sport 7 HD",
      "Digi Sport 8 HD",
      "Digi Sport 9 HD",
      "Arena Sport 1 HD",
      "Arena Sport 2 HD",
      "FightBox HD",
      "Golf Channel",
      "ČT sport HD",
      "Nova Sport 1 HD",
      "Nova Sport 2 HD",
      "Eurosport 1 HD",
      "Eurosport 2 HD",
      "ČT24 HD"
    ]
  },
  {
    header: {
      background: 'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/bg/balicek-zakladni.jpg',
      image: 'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/front/balicek-zakladni.png',
      content: <DigiTVOfferPrice price={349} />,
    },
    text: {
      title: 'Základní',
      paragraph: 'Filmy, sport, hudba, dětské programy i televizní klasika. Takový je Základní balíček. Každý v rodině si najde to své.',
      subtitle: <>
        76 TV kanálů, 46 v HD<br />
        <span style={{ fontSize: '200%', lineHeight: '.8em', display: 'flex', justifyContent: 'center' }}>+</span>
        <span style={{ fontWeight: 'bold' }}>
          <span style={{ borderRadius: '4px', background: '#662d91', padding: '0 5px', color: 'white', letterSpacing: '0.1em', fontSize: '120%' }}>Maxi</span>
          &nbsp;na měsíc&nbsp;
          <span style={{ fontSize: '120%', textTransform: 'uppercase', letterSpacing: '0.05em', marginInlineStart: '.1em' }}>zdarma</span>
        </span>
      </>,
    },
    logos: [
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/nova-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/prima-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/filmbox-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/digi-sport-2-long-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/discovery-2-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/cartoon-network-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/disney-60.png',
    ],
    colour: '#0676ca',
    channels: [
      "ČT1 HD",
      "ČT2 HD",
      "Nova HD",
      "Nova 2 HD",
      "Nova Gold HD",
      "Nova Action HD",
      "Prima HD",
      "Prima LOVE HD",
      "TV Seznam HD",
      "TV Barrandov HD",
      "Barrandov News",
      "Jednotka HD",
      "Dvojka HD",
      "JOJ Family HD",
      "Nova Cinema HD",
      "JOJ Cinema HD",
      "Prima COOL HD",
      "Prima MAX HD",
      "Prima Krimi HD",
      "Prima Comedy Central HD",
      "Epic Drama HD",
      "AXN",
      "FilmBox HD",
      "FilmBox Plus",
      "FilmBox Premium HD",
      "FilmBox Extra HD",
      "Kino Barrandov HD",
      "Barrandov Krimi HD",
      "Digi Sport 2 HD",
      "Arena Sport 2 HD",
      "FightBox HD",
      "ČT sport HD",
      "Nova Sport 1 HD",
      "Eurosport 1 HD",
      "Eurosport 2 HD",
      "Prima ZOOM HD",
      "Discovery HD",
      "Investigation Discovery HD",
      "National Geographic HD",
      "Nat Geo Wild HD",
      "TLC",
      "Animal Planet HD",
      "DocuBox HD",
      "Fishing and Hunting",
      "Viasat Explore HD",
      "Viasat History HD",
      "Viasat Nature HD",
      "ČT :D/ČT Art HD",
      "Cartoon Network",
      "Duck TV",
      "Nickelodeon",
      "Disney Channel",
      "Nicktoons",
      "ČT 1 JM",
      "Praha TV",
      "Brno TV",
      "Regionální TVS",
      "JTV",
      "TV V1",
      "Polar TV",
      "Dorcel TV",
      "ČT24 HD",
      "TA3 HD",
      "CNN",
      "Deutsche Welle",
      "Comedy House HD",
      "Óčko TV",
      "Óčko Expres",
      "Óčko Star",
      "MTV Dance International",
      "MTV Live HD",
      "Šlágr TV",
      "RETRO",
      "MTV Europe",
      "VH1",
      "VH1 Classic",
      "English club TV"
    ]
  },
  {
    header: {
      background: 'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/bg/balicek-maxi.jpg',
      image: 'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/front/balicek-maxi.png',
      content: <DigiTVOfferPrice price={849} />,
    },
    text: {
      title: 'Maxi',
      paragraph: 'Vezměte si všechno! Nejširší nabídka DIGI TV zahrnuje filmy, sport i erotiku. Maxi balíček je to nejlepší, co můžete mít.',
      subtitle: '111 TV kanálů, 68 v HD',
    },
    logos: [
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/hbo-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/cinemax-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/premier-sport-long-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/digi-sport-1-long-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/national-geographic-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/nickelodeon-60.png',
      'https://novadigitv.cz/wp-content/themes/novadigitv/assets/images/channel/eroxxx-2-60.png',
    ],
    colour: '#662d91',
    channels: [
      "ČT1 HD",
      "ČT2 HD",
      "Nova HD",
      "Nova 2 HD",
      "Nova Gold HD",
      "Nova Action HD",
      "Prima HD",
      "Prima LOVE HD",
      "TV Seznam HD",
      "TV Barrandov HD",
      "Barrandov News",
      "Jednotka HD",
      "Dvojka HD",
      "JOJ Family HD",
      "HBO HD",
      "HBO 2 HD",
      "HBO 3 HD",
      "Cinemax HD",
      "Cinemax 2 HD",
      "Nova Cinema HD",
      "JOJ Cinema HD",
      "Prima COOL HD",
      "Prima MAX HD",
      "Prima Krimi HD",
      "Prima Comedy Central HD",
      "Epic Drama HD",
      "AXN",
      "AXN Black",
      "AXN White",
      "FilmBox HD",
      "FilmBox Plus",
      "FilmBox Premium HD",
      "FilmBox Extra HD",
      "CS Film",
      "Horor Film",
      "Kino Barrandov HD",
      "Barrandov Krimi HD",
      "Premier Sport HD",
      "Digi Sport 1 HD",
      "Digi Sport 2 HD",
      "Digi Sport 3 HD",
      "Digi Sport 6 HD",
      "Digi Sport 7 HD",
      "Digi Sport 8 HD",
      "Digi Sport 9 HD",
      "Arena Sport 1 HD",
      "Arena Sport 2 HD",
      "FightBox HD",
      "Golf Channel",
      "ČT sport HD",
      "Nova Sport 1 HD",
      "Nova Sport 2 HD",
      "Eurosport 1 HD",
      "Eurosport 2 HD",
      "Prima ZOOM HD",
      "Discovery HD",
      "Discovery Science",
      "Investigation Discovery HD",
      "DTX HD",
      "National Geographic HD",
      "Nat Geo Wild HD",
      "TLC",
      "Animal Planet HD",
      "DocuBox HD",
      "Crime & Investigation",
      "Fishing and Hunting",
      "Travel Channel HD",
      "Viasat Explore HD",
      "Viasat History HD",
      "Viasat Nature HD",
      "History Channel HD",
      "ČT :D/ČT Art HD",
      "Cartoon Network",
      "Duck TV",
      "CS Mini",
      "Nickelodeon",
      "Disney Channel",
      "Disney Junior",
      "Nick Jr CZ",
      "Filmbox Family",
      "Nicktoons",
      "ČT 1 JM",
      "Praha TV",
      "Brno TV",
      "Regionální TVS",
      "JTV",
      "TV V1",
      "Polar TV",
      "Eroxxx HD",
      "Dorcel TV",
      "ČT24 HD",
      "TA3 HD",
      "CNN",
      "Deutsche Welle",
      "Comedy House HD",
      "Óčko TV",
      "Óčko Expres",
      "Óčko Star",
      "MTV Dance International",
      "MTV Live HD",
      "Šlágr TV",
      "RETRO",
      "MTV Europe",
      "MTV Rocks",
      "MTV Hits",
      "VH1",
      "VH1 Classic",
      "Fine Living Network HD",
      "English club TV",
      "Food Network HD",
      "Auto Motor Sport HD"
    ]
  },]