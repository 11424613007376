import React from 'react';

// import styled from 'styled-components'
import {
  arrayOf
} from 'prop-types'
import {
  NavLink,
  withRouter
} from 'react-router-dom'
import {
  hash,
  isObject,
  strToPath,
  pathCompare,
} from 'scripts/helpers'
import './style.scss';
import uuid from 'uuid'
import form_navItem from 'types/form_navItem';
const key_seed = uuid()

function FullMenu(props) {
  return <div className='Component-FullMenu'>
    {props.itemGroups.map((itemGroup, key_itterator) =>
      <div className='menu-section' key={hash(key_seed, key_itterator)}>
        {itemGroup.map((item, item_key_itterator) => {
          // console.log({ item })
          return <NavLink
            to={{
              ...strToPath(item.path),
              state: isObject(item.options) ?
                item.options.state :
                undefined,
            }}
            isActive={() => pathCompare(
              props.location,
              item.activePath || item.path
            )}
            activeClassName='current-page'
            key={hash(key_seed, key_itterator, item_key_itterator)}
            className={`menu-button ${item.class}`}>
            <div className='content-wrapper'>
              {item.icon}
              <span className='text-container'>
                {item.label}
              </span>
            </div>
          </NavLink>
        })}
      </div>
    )}
  </div>;
}

FullMenu.propTypes = {
  itemGroups: arrayOf(arrayOf(form_navItem)).isRequired,
}

export default withRouter(FullMenu)