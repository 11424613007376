import React, { ReactNode } from 'react';
import { useSpring, animated } from 'react-spring'
import CloseIcon from 'components/CloseIcon'
import './style.scss';
import ResizeObserver from 'components/ResizeObserver';
import { isFunction } from 'scripts/helpers'

const $$init_size: unique symbol = Symbol('Initial size')
function Expander(props: {
  className?: any;
  content_className?: any;
  children: ReactNode;
  config: any;
  isOpen: boolean;
  close?: Function;
  onRest?: Function;
  verbose?: boolean;
  snapTolerance: number;
  direction: 'vertical' | 'horizontal';
}) {
  const {
    className,
    content_className,
    children,
    config,
    isOpen,
    close,
    onRest,
    verbose,
    snapTolerance,
    direction,
    ...passProps
  } = props

  let sizeProp: { max: 'maxHeight' | 'maxWidth', dimension: 'height' | 'width', direction: 'vertical' | 'horizontal', scroll: 'scrollWidth' | 'scrollHeight' }

  switch (direction) {
    case 'vertical':
      sizeProp = {
        max: 'maxHeight',
        dimension: 'height',
        direction: 'vertical',
        scroll: 'scrollHeight',
      }
      break
    case 'horizontal':
      sizeProp = {
        max: 'maxWidth',
        dimension: 'width',
        direction: 'horizontal',
        scroll: 'scrollWidth',
      }
      break
    default:
      throw new Error('Invalid direction: ' + String(direction))
  }

  const
    [size, setSize] = React.useState($$init_size as number | typeof $$init_size),
    currentState = React.useRef<Boolean>(),
    [expanderAnim, set_expanderAnim] = useSpring(() => ({
      [sizeProp.max]: isOpen ? 'unset' : 0,
      overflow: isOpen ? 'visible' : 'hidden',
      immediate: true,
      config: {
        clamp: true,
        ...config
      },
    })),
    has_closeIcon = isFunction(close)

  //-- Handle content size and isOpen change
  React.useEffect(() => {
    if (size !== $$init_size &&
      isOpen !== currentState.current) {

      // console.log('expander ' + (isOpen ? 'opening' : 'closing'))
      set_expanderAnim({
        to: async (next: Function, stop: Function) => {
          stop()
          await next({
            [sizeProp.max]: isOpen ? 0 : size,
            overflow: 'hidden'
          })
          await next({ [sizeProp.max]: isOpen ? size : 0 })
        },
        onRest: (...args: any[]) => {

          onRest && onRest(...args)

          if (isOpen)
            set_expanderAnim({
              [sizeProp.max]: 'unset',
              overflow: 'visible',
            })

          set_expanderAnim({
            immediate: false,
          })
        }
      })

      currentState.current = isOpen
    }
  }, [size, isOpen, set_expanderAnim, sizeProp.max, sizeProp.dimension, onRest])

  // console.log('rendering expander')
  return <animated.div {...passProps}
    className={['Expander-Component', className].toClass()}
    style={expanderAnim}>
    {has_closeIcon ? <CloseIcon className='close-icon' onClick={close} /> : null}
    <ResizeObserver verbose={verbose}
      className={['Expander-Content', content_className].toClass()}
      handleHeight={direction === 'vertical'} handleWidth={direction === 'horizontal'}
      tolerance={snapTolerance} onResize={(dimensions => {
        verbose && console.log('Expander new', { dimensions })
        setSize(dimensions[sizeProp.dimension])
      })}>
      {children}
    </ResizeObserver>
  </animated.div>
}

Expander.defaultProps = {
  config: null,
  snapTolerance: 1,
  direction: 'vertical'
}

export default Expander