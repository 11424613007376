import React from 'react';
import useTitle from 'hooks/useTitle';

export default function (Component: typeof React.Component) {
  return React.forwardRef((props, ref) => {
    return <Component {...props}
      Title={useTitle()}
      ref={ref as any}
    />
  })
}