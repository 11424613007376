import React from 'react';
import Form, {
  generateDefaultData,
  validateForm,
} from 'components/Form'
import contactForm from 'forms/contact'
import './style.scss';
import ModalBox from 'components/ModalBox'
import { withRouter } from 'react-router-dom'
import useTitle from 'hooks/useTitle';

const contactFields = [
  ...contactForm.layout.map(field => ({
    ...field,
    label: field.name === 'address-delimeter' ? 'Adresa odběrného místa' : field.label
  })),
  {
    name: 'customer-id',
    type: 'text',
    options: {
      mandatory: true,
      placeholder: 'Číslo zákazníka (nepovinné)',
      tooltip: 'Uvedené na faktuře',
      maxRows: 1,
    }
  }
]

function PREgas(props) {
  const
    [showSuccessModal, setShowSuccessModa] = React.useState(false),
    [formData, setFormData] = React.useState(
      generateDefaultData(contactFields)
    ),
    formAccess = {
      getFormData: key => formData[key],
      setFormData: (key, val) => setFormData({ ...formData, [key]: val }),
    },
    invalidFields = validateForm(contactFields, formAccess.getFormData),
    Title = useTitle()

  return <>
    <Title>PRE plyn</Title>
    <div className='Component-PREgas whitebox'>
      <h1 className='special-offer-heading'>
        Lepší nabídka od PRE
      </h1>
      <p>
        prosím vyplňte kontakní údaje
      </p>
      <Form
        name='contact'
        formFields={contactFields}
        {...formAccess}
      />
      <div className={'button' + (invalidFields.length ? ' disabled' : '')}
        onClick={() => {
          if (invalidFields.length === 0) {
            setFormData(generateDefaultData(contactFields))
            setShowSuccessModa(true)
          }
        }}
      >
        Odeslat
    </div>
      <ModalBox isOpen={showSuccessModal}>
        <div className='success-modal-content'>
          <h2>Dekujeme za Váš zájem.</h2>
          <h4>Brzy Vás budeme kontaktovat.</h4>
          <div className='button home-button'
            onClick={() => props.history.replace('/')}>
            Zpět na úvod
        </div>
        </div>
      </ModalBox>
    </div>
  </>
}

export default withRouter(PREgas)