import { reactDev } from "./helpers"

let lsAPI = null
const verbose = false

export default async function () {
  if (lsAPI === null) {
    if (reactDev())
      lsAPI = {
        startConversation: (...callArgs) => {
          verbose && console.log('startConversation', ...callArgs)
          return Promise.resolve(callArgs)
        },
        setActionMenuActive: (...callArgs) => {
          verbose && console.log('setActionMenuActive', ...callArgs)
          return Promise.resolve(callArgs)
        },
        registerSetrichytreCustomer: (...callArgs) => {
          verbose && console.log('registerSetrichytreCustomer', ...callArgs)
          return Promise.resolve(callArgs)
        },
        getResolvingOperator: (...callArgs) => {
          verbose && console.log('getResolvingOperator', ...callArgs)
          return Promise.resolve(null)
        }
      }
    else
      lsAPI = new Promise(resolve =>
        window.addEventListener('LIVESUPP_LOADED', async ({ detail }) =>
          resolve(detail.api)
        )
      )
  }

  let toReturn = null
  await Promise.resolve(lsAPI).then(lsAPI => toReturn = lsAPI)
  return toReturn
}