import {
  node,
  oneOfType,
} from "prop-types"
import reactComponent from "./reactComponent";

export default oneOfType([
  node,
  reactComponent,
]);
