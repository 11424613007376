import React from 'react';
// import { hash } from 'scripts/helpers'
import uuid from 'uuid'
import liveSuppGetPageAPI from 'scripts/LS_PageAPI';
const
  child_key = uuid(),
  ref_key = uuid(),
  renderWithout_key = uuid()

function WithLSPageAPI(props) {
  const [pageAPI, setPageAPI] = React.useState(null)

  React.useEffect(() => {
    // console.log('awaiting pageAPI')
    liveSuppGetPageAPI()
      .then(pageAPI => {
        // console.log('setting page API', pageAPI)
        setPageAPI(pageAPI)
      })
  }, [])

  const {
    [child_key]: Component,
    [ref_key]: ref,
    [renderWithout_key]: renderWithout,
    ...passProps
  } = props

  if (renderWithout || pageAPI)
    return <Component {...{ ...passProps, pageAPI }} />
  return null
}

/**
 * Inject Livesupp PageAPI into component props
 * @param {*} Component Component to inject PageAPI into.
 * @param {boolean} [renderWithout = false] Render with pageAPI=null until Livesupp loads.
 */
export default function (Component, renderWithout = false) {
  // console.log({ Component })

  return React.forwardRef((props, ref) =>
    <WithLSPageAPI {...{
      [child_key]: Component,
      ...props,
      [ref_key]: ref,
      [renderWithout_key]: renderWithout,
    }} />)
}