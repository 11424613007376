import React from 'react';

import './style.scss';
import LoadingIcon from 'components/LoadingIcon';

function Loading(props) {
  const {
    className,
  } = props

  return <div className={['SCForm-Loading-Component', className].toClass()}>
    <div className='loading-icon-wrapper'>
      <LoadingIcon className='loading-icon' />
    </div>
  </div>
}

export default Loading