import setriChytreAPI from 'scripts/SCH_API'
import liveSuppGetPageAPI from 'scripts/LS_PageAPI'
// import { reactDev } from './helpers'

export default function (verbose = false) {

  return new Promise((resolve, reject) => {
    setriChytreAPI('/customers/self/externalAuth', null, { verbose })
      .then(body => {
        verbose && console.log({ body })

        // if (reactDev()) {
        //   VERBOSE && console.log({ reactDev: 'customer pairing faking a resolution' })
        //   resolve('DEVMODE')
        //   // reject('DEVMODE')
        // }
        // else {
        liveSuppGetPageAPI()
          .then(async pageAPI => {
            const keyObj = {
              externalAuthKey: body.data.authKey
            }
            verbose && console.log('passing to pageAPI', keyObj)
            pageAPI.registerSetrichytreCustomer(keyObj)
              .then((...a) => {
                verbose && console.log('SC and LS customers paired', ...a)
                resolve({ keyObj })
              })
              .catch((...err) => {
                verbose && console.error('Failed to pair SC customer with LS', ...err)
                reject(...err)
              })
          })
          .catch((...err) => {
            verbose && console.error('Failed to pair SC customer with LS', ...err)
            reject(...err)
          })
        // }
      })
      .catch((...err) => {
        verbose && console.error('Failed get externalAuth token', ...err)
        reject(...err)
      })
  })
}