import React from 'react'
import PropTypes from 'prop-types'
import { mod } from 'scripts/helpers'
import './style.scss'

function ASCIIanimation(props) {
  const {
    className,
    random: isRandom,
    symbols,
    frequency,
    timingDeviation,
    verbose,
  } = props,
    getRandom = () => Math.floor(symbols.length * Math.random()),
    [symbolIndex, setSymbolIndex] = React.useState(isRandom ? getRandom() : 0)

  React.useEffect(() => {
    verbose && console.log('ASCII animation starting up', { isRandom, symbols })

    let nextStateTimeout
    function nextStep() {
      nextStateTimeout = setTimeout(
        //-- Run after timeout
        () => {
          const newSymbolIndex = isRandom ? getRandom() : mod(symbolIndex + 1, symbols.length)
          verbose && console.log({ newSymbolIndex })
          //-- Assign next symbol
          setSymbolIndex(newSymbolIndex)
          //-- Recurse self
          nextStep()
        },
        //-- Timeout delay
        frequency * ((1 - timingDeviation) + 2 * Math.random() * timingDeviation)
      )
    }

    nextStep()

    return () => {
      verbose && console.log('ASCII animation clearing timeout on unmount')
      clearTimeout(nextStateTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className={['Component-ASCIIanimation', className].toClass()}>
    {symbols[symbolIndex]}
  </div>
}

ASCIIanimation.propTypes = {
  symbols: PropTypes.arrayOf(PropTypes.string),
  frequency: PropTypes.number,
  random: PropTypes.bool,
  timingDeviation: PropTypes.number,
  verbose: PropTypes.bool,
}
ASCIIanimation.defaultProps = {
  symbols: ['—', '\\', '|', '/'],
  frequency: 200,
  random: false,
  timingDeviation: 0,
  verbose: false,
}

export default ASCIIanimation