import React from 'react';
import PropTypes from 'prop-types'
import offerPT from 'types/offer'
import {
  isEmpty,
  hash,
  syntheticRender,
  isFunction,
} from 'scripts/helpers'
import './style.scss';
import uuid from 'uuid'
import StarfallBackground from '../StarfallBackground';
import PrivateRibbon from '../PrivateRibbon';
import BoonLabels from '../BoonLabels';
// import BoonLabel from 'components/BoonLabel';
import { getVIPBoon } from '..';
const key_seed = uuid()

function FullOffer(props) {
  const
    {
      logo,
      offer,
      continueButton,
      moreInfoButton,
      noButtons,
      className,
    } = props,
    VIPBoon = getVIPBoon(offer),
    isPrivate = !!VIPBoon,
    headerContent = <>
      <span className='companyName'>
        {offer.provider.name}
      </span>
      <span className='productName'>
        {offer.name}
      </span>
      <BoonLabels className='boon-labels'>
        {offer.boons}
      </BoonLabels>
      {/* {isPrivate ? <div className='private-boon-wrapper'>
        <BoonLabel>
          {VIPBoon}
        </BoonLabel>
      </div> : null} */}
    </>

  return <div className={[className, 'Component-FullOffer'].toClass()}>
    {isPrivate ?
      <StarfallBackground className='offer-header private-header'>
        {headerContent}
        {noButtons ? null :
          <div className='private-offer-ribbon-wrapper'>
            <PrivateRibbon angle={22.5} verticalPadding='2px' />
          </div>}
      </StarfallBackground> :
      <div className='offer-header'>
        {headerContent}
      </div>}

    <div className='fields-container'>
      <div className='field logo-field'>
        {logo}
      </div>

      {offer.fields.map((field, key_iterator) => {

        const cellProps = { compact: false, label: field.label }

        if (field.cell && (!isFunction(field.shouldDisplay) || field.shouldDisplay(cellProps)))
          return <div key={hash('field', key_seed, key_iterator)} className='field offer-field'>
            {syntheticRender(field.cell, cellProps)}
          </div>

        return null
      })
      }

      {(continueButton || moreInfoButton) ?
        <div className='field buttons-field'>
          {continueButton}
          {isEmpty(offer.info) ? null :
            moreInfoButton}
        </div>
        : null}
    </div>
  </div>;
}

FullOffer.propTypes = {
  offer: offerPT.isRequired,
  noButtons: PropTypes.bool,
  continueButton: PropTypes.node,
  MoreInfoButton: PropTypes.node,
  logo: PropTypes.node.isRequired,
}
FullOffer.defaultProps = {
  noButtons: true,
  continueButton: null,
  MoreInfoButton: null,
}

export default FullOffer