import React, { useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import InputDelimeter from '../../Input/Delimeter'

import {
  isObject,
  hash
} from 'scripts/helpers'

import './style.scss'

import uuid from 'uuid'
const key_seed = uuid()

function Cloneable(props) {
  // console.log(props)

  // let key_iterator = 0

  const
    { field, generateDefaultData, getFormData, setFormData } = props,
    controlled = isObject(field.options) && field.options.controlled === true

  var goal
  if (controlled)
    goal = props.getFormData(props.field.options.num_src)

  const add_n_clones = useCallback(
    async function (n, field, generateDefaultData) {
      // console.log('Adding', n, 'clones')
      let current_data = getFormData(field.name)
      current_data.push(...Array(n).fill(generateDefaultData(field.data)))
      setFormData(field.name, current_data)
    },
    [getFormData, setFormData]
  )

  const remove_last_n_clones = useCallback(
    async function (n, field) {
      // console.log('Removing', n, 'clones')
      let current_data = getFormData(field.name)
      current_data.splice(current_data.length - n, n)
      setFormData(field.name, current_data)
    },
    [getFormData, setFormData]
  )


  useEffect(() => {
    //if we're using a controlled cloneable, make the clone number match the number source
    if (isObject(field.options) && field.options.controlled === true) {
      const length = getFormData(field.name).length

      if (!isNaN(Number(goal))) {

        if (length < goal)
          add_n_clones(goal - length, field, generateDefaultData)
        else if (length > goal)
          remove_last_n_clones(length - goal, field)
      }
      else if (length > 0)
        remove_last_n_clones(length, field)
    }
  }, [goal, field, generateDefaultData, getFormData, setFormData, add_n_clones, remove_last_n_clones])

  return <div className='Component-Cloneable'>
    {props.getFormData(props.field.name).map((_, index, { length }) =>
      <React.Fragment
        key={hash(key_seed + index)}>
        {((!controlled && index !== 0) || (controlled && length > 1)) ? <InputDelimeter
          xHandler={
            (!isObject(props.field.options) || props.field.options.controlled !== true) ?
              () => {
                let current_data = props.getFormData(props.field.name)
                current_data.splice(index, 1)
                props.setFormData(props.field.name, current_data)
              } : undefined
          }
        >
          {props.placeholder ?
            <span className='placeholder-text'>
              {`${props.placeholder} #${index + 1}`}
            </span> : undefined}
        </InputDelimeter> : null}
        {props.field.data.map((subfield, key_iterator) =>
          props.generateFormRow({
            ...subfield,
            name: `${props.field.name}.${index}.${subfield.name}`
          }, hash(key_seed + index + key_iterator))
        )}
      </React.Fragment>
    )
    }
    {!isObject(props.field.options) || props.field.options.controlled !== true ?
      <div className="button clone-button"
        onClick={() => add_n_clones(1, props.field, props.generateDefaultData)}
      >{props.field.options.button}</div> :
      null
    }
  </div>
}

Cloneable.propTypes = {
  generateDefaultData: PropTypes.func.isRequired,
  generateFormRow: PropTypes.func.isRequired,
  field: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  getFormData: PropTypes.func.isRequired
}

export default Cloneable