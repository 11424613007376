import React from 'react';

import './style.scss';

function PlaceholderPage(props) {
  const {
    className,
  } = props

  return <div className={['PlaceholderPage-Component', className].toClass()}>
    <h1>Tato stránka je ve vývoji</h1>
    <img src='http://placekitten.com/500' alt='kitten' />
  </div>
}

export default PlaceholderPage