import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import offer from 'types/offer'
import Input from 'components/Input'
import { hash, isEmpty, isVoid } from 'scripts/helpers'
// import { hash } from 'scripts/helpers'
// import FilterButton from './FilterButton'
// import EvenRowGrid from 'components/EvenRowGrid'
import boon_types from 'pages/Offers/boons'
import uuid from 'uuid'
// import { useTraceUpdate } from 'hooks/useTraceUpdate'
import cloneDeep from 'lodash/cloneDeep'
import { isNumber } from 'util'
import useResponsivity from 'hooks/useResponsivity'
const
  key_seed = uuid(),
  defaultBoonFilter = offers => offers,
  defaultCountFilter = offers => offers

function OffersFilter(props) {
  const {
    className,
    children: offers,
    setFilter,
    unsetFilter,
    modalPoint,
  } = props,
    { isGreaterThan } = useResponsivity(),
    [count, setCount] = React.useState(null),
    [filterCriteria, setFilterCriteria] = React.useState(new Set()),
    [boonFilter, setBoonFilter] = React.useState(() => defaultBoonFilter),
    newBoonFilter = React.useCallback(offers => {
      return cloneDeep(offers).filter(offer =>
        [...filterCriteria].every(criterion =>
          offer.boons.some(boon =>
            boon.type === criterion
          )
        )
      )
    }, [filterCriteria]),
    [countFilter, setCountFilter] = React.useState(() => defaultCountFilter),
    newCountFilter = React.useCallback(offers => {
      return cloneDeep(offers).slice(0, count)
    }, [count]),
    //-- get filterable fields
    availableFilterCriteria = React.useCallback((offers || [])
      .reduce((all, offer) => [
        ...all,
        ...offer.boons.map(({ type: name }) => name)
      ], [])
      .filter((boon, index, boons) => {
        return boons.indexOf(boon) === index &&
          boon_types.find(({ type }) => boon === type).options.canFilter
      }), [offers]),
    newFilter = React.useCallback(offers => {
      const
        boonFiltered = boonFilter(offers),
        countFiltered = countFilter(boonFiltered)

      // console.log('Filter:', { unfiltered: offers, boonFiltered, countFiltered })

      return countFiltered
    }, [boonFilter, countFilter])

  // console.log({ availableFilterCriteria })

  // useTraceUpdate(props, { filterCriteria, boonFilter, count, countFilter })

  //-- Submit filter
  React.useEffect(() => {
    if (boonFilter === defaultBoonFilter && countFilter === defaultCountFilter) {
      // console.log('unsetting filter')
      unsetFilter()
    }
    else
      setFilter(newFilter)

  }, [boonFilter, countFilter, newFilter, setFilter, unsetFilter])

  //-- Set boon filter when filter criteria change
  React.useEffect(() => {
    // console.log({ filterCriteria })
    if (isEmpty(filterCriteria)) {
      // console.log('settimg boon filter to default')
      setBoonFilter(() => defaultBoonFilter)
    }
    else
      setBoonFilter(() => newBoonFilter)
  }, [filterCriteria, newBoonFilter])

  //-- Set count filter when count changes
  React.useEffect(() => {
    if (isVoid(count)) {
      // console.log({ defaultCountFilter })
      setCountFilter(() => defaultCountFilter)
    }
    else
      setCountFilter(() => newCountFilter)

  }, [count, newCountFilter])

  //-- Keep offer count in sync
  React.useEffect(() => {
    if (offers) {
      // console.log({ offers })
      setCount(offers.length)
    }
  }, [offers])

  //-- Keep count maxed when total increases
  const
    totalOffers = offers ? boonFilter(offers).length : null,
    old_totalOffers = React.useRef()
  React.useEffect(() => {
    const { current: old_total } = old_totalOffers
    // console.log({ totalOffers, old_total, count })

    if (totalOffers > old_total && old_total === count)
      setCount(totalOffers)

    old_totalOffers.current = totalOffers
  }, [totalOffers, count])

  // console.log({ count })
  return <div className={['OffersFilter-Component', className, isGreaterThan(modalPoint) ? 'asExpander' : 'asModal'].toClass()}>
    <div className='filter-section filter-section-A'>
      <Input type='number' //key={hash(key_seed, totalOffers)}
        className='offer-count-input'
        value={count}
        onChange={setCount}
        max={totalOffers}
        min={1}
        options={{
          ...(totalOffers ? {
            default: totalOffers,
          } : null),
          inputParser: val => {
            if (!isNumber(val))
              return

            if (totalOffers) {
              const
                maxed = val >= totalOffers,
                single = val === 1,
                small = 2 <= val && val < 5,
                other = !single && !small

              return [
                `zobrazen${single ? 'a' : small ? 'y' : 'o'}`,
                single ? null : maxed ?
                  `všech${small ? 'ny' : ''}` :
                  `první${other ? 'ch' : ''}`,
                single ? maxed ? 'jedna' : 'první' : String(val),
                `nabíd${single ? 'ka' : small ? 'ky' : 'ek'}`
              ].filter(_ => _).join(' ')
            }
          },
          outputParser: (val, defaultOutputParser) => {
            // console.log({ ouputParser_val: val })
            if (val) {
              const numbers = val.match(/[0-9]+/)
              // console.log({ val, numbers })

              if (isEmpty(numbers))
                return 1

              return defaultOutputParser(numbers[0])
            }
          }
        }}
      />
    </div>
    <div className='filter-section filter-section-B'>
      {availableFilterCriteria.map((criterion, key_iterator) =>
        <Input type='check' key={hash(key_seed, key_iterator)}
          className='offer-boon-input'
          value={filterCriteria.has(criterion)}
          onChange={value => {
            if (value) filterCriteria.add(criterion)
            else filterCriteria.delete(criterion)

            setFilterCriteria(new Set(filterCriteria))
          }}
        >
          {boon_types.find(({ type }) => criterion === type).label}
        </Input>)}
    </div>
  </div>
}

OffersFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  unsetFilter: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(offer),
}

export default React.memo(OffersFilter)