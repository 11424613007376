import React from 'react';
import PropTypes from 'prop-types'
import url_proptype from 'url-prop-type'
import './style.scss';
import liveSuppGetPageAPI from 'scripts/LS_PageAPI';
import {
  FaSadTear as UnavailableIcon
} from 'react-icons/fa'
import parseColor from 'parse-color'
import makeCancellablePromise from 'make-cancellable-promise'
import {
  // reactDev,
  hash,
  constructArray,
  // useTraceUpdate,
} from 'scripts/helpers';
import renderable from 'types/renderable';
import { useSpring, animated } from 'react-spring'
import FitText from 'react-fittext'
import uuid from 'uuid'
import LoadingIcon from 'components/LoadingIcon';
import registerActionBanner from 'scripts/LS_actionBannerManager';
const key_seed = uuid()

const borderWidth = '6px'
function OperatorOrb(props) {
  const {
    size,
    image,
    onLoad,
    hoverText,
    onActionMenu,
    forceConversation,
    disableHover,
    forceHover,
    className,
    color,
  } = props,
    [selfRef, setSelfRef] = React.useState(null),
    [animation_key] = React.useState(hash(key_seed)),
    [loaded, setLoaded] = React.useState(null),
    [pageAPI, setPageAPI] = React.useState(null),
    [hovered, setHovered] = React.useState(false),
    [forcedConversation, setForcedConversation] = React.useState(false),
    show_hoverText = !disableHover && loaded && (hovered || forceHover),
    startConversation = React.useCallback(() => {
      if (loaded === true) {
        pageAPI.setActionMenuActive(true)
        onActionMenu(true)
      }
    }, [loaded, onActionMenu, pageAPI]),
    pulseColor = parseColor(
      color ?
        color :
        selfRef ?
          window.getComputedStyle(selfRef).getPropertyValue('color') :
          null)

  React.useEffect(() => {
    const
      {
        promise: pageAPI_promise,
        cancel: cancel_pageAPI_promise
      } = makeCancellablePromise(liveSuppGetPageAPI()),
      cancelActionBanner = registerActionBanner()

    pageAPI_promise.then(pageAPI => {
      setLoaded(true)
      setPageAPI(pageAPI)
      onLoad(pageAPI)
    }).catch(() => setLoaded(false))


    return () => {
      cancelActionBanner()
      cancel_pageAPI_promise()
    }
  }, [onLoad])

  React.useEffect(() => {
    if (!!forceConversation) {
      if (!forcedConversation) {
        startConversation()
        setForcedConversation(true)
      }
    }
    else if (!!forcedConversation)
      setForcedConversation(false)
  }, [forcedConversation, forceConversation, startConversation])

  // useTraceUpdate(props, {
  //   selfRef,
  //   animation_key,
  //   loaded,
  //   pageAPI,
  //   hovered,
  //   forcedConversation,
  // })

  // console.log('operator orb rerendering')
  return <div className={['Component-OperatorOrb', className,
    loaded === true ? 'loaded' : loaded === false ? 'unloaded' : 'loading'
  ].toClass()}
    style={{
      width: `calc(${size} + 2 * ${borderWidth})`,
      height: `calc(${size} + 2 * ${borderWidth})`,
    }}
    onClick={e => {
      e.stopPropagation()
      startConversation()
    }}
    ref={setSelfRef}
  >
    <style>{`
        @keyframes ${animation_key} {
          0% {
            box-shadow: 0 0 5px 0 transparent;
          }

          40% {
            box-shadow: 0 0 5px 0 ${pulseColor.hex}9d;
          }

          100% {
            box-shadow: 0 0 5px 80px ${pulseColor.hex}00;
          }
        }
      `}</style>

    <div className='animated-border'>
      {!!loaded ? constructArray(2, i =>
        <div className='pulsor' key={hash(key_seed, i)} style={{
          animation: `${animation_key} 2.4s ease-out ${.3 * i}s infinite`
        }} />
      ) : null}
    </div>
    <animated.img src={image} className='chat-bubble' alt='Volat' style={{
      height: size,
      margin: borderWidth,
      filter: useSpring({ chatBubble_filter: (show_hoverText || !loaded) ? 1 : 0 })
        .chatBubble_filter.interpolate(val =>
          `blur(${val * 2}px) brightness(${.8 + .2 * (1 - val)}) saturate(${.8 + .2 * (1 - val)})`
        )
    }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    />

    <animated.div className='text-container hover-text-container'
      style={useSpring({ opacity: show_hoverText ? 1 : 0 })}>
      <FitText compressor={.5}>
        <div className='hover-text'>
          {hoverText}
        </div>
      </FitText>
    </animated.div>

    {loaded === true ? null
      : loaded === null ?
        <div className='spinner-wrapper'>
          {/* <JumpingEllipsis style={{ fontSize: size }} animationDelay={.15} /> */}
          <LoadingIcon />
        </div> :
        <div className='text-container unavailable-text-container'>
          <UnavailableIcon className='unavailable-icon' />
          <div className='unavailable-text'>
            LiveSupp dočasně nedostupný
            </div>
        </div>
    }
  </div>
}

OperatorOrb.propTypes = {
  image: url_proptype.isRequired,
  size: PropTypes.string,
  onLoad: PropTypes.func,
  onCall: PropTypes.func,
  onActionMenu: PropTypes.func,
  hoverText: renderable,
  forceHover: PropTypes.bool,
  forceConversation: PropTypes.bool,
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.number
    )
  ]),
  disableHover: PropTypes.bool,
}
OperatorOrb.defaultProps = {
  size: '200px',
  onLoad: () => { },
  onCall: () => { },
  onActionMenu: () => { },
  hoverText: 'Osobní asistece',
  forceHover: false,
  forceConversation: false,
  color: null,
  disableHover: false,
}

export default React.memo(OperatorOrb)