import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';
import renderable from 'types/renderable';
import { syntheticRender } from 'scripts/helpers';
import {
  FaSortUp as AscIcon,
  FaSortDown as DscIcon,
  FaSort as NoSortIcon,
} from 'react-icons/fa'

function SortButton(props) {
  const {
    className,
    sortDirection,
    children,
    ...passProps
  } = props,
    Asc = ({ className }) => <AscIcon className={['asc-icon', className].toClass()} />,
    Dsc = ({ className }) => <DscIcon className={['dsc-icon', className].toClass()} />,
    NoSort = ({ className }) => <NoSortIcon className={['nosort-icon', className].toClass()} />

  let
    SortIcon,
    BgIcon
  switch (sortDirection) {
    case 'asc':
      SortIcon = Asc
      BgIcon = Dsc
      break
    case 'dsc':
      SortIcon = Dsc
      BgIcon = Asc
      break
    default:
      SortIcon = NoSort
      BgIcon = () => null
      break
  }

  return <div className={['SortButton-Component', className].toClass()} {...passProps}>
    <div className='button-text-wrapper'>
      {syntheticRender(children, { sortDirection })}
    </div>
    <div className='sort-icon-wrapper'>
      <BgIcon className='bg-icon' />
      <SortIcon className='sort-icon' />
    </div>
  </div>
}

SortButton.propTypes = {
  sortDirection: PropTypes.oneOf(['asc', 'dsc']),
  onClick: PropTypes.func.isRequired,
  children: renderable.isRequired,
}

export default SortButton