import React from 'react';

import {
  FaLightbulb as ElectricityIcon,
  FaPlug,
} from 'react-icons/fa'
import {
  currencyCZ,
} from 'scripts/helpers'
import currentMonthlyCost from 'forms/fields/currentMonthlyCost'
import MidBold from 'forms/cells/MidBold'
import CostCell from 'forms/cells/CostCell'
import CostRow, { DPHspan } from 'forms/inforows/CostRow'
import InfoElement from 'forms/InfoElement'
// import PRE_standing_customer from './../specialOffers/PRE standing customer'
import SCH_getVATs from 'scripts/SCH_getVATs'
import { appendBoonCell } from 'forms/cells/BoonsCell'
import { GetFormData } from 'types/FormAPI'
import CustomerType from 'types/CustomerType'
import Indexable from 'types/Indexable'
import {
  CheckStylewrap,
  create_depositCell,
  onOffer_rawInfo,
  commonTop,
  usageEstimatePurpose,
  Savings,
} from '../utility_funcs';
import {
  WithId,
  WithName,
  WithType,
  Region,
  ProductProvider,
} from '../types';
import {
  getSCFormPageInfo
} from '../../'
import FormData from './FormData'
import Product, { BriefProduct, FullProduct } from './Product';
// import { Offer } from 'pages/Offers/Offer';

interface CircuitBreaker extends WithId, WithType, WithName { }
interface DistributionTariff extends WithId, WithType { }

const electricityInfo = getSCFormPageInfo('utilities', 'electricity')

export default {
  label: electricityInfo.label,
  value: electricityInfo.name,
  slug: electricityInfo.slug,
  title: "Spočítej si úsporu na elektřině",
  tagline: "Pokud chcete s nabídkou pomoci, stačí kliknout na ikonu operátora",
  submit: "Porovnat",
  navItem: {
    label: electricityInfo.label,
    icon: <ElectricityIcon className='icon' />,
    path: electricityInfo.path.form,
  },
  options: {
    showHelpButtom: true,
    hooks: {
      onLoad: (_: any, data: Indexable) => {
        // console.log({ electricityData: data })
        return ({
          circuitBreakers: data.circuitBreakers
            .map((circuitBreaker: CircuitBreaker) => ({
              value: circuitBreaker.id,
              label: circuitBreaker.name,
              type: circuitBreaker.type,
            })
            ),
          distributionTariffs: data.distributionTariffs
            .map((distributionTariff: DistributionTariff) => ({
              ...distributionTariff,
              value: distributionTariff.id,
              label: distributionTariff.id
            })
            ),
          productProviders: data.productProviders
            .map((productProvider: ProductProvider) => ({
              ...productProvider,
              value: productProvider.id,
              label: productProvider.name
            })
            ),
          regions: data.regions
            .map((region: Region) => ({
              value: region.id,
              label: region.name
            })
            )
        })
      },
      onSubmit: (_: any, data: Indexable) => {
        let kwhPerYear

        switch (data['usage_known']) {

          case 'known':
            kwhPerYear = Number(data.usage)
            break

          case 'unknown':
            const usageTable = JSON.parse('{"apartment":{"1":{"appliances":1100,"water":1000,"cooking":200,"heating":5500},"2-3":{"appliances":2750,"water":2500,"cooking":500,"heating":8250},"4+":{"appliances":6000,"water":5000,"cooking":1000,"heating":11000}},"house":{"1":{"appliances":1200,"water":1000,"cooking":200,"heating":11000},"2-3":{"appliances":3250,"water":2500,"cooking":500,"heating":22000},"4+":{"appliances":7000,"water":5000,"cooking":1000,"heating":33000}}}')

            kwhPerYear = 0

            if (data['usage_estimate_purpose::appliances'])
              kwhPerYear +=
                usageTable
                [data['usage_estimate_accomedation']]
                [data['usage_estimate_persons']]
                  .appliances

            if (data['usage_estimate_purpose::water'])
              kwhPerYear +=
                usageTable
                [data['usage_estimate_accomedation']]
                [data['usage_estimate_persons']]
                  .water

            if (data['usage_estimate_purpose::cooking'])
              kwhPerYear +=
                usageTable
                [data['usage_estimate_accomedation']]
                [data['usage_estimate_persons']]
                  .cooking

            if (data['usage_estimate_purpose::heating'])
              kwhPerYear +=
                usageTable
                [data['usage_estimate_accomedation']]
                [data['usage_estimate_persons']]
                  .heating

            break

          case 'quick_compare':
            return undefined

          default:
            throw Error('invalid val for usage_known: ' + data['usage_known'])
        }

        return ({
          type: data.type,
          kwhPerYear: kwhPerYear,
          circuitBreaker: data.circuitBreakers,
          region: data.regions,

          currentProvider: Number(data.productProviders) || null,
          currentDistributionTariff: Number(data.distributionTariffs) || null,
          currentMonthlyCost: Number(data.cost) || null,
        })
      },
      onOffer: async (product: Product, formData: FormData) => {
        const
          type = formData === undefined ? 'brief' : formData.type,
          vat = (await Promise.resolve(SCH_getVATs())).electricity,
          incVAT = type === 'household',
          {
            info,
            costs: rawCosts,
            fields
          } = await onOffer_rawInfo(product, formData, vat),
          costs = rawCosts as Product['costs'] & Savings

        // console.log({ product })

        if (type === 'brief') {

          fields.splice(2, 0, {
            name: 'fixedCost',
            label: 'Fixní měsíční poplatek',
            selector: costs.fixedCostPerMonth as number,
            cell: (passProps: Indexable) => <MidBold {...passProps}>
              Fixní měsíční poplatek
              {currencyCZ((costs.fixedCostPerMonth as number) * vat)}
              <DPHspan incVat={true} />
            </MidBold>
          },
            {
              name: 'tarriffCost',
              label: 'Vysoký tarif',
              selector: (costs as BriefProduct['costs']).highTariffCostPerMWh,
              cell: (passProps: Indexable) => <CostCell {...passProps}
                cost={(costs as BriefProduct['costs']).highTariffCostPerMWh}
                vat={vat} />
            })
        }
        else {
          fields.push(create_depositCell((costs as FullProduct['costs']).totalCostPerMonth, vat));
          info[0].push(
            {
              label: 'Ekologická daň',
              value: <CostRow vat={null}>{(costs as FullProduct['costs']).ecologyCostPerMonth}</CostRow>,
              hide: true
            },
            {
              label: 'Celkem DPH',
              value: currencyCZ((costs as FullProduct['costs']).totalCostPerMonth * vat),
              hide: true
            },
          )
          info[1].splice(1, 0,
            {
              label: 'Základní cena produktu',
              value: currencyCZ((product as FullProduct).costCalculation.fixedCostPerMonth.baseProduct),
              hide: true
            },
            {
              label: 'Distribuce produktu',
              value: currencyCZ((product as FullProduct).costCalculation.fixedCostPerMonth.distribution),
              hide: true
            },
            {
              label: 'Provozní poplatek',
              value: currencyCZ((product as FullProduct).costCalculation.fixedCostPerMonth.operatorServicesFee),
              hide: true
            },
            {
              label: 'DPH za fixní poplatek',
              value: currencyCZ(costs.fixedCostPerMonth * vat),
              hide: true
            },
            {
              label: 'Spotřební záloha za měsíc',
              value: <CostRow vat={incVAT ? vat : null}>
                {(costs as FullProduct['costs']).usageCostPerMonth}
              </CostRow>
            },
            {
              label: 'Cena za 1000 Kwh',
              value: <CostRow vat={incVAT ? vat : null}>
                {((product as FullProduct).costCalculation.usageCostPerKwh.distributorHighTariff +
                  (product as FullProduct).costCalculation.usageCostPerKwh.productHighTariff +
                  (product as FullProduct).costCalculation.usageCostPerKwh.tax)
                  * 1000}
              </CostRow>,
              hide: true
            },
            {
              label: 'Tariff poskytovatele (za 1000 Kwh)',
              value: currencyCZ((product as FullProduct).costCalculation.usageCostPerKwh.productHighTariff * 1000),
              hide: true
            },
            {
              label: 'Tariff distributora (za 1000 Kwh)',
              value: currencyCZ((product as FullProduct).costCalculation.usageCostPerKwh.distributorHighTariff * 1000),
              hide: true
            },
            {
              label: 'Energetická daň (za 1000 Kwh)',
              value: currencyCZ((product as FullProduct).costCalculation.usageCostPerKwh.tax * 1000),
              hide: true
            },
            {
              label: 'DPH za spotřebu',
              value: currencyCZ((costs as FullProduct['costs']).usageCostPerMonth * vat),
              hide: true
            },
          )
        }

        //-- TODO: Splice eco tax into info

        appendBoonCell(product, fields)

        return {
          ...product,
          fields,
          costs,
          info: <InfoElement info={info} />
        }

      }
    },
  },
  fields: [
    ...commonTop,
    {
      name: "usage",
      label: "kWh",
      type: "number",
      options: {
        min: 0,
        max: 100,
        step: 10,
        // default: 10,
        // inputParser: (val, defaultParser) => defaultParser(val).replace('.', ','),
        // outputParser: (val, defaultParser) => defaultParser(val.replace(',', '.')),
        conditional: [
          [
            {
              key: "usage_known",
              value: "known"
            },
            {
              key: "usage_known",
              value: "!quick_compare"
            }
          ],
        ],
        placeholder: "Roční spotřeba",
        tooltip: "Naleznete na posledním ročním vyúčtování.",
        mandatory: true,
      }
    },
    {
      ...usageEstimatePurpose,
      data: [
        {
          name: "usage_estimate_purpose::appliances",
          label: (props: Indexable) =>
            <CheckStylewrap {...props}>
              <div className='icon-wrapper'>
                <FaPlug className='icon' />
              </div>
              <div className='text-wrapper'>
                <span className='text'>Domácí spotřebiče</span>
              </div>
            </CheckStylewrap>,
          type: "check",
          options: {
            hideCheckbox: true,
            mandatory: true,
          },
        },
        ...usageEstimatePurpose.data
      ]
    },
    {
      name: "distributionTariffs",
      type: "select",
      options: {
        placeholder: "Distribuční sazba",
        tooltip: "Díky vašemu produktu budeme moci přesněji určit, kolik můžete ušetřit.",
        mandatory: true,
        conditional: [
          [
            {
              key: "usage_known",
              value: "known"
            }
          ]
        ],
        filter: (
          { type }: { type: CustomerType | 'both' },
          getFormData: GetFormData,
        ) => {

          if (type === 'both')
            return true

          return type === getFormData('type')
        }
      }
    },
    {
      name: "circuitBreakers",
      type: "select",
      options: {
        placeholder: "Jistič",
        tooltip: "Naleznete na posledním ročním vyúčtování.",
        mandatory: true,
        conditional: [
          [
            {
              key: "usage_known",
              value: "known"
            }
          ]
        ],
        filter: (
          { type }: { type: CustomerType | 'both' },
          getFormData: GetFormData
        ) => {
          if (type === 'both')
            return true

          return type === getFormData('type')
        }
      }
    },
    {
      name: "regions",
      type: "select",
      options: {
        placeholder: "Kraj",
        tooltip: "Vyberte kraj, ve kterém elektřinu odebíráte. Ceny se v jednotlivých krajích liší.",
        conditional: [
          [
            {
              key: "usage_known",
              value: "!quick_compare"
            }
          ]
        ],
        mandatory: true
      }
    },
    {
      name: "productProviders",
      type: "select",
      options: {
        placeholder: "Současný dodavatel",
        tooltip: "Vyberte dodavatele, od kterého teď odebíráte elektřinu. Porovnáme vám jeho ceny s ostatními na trhu.",
        mandatory: false,
        conditional: [
          [
            {
              key: "usage_known",
              value: "!quick_compare"
            }
          ]
        ],
      }
    },
    currentMonthlyCost
  ]
}