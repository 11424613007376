import React from 'react';

import PropTypes from 'prop-types';

import './style.scss'
import SCButton from 'components/SCButton';

function OfferTopButton(props) {
  const {
    className,
    ...passProps
  } = props

  return <div className={['OfferTopButton-Component', 'theme-1', className].toClass()}>
    <SCButton overrideStyle className='OfferTopButton-body' {...passProps} />
  </div>
}

OfferTopButton.propTypes = {
  onClick: PropTypes.func
}

export default OfferTopButton;