import {
  getVIPBoon,
  getField,
} from 'components/Offer'

export default function (offers) {
  // console.log({ offers })

  function getCost(offer) {
    const costField = getField(offer, 'cost')
    if (costField && typeof costField.selector === 'number')
      return costField.selector
    return null
  }

  const topOffers = offers
    .filter(offer => !!getVIPBoon(offer)) //-- Get only VIP offers
    .reduce((topOffers, privateOffer) => {
      const provider_id = privateOffer.provider.id

      if (!(provider_id in topOffers) ||
        getCost(privateOffer) < getCost(topOffers[provider_id]))
        topOffers[provider_id] = privateOffer

      return topOffers
    }, {}),
    isTopOffer = offer => Object.entries(topOffers).some(([_, topOffer]) => topOffer === offer)

  // console.log({ topOffers })

  return offers.sort(
    (lhs, rhs) => {
      const isTop = {
        lhs: isTopOffer(lhs),
        rhs: isTopOffer(rhs),
      }

      if (isTop.lhs !== isTop.rhs)
        return isTop.rhs - isTop.lhs

      const
        cost = {
          lhs: getCost(lhs),
          rhs: getCost(rhs),
        },
        hasCost = {
          lhs: cost.lhs !== null,
          rhs: cost.rhs !== null,
        }

      if (!hasCost.lhs || !hasCost.rhs)
        return hasCost.lhs - hasCost.rhs

      return cost.lhs - cost.rhs
    }
  )
}