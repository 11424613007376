import React from 'react'
import { createPortal } from 'react-dom'

import { Link } from 'react-router-dom'

import './style.scss'
import CookieConsent from 'react-cookie-consent'
import AnimatedUnderline from 'components/AnimatedUnderline'
import SCButton from 'components/SCButton'
import { useCookies } from 'react-cookie'
import useError from 'hooks/useError'
import useResponsivity from 'hooks/useResponsivity'
// import getSize from 'get-size'

const consetCookie_name = 'cookies-consent'
function Footer() {
  const
    [isCookiesShown, setCookiesShown] = React.useState(!useCookies()[0][consetCookie_name]),
    [cookiesHeight, setCookiesHeight] = React.useState<number>(),
    footerElement = document.getElementById('page-footer'),
    { addResizeListener } = useResponsivity(),
    throwError = useError()

  React.useEffect(() => addResizeListener(
    () => { setCookiesHeight(undefined) },
    { width: true, height: false }
  ), [addResizeListener])

  if (footerElement === null) {
    console.error({ footerElement })
    throwError("Couldn't find footer element")
    return null
  }

  // console.log({ isCookiesShown, cookiesHeight })

  return <>
    {(isCookiesShown && cookiesHeight) ?
      createPortal(
        <style type='text/css'>{`
          #page-footer {
            padding-block-end: ${cookiesHeight + 10}px;
          }
        `}</style>
        , document.head)
      : null}
    {createPortal(
      <>
        <div className='contactbox'>
          <Link to='/eula' target='_blank'>
            Podmínky použití
          </Link>
        </div>
        <div className='contactbox'>
          <Link to='/gdpr' target='_blank'>
            Ochrana osobních údajů a GDPR
          </Link>
        </div>
        <div className='contactbox'>
          <Link to='/kontakt'>
            Provozovatel: Šetři chytře s.r.o.
        </Link>
        </div>
        <CookieConsent
          location='bottom'
          buttonText='Rozumím'
          cookieName={consetCookie_name}
          disableStyles={true}
          containerClasses='cookies-container'
          contentClasses='cookies-content'
          buttonClasses='cookies-accept'
          ButtonComponent={SCButton}
          onAccept={() => setCookiesShown(false)}
        >
          <div className='cookies-wrapper' ref={ref => {
            if (ref && ref.parentElement && ref.parentElement.parentElement)
              setCookiesHeight(ref.parentElement.parentElement.clientHeight)
          }}>
            <span className='cookies-text'>
              Ke správné funkčnosti stránek využíváme soubory Cookies.
            </span>
            <span className='more-info-wrapper'>
              <Link to='/gdpr' className='cookies-more-info'>
                <AnimatedUnderline>
                  Více info
                </AnimatedUnderline>
              </Link>
            </span>
          </div>
        </CookieConsent>
      </>,
      footerElement)}
  </>
}

export default Footer