import React from 'react';

import PropTypes from 'prop-types';

import { FaTimes as DeleteSectionIcon } from 'react-icons/fa'

import { isFunction } from 'scripts/helpers'

import './style.scss'

function InputDelimeter(props) {
  return <div className='Component-InputDelimeter' style={{
    gridTemplateColumns: (() => {
      let out = []
      if (props.children !== undefined || props.label !== undefined)
        out.push('auto')
      out.push('1fr')
      if (isFunction(props.xHandler))
        out.push('auto')

      return out.join(' ')
    })()
  }}>
    {props.children !== undefined ?
      props.children :
      props.label !== undefined ?
        props.label : null}
    <span className='delimeter' />
    {isFunction(props.xHandler) ?
      <DeleteSectionIcon
        onClick={props.xHandler}
      /> : null}
  </div>;
}

InputDelimeter.propTypes = {
  xHandler: PropTypes.func,
  children: PropTypes.node
}

export default InputDelimeter