import React, { ReactNode, Ref } from 'react';
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import './style.scss';
import uuid from 'uuid'
import { hash } from 'scripts/helpers';

const Tooltip = React.forwardRef((props: {
  className?: string;
  text?: ReactNode | undefined;
  children?: ReactNode | undefined;
}, ref?: Ref<HTMLDivElement>) => {
  const {
    className,
    text,
    ...passProps
  } = props,
    active = !!text,
    [tooltip_id] = React.useState(hash(uuid()))

  return <>
    <div className={[active ? 'Tooltip-Component' : null, className].toClass()} data-tip data-for={tooltip_id}
      {...{ ...passProps, ref }}>
      {props.children}
    </div>
    {active ?
      <ReactTooltip multiline={true} className='Tooltip-wrapper' id={tooltip_id} effect='solid' border={true}>
        {props.text}
      </ReactTooltip > :
      null}
  </>
})

Tooltip.propTypes = {
  text: PropTypes.node
}

export default Tooltip