import React from 'react'

import './style.scss'
import proptypes from '../pageProptypes'

export const initialState = {}

function subsidy_display(props) {
  const { goToPage } = props

  return <div className='Component-subsidy_display whitebox'>
    <header>
      <h2>
        Dotace
      </h2>
    </header>
    <main>
      <div className='amount'>
        15,000 kč
        </div>
      <div className='contract-duration'>
        Závazek: 5 let
        </div>
    </main>
    <footer>
      <div className='button-container'>
        <div className='button-wrapper'>
          <div className='button' onClick={() => goToPage('payment_choice')}>
            Zpět
          </div>
        </div>
        <div className='button-wrapper'>
          <div className='button' onClick={() => goToPage('supplier_form')}>
            Dále
          </div>
        </div>
      </div>
    </footer>
  </div >;
}

subsidy_display.propTypes = proptypes

export default subsidy_display