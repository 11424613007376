import React from 'react';

import { Link } from 'react-router-dom'
import {
  FaSeedling as LogoIcon
} from 'react-icons/fa'

import './style.scss';

function Logo(props) {
  const { className, ...passProps } = props

  return <Link to='/' className={'Component-Logo ' + (className || '')} {...passProps}>
    <LogoIcon className='logo-icon' />
    &nbsp;
   <span>Šetři chytře</span>
  </Link>;
}

export default Logo