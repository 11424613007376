import React from 'react'

import './style.scss';
import {
  syntheticRender
} from 'scripts/helpers'
import proptypes from '../pageProptypes'
import { getSCForm } from 'forms';

export const initialState = {
  gas: false,
  electricity: false,
}

const utilities = getSCForm('utilities')

function util_choice(props) {
  const { state, setState, goToPage } = props

  return <div className='Component-util_choice'>

    <h2>Zaklikněte položky, na kterých chcete ušetřit</h2>

    <div className='tiles-container'>
      <div className={'tile electricity-tile' + (state.electricity === true ? ' active' : '')}
        onClick={() => setState({ electricity: !state.electricity })}
      >
        {syntheticRender(utilities.layout.find(f => f.value === 'electricity').options.icon)}
        <div className='text'>
          Elektřina
          {/* Normálně */}
        </div>
      </div>
      <div className={'tile gas-tile' + (state.gas === true ? ' active' : '')}
        onClick={() => setState({ gas: !state.gas })}
      >
        {syntheticRender(utilities.layout.find(f => f.value === 'gas').options.icon)}
        <div className='text'>
          Plyn
          {/* Po najetí myší */}
        </div>
      </div>
      {/* <div className={'tile active'}>
        <TestIcon className='icon' />
        <div className='text'>
          Vybraná
        </div>
      </div> */}
    </div>

    <div className='button-wrapper'>
      <div className={'button' + (!(state.electricity || state.gas) ? ' disabled' : '')}
        onClick={() => goToPage('payment_choice')}>
        Pokračovat
      </div>
    </div>
  </div >;
}

util_choice.propTypes = proptypes

export default util_choice