import {
  oneOf
} from 'prop-types'

export default oneOf([
  "check",
  "cloneable",
  "custom",
  "date",
  "delimeter",
  "file",
  "hinted-text",
  "loading",
  "number",
  "select",
  "slider",
  "switch",
  "text",
  "text-area",
  "unit-label",
  "wrapper"
])