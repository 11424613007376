import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';
import renderable from 'types/renderable';
import { syntheticRender } from 'scripts/helpers';
import { useSpring } from 'react-spring';
import Bounce from './animations/Bounce'
import getSize from 'get-size'
import getPageAPI from 'scripts/LS_PageAPI'
import cancellablePromise from 'make-cancellable-promise'
import registerActionBanner from 'scripts/LS_actionBannerManager'

const animations = {
  'bounce-left': props => <Bounce {...props} direction='left' />,
  'bounce-right': props => <Bounce {...props} direction='right' />,
  // 'bounce-right': require('./animations/BounceRight'),
}

function SCButton(props) {
  const {
    icon,
    iconPos,
    liveSupp,
    animation,
    onMouseEnter,
    overrideStyle,
    onMouseLeave,
    className,
    children,
    onClick,
    ratio,
    style,
    ...passProps
  } = props,
    hasIcon = !!icon,
    hasAnim = hasIcon && animation !== 'none',
    [pageAPI, setPageAPI] = React.useState(null),
    [selfRef, setSelfRef] = React.useState(null),
    [hovered, setHovered] = React.useState(false),
    [ratioSize, setRatioSize] = React.useState(null),
    [oldRatio, setOldRatio] = React.useState(null),
    { animationValue } = useSpring({
      animationValue: hovered ? 1 : 0,
      config: {
        mass: 2,
        friction: 20,
        tension: 700,
        clamp: !hovered,
      }
    }),
    Animation = hasAnim ? animations[animation] : ({ children }) => children

  // console.log({ Animation })

  const renderedIcon = <Animation
    animationValue={animationValue}>
    {syntheticRender(icon)}
  </Animation>

  React.useEffect(() => {
    if (ratio && selfRef && (ratioSize === null)) {
      const
        ratioTolerance = .01,
        { width, height } = getSize(selfRef),
        currentRatio = width / height,
        ratioDiff = ratio - currentRatio

      if (Math.abs(ratioDiff) > ratioTolerance)
        if (ratioDiff > 0)
          setRatioSize({ minWidth: `${width * ratio}px` })
        else
          setRatioSize({ minHeight: `${height * 1 / ratio}px` })
    }
    else if (oldRatio !== ratio) {
      setRatioSize(null)
      setOldRatio(ratio)
    }
  }, [ratio, oldRatio, ratioSize, selfRef])

  React.useEffect(() => {
    if (liveSupp) {
      const
        cancelActionBanner = registerActionBanner(),
        { promise: pageAPI_promise, cancel: cancel_pageAPI_promise } = cancellablePromise(getPageAPI())

      pageAPI_promise.then(setPageAPI)

      return () => {
        cancelActionBanner()
        cancel_pageAPI_promise()
      }
    }
  }, [liveSupp, pageAPI])

  return <div className={[overrideStyle ? null : 'Component-SCButton', className, (liveSupp && !pageAPI) ? 'disabled' : null].toClass()}
    ref={setSelfRef}
    style={{
      ...style,
      ...ratioSize,
    }}
    {...passProps}
    onClick={(event, ...args) => {
      if (liveSupp && pageAPI) {
        pageAPI.setActionMenuActive(true)
        event.stopPropagation()
      }
      if (onClick)
        return onClick(...args)
    }}
    onMouseEnter={(...args) => {
      setHovered(true)
      onMouseEnter && onMouseEnter(...args)
    }}
    onMouseLeave={(...args) => {
      setHovered(false)
      onMouseLeave && onMouseLeave(...args)
    }}>

    {iconPos === 'before' ?
      renderedIcon : null}

    <div className='SCButton-content'>
      {children}
    </div>

    {iconPos === 'after' ?
      renderedIcon : null}
  </div>
}

SCButton.propTypes = {
  icon: renderable,
  iconPos: PropTypes.oneOf([
    'before',
    'after',
  ]),
  animation: PropTypes.oneOf([
    'none',
    'bounce-left',
    'bounce-right',
    'jump-spin',
    // 'spin',
  ]),
  ratio: PropTypes.number,
  liveSupp: PropTypes.bool,
  overrideStyle: PropTypes.bool,
}
SCButton.defaultProps = {
  icon: null,
  animation: 'none',
  ratio: null,
  liveSupp: false,
  overrideStyle: false,
}

export default SCButton