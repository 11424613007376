export default {
  name: 'cost',
  label: 'Kč',
  type: 'number',
  options: {
    min: 0,
    step: 100,
    placeholder: 'Současná měsíční platba (nepovinné)',
    tooltip: 'Kolik nyní platíte každý měsíc.',
    conditional: [
      [
        {
          key: "usage_known",
          value: "!quick_compare"
        }
      ]
    ],
  }
}