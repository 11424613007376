import React from 'react';

import {
  IoIosFlame as GasIcon
} from 'react-icons/io'
import { currencyCZ } from 'scripts/helpers'
import currentMonthlyCost from 'forms/fields/currentMonthlyCost'
import CostCell from 'forms/cells/CostCell'
import CostRow from 'forms/inforows/CostRow'
import InfoElement from 'forms/InfoElement'
import SCH_getVATs from 'scripts/SCH_getVATs'
import { appendBoonCell } from 'forms/cells/BoonsCell';
import Indexable from 'types/Indexable';
import { ProductProvider, Region } from '../types';
import {
  onOffer_rawInfo,
  create_depositCell,
  commonTop,
  usageEstimatePurpose,
  getType,
  Savings
} from '../utility_funcs'
import { getSCFormPageInfo } from 'forms'
import Product, { BriefProduct } from './Product'
import FormData from './FormData'

const gasInfo = getSCFormPageInfo('utilities', 'gas')

export default {
  label: gasInfo.label,
  value: gasInfo.name,
  slug: gasInfo.slug,
  title: "Spočítej si úsporu na plynu",
  tagline: "Pokud chcete s nabídkou pomoci, stačí kliknout na ikonu operátora",
  submit: "Porovnat",
  navItem: {
    label: gasInfo.label,
    icon: <GasIcon className='icon' style={{ transformOrigin: 'center', transform: 'scale(1.15)' }} />,
    path: gasInfo.path.form,
  },
  options: {
    showHelpButtom: true,
    icon: ({ className }: { className: string }) =>
      <GasIcon className={(className ? className : '') + ' icon'} style={{ transform: 'scale(1.15)' }} />,
    hooks: {
      onLoad: (_: any, data: Indexable) => {
        // console.log({ gasData: data })
        return ({
          productProviders: data.productProviders
            .map((productProvider: ProductProvider) => ({
              value: productProvider.id,
              label: productProvider.name
            })
            ),
          regions: data.regions
            .map((region: Region) => ({
              value: region.id,
              label: region.name
            })
            )
        })
      },
      onSubmit: (_: any, data: Indexable) => {
        let perYear, type

        switch (data['usage_known']) {
          case 'known':
            perYear = Number(data.usage)
            type = data['usage::label'].replace('³', '3')
            break;

          case 'unknown':
            const usageTable = JSON.parse('{"apartment":{"1":{"water":1450,"cooking":200,"heating":5000},"2-3":{"water":3625,"cooking":500,"heating":7500},"4+":{"water":7250,"cooking":1000,"heating":10000}},"house":{"1":{"water":1450,"cooking":200,"heating":10000},"2-3":{"water":3625,"cooking":500,"heating":20000},"4+":{"water":7250,"cooking":1000,"heating":30000}}}')

            let kwhPerYear = 0

            if (data['usage_estimate_purpose::water'])
              kwhPerYear +=
                usageTable
                [data['usage_estimate_accomedation']]
                [data['usage_estimate_persons']]
                  .water

            if (data['usage_estimate_purpose::cooking'])
              kwhPerYear +=
                usageTable
                [data['usage_estimate_accomedation']]
                [data['usage_estimate_persons']]
                  .cooking

            if (data['usage_estimate_purpose::heating'])
              kwhPerYear +=
                usageTable
                [data['usage_estimate_accomedation']]
                [data['usage_estimate_persons']]
                  .heating

            type = 'MWh'
            perYear = kwhPerYear / 1000
            break

          case 'quick_compare':
            return undefined

          default:
            throw Error('invalid val for usage_known: ' + data['usage_known'])
        }

        return ({
          type: data.type,
          perYear: {
            type: type,
            value: perYear
          },
          region: data.regions,

          currentProvider: Number(data.productProviders) || null,
          currentMonthlyCost: Number(data.cost) || null
        })
      },
      onOffer: async (product: Product, formData: FormData) => {

        const
          type = getType(formData),
          vat = (await Promise.resolve(SCH_getVATs())).gas,
          incVAT = type === 'household',
          {
            info,
            costs: rawCosts,
            fields
          } = await onOffer_rawInfo(product, formData, vat),
          costs = rawCosts as Product['costs'] & Savings

        // console.log({ info })

        if (type === 'brief') {

          fields.splice(2, 0,
            {
              name: 'fixedCost',
              label: 'Měsíční poplatek',
              selector: (costs as BriefProduct['costs']).fixedCostPerMonth,
              cell: (passProps: Indexable) => <CostCell {...passProps}
                cost={(costs as BriefProduct['costs']).fixedCostPerMonth}
                vat={vat}
              />
            },
            {
              name: 'Cost',
              label: 'Cena za m³',
              selector: (costs as BriefProduct['costs']).costPerM3,
              cell: (passProps: Indexable) => <CostCell {...passProps}
                cost={(costs as BriefProduct['costs']).costPerM3}
                vat={vat} />
            },
            {
              name: 'Cost',
              label: 'Cena za MWh',
              selector: (costs as BriefProduct['costs']).costPerMWh,
              cell: (passProps: Indexable) => <CostCell {...passProps}
                cost={(costs as BriefProduct['costs']).costPerMWh}
                vat={vat} />
            }
          )
        }
        else {
          fields.push(create_depositCell(costs.totalCostPerMonth, vat))

          info[0].push(
            {
              label: 'Celkem DPH',
              value: currencyCZ(costs.totalCostPerMonth * vat),
              hide: true
            },
          )
          info[1].splice(1, 0,
            {
              label: 'Základní cena produktu',
              value: currencyCZ(product.costCalculation.fixedCostPerMonth.baseProduct),
              hide: true
            },
            {
              label: 'Distribuce produktu',
              value: currencyCZ(product.costCalculation.fixedCostPerMonth.distribution),
              hide: true
            },
            {
              label: 'Cena celkem za 1 MWh',
              value: <CostRow vat={incVAT ? vat : null}>
                {product.costCalculation.usageCostPerMWh.distribution +
                  product.costCalculation.usageCostPerMWh.product +
                  product.costCalculation.usageCostPerMWh.tax}
              </CostRow>,
            },
            {
              label: 'Cena za 1 MWh',
              value: currencyCZ(product.costCalculation.usageCostPerMWh.product),
              hide: true
            },
            {
              label: 'Distribuce za 1 MWh',
              value: currencyCZ(product.costCalculation.usageCostPerMWh.distribution),
              hide: true
            },
            {
              label: 'Energetická daň za 1 MWh',
              value: currencyCZ(product.costCalculation.usageCostPerMWh.tax),
              hide: true
            },
            {
              label: 'DPH za 1 MWh',
              value: currencyCZ((
                product.costCalculation.usageCostPerMWh.distribution +
                product.costCalculation.usageCostPerMWh.product +
                product.costCalculation.usageCostPerMWh.tax) * vat),
              hide: true
            },
            {
              label: 'Cena celkem za 1 m³',
              value: <CostRow vat={incVAT ? vat : null}>
                {product.costCalculation.usageCostPerM3.distribution +
                  product.costCalculation.usageCostPerM3.product +
                  product.costCalculation.usageCostPerM3.tax}
              </CostRow>,
            },
            {
              label: 'Cena za 1 m³',
              value: currencyCZ(product.costCalculation.usageCostPerM3.product),
              hide: true
            },
            {
              label: 'Distribuce za 1 m³',
              value: currencyCZ(product.costCalculation.usageCostPerM3.distribution),
              hide: true
            },
            {
              label: 'Energetická daň za 1 m³',
              value: currencyCZ(product.costCalculation.usageCostPerM3.tax),
              hide: true
            },
            {
              label: 'DPH za 1 m³',
              value: currencyCZ((
                product.costCalculation.usageCostPerM3.distribution +
                product.costCalculation.usageCostPerM3.product +
                product.costCalculation.usageCostPerM3.tax) * vat),
              hide: true
            },
          )
        }

        appendBoonCell(product, fields)

        return {
          ...product,
          fields,
          costs,
          info: <InfoElement info={info} />
        }
      },
      onSpecialOffer: () => null
    }
  },
  fields: [
    ...commonTop,
    usageEstimatePurpose,
    {
      name: "usage",
      label: [
        "MWh",
        "m³"
      ],
      type: "number",
      options: {
        min: 0,
        max: 62,
        step: 1,
        conditional: [
          [
            {
              key: "usage_known",
              value: "known"
            },
            {
              key: 'usage::label',
              value: 'MWh'
            }
          ]
        ],
        placeholder: "Roční spotřeba",
        tooltip: "Naleznete na posledním ročním vyúčtování.",
        mandatory: true,
      }
    },
    {
      name: "usage",
      label: [
        "MWh",
        "m³"
      ],
      type: "number",
      options: {
        min: 0,
        max: 5971,
        step: 100,
        conditional: [
          [
            {
              key: "usage_known",
              value: "known"
            },
            {
              key: 'usage::label',
              value: 'm³'
            }
          ]
        ],
        placeholder: "Roční spotřeba",
        tooltip: "Naleznete na posledním ročním vyúčtování.",
        mandatory: true,
      }
    },
    {
      name: "regions",
      type: "select",
      options: {
        placeholder: "Kraj",
        tooltip: "Vyberte kraj, ve kterém elektřinu odebíráte. Ceny se v jednotlivých krajích liší.",
        conditional: [
          [
            {
              key: "usage_known",
              value: "!quick_compare"
            }
          ]
        ],
        mandatory: true
      }
    },
    {
      name: "productProviders",
      type: "select",
      options: {
        placeholder: "Současný dodavatel",
        tooltip: "Vyberte dodavatele, od kterého teď odebíráte elektřinu. Porovnáme vám jeho ceny s ostatními na trhu.",
        conditional: [
          [
            {
              key: "usage_known",
              value: "!quick_compare"
            }
          ]
        ],
        mandatory: true
      }
    },
    currentMonthlyCost
  ]
}
