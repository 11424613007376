import React from 'react';

import {
  useHistory,
  useLocation
} from 'react-router-dom'
import {
  // FaTimes as RemoveIcon,
  FaUser as OperatorLoadingIcon,
  FaRegCheckCircle as DataLoadedIcon
} from 'react-icons/fa';
import Form, {
  generateDefaultData,
  validateForm
} from 'components/Form'
import {
  // joinArrayCZ,
  isEmpty,
  mergeDeep,
  hash,
  reactDev,
  // isObject,
  sleep,
  getSessionItem,
  setSessionItem,
  removeSessionItem,
  isPureObject,
  cancellablePromise,
  // pathToStr,
  // awaitAll,
} from 'scripts/helpers';
import './style.scss'
import Offer from 'components/Offer/Static'
import useBasket from 'hooks/useBasket'
import contactForm from 'forms/contact'
import ModalBox from 'components/ModalBox'
import uuid from 'uuid'
import NotificationSystem from 'react-notification-system'
// import withResponsivity from 'HOCs/withResponsivity'
import liveSuppGetPageAPI from 'scripts/LS_PageAPI'
import setriChytreAPI, { FileToSCH } from 'scripts/SCH_API'
import LoadingSpinner from 'components/LoadingIcon'
import JumpingEllipsis from 'components/JumpingEllipsis'
import Animation from 'react-useanimations';
import AgreementText from 'components/AgreementText'
import { createPortal } from 'react-dom';
import useResponsivity from 'hooks/useResponsivity';
import LoadingIcon from 'components/LoadingIcon';
// import { getOffers } from 'pages/Offers';
import createOffer from 'pages/Offers/createOffer';
import address from 'forms/address';
import SCButton from 'components/SCButton';
import { getSCFormPageInfo, getSCFormPage } from 'forms';
import useError from 'hooks/useError';
import useTitle from 'hooks/useTitle';
// import CloseIcon from 'components/CloseIcon';
import Tooltip from 'components/Tooltip';

const
  key_seed = uuid(),
  DataLoadingIcon = props => <Animation {...props} animationKey='arrowUp' />,
  formFields = contactForm.layout,
  distribution_button = [
    {
      label: 'Odběrové místo není stejné jako trvalé bydliště',
      type: 'household'
    },
    {
      label: 'Odběrové místo není stejné jako sídlo firmy',
      type: 'business'
    }
  ]

formFields.splice(8, 0,
  ...distribution_button.map(
    ({ label, type }) => ({
      name: 'differentSupplyPointAddress',
      type: 'check',
      label: label,
      options: {
        checkbox_first: true,
        conditional: [
          [
            {
              key: 'type',
              value: type
            }
          ]
        ]
      }
    })
  ),
  {
    name: 'address-delimeter',
    label: 'Adresa odběrného místa',
    type: 'delimeter',
    options: {
      conditional: [
        [
          {
            key: 'differentSupplyPointAddress',
            value: true
          }
        ]
      ]
    },
  },
  {
    name: 'supplyPointAddress',
    type: 'wrapper',
    options: {
      wrap_type: 'none',
      mandatory: true,
      conditional: [
        [
          {
            key: 'differentSupplyPointAddress',
            value: true
          }
        ]
      ]
    },
    data: address.layout
  },
)

formFields.push({
  name: 'invoices',
  type: 'file',
  className: 'fullwidth',
  label: 'Nahrát další',
  options: {
    placeholder: 'Nahrajte fakturu',
    tooltip: 'Z Vaší faktury získáme informace na které bychom se Vás jinak museli doptávat',
  }
})

// console.log({ importedContactForm: contactForm })
function Checkout(props) {
  const {
    className
  } = props,
    [basket, setBasket] = useBasket(),
    [formData, _setFormData] = React.useState(
      mergeDeep(
        generateDefaultData(formFields),
        getSessionItem('checkoutData', 'json') || {},
      )
    ),
    [submitting, setSubmitting] = React.useState(false),
    [isLoading, setLoading] = React.useState(true),
    [highlight, setHightlight] = React.useState(false),
    {
      isGreaterThan,
      isLessThan,
    } = useResponsivity(),
    [removeNotification, setRemoveNotification] = React.useState(null),
    notification_ref = React.useRef(),
    getFormData = React.useCallback(key => formData[key], [formData]),
    setFormData = React.useCallback((key, val) => {
      _setFormData({
        ...formData,
        [key]: val
      })
    }, [formData]),
    location = useLocation(),
    history = useHistory(),
    throwError = useError(),
    onComplete = React.useCallback(() => {
      // setSubmitting(false)
      removeSessionItem('checkoutData')
      setBasket([])
      history.replace({
        pathname: '/'
      })
    }, [history, setBasket]),
    iconSize = 60,
    submitStates = {
      operator: {
        icon: <>
          <LoadingSpinner size={iconSize} duration={submitting === 'operator' ? 3 : 0} weight={4} />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '100%',
          }}>
            <OperatorLoadingIcon className='icon' />
          </div>
        </>,
        label: <>Přiřazuji objednávku operátorovi<JumpingEllipsis /></>
      },
      data: {
        icon: <DataLoadingIcon size={iconSize} />,
        label: <>Odesílám objednávku<JumpingEllipsis /></>
      },
      complete: {
        icon: <DataLoadedIcon size={iconSize} style={{ color: 'green' }} />,
        label: <>Děkujeme za objednávku. Dokumenty Vám přiveze kurýr.</>
      }
    },
    onContinue = React.useCallback(async function submit_order() {
      // console.log('onContinue clicked')

      const invalid = validateForm(formFields, getFormData)

      // Show error and return if there are invalid fields
      if (!isEmpty(invalid)) {
        console.warn({ invalid })
        setHightlight(false)
        const timeout = setTimeout(() => { setHightlight(true) }, 1)
        return () => { clearTimeout(timeout) }
      }

      // Assign an operator
      setSubmitting('operator')
      const operator = await (await liveSuppGetPageAPI()).getResolvingOperator()

      // Send off form data
      setSubmitting('data')
      let dataToSend = {
        lsOperatorId: operator,
        specialOffers: [],
        products: basket.map(item => {
          let brief

          if (item.origin.searchId === undefined && item.origin.formData === undefined)
            brief = true
          else if (typeof item.origin.searchId === 'number' && isPureObject(item.origin.formData))
            brief = false
          else {
            console.error({ item })
            throwError(new Error('Basket item has undetermined `brief` state'))
          }

          return {
            amount: 1,
            id: item.product.id,
            productType: item.origin.formPage,
            searchId: item.origin.searchId,
            brief
          }
        }),
        contact: {
          name: getFormData('first-name') + ' ' + getFormData('last-name'),
          email: getFormData('email'),
          phone: getFormData('phone'),
        }
      }

      //-- Process and push invoice files
      dataToSend.files = await Promise.all(
        formData.invoices
          .filter(file => file instanceof File)
          .map(file => new Promise(async resolve => {
            const SCHFile = await FileToSCH(file)
            resolve(SCHFile)
          })))

      const
        address = {
          street: getFormData('street'),
          streetNumber: getFormData('streetNumber'),
          city: getFormData('city'),
          postalCode: getFormData('postalCode'),
        },
        supplyPointAddress = {
          street: getFormData('supplyPointAddress.street'),
          streetNumber: getFormData('supplyPointAddress.streetNumber'),
          city: getFormData('supplyPointAddress.city'),
          postalCode: getFormData('supplyPointAddress.postalCode'),
        }

      switch (formData.type) {
        case 'household':
          dataToSend.contact.address = address

          if (getFormData('differentSupplyPointAddress'))
            dataToSend.contact.supplyPointAddress = supplyPointAddress

          break
        case 'business':
          dataToSend.company = {
            name: getFormData('company-name'),
            address: address,
            vatin: getFormData('company-ico')
          }
          dataToSend.contact.positionName = getFormData('positionName')

          if (getFormData('differentSupplyPointAddress'))
            dataToSend.company.supplyPointAddress = supplyPointAddress

          break
        default:
          throw Error(`${formData.type} is an invalid type`)
      }

      let orderSendSuccess
      if (reactDev()) {
        await sleep(2000)
        console.log({ dataToSend })
        orderSendSuccess = true
      }
      else
        await setriChytreAPI('/offers/interested', dataToSend)
          .then(() => orderSendSuccess = true)
          .catch(err => {
            orderSendSuccess = false
            console.error(err)
          })

      if (orderSendSuccess === false) {
        const { current: notification } = notification_ref
        notification.addNotification({
          title: 'Nezdařená objednávka',
          message: 'Nepodařilo se odeslat objednávku. Prosím zkuste to později.',
          level: 'error',
          position: 'tc',
          autoDismiss: 10,
          dismissible: 'button',
        })
        setSubmitting(false)
        return
      }

      setSubmitting('complete')
    }, [basket, formData.invoices, formData.type, getFormData, throwError]),
    editOffers = ({ origin }) => () => {
      history.push({
        pathname: getSCFormPageInfo(origin.formType, origin.formPage).path.offers,
        state: {
          from: location,
          ...origin,
        }
      })
    },
    removeItem = (item) => () => {
      if (removeNotification)
        return

      notification_ref.current.addNotification({
        title: 'Opravdu chcete odstranit nabídku?',
        level: 'warning',
        position: 'tc',
        autoDismiss: Infinity,
        dismissible: 'button',
        onAdd: newNotification => {
          setRemoveNotification(newNotification)
        },
        onRemove: () => {
          setRemoveNotification(null)
        },
        action: {
          label: 'Odstranit',
          callback: () => {
            setBasket(basket.filter(basket_item => basket_item !== item))
          },
        }
      })
    },
    Title = useTitle()

  //-- Check if all basket items are business
  React.useEffect(() => {

    const
      types = ['household', 'business'],
      [promise, cancel] = cancellablePromise(
        Promise.all(
          basket.map(
            async item => {
              const { customerType } = await createOffer(item.origin, item.product)
              return types.reduce((is, type) => ({
                ...is, [type]: customerType ? customerType === type : null
              }), {})
            }
          )
        )
      )

    promise.then(is_arr => {
      for (const type of types)
        if (
          is_arr.every(is => is[type] || (is[type] === null)) &&
          is_arr.some(is => is[type])
        ) {
          // console.log({ type })
          setFormData('type', type)
          return
        }
    })

    setLoading(false)
    return cancel

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //-- Store form data
  React.useEffect(() => {
    setSessionItem('checkoutData', formData)
  }, [formData])

  // console.log({ basket })
  // console.log('checkout rendering')
  // console.log({ removeNotification })
  // console.log({ formFields })
  return <>
    <Title>objednávka</Title>

    <div className={['Component-Checkout', 'whitebox', className].toClass()}>
      <h2>Objednávkový formulář</h2>

      {isLoading ? <LoadingIcon className='loading-icon' /> :
        <div className={['form-wrapper',
          isGreaterThan('medium') ?
            'two-col' :
            'one-col'
        ].toClass()}>
          <Form {...{ formFields, getFormData, setFormData, highlight }} />
        </div>}

      <div className='products-section-wrapper'>
        <div className='product-section-title'>
          {basket.length === 0 ?
            'Košík je prázdný' : 'Vybrané produkty'}
        </div>
        <div className='product-section-scrollable'>
          {basket.map((item, key_iterator) => {
            // console.log({ item })

            return <div className='offer-wrapper'
              key={hash(key_seed, key_iterator)} >
              <Offer
                compact={isLessThan('section')}
                noButtons={true}
                product={item.product}
                origin={item.origin}
              />
              <div className='offer-buttons-container'>

                {/* <div className='offer-buttons-left-container'> */}
                <Tooltip className='offer-button-like offer-icon-wrapper'
                  text={getSCFormPageInfo(item.origin.formType, item.origin.formPage).label}>
                  {getSCFormPage(item.origin.formType, item.origin.formPage).navItem.icon}
                </Tooltip>
                {/* </div> */}

                {/* <div className='offer-buttons-right-container'> */}
                <span className='offer-button offer-button-edit' onClick={editOffers(item)}>
                  Zpět na nabídky
                  </span>
                <span className={['offer-button', 'offer-button-remove',
                  removeNotification ? 'disabled' : null
                ].toClass()} onClick={removeItem(item)}>
                  Odstranit
                    </span>
                {/* </div> */}
              </div>
            </div>
          })}
        </div>
      </div>

      <div className='buttons-outer-wrapper'>
        <div className='buttons-wrapper'>
          {basket.length === 0 ? null :
            <AgreementText>
              <SCButton onClick={onContinue}>
                Odeslat
              </SCButton>
            </AgreementText>}
        </div>
      </div>

      <ModalBox id='submit-progress' isOpen={!!submitting}
        close={submitting === 'complete' ? onComplete : undefined}>
        <div className='submit-progress-container'>
          <div className='icon-wrapper'>
            {submitStates[submitting ? submitting : 'complete'].icon}
          </div>
          <div className='text-wrapper'>
            {submitStates[submitting ? submitting : 'complete'].label}
          </div>
        </div>
      </ModalBox>

      {createPortal(<NotificationSystem ref={notification_ref} />, document.body)}
    </div >
  </>
}

export default Checkout