import React, { ReactNode } from 'react';
import PropTypes from 'prop-types'
import {
  someKeys,
  compareDeep,
} from 'scripts/helpers'
import getSize from 'get-size'
import { isNumber } from 'util'
import Indexable from 'types/Indexable'

const verbose = {
  resize: false,
  queryFuncs: false,
  getQueryFuncs: false,
  getBreakpoint: false,
  resizeListener: false,
}

export type breakpoint = {
  name: string,
  breakpoint: number,
}

type SizeQuery = string | number

type SizeQueryFunc = (query: SizeQuery) => boolean

interface Dimensions extends Indexable {
  width: number,
  height: number,
}

type ListenerFunc = (newDimensions: Dimensions) => void
type ListenerType = {
  [dimension in keyof Dimensions]: boolean
}
type Listener = {
  callback: ListenerFunc,
  type: ListenerType,
}
type Listeners = {
  [listenerId: string]: Listener
}

type SizeQueryFuncs = {
  isLessThan: SizeQueryFunc,
  isLessThanOrEqual: SizeQueryFunc,
  isGreaterThan: SizeQueryFunc,
  isGreaterThanOrEqual: SizeQueryFunc,
  isEqual: SizeQueryFunc,
}

interface ValueType extends SizeQueryFuncs {
  addResizeListener: (callback: ListenerFunc, type: ListenerType) => () => void,
}

const ResponsivityContext = React.createContext<ValueType | null>(null);
export default ResponsivityContext
export const ResponsivityConsumer = ResponsivityContext.Consumer

function getQueryFuncs(
  dimensions: Dimensions,
  getBreakpoint: (query: SizeQuery) => number
): SizeQueryFuncs {
  verbose.getQueryFuncs && console.log('getQueryFuncs():', { dimensions })

  return {
    isLessThan: (query: SizeQuery) => {
      verbose.queryFuncs && console.log(`isLessThan(${query})`, { width: dimensions.width })
      return dimensions.width < getBreakpoint(query)
    },
    isLessThanOrEqual: (query: SizeQuery) => {
      verbose.queryFuncs && console.log(`isLessThanOrEqual(${query})`, { width: dimensions.width })
      return dimensions.width <= getBreakpoint(query)
    },
    isGreaterThan: (query: SizeQuery) => {
      verbose.queryFuncs && console.log(`isGreaterThan(${query})`, { width: dimensions.width })
      return dimensions.width > getBreakpoint(query)
    },
    isGreaterThanOrEqual: (query: SizeQuery) => {
      verbose.queryFuncs && console.log(`isGreaterThanOrEqual(${query})`, { width: dimensions.width })
      return dimensions.width >= getBreakpoint(query)
    },
    isEqual: (query: SizeQuery) => {
      verbose.queryFuncs && console.log(`isEqual(${query})`, { width: dimensions.width })
      return dimensions.width === getBreakpoint(query)
    }
  }
}

export function ResponsivityProvider(props: {
  breakpoints: breakpoint[],
  children: ReactNode,
}) {
  const {
    breakpoints,
    children,
  } = props,
    dimensions_ref = React.useRef({ width: NaN, height: NaN } as Dimensions),
    resizeListeners_ref = React.useRef({} as Listeners),
    getBreakpoint = React.useCallback((query: SizeQuery) => {

      if (isNumber(query)) {
        verbose.getBreakpoint && console.log(`getBreakpoint(${query}) =`, query)
        return query
      }

      const breakpoint = breakpoints.find(bp => bp.name === query)
      if (breakpoint) {
        verbose.getBreakpoint && console.log(`getBreakpoint(${query}) =`, breakpoint.breakpoint)
        return breakpoint.breakpoint
      }

      verbose.getBreakpoint && console.log(`getBreakpoint(${query}) =`, NaN)
      return NaN
    }, [breakpoints]),
    [queryFuncs, setQueryFuncs] = React.useState(getQueryFuncs(dimensions_ref.current, getBreakpoint)),

    /**
     * Adds a resize listener
     * @arg {Function} callback Called on resize.
     * @arg {Object} [type] Which dimensions to listen for changes in.
     * @arg {Bool} [type.width = true] Listen for changes in width.
     * @arg {Bool} [type.height = true] Listen for changes in height.
     * @returns {Function} Calling this function removes the listener.
     */
    addResizeListener = React.useCallback((
      callback: ListenerFunc,
      type: Partial<ListenerType> = {}
    ) => {
      const
        listenerId = Symbol('Resize listener') as any,
        listenerType: ListenerType = {
          height: true,
          width: true,
          ...type
        };

      resizeListeners_ref.current[listenerId] = { callback, type: listenerType }
      verbose.resizeListener && console.log('added listener', { id: listenerId, callback })

      return () => {
        verbose.resizeListener && console.log('removed listener', resizeListeners_ref.current[listenerId])
        delete resizeListeners_ref.current[listenerId]
      }
    }, []),
    sizeProps_ref = React.useRef({})

  //-- Set resize listener
  React.useEffect(() => {

    function updateDimensions() {
      const
        { current: old_dimensions } = dimensions_ref,
        new_dimensions = someKeys(['width', 'height'], getSize(document.documentElement)) as Dimensions

      verbose.resize && console.log({ old_dimensions, new_dimensions })
      if (!compareDeep(old_dimensions, new_dimensions)) {

        const updated_dimensions = Object.entries(new_dimensions)
          .reduce((updated, [name, new_dim]) => ({
            ...updated,
            [name]: new_dim !== old_dimensions[name]
          }), {})

        //-- Commit dimentions
        dimensions_ref.current = new_dimensions

        //-- Generate new query funcs

        const
          queryFuncs = getQueryFuncs(new_dimensions, getBreakpoint),
          //-- Call listeners
          { current: resizeListeners } = resizeListeners_ref
        Object.getOwnPropertySymbols(resizeListeners)
          .map(listenerId => (resizeListeners as any)[listenerId])
          .forEach(listener => {
            if (Object.entries(updated_dimensions)
              .some(([dimension, updated]) => listener.type[dimension] && updated)
            ) {
              verbose.resizeListener && console.log('Calling resize', { listener })
              listener.callback(new_dimensions)
            }
          })

        //-- Get size props
        const
          { current: old_sizeProps } = sizeProps_ref,
          allBreakpoints = breakpoints.map(({ name }) => name),
          new_sizeProps = {
            'width-le': allBreakpoints.filter(queryFuncs.isLessThanOrEqual),
            'width-gt': allBreakpoints.filter(queryFuncs.isGreaterThan),
          }

        verbose.resize && console.log({ old_sizeProps, new_sizeProps })
        if (!compareDeep(old_sizeProps, new_sizeProps)) {

          //-- Update body attributes
          Object.entries(new_sizeProps)
            .forEach(([propName, sizeProps]) => {
              verbose.resize && console.log(`body.attr(${propName}) =`, sizeProps)
              document.body.setAttribute(propName, sizeProps.pureJoin(' '))
            })

          //-- Commit size properties
          sizeProps_ref.current = new_sizeProps

          //-- Commit new query funcs (and cause rerender)
          setQueryFuncs(queryFuncs)
        }
      }
    }

    window.addEventListener('resize', updateDimensions)
    window.dispatchEvent(new Event('resize'))

    return () => window.removeEventListener('resize', updateDimensions)
  }, [breakpoints, getBreakpoint, queryFuncs.isGreaterThan, queryFuncs.isLessThanOrEqual])

  return <ResponsivityContext.Provider
    value={{
      ...queryFuncs,
      // sizeProps: Object.entries(sizeProps_ref.current)
      //   .map(([propName, propValues]) => ([propName, propValues.pureJoin(' ')])),
      addResizeListener
    }}>
    {children}
  </ResponsivityContext.Provider>;
}

ResponsivityProvider.propTypes = {
  breakpoints: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      breakpoint: PropTypes.number.isRequired
    })
  ).isRequired
}