import {
  number,
  shape,
  string
} from "prop-types"

import url from "url-prop-type"

export default shape({
  id: number.isRequired,
  logoUrl: url,
  name: string.isRequired
});
