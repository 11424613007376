import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';
import dimension from 'types/dimension';
import { calcDimension } from 'scripts/helpers';
import Tooltip from 'components/Tooltip'
import boon_types from 'pages/Offers/boons'
import { getVIPBoon } from '..';

function PrivateRibbon(props) {
  return <div className='Component-PrivateRibbon'>
    <Tooltip className='private-offer-ribbon'
      text={getVIPBoon({ boons: boon_types }).description}
      style={{
        transform: `rotate(${props.angle}deg)`,
        paddingBlockStart: calcDimension(props.verticalPadding),
        paddingBlockEnd: calcDimension(props.verticalPadding),
      }}>
      Chytrá volba
    </Tooltip>
  </div>
}

PrivateRibbon.propTypes = {
  angle: PropTypes.number.isRequired,
  verticalPadding: dimension,
}
PrivateRibbon.defaultProps = {
  verticalPadding: 0
}

export default PrivateRibbon