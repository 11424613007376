import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  FaBars as FullIcon,
  FaTh as CompactIcon,
} from 'react-icons/fa'
import OfferTopButton from '../OfferTopButton'

import './style.scss'

function OfferViewSwitchIcon(raw_props) {
  const {
    compact,
    ...props
  } = raw_props

  return <OfferTopButton {...{
    ...props,
    className: `${props.className} Component-OfferViewSwitchIcon`
  }}>
    <div className={`icon-wrapper ${compact ? 'compact' : 'full'}`}>
      <FullIcon className='icon icon-full' />
      <CompactIcon className='icon icon-compact' />
    </div>
  </OfferTopButton>
}

OfferViewSwitchIcon.propTypes = {
  compact: PropTypes.bool.isRequired
}

export default styled(OfferViewSwitchIcon)`
`;