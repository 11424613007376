import React from 'react';

import DisplayFitting from 'components/DisplayFitting'
import InputNumber from 'components/Input/Number'

function TestingPage(props: {
  className?: string,
}) {
  const {
    className,
  } = props,
    [width, setWidth] = React.useState<number>(200)

  return <div className={['TestingPage-Component', className].toClass()} style={{ width }}>
    <div style={{ outline: '1px solid red' }}>
      <DisplayFitting>
        <div>this is longer text</div>
        <div>short text</div>
      </DisplayFitting>
    </div>

    <div style={{
      bottom: '20px',
      color: 'black',
      position: 'fixed',
      right: '20px'
    }}>
      <InputNumber
        value={width}
        onChange={setWidth}
        min={0}
      />
    </div>
  </div>
}

export default TestingPage