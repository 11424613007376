import React from 'react';
import { useSpring, animated } from 'react-spring'
import { Link } from 'react-router-dom'
import withResponsivity from 'HOCs/withResponsivity'
import './style.scss';

function Tile(props) {
  const
    [hovered, setHovered] = React.useState(false),
    // [mousePos, setMousePos] = React.useState({ x: 0, y: 0 }),
    {
      path,
      icon,
      label,
      isGreaterThan,
    } = props,
    animateOnHover = isGreaterThan('section'),
    // perspectiveOnHover = isGreaterThan('medium'),
    { hover_anim } = useSpring({
      hover_anim: (hovered && animateOnHover) ? 1 : 0,
      config: {
        mass: 2,
        tension: hovered ? 500 : 600,
        // clamp: !hovered
      }
    })
  // ,
  // [persp_anim, setPersp_anim] = useSpring(() => ({
  //   xy: [0, 0]
  // }))

  return <animated.div className='Component-Tile' style={{
    transform: hover_anim.interpolate(val =>
      `translateY(${Math.abs(val) * -10}px)`
    ),
    //   filter: hover_anim.interpolate(val =>
    //     `drop-shadow(0 ${Math.abs(val) * 12.5}px ${Math.abs(val) * 6}px #0003)`
    //   ),
    //   textShadow: hover_anim.interpolate(_val => {
    //     const val = Math.abs(_val)
    //     return `0 ${val * 5}px ${val * 4}px #0006`
    //   }
    //   )
    // }}>
    //   <animated.div className='animation-wrapper' style={{
    //     transform: persp_anim.xy.interpolate((x, y) =>
    //       `perspective(100px) rotateY(${x}deg) rotateX(${-y}deg)`
    //     )
  }}>

    <Link to={path} className='tile'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false)
        // setPersp_anim({ xy: [0, 0] })
      }}
    // onMouseMove={({ clientX, clientY, target }) => {
    //   if (!perspectiveOnHover) {
    //     setPersp_anim({ xy: [0, 0] })
    //     return
    //   }

    //   const {
    //     x: rectX,
    //     y: rectY,
    //     width: rectW,
    //     height: rectH,
    //   } = target.getBoundingClientRect()

    //   setPersp_anim({
    //     xy: [
    //       (2 * (clientX - rectX) / rectW - 1),
    //       (2 * (clientY - rectY) / rectH - 1),
    //     ]
    //   })
    // }}
    >
      {isGreaterThan('medium') ? icon : null}
      <div className='text'>
        {label}
      </div>
    </Link>
    {/* </animated.div > */}
  </animated.div >
}

export default withResponsivity(Tile)