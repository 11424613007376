import React from 'react';

import './style.scss';
import {
  useHistory,
  useLocation
} from 'react-router-dom';
import {
  GoAlert as AlertIcon
} from 'react-icons/go'
import useTitle from 'hooks/useTitle';

function RedirectPage(props) {

  const
    Title = useTitle(),
    history = useHistory(),
    location = useLocation()

  return <>
    <Title>Přesměrování</Title>
    <div className='Component-RedirectPage'>
      <div className='message-container'>
        <div className='message-title-wrapper'>
          <AlertIcon className='alert-icon' />
          <div className='message-title'>
            Upozornění
        </div>
          <AlertIcon className='alert-icon' />
        </div>
        <div className='message-wrapper'>
          <div className='message'>
            {props.message}
          </div>
        </div>
        <div className='button-wrapper'>
          <div className='button'
            onClick={() => {
              history.replace(location)
            }}>
            Pokračovat
        </div>
        </div>
      </div>
    </div>
  </>
}

export default RedirectPage