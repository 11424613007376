import React from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types';
import CloseIcon from 'components/CloseIcon'
import {
  enableBodyScroll,
  disableBodyScroll,
  isFunction,
} from 'scripts/helpers'
import {
  useSpring,
  animated,
} from 'react-spring'
import './style.scss'
import { isMobileOnly } from 'react-device-detect'
import useResponsivity from 'hooks/useResponsivity';

function ModalBox(props) {

  //-- enable scroll on unmount
  React.useEffect(() => enableBodyScroll, [])

  //-- disable scroll if open
  React.useEffect(() => {
    if (props.isOpen) {
      disableBodyScroll()
      setRenderContent(true)
    }
    else
      enableBodyScroll()
  }, [props.isOpen])

  const {
    className,
    children,
    isOpen,
    persistentContent,
    onClick,
    close: _close,
    ...passProps
  } = props,
    [renderContent, setRenderContent] = React.useState(isOpen),
    close = isFunction(_close) ? () => {
      enableBodyScroll()
      _close()
    } : null,
    { isLessThanOrEqual } = useResponsivity(),
    skip_animations = isMobileOnly || isLessThanOrEqual('mobile'),
    anim_config = {
      mass: 1 * 10 ** -1,
      friction: 1 * 10 ** 1,
      tension: 1 * 10 ** 2,
    },
    background_anim = useSpring({
      config: {
        ...anim_config,
        tension: anim_config.tension * 2,
      },
      opacity: isOpen ? 1 : 0,
      pointerEvents: isOpen ? 'auto' : 'none',
      immediate: skip_animations,
    }),
    body_anim = useSpring({
      bottom: `${!isOpen * 25}%`,
      config: anim_config,
      onRest: () => {
        if (!isOpen)
          setRenderContent(false)
      },
      // filter: `contrast(${1 - .2 * !isOpen}) brightness(${1 + 9 * !isOpen})`,
      // transform: `scaleX(${1 * isOpen}) scaleY(${1 + 19 * !isOpen})`,
      transform: `scale(${1 + !isOpen})`,
      immediate: skip_animations,
    })

  return (renderContent || persistentContent) ?
    ReactDOM.createPortal(
      <animated.div className='Component-ModalBox' onClick={close} style={background_anim}>
        <animated.div className='Component-ModalBox-content-wrapper' style={body_anim}>
          <div className={['Component-ModalBox-content', className].toClass()}
            onClick={e => {
              e.stopPropagation()
              if (onClick)
                return onClick(e)
            }}
            {...passProps}>
            {isFunction(close) ?
              <CloseIcon className='closeIcon' onClick={close} /> :
              null}
            {children}
          </div>
        </animated.div>
      </animated.div>, document.body)
    : null
}

ModalBox.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  persistentContent: PropTypes.bool,
}
ModalBox.defaultProps = {
  isOpen: true,
  close: null,
  persistentContent: true,
}

export default ModalBox;