import React from 'react';

import useResponsivity from 'hooks/useResponsivity'

export default function (Component: typeof React.Component) {
  return React.forwardRef((props, ref) => {
    return <Component {...props}
      {...useResponsivity()}
      ref={ref as any}
    />
  })
}