import React from 'react'

import PropTypes from 'prop-types';
import {
  hash,
  isEmpty,
  compareDeep,
  constructArray,
} from 'scripts/helpers'
import offer_proptype from 'types/offer'
import useBasket from 'hooks/useBasket'
import './style.scss'
// import {
//   animated,
//   useSpring
// } from 'react-spring'
import uuid from 'uuid'
// import withResponsivity from 'HOCs/withResponsivity';
import EvenRowGrid from 'components/EvenRowGrid';
// import { isMobile } from 'react-device-detect';
import useResponsivity from 'hooks/useResponsivity';
import {
  $$offer_product,
  $$offer_origin
} from '..';
import Offer from './OfferWrapper'
// import Debounce from 'awesome-debounce-promise';

// export const logo_element = Symbol('logo element')

const
  key_seed = uuid(),
  TableGrid = React.forwardRef((props, ref) => {
    // console.log(props)
    return <EvenRowGrid
      className='offers-grid'
      rowGap='30px'
      columnGap='30px'
      {...{ ref }}
      {...props}
    />
  })

function OffersTable(props) {
  const
    {
      children: offers,
      isLoading,
    } = props,
    {
      isLessThan
    } = useResponsivity(),
    [basket, setBasket] = useBasket(),
    oldOffers_ref = React.useRef(),
    oldBasket_ref = React.useRef(),
    // [displayLoadingOffers, setDisplayLoadingOffers] = React.useState(true),
    // [failedLogos, setFailedLogos] = React.useState([]),
    gridRef = React.useRef(),
    mobile = isLessThan('section'),
    compact = props.forceMobile || mobile
  // loading_anim = useSpring({
  //   immediate: isMobile,
  //   opacity: isLoading ? 1 : 0,
  //   onRest: () => {
  //     if (isLoading === false)
  //       setDisplayLoadingOffers(false)
  //   }
  // }),


  //-- Recal offers if offers change
  React.useEffect(() => {
    const
      { current: oldOffers } = oldOffers_ref,
      { current: oldBasket } = oldBasket_ref

    if (!compareDeep(offers, oldOffers) || !compareDeep(basket, oldBasket)) {
      oldOffers_ref.current = offers
      oldBasket_ref.current = basket

      if (gridRef.current) {
        // console.log('forcing recalc for offer change')
        gridRef.current.forceRecalc()
      }
    }
  }, [basket, gridRef, offers, props.forceMobile])

  React.useEffect(() => {
    if (gridRef.current) {
      // console.log('forcing recalc for compact change')
      gridRef.current.forceRecalc()
    }
  }, [compact])

  // console.log({ 'table offers': JSON.parse(JSON.stringify(offers)) })
  return <div className={['Component-OffersTable', compact ? 'compact' : 'full'].toClass()}>

    {(isEmpty(offers) && !isLoading) ?
      <div className='no-offers'>
        Zvoleným požadavkům neodpovídají žádné nabídky
      </div> :
      <TableGrid ref={gridRef} justifyContent={compact ? 'center' : 'stretch'}>
        {(isLoading ? constructArray(6, () => null) : offers)
          .map((offer, index) =>
            <Offer className='offer-wrapper' key={hash(key_seed, index)} index={index}
              compact={compact}
              openModal={props.openModal}
              onContinue={() => {
                setBasket([
                  ...basket,
                  {
                    product: offer[$$offer_product],
                    origin: offer[$$offer_origin],
                  }
                ])

                props.continue()
              }}>
              {offer}
            </Offer>
          )}
      </TableGrid>
    }
  </div>
}

OffersTable.propTypes = {
  children: PropTypes.arrayOf(offer_proptype),
  isLoading: PropTypes.bool.isRequired,
  forceMobile: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  continue: PropTypes.func.isRequired,
}
OffersTable.defaultProps = {
  openModal: () => console.error('No open modal handler'),
  // onInterest: () => {},
  // continue: () => {},
}

export default OffersTable
