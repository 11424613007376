import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  withRouter,
} from 'react-router-dom'
import toPx from 'to-px'
import {
  index,
  awaitAll,
  isObject,
  isEmpty,
  mergeDeep,
  isFunction,
  isPureObject,
  getSessionItem,
  setSessionItem,
  // strToPath,
  pathCompare,
  // index_eraseValue,
} from 'scripts/helpers'
import SCFormLoading from './Loading'
import setriChytreAPI from 'scripts/SCH_API'
import LoadingSpinner from 'components/LoadingIcon'
import Form, {
  generateDefaultData,
  validateForm
} from 'components/Form'
import './style.scss'
import {
  getSCFormTypeInfo,
  getSCForm,
  getSCFormPageInfo,
  getSCFormPage,
} from 'forms'
import AgreementText from 'components/AgreementText'
import operatorIcon from 'assets/Etel-orb.jpg'
import OperatorOrb from 'components/OperatorOrb'
import withResponsivity from 'HOCs/withResponsivity'
import { Spring, animated } from 'react-spring/renderprops'
import getSize from 'get-size'
import withError from 'HOCs/withError'
import withTitle from 'HOCs/withTitle'

const loading_height = 500
class Forms extends Component {
  state = {
    loading: true,
    submitting: false,
    submittedData: false,
    highlightFields: false,
    currentFormPage: getSCFormPageInfo(
      this.props.match.params.formTypeSlug,
      this.props.match.params.formPageSlug
    ).name,
    formHeight: loading_height,
    loadingResizeFinished: false,
  }

  formHead = getSCFormTypeInfo(this.props.match.params.formTypeSlug)
  form = getSCForm(this.props.match.params.formTypeSlug)

  updateFormPage(options) {
    const
      {
        newFormPage,
        callback
      } = {
        newFormPage: this.state.currentFormPage,
        callback: () => { },
        ...options
      },
      formPageInfo = getSCFormPageInfo(
        this.props.match.params.formTypeSlug,
        newFormPage
      ),
      updateURL = () => {
        if (!pathCompare(this.props.location, formPageInfo.path.form)) {
          // console.log('updating URL to', formPageInfo.path.form)
          this.props.history.replace(formPageInfo.path.form)
        }
      }

    // console.log({ options, newFormPage, formPageInfo })

    if (this.state.currentFormPage !== formPageInfo.name)
      this.setState({ currentFormPage: formPageInfo.name },
        (...args) => {
          updateURL()
          this.runHook('onPageLoad', this.getCurrentForm())
          return callback(...args)
        }
      )
    else
      updateURL()
  }

  //-- load form
  async componentDidMount() {

    let form_fields = {}

    // console.log(this.form)
    //get and hook form layout
    await awaitAll(
      this.form.map(formPage => {

        const formPageInfo = getSCFormPageInfo(this.formHead.name, formPage.value)

        return setriChytreAPI(`${formPageInfo.name}/formData`)
          .then(({ data }) => this.runHook('onLoad', formPage, data))
          .then(data => form_fields[formPage.value] = data)
          .catch(err => {
            if (!isPureObject(err))
              this.props.throwError(err)
            else if (err.err !== 'routeNotValid')
              this.props.throwError(err.err)
          })
      })
    )

    // data is processed

    //for each form, read options into form_page
    for (const formPage of this.form)
      if (formPage.value in form_fields)
        for (const name in form_fields[formPage.value]) {
          const form_field = formPage.fields.find(field => field.name === name)
          // console.log({ name: form_field, value: form.value, form_fields })
          // if (isObject(form_field))
          form_field.data = form_fields[formPage.value][name]
        }

    // grab form data if given
    let formData = {}
    const storedData = getSessionItem('formData', 'json')

    if (this.props.location.state && this.props.location.state.formData) {
      // console.log('Loading data from location', this.props.location)
      formData = this.props.location.state.formData
    }
    else if (storedData && storedData[this.formHead.name]) {
      // console.log('Loading data from storage', { storedData })
      formData = storedData[this.formHead.name]
    }

    // fill out form data in missing fields
    for (const form of this.form) {
      const generatedData = generateDefaultData(form.fields)
      // console.log('generating data', { form, formData: formData[form.value], generatedData })
      formData[form.value] = mergeDeep(generatedData, formData[form.value])
    }

    // console.log({ formData })
    this.setState({
      formData: formData,
      loading: false,
    }, () => {
      this.saveFormData()
      // this.updateWatch()
      this.runHook('onPageLoad', this.getCurrentForm())
    })
  }

  //-- change form on url chage
  componentDidUpdate(oldProps) {

    const
      {
        formTypeSlug,
        formPageSlug
      } = this.props.match.params,
      // { hash: formPageSlug } = strToPath(this.props.location),
      {
        formPageSlug: oldFormPageSlug
      } = oldProps.match.params

    if (oldFormPageSlug !== formPageSlug) {
      const newFormPage = getSCFormPageInfo(formTypeSlug, formPageSlug).name

      // console.log('URL page:', newFormPage)
      this.updateFormPage({ newFormPage })
    }
  }

  getCurrentForm = () => getSCFormPage(this.formHead.name, this.state.currentFormPage)
  getCurrentFormInfo = () => getSCFormPageInfo(this.formHead.name, this.state.currentFormPage)

  runHook = (name, form, ...args) => {
    if (isObject(form.options) &&
      isObject(form.options.hooks) &&
      isFunction(form.options.hooks[name]))
      return Promise.resolve(form.options.hooks[name]({
        getFormData: this.getFormData,
        setFormData: this.setFormData,
        formData: this.state.formData,
        props: this.props,
        setState: this.setState.bind(this),
        state: this.state,
      }, ...args))

    return Promise.resolve()
  }

  saveFormData = () => {
    const
      storedData = getSessionItem('formData', 'json'),
      combinedData = {
        ...storedData,
        [this.formHead.name]: this.state.formData,
      }

    // console.log({ this: this, formType: this.props.name })

    // console.log({ storedData, combinedData })

    setSessionItem('formData', combinedData)
  }

  getFormData = key => {
    // console.log({ key, state: this.state })
    const val = index(this.state.formData[this.state.currentFormPage], key)
    // console.log({key, val})
    return val
  }

  /**
   * Sets the key in the form to the supplied value. If a hook exists, it will be called asynchronously.
   * 
   * @param {any} key The key of the form to set.
   * @param {any} value The value to set the key to.
   */
  setFormData = (key, value) => {
    // console.log({ key, value })

    this.setState(state => {
      let newData = {}

      //-- assign new value
      index(
        newData,
        key,
        value,
        {
          force: true,
          // verbose: true,
        }
      )

      // console.log({ newData })
      return {
        formData: {
          ...state.formData,
          [state.currentFormPage]: mergeDeep(state.formData[state.currentFormPage], newData, {
            overwrite_undefined: true,
            // verbose: true,
          })
        }
      }
    }, () => {
      this.saveFormData()
      // this.updateWatch()
    })
  }

  formSubmit = () => {
    const
      form = this.getCurrentForm(),
      invalidFields = validateForm(form.fields, this.getFormData)

    // console.log({ invalidFields, isEmpty: isEmpty(invalidFields) })

    if (!isEmpty(invalidFields))
      this.setState({ highlightFields: false },
        () => setTimeout(() => this.setState({ highlightFields: true }), 1)
      )
    else {
      this.setState(
        { submitting: true },
        () => {
          //call post hook
          this.runHook('onSubmit', form, this.state.formData[this.state.currentFormPage])
            .then(processedData => {

              this.props.history.push
                // console.log
                ({
                  pathname: this.getCurrentFormInfo().path.offers,
                  // hash: this.state.currentFormPage,
                  state: {
                    formData: processedData,
                    ...(form.options.state || null)
                  }
                })

              this.setState({ submitting: false })
            })
            .catch(this.props.throwError)
        })
    }
  }

  render() {
    const form = this.getCurrentForm()

    return <>
      <this.props.Title>{form.label}</this.props.Title>
      <div className={['Component-SCForm', 'whitebox', this.state.loading ? 'loading' : null].toClass()}>

        {// don't render page switcher if there's only one form page
          this.form.length <= 1 ? null :
            <div className='form-page-select'>
              {this.state.loading ?
                <div className='fake-switch' /> :
                <Form
                  formFields={[{
                    name: "currentFormPage",
                    type: "switch",
                    options: {
                      nonresponsive: true
                    },
                    data: this.form
                  }]}
                  setFormData={(_, value) => {
                    // console.log('Manual change to', value)
                    this.updateFormPage({
                      newFormPage: value,
                      // callback: () => this.updateWatch(),
                    })
                  }}
                  getFormData={() => this.state.currentFormPage}
                />}
            </div>}
        <Spring native
          from={{ maxHeight: loading_height }}
          to={{
            opacity: this.state.loading ? 0 : 1,
            maxHeight: this.state.loadingResizeFinished ? 'unset' : this.state.formHeight
          }}
          onRest={() => {
            if (this.state.loading === false)
              this.setState({ loadingResizeFinished: true })
          }}
        >
          {props => this.state.loading ? null :
            <animated.div className='main-form-section' style={props}>
              <div className='form-height-measurer' ref={ref => {
                if (!ref) return
                const { outerHeight: height } = getSize(ref)
                if (height !== this.state.formHeight)
                  this.setState({ formHeight: height })
              }}>

                <div className='title-section-wrapper'>
                  <h1>{form.title}</h1>
                  <div className='help-text'>
                    <p>{form.tagline}</p>
                    <div className='operator-orb-wrapper'>
                      <OperatorOrb className='help-orb' image={operatorIcon} size='80px' />
                    </div>
                  </div>
                </div>

                <Form
                  formFields={form.fields}
                  setFormData={this.setFormData}
                  getFormData={this.getFormData}
                  highlight={this.state.highlightFields}
                />
                <AgreementText>
                  {form.submit === null ? null :
                    <div className={['button', 'form-submit', (
                      !isPureObject(this.state.formData[this.state.currentFormPage]) ||
                      isEmpty(this.state.formData[this.state.currentFormPage]) ||
                      this.state.submitting ||
                      (isObject(form.options) && form.options.disabled)) ?
                      'disabled' : null
                    ].toClass()}
                      onClick={this.formSubmit}>{
                        this.state.submitting ?
                          <LoadingSpinner size={toPx('1.5em')} weight={2} duration={2} /> :
                          form.submit
                      }</div>
                  }
                </AgreementText>
              </div>
            </animated.div>}
        </Spring>
        <Spring native to={{
          opacity: this.state.loading ? 1 : 0,
          // minHeight: this.state.formHeight
        }}>
          {props => <animated.div className='loading-wrapper' style={props}>
            <SCFormLoading />
          </animated.div>}
        </Spring>
      </div >
    </>
  }
}

Forms.propTypes = {
  // name: PropTypes.string.isRequired,
  // layout: PropTypes.arrayOf(PropTypes.object),
  watch: PropTypes.objectOf(PropTypes.func),
  // formPage: PropTypes.string.isRequired,
}
Forms.defaultProps = {
  layout: null,
  watch: {}
}

export default withRouter(withResponsivity(withTitle(withError(Forms))));