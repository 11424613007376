import React from 'react';
import PropTypes from 'prop-types'
import {
  withRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

function NestedSwitch(props) {
  const {
    basepath,
    location,
    errorPage,
    children,
    ...passProps
  } = props,
    pathname = location.pathname.slice(basepath.length)

  // console.log({ location: location.pathname, basepath, pathname })

  return <Switch
    {...passProps}
    location={{
      ...location,
      pathname
    }}>
    {props.children}
    <Route>
      <Redirect to={errorPage} />
    </Route>
  </Switch>
}

NestedSwitch.propTypes = {
  basepath: PropTypes.string,
  errorPage: PropTypes.string,
}
NestedSwitch.defaultProps = {
  basepath: '',
  errorPage: '/',
}


export default withRouter(NestedSwitch)