import React from 'react';

import './style.scss';

import withResponsivity from 'HOCs/withResponsivity'
import { isFunction, mergeDeep, nestedSetState } from 'scripts/helpers';
import landingPagePT from '../landingPagePT';

const { default: initialPage } = require('./pages')

export const initialState = {
  showInfoPopup: true,
  total: undefined,
  saving: undefined,
  duration: undefined,
  currentPage: initialPage,
  pageStates: {
    [initialPage]: require(`./pages/${initialPage}`).initialState || {}
  }
}

function EnergieLP(props) {
  const CurrentPage = require(`./pages/${props.state.currentPage}`)

  // console.log({ state: props.state })

  return <div className='Component-EnergieLP'>
    <CurrentPage.default
      goToPage={page => {
        // console.log('going to page', page)
        props.setState(
          state => {
            const newPageState = mergeDeep(
              require(`./pages/${page}`).initialState,
              state.pageStates[page],
            )
            const newState = {
              currentPage: page,
              pageStates: {
                ...state.pageStates,
                [page]: newPageState
              }
            }
            // console.log(`goToPage("${page}")`, { newState, oldState: state })
            return newState
          })
      }}
      state={props.state.pageStates[props.state.currentPage]}
      setState={nestedSetState(
        props.state.currentPage,
        () => props.state.pageStates,
        (newState, callback) =>
          props.setState({
            pageStates: {
              ...props.state.pageStates,
              ...newState
            }
          }, callback),
        // { verbose: true },
      )}
      getState={pageName => props.state.pageStates[pageName]}
    />

    <div className='buttons-container'>
      {!isFunction(CurrentPage.goBack) ? null :
        <div className='button back-button'>
          Zpět
        </div>}
    </div>
  </div>
  // }
}

EnergieLP.propTypes = landingPagePT

export default withResponsivity(EnergieLP)