import React from 'react';

import {
  GiTwoCoins as LoanIcon
} from 'react-icons/gi'

export default {
  path: '/form/finance',
  label: 'Půjčky',
  class: '',
  icon: <LoanIcon className='icon' />,
  hooks: {
    offerHooks: {},
    formHooks: {}
  },
  layout: [
    {
      label: 'Půjčka',
      value: 'loan',
      title: 'Kalkulačka půjčky',
      tagline: 'Kalkulátory cen zatím připravujeme. Pro indiviuální nabídky k ušetření nás neváhejte kontaktovat.',
      submit: 'Spočítat úvěr',
      options: {
        disabled: true,
        showHelpButtom: true,
      },
      fields: [
        {
          name: 'type',
          type: 'switch',
          options: {},
          data: [
            {
              label: 'Fyzická osoba',
              value: 'household'
            },
            {
              label: 'Právnická osoba',
              value: 'business'
            }
          ]
        },
        {
          name: 'purpose',
          type: 'select',
          options: {
            placeholder: 'Účel půjčky',
            mandatory: true,
            conditional: [
              [
                {
                  key: 'type',
                  value: 'household'
                }
              ]
            ]
          },
          data: [
            {
              label: 'Na cokoli',
              value: 'any'
            },
            {
              label: 'Vybavení domácnosti',
              value: 'household'
            },
            {
              label: 'Auto',
              value: 'automotive'
            },
            {
              label: 'Rekonstrukce',
              value: 'reconstruction'
            },
            {
              label: 'Refinancování',
              value: 'refinancing'
            },
            {
              label: 'Konsolidace',
              value: 'consolidation'
            }
          ]
        },
        {
          name: 'sum-select',
          type: 'select',
          options: {
            placeholder: 'Částka',
            mandatory: true,
            conditional: [
              [
                {
                  key: 'purpose',
                  value: '!consolidation'
                }
              ],
              [
                {
                  key: 'type',
                  value: 'business'
                }
              ]
            ]
          },
          data: [
            {
              label: '5 tisíc Kč',
              value: 5000
            },
            {
              label: '10 tisíc Kč',
              value: 10000
            },
            {
              label: '20 tisíc Kč',
              value: 20000
            },
            {
              label: '50 tisíc Kč',
              value: 50000
            },
            {
              label: '100 tisíc Kč',
              value: 100000
            },
            {
              label: '200 tisíc Kč',
              value: 200000
            },
            {
              label: '500 tisíc Kč',
              value: 500000
            },
            {
              label: 'Jiná částka',
              value: 'exact'
            }
          ]
        },
        {
          name: 'sum-number',
          type: 'number',
          options: {
            min: 0,
            step: 1000,
            label: 'Kč',
            placeholder: 'Přesná částka',
            conditional: [
              [
                {
                  key: 'sum-select',
                  value: 'exact'
                },
                {
                  key: 'purpose',
                  value: '!consolidation'
                }
              ],
              [
                {
                  key: 'sum-select',
                  value: 'exact'
                },
                {
                  key: 'type',
                  value: 'business'
                }
              ]
            ],
            mandatory: true
          }
        },
        {
          name: 'loan-duration',
          type: 'select',
          options: {
            placeholder: 'Doba splácení',
            mandatory: true,
            conditional: [
              [
                {
                  key: 'purpose',
                  value: '!consolidation'
                }
              ],
              [
                {
                  key: 'type',
                  value: 'business'
                }
              ]
            ]
          },
          data: [
            {
              label: '3 měsíce',
              value: 3
            },
            {
              label: '6 měsíců',
              value: 6
            },
            {
              label: '12 měsíců',
              value: 12
            },
            {
              label: '2 roky',
              value: 24
            },
            {
              label: '3 roky',
              value: 36
            },
            {
              label: '4 roky',
              value: 48
            },
            {
              label: '5 let',
              value: 60
            },
            {
              label: '6 let',
              value: 72
            },
            {
              label: '7 let',
              value: 84
            },
            {
              label: '8 let',
              value: 96
            },
            {
              label: '9 let',
              value: 108
            },
            {
              label: '10 let',
              value: 120
            }
          ]
        },
        {
          name: 'consolidation-sum',
          type: 'number',
          options: {
            min: 0,
            step: 1000,
            label: 'Kč',
            placeholder: 'Součet všech půjček',
            mandatory: true,
            conditional: [
              [
                {
                  key: 'purpose',
                  value: 'consolidation'
                },
                {
                  key: 'type',
                  value: '!business'
                }
              ]
            ]
          }
        },
        {
          name: 'consolidation-payment',
          type: 'number',
          options: {
            min: 0,
            step: 1000,
            label: 'Kč',
            placeholder: 'Kolik splácíte měsíčně',
            mandatory: true,
            conditional: [
              [
                {
                  key: 'purpose',
                  value: 'consolidation'
                },
                {
                  key: 'type',
                  value: '!business'
                }
              ]
            ]
          }
        },
        {
          name: 'consolidation-duration',
          type: 'number',
          options: {
            min: 1,
            step: 1,
            max: 10,
            label: 'let',
            placeholder: 'Jak dlouho chcete ještě splácet',
            mandatory: true,
            conditional: [
              [
                {
                  key: 'purpose',
                  value: 'consolidation'
                },
                {
                  key: 'type',
                  value: '!business'
                }
              ]
            ]
          }
        }
      ]
    },
    // {
    //   label: 'Hypotéka',
    //   value: 'mortgage',
    //   title: 'Kalkulačka hypotéky',
    //   tagline: 'Kalkulátory cen zatím připravujeme. Pro indiviuální nabídky k ušetření nás neváhejte kontaktovat.',
    //   submit: 'Spočítat úvěr',
    //   options: {
    //     disabled: true
    //   },
    //   fields: [
    //     {
    //       name: 'purpose',
    //       type: 'select',
    //       options: {
    //         placeholder: 'Účel hypotéky',
    //         mandatory: true
    //       },
    //       data: [
    //         {
    //           label: 'Koupě nemovitosti (max 90%)',
    //           value: 'property'
    //         },
    //         {
    //           label: 'Refinancování (max 90%)',
    //           value: 'refinancing'
    //         },
    //         {
    //           label: 'Výstavba nemovitosti (max 90%)',
    //           value: 'construction'
    //         },
    //         {
    //           label: 'Rekonstrukce (max 90%)',
    //           value: 'reconstruction'
    //         },
    //         {
    //           label: 'Neúčelová (max 70%)',
    //           value: 'purposeless'
    //         }
    //       ]
    //     },
    //     {
    //       name: 'cost',
    //       type: 'number',
    //       options: {
    //         min: 0,
    //         step: 1000,
    //         label: 'Kč',
    //         placeholder: 'Cena nemovitosti',
    //         mandatory: true
    //       }
    //     },
    //     {
    //       name: 'loan-sum',
    //       type: 'number',
    //       options: {
    //         min: 0,
    //         step: 1000,
    //         label: 'Kč',
    //         placeholder: 'Částka',
    //         mandatory: true
    //       }
    //     },
    //     {
    //       name: 'loan-duration',
    //       type: 'select',
    //       options: {
    //         placeholder: 'Doba splácení',
    //         mandatory: true
    //       },
    //       data: [
    //         {
    //           label: '5 let',
    //           value: 5
    //         },
    //         {
    //           label: '10 let',
    //           value: 10
    //         },
    //         {
    //           label: '15 let',
    //           value: 15
    //         },
    //         {
    //           label: '20 let',
    //           value: 20
    //         },
    //         {
    //           label: '25 let',
    //           value: 25
    //         },
    //         {
    //           label: '30 let',
    //           value: 30
    //         },
    //         {
    //           label: '35 let',
    //           value: 35
    //         },
    //         {
    //           label: '40 let',
    //           value: 40
    //         }
    //       ]
    //     },
    //     {
    //       name: 'fixation-duration',
    //       type: 'select',
    //       options: {
    //         placeholder: 'Doba fixace úroku',
    //         mandatory: true
    //       },
    //       data: [
    //         {
    //           label: '1 rok',
    //           value: 1
    //         },
    //         {
    //           label: '2 roky',
    //           value: 2
    //         },
    //         {
    //           label: '3 roky',
    //           value: 3
    //         },
    //         {
    //           label: '4 roky',
    //           value: 4
    //         },
    //         {
    //           label: '5 let',
    //           value: 5
    //         },
    //         {
    //           label: '6 let',
    //           value: 6
    //         },
    //         {
    //           label: '7 let',
    //           value: 7
    //         },
    //         {
    //           label: '8 let',
    //           value: 8
    //         },
    //         {
    //           label: '9 let',
    //           value: 9
    //         },
    //         {
    //           label: '10 let',
    //           value: 10
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   label: 'Účet',
    //   value: 'account',
    //   title: 'Kalkulačka spořicího účtu',
    //   tagline: 'Kalkulátory cen zatím připravujeme. Pro indiviuální nabídky k ušetření nás neváhejte kontaktovat.',
    //   submit: 'Spočítat úvěr',
    //   options: {
    //     disabled: true
    //   },
    //   fields: []
    // },
    // {
    //   label: 'Spoření',
    //   value: 'savings',
    //   title: 'Kalkulačka spoření',
    //   tagline: 'Kalkulátory cen zatím připravujeme. Pro indiviuální nabídky k ušetření nás neváhejte kontaktovat.',
    //   submit: 'Spočítat úvěr',
    //   options: {
    //     disabled: true
    //   },
    //   fields: [
    //     {
    //       name: 'type',
    //       type: 'switch',
    //       data: [
    //         {
    //           label: 'Stavební spoření',
    //           value: 'construction'
    //         },
    //         {
    //           label: 'Termínované vklady',
    //           value: 'regular'
    //         },
    //         {
    //           label: 'Spořící účty',
    //           value: 'accounts'
    //         }
    //       ]
    //     }
    //   ]
    // }
  ],
}