import React from 'react';

import useBasket from 'hooks/useBasket';

export default WrappedComponent =>
  React.forwardRef((props, ref) => {
    const [basket, setBasket] = useBasket()
    return <WrappedComponent
      basket={basket}
      setBasket={setBasket}
      {...props}
      ref={ref}
    />
  })