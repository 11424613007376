import {
  shape,
  string,
  any,
  number,
} from 'prop-types'
import fieldType from './field_type'
import label from './field_label'

export default shape({
  name: string,
  label: label,
  type: fieldType.isRequired,
  options: shape({
    button: any,
    conditional: any,
    data_src: any,
    default: any,
    filter_by: any,
    mandatory: any,
    max: number,
    min: number,
    placeholder: string,
    sequential: any,
    step: number,
    tooltip: string,
  }).isRequired,
  data: any
})