import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';
import { hash } from 'scripts/helpers';
import uuid from 'uuid'
import channelPT from '../channelPT';
const key_seed = uuid()

function ChannelList(props) {
  return <div className='Component-ChannelList'>
    <div className='channels-wrapper'>
      <div className='title'>
        {props.title}
      </div>
      <ul className='channels nopoint'>
        {props.channels.map((channel, key_index) =>
          <li className='channel' key={hash(key_seed, key_index)}>
            <div className='logo-wrapper'>
              <img src={channel.logo} alt='logo' />
            </div>
            <div className='channel-name'>
              {channel.label}
            </div>
          </li>
        )}
      </ul>
    </div>
  </div>;
}

ChannelList.propTypes = {
  title: PropTypes.string.isRequired,
  channels: PropTypes.arrayOf(channelPT).isRequired
}

export default ChannelList