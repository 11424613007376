import { validatePSC } from "scripts/helpers"

const formFields = [
  {
    name: "street",
    type: "text",
    options: {
      placeholder: "Ulice",
      mandatory: true,
    }
  },
  {
    name: "streetNumber",
    type: "text",
    options: {
      placeholder: "Číslo popisné / orientační",
      mandatory: /[0-9]+\s*[a-z]?(\s*\/\s*[0-9]+)?/,
    }
  },
  {
    name: "city",
    type: "text",
    options: {
      placeholder: "Obec",
      mandatory: true,
    }
  },
  {
    name: "postalCode",
    type: "text",
    options: {
      placeholder: "PSČ",
      mandatory: validatePSC,
    }
  },
]

export default {
  layout: formFields,
}