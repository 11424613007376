import { monthSuffixCZ } from 'scripts/helpers';
import { BoonInfo } from 'types/Boon';


const boons: BoonInfo[] = [
  {
    type: 'vipPartner',
    label: 'Chytrá volba',
    description: 'Dodavatel pečlivě ověřený referencemi zákazníků',
    options: {
      displayInHeader: false,
      displayInCell: false,
      canFilter: true,
    }
  },
  {
    type: 'priceGuarantee',
    label: 'Garance ceny',
    description: null,
    options: {
      displayInHeader: true,
      displayInCell: true,
      canFilter: true,
    }
  },
  {
    type: 'limitedDiscount',
    label: 'Sleva po určitou dobu',
    labelParser: (params: {
      amount: number,
      duration: number,
    }) => `Sleva ${params.amount * 100}% po dobu ${monthSuffixCZ(params.duration)}`,
    description: null,
    options: {
      displayInHeader: true,
      displayInCell: true,
      canFilter: true,
    }
  },
  {
    type: 'reliableProvider',
    label: 'doděláme zobrazení later',
    description: null,
    options: {
      displayInHeader: true,
      displayInCell: true,
      canFilter: true,
    }
  },
  {
    type: 'marketingSlogan',
    label: 'Slogan poskytovatele',
    labelParser: (params: any) => `'${JSON.stringify(params)}'`,
    description: null,
    options: {
      displayInHeader: true,
      displayInCell: false,
      canFilter: false,
    }
  },
  {
    type: 'action',
    label: 'Akční nabídka',
    description: null,
    options: {
      displayInHeader: true,
      displayInCell: true,
      canFilter: false,
    }
  },
  {
    type: 'general',
    label: 'Obecné',
    labelParser: (params: any) => JSON.stringify(params),
    description: null,
    options: {
      displayInHeader: false,
      displayInCell: false,
      canFilter: false,
    }
  }
]

export default boons