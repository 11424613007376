import React from 'react';
import contact from 'forms/contact'
import './style.scss';
import Form, {
  generateDefaultData
} from 'components/Form';
import AgreementText from 'components/AgreementText'
// import SlideoverArea from 'components/SlideoverArea'
import {
  FaArrowAltCircleRight as ContinueIcon,
  // FaFileInvoice as LeftIcon,
  // FaListUl as RightIcon,
  FaAt,
  FaPhone,
} from 'react-icons/fa'
import { isEmpty, reactDev, fileToBase64, awaitAll } from 'scripts/helpers'
import NotificationSystem from 'react-notification-system'
import { validateForm } from 'components/Form';
import liveSuppGetPageAPI from 'scripts/LS_PageAPI';
import setriChytreAPI from 'scripts/SCH_API';
import ModalBox from 'components/ModalBox';

const invoiceForm = [{
  name: 'invoice',
  type: 'file',
  options: {
    placeholder: 'Vybrat soubor',
  }
}]

function Contact(props) {
  const
    [invoices, setInvoices] = React.useState([]),
    [formData, setFormData] = React.useState(generateDefaultData(contact.layout)),
    [fieldHighlight, setFieldHighlight] = React.useState(false),
    [notificationSystem, setNotificationSystem] = React.useState(null),
    [contactMethodOpen, setContactMethodOpen] = React.useState(false),
    [dataToSend, setDataToSend] = React.useState(null),
    [contactState, setContactState] = React.useState('invoice'),
    [sending, setSending] = React.useState(false),
    onContinue = () => {
      if (!isEmpty(invoices))
        setContactState('form')
      else {
        notificationSystem.addNotification({
          message: 'Pokud si nejste jistí, vytočte si operátora',
          level: 'info',
          position: 'bc'
        })
        notificationSystem.addNotification({
          message: 'Je třeba nahrát vyůčtování',
          level: 'warning',
          position: 'bc'
        })
        setFieldHighlight(false)
        setTimeout(() => setFieldHighlight(true), 1)
      }
    }

  React.useEffect(() => {
    if (dataToSend) {
      if (dataToSend.specialOffers[0].params.preferredContactMedium === null) {
        if (contactMethodOpen === false)
          setContactMethodOpen(true)
      }
      else {
        // console.log('sending', dataToSend)
        setSending(true)
        setriChytreAPI('/offers/interested', dataToSend)
          .then(() => {
            setFormData(generateDefaultData(contact.layout))
            setInvoices([])
            notificationSystem.addNotification({
              message: 'Údaje odeslány, brzy se Vám ozveme!',
              level: 'success',
              position: 'bc'
            })
          })
          .catch(err => {
            console.error(err)
            notificationSystem.addNotification({
              message: err.err,
              level: 'error',
              position: 'bc'
            })
          })
          .finally(() => setSending(false))
      }
    }
  }, [dataToSend, contactMethodOpen, notificationSystem])

  return <div className='Component-Contact'>
    <div className={'contact-content-container'}>
      {(() => {
        switch (contactState) {

          case 'invoice':
            return <section className={'invoice-area' + (sending ? ' disabled' : '')} >
              <div className='invoice-text-container'>
                <div className='invoice-text'>
                  nebo nahrajte své poslední vyúčtování
                </div>
                <div className='invoice-subtext'>
                  a my Vám zpracujeme nezávaznou nabídku
                </div>
              </div>
              <Form
                name='invoice'
                getFormData={() => invoices}
                setFormData={(_, value) => setInvoices(value)}
                formFields={invoiceForm}
              />
              <div className='agreement-text-wrapper'>
                <AgreementText>
                  <div className='continue-button-container'>
                    <div className='button continue-button' onClick={onContinue}>
                      Chci nezávaznou nabídku <ContinueIcon />
                    </div>
                  </div>
                </AgreementText>
              </div>
            </section>

          case 'form':
            return <section className={['contact-area', sending ? ' disabled' : null].toClass()}>
              <div className='contact-form-wrapper'>
                <Form
                  name='contact-form'
                  getFormData={key => formData[key]}
                  setFormData={(key, value) => setFormData({ ...formData, [key]: value })}
                  formFields={contact.layout}
                  highlight={fieldHighlight}
                />
              </div>
              <div className='continue-button-container'>
                <div className='button continue-button' onClick={() => {
                  const formValidation = validateForm(contact.layout, key => formData[key])

                  if (formValidation === true) {
                    (reactDev() ?
                      Promise.resolve({ getResolvingOperator: () => null })
                      : liveSuppGetPageAPI())
                      .then(async pageAPI => {
                        let files = []

                        await awaitAll(invoices.map(file =>
                          fileToBase64(file)
                            .then(b64 => files.push({
                              filename: file.name,
                              b64content: b64.split(';base64,')[1]
                            }))
                        ))

                        const address = {
                          street: formData.street,
                          streetNumber: formData.streetNumber,
                          city: formData.city,
                          postalCode: formData.postalCode
                        }

                        let submitData = {
                          lsOperatorId: pageAPI.getResolvingOperator(),
                          specialOffers: [{
                            id: props.specialOffer.id,
                            params: {
                              preferredContactMedium:
                                !formData.phone ? 'email' :
                                  !formData.email ? 'phone' :
                                    null
                            }
                          }],
                          files,
                          products: [],
                          contact: {
                            name: formData['first-name'] + ' ' + formData['last-name'],
                            email: formData.email || null,
                            phone: formData.phone || null,
                            positionName: formData.position || null,
                            address: formData.type === 'household' ? address : null,
                          },
                          company: formData.type === 'business' ? {
                            name: formData['company-name'],
                            address,
                            vatin: formData['company-ico']
                          } : null
                        }

                        // console.log({ formData, submitData })
                        setDataToSend(submitData)
                      })
                  }
                  else {
                    notificationSystem.addNotification({
                      message: 'Pokud si nejste jistí, vytočte si operátora',
                      level: 'info',
                      position: 'bc'
                    })
                    notificationSystem.addNotification({
                      message: 'Je třeba správně vyplnit kontaktní formulář',
                      level: 'warning',
                      position: 'bc'
                    })
                    setFieldHighlight(false)
                    setTimeout(() => setFieldHighlight(true), 1)
                  }
                }}>
                  Odeslat <ContinueIcon />
                </div>
              </div>
            </section>
          default:
            window.location.reload()
        }
      })()}
    </div>

    <ModalBox isOpen={contactMethodOpen}>
      <div className='contact-method-modal'>
        <div className='text-wrapper'>
          Jak Vás máme kontaktovat?
        </div>
        <div className='tile-container'>
          <div className='tile phone-tile' onClick={() => {
            dataToSend.specialOffers[0].params.preferredContactMedium = 'phone'
            setDataToSend(dataToSend)
            setContactMethodOpen(false)
          }}>
            <FaPhone className='icon' />
            <div className='text'>
              Telefonem
            </div>
          </div>
          <div className='tile phone-email' onClick={() => {
            dataToSend.specialOffers[0].params.preferredContactMedium = 'email'
            setDataToSend(dataToSend)
            setContactMethodOpen(false)
          }}>
            <FaAt className='icon' />
            <div className='text'>
              Emailem
            </div>
          </div>
        </div>
      </div>
    </ModalBox>
    <NotificationSystem ref={setNotificationSystem} />
  </div>
}

export default Contact