import React from 'react'

import PropTypes from 'prop-types'

import {
  hash, isFunction, isObject, syntheticRender,
  // isPropType,
} from 'scripts/helpers'

import './style.scss'

import uuid from 'uuid'
const key_seed = uuid()

function InputSwitch(props) {
  // console.log(
  //   props.data[0].label,
  // isPropType('node', props.data[0].label, { verbose: true })
  // )
  const onChange = val => {
    props.onChange(val)
    // if (isObject(props.options.hooks) && isFunction(props.options.hooks.onChange))
    //   props.options.hooks.onChange(val)
  }

  return <div className={
    'Component-InputSwitch' +
    (props.options.nonresponsive === true ?
      ' nonresponsive' : '')
  }>
    {props.data.map((option, key_iterator) => {

      const passProps = {
        key: hash(key_seed + key_iterator),
        className: `switch${option.value === props.value ? ' active' : ''}`,
        onClick: () => onChange(option.value)
      }

      if (isObject(option.label) || isFunction(option.label))
        return syntheticRender(option.label, { ...passProps, value: option.value })

      return <div {...passProps}>{option.label}</div>
    })}
  </div >
}

InputSwitch.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.node.isRequired,
        PropTypes.func.isRequired,
        PropTypes.instanceOf(React.Component).isRequired,
        PropTypes.instanceOf(React.PureComponent).isRequired,
      ]).isRequired,
      value: PropTypes.any.isRequired
    }).isRequired
  )
}

InputSwitch.defaultProps = {
  data: []
}

export default InputSwitch