import React from 'react'
import ReactDOM from 'react-dom'
import {
  FaPhone as ContactIcon,
  FaShoppingBasket as OrderIcon,
  // FaRegClock as ActionOfferIcon,
  // FaPiggyBank as ClubIcon
} from 'react-icons/fa'

import Logo from 'components/Logo'
import {
  getSCNavItems
} from 'forms'
import './style.scss'
import Menu from 'components/Menu'
import useBasket from 'hooks/useBasket'
// import withResponsivity from 'HOCs/withResponsivity'
// import { withRouter } from 'react-router-dom'
import {
  disableBodyScroll,
  enableBodyScroll,
  // } from 'body-scroll-lock'
  // import {
  // isObject,
  isEmpty,
  // compareDeep,
} from 'scripts/helpers'
import {
  animated,
  useSpring,
} from 'react-spring'
import useResponsivity from 'hooks/useResponsivity'
// import { useTraceUpdate } from 'hooks/useTraceUpdate'
// import withForceUpdate from 'HOCs/withForceUpdate'

function Header(props) {
  const
    // lastBasket = React.useRef(null),
    // [basketItemCount, setBasketItemCount] = React.useState(0),
    [menuOpen, setMenuOpen] = React.useState(false),
    { color: logoColour } = useSpring({ color: menuOpen ? '#000' : '#fff' }),
    // basket = React.useContext(BasketContext),
    [basket] = useBasket(),
    { isGreaterThan } = useResponsivity(),
    itemGroups = [
      //-- Add form buttons
      getSCNavItems(),

      // [
      //   //-- Add action offers button
      //   {
      //     path: {
      //       pathname: '/nabidky/akcni'
      //     },
      //     label: 'Akční nabídky',
      //     icon: <ActionOfferIcon className='icon' />
      //   },

      //   //-- Add club button
      //   {
      //     path: {
      //       pathname: '/klub-chytrych'
      //     },
      //     label: 'Klub chytrých',
      //     icon: <ClubIcon className='icon' />
      //   }
      // ],

      //-- Add contact button
      [{
        path: {
          pathname: '/kontakt',
        },
        label: 'Kontakt',
        icon: <ContactIcon className='icon' />
      }]

    ]//.filter(_ => _) //-- filter out empty items

  //-- Conditionally add basket button
  if (!isEmpty(basket))
    itemGroups.push([{
      path: {
        pathname: '/objednavka',
      },
      label: <div style={{ display: 'flex' }}>
        Objednávka
          </div>,
      icon:
        <div className='numberBall-wrapper'>
          <OrderIcon className='icon' />
          <div className='numberBall'>
            {basket.length}
          </div>
        </div>
    }])

  // // Set up basket observer
  // React.useEffect(() =>
  //   basket.addObserver(basket => {
  //     setBasketItemCount(basket.length)
  //   }, { description: 'header' })
  //   , [basket])

  // useTraceUpdate(props, {
  //   basketItemCount,
  //   menuOpen,
  // })

  // console.log('rendering header')
  return ReactDOM.createPortal(<animated.div
    className='Header-Component'
    style={{ color: logoColour.interpolate(clr => clr) }}>

    <Logo />

    <Menu
      type={isGreaterThan('menu') ? 'full' : 'mobile'}
      itemGroups={itemGroups}
      onMenuOpen={() => {
        // console.log('menu opening')
        setMenuOpen(true)
        disableBodyScroll()
      }}
      onMenuClose={() => {
        // console.log('menu closing')
        setMenuOpen(false)
        enableBodyScroll()
      }}
    />

  </animated.div>,
    document.getElementById('page-header'))
}

export default Header