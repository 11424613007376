import React from 'react';
import PropTypes from 'prop-types'
import {
  hash, someKeys
} from 'scripts/helpers'
import getSize from 'get-size'
import './style.scss';
import uuid from 'uuid'
const key_seed = uuid()

let animationSize = { width: 0, height: 0 }

function StarfallBackground(props) {
  const [
    animationContainerRef,
    setAnimationContainerRef
  ] = React.useState(null),
    [animationId] = React.useState(uuid()),
    animationKey = hash(key_seed, animationSize, animationId),
    animNames = {
      before: hash('before', animationKey),
      after: hash('after', animationKey),
    }

  animationSize = animationContainerRef ?
    someKeys(['width', 'height'], getSize(animationContainerRef)) :
    animationSize

  return <>
    <div
      className={'Component-StarfallBackground ' + props.className}
      style={props.style}>
      {animationSize === undefined ? null :
        <div ref={setAnimationContainerRef}
          className={'animation-container ' + animationKey}
          style={{
            height: '100%',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '100%',
            zIndex: '-1',
          }}>
          <style>{`
  .${animationKey}::before {
    animation: ${animNames.before} 10s linear infinite;
  }

  @keyframes ${animNames.before} {
    ${getAnimation('starfall', animationSize, 13, animationId)}
  }
          `}</style>
          <style>{`
.${animationKey}::after {
  animation: ${animNames.after} 10s linear .5s infinite;
}

@keyframes ${animNames.after} {
  ${getAnimation('starfall', animationSize, 9, animationId)}
}
`}</style>
        </div>}
      {props.children}
    </div>
  </>
}

StarfallBackground.propTypes = {
  style: PropTypes.object
}
StarfallBackground.defaultProps = {
  style: {}
}

export default StarfallBackground

/**
 * Generates keyframes for a star in the starfall animation
 * @param {boolean} compact Whether to generate for compact view
 */
const
  keyframe_generator = {
    starfall: (size, _repetitions = 10) => {
      const repetitions = _repetitions * (.95 + .1 * Math.random())
      let out_str = ''

      for (let i = 0; i < repetitions; ++i) {
        const
          star_pos = Math.random() * (size.width + size.height),
          delay = .05 * Math.random(),
          starLength = 30,
          static_styling = `
            background: linear-gradient(45deg, white, transparent);
            width: ${starLength}px;
            height: 1px;
            filter: blur(1px);
            transform: rotate(-45deg);
          `

        out_str += `
        ${i * (100 / repetitions) + delay}% {
          bottom: calc(100% + ${starLength}px);
          left: ${star_pos}px;
          opacity: 0;
          ${static_styling}
        }
        ${i * (100 / repetitions) + delay + 0.1}% {
          bottom: calc(100% + ${starLength}px);
          left: ${star_pos}px;
          opacity: 1;
          ${static_styling}
        }
        ${(i + 1) * (100 / repetitions) - 0.1}% {
          bottom: calc(100% - ${size.height + starLength}px);
          left: ${star_pos - size.height - 2 * starLength}px;
          opacity: 1;
          ${static_styling}
        }
        ${(i + 1) * (100 / repetitions) - 0.05}% {
          bottom: calc(100% - ${size.height + starLength}px);
          left: ${star_pos - size.height - 2 * starLength}px;
          opacity: 0;
          ${static_styling}
        }`
      }

      // console.log('generated starfall')
      return out_str
    },
    // bubbleup: (compact, repetitions = 10) => {
    //   const
    //     height = compact ? 160 : 100,
    //     width = compact ? 600 : 1000
    //   let out_str = ''

    //   for (let i = 0; i < repetitions; ++i) {
    //     const
    //       pos = Math.floor(Math.random() * (width)),
    //       scale = 1 + Math.random()
    //     const size = 6 + 6 * scale * scale
    //     const static_styling = `
    //       width: ${size}px;
    //       height: ${size}px;
    //       border: 1px solid white;
    //       border-radius: 50%;
    //       filter: opacity(${20 + 80 * 1 / (scale - 1)}%) blur(1px);
    //       mask-image: radial-gradient(circle ${size}px at center center, black 0, black ${size}px, transparent);
    //       left: ${pos}px;
    //     `

    //     out_str += `
    //     ${i * (100 / repetitions)}% {
    //       bottom: calc(100% - ${height}px * ${scale * scale});
    //       opacity: 0;
    //       ${static_styling}
    //     }
    //     ${i * (100 / repetitions) + 0.1}% {
    //       bottom: calc(100% - ${height}px * ${scale * scale});
    //       opacity: .8;
    //       ${static_styling}
    //     }
    //     ${(i + 1) * (100 / repetitions) - 0.1}% {
    //       bottom: calc(100%);
    //       opacity: .8;
    //       ${static_styling}
    //     }
    //     ${(i + 1) * (100 / repetitions) - 0.05}% {
    //       bottom: calc(100%);
    //       opacity: 0;
    //       ${static_styling}
    //     }`
    //   }

    //   // console.log(out_str)
    //   return out_str
    // },
    '_memoised': {}
  },
  getAnimation = (name, size, iterations, animationKey) => {
    const id = hash(name, size, iterations, animationKey)
    // console.log({ name, id })
    if (!(id in keyframe_generator._memoised)) {
      // console.log('generating animation', id)
      keyframe_generator._memoised[id] = keyframe_generator[name](size, iterations)
    }
    // else console.log('memoised animation', id)

    return keyframe_generator._memoised[id]
  }