import React from 'react';
import Product from 'types/Product'
import Indexable from 'types/Indexable'
import useBasket from 'hooks/useBasket';
import OfferLoaded from 'components/Offer'
import OfferLoading from 'components/Offer/Loading'
import {
  FaShoppingBasket as BasketIcon
} from 'react-icons/fa'
import { compareDeep, isVoid } from 'scripts/helpers';
import { $$offer_origin, $$offer_product } from '..';
import AnimatedUnderline from 'components/AnimatedUnderline';

type Offer = (Product & Indexable)
function OffersTable(props: {
  children: Offer | null,
  compact: boolean,
  // inBasket: boolean,
  className?: string
}) {
  const {
    children: offer,
    compact,
    // inBasket,
    className,
    ...passProps
  } = props,
    [basket, setBasket] = useBasket(),
    inBasket = basket.find(item => {
      if (!(offer))
        return false

      return compareDeep(item.origin, offer[$$offer_origin as any]) &&
        item.product.id === offer[$$offer_product as any].id
    }),
    Offer = isVoid(offer) ? OfferLoading : OfferLoaded

  return <div className={['OfferWrapper-Component',
    inBasket ? 'in-basket' : null,
    compact ? 'compact' : 'full',
    className].toClass()}>

    <Offer
      offer={offer}
      compact={compact}
      {...passProps}
    />


    {inBasket ?
      <div className='in-basket-wrapper'>
        <div className='in-basket-container'>
          <div className='in-basket-item'>
            Již v košíku&nbsp;<BasketIcon />
          </div>
          <AnimatedUnderline>
            <div className='in-basket-remove' onClick={() => {
              setBasket(basket.filter(item => item !== inBasket))
            }}>
              Odstranit z košíku
                      </div>
          </AnimatedUnderline>
        </div>
      </div> :
      null}
  </div>
}

export default OffersTable