import React from 'react'
import './style.scss'
import OfferBox from './OfferBox'
// import headerImg from 'assets/header-bg.png'
import tvImg from 'assets/tv.png'
import { FaChevronRight } from 'react-icons/fa'
import DigiTVOffer from './DigiTVOffer'
import { hash } from 'scripts/helpers'
import uuid from 'uuid'
import ChannelsModal from './ChannelsModal'
import DigiTVPackage from './DigiTVPackage'
import withResponsivity from 'HOCs/withResponsivity'
import landingPagePT from '../landingPagePT'
import EvenRowGrid from 'components/EvenRowGrid'
import offers from './offers'
import packages from './packages'
import useTitle from 'hooks/useTitle'
const key_seed = uuid()

const
  InterestButton = () => {
    return <div
      className={'InterestButton button continue-button'}
      livesupp-call=''
    >
      Mám zájem
  <FaChevronRight className='icon' />
    </div>
  }

function DigiTV(props) {
  const
    [modalData, setModalData] = React.useState(null),
    closeProgramModal = () => setModalData(null),
    upperText = <div className='text-upper'>
      Nejvychytanější online televize od Digi TV
  </div>,
    Title = useTitle()

  return <>
    <Title>DigiTV</Title>
    <div className='Component-digitv'>
      <OfferBox
        header={{
          // background: headerImg,
          left: <>
            {props.isGreaterThan('section') ? null : upperText}
            <img src={tvImg} alt='header-img' className='header-image' />
          </>,
          right: <div className='main-header-content'>
            <div className='text'>
              {props.isGreaterThan('section') ? upperText : null}
              <div className='text-lower'>
                nyní na 3 měsíce <span style={{ textTransform: 'uppercase' }}>zdarma</span>
              </div>
            </div>
            <InterestButton />
          </div>,
        }}
        main={<>
          <div className='subtitle'>
            Přes 110 skvělých programů, zpětné přehrávání, využití až na 4 zařízeních po celé EU v ceně.
          </div>
          <div className='packages-container'>
            <EvenRowGrid
              rowGap='60px'
              columnGap='30px'
              justifyContent='center'
            // verbose={true}
            >
              {offers.map((offer, key_iterator) =>
                <DigiTVOffer
                  key={hash(key_seed, key_iterator)}
                  openProgramModal={() => setModalData(offer.channels)}
                  {...offer} />
              )}
            </EvenRowGrid>
          </div>
          <div className='packages-section'>
            <div className='packages-title'>
              Doplňkové balíčky
          </div>
            <div className='packages-subtitle'>
              K balíčku <b>Základní</b> si navíc objednejte i <b>doplňkové balíčky</b>:
          </div>
            <div className='packages-container'>
              <EvenRowGrid
                rowGap='20px'
                columnGap='20px'
              >
                {packages.map((pckg, key_iterator) =>
                  <DigiTVPackage key={hash('package', key_seed, key_iterator)}
                    openProgramModal={() => setModalData(pckg.channels)}
                    {...pckg}
                  />
                )}
              </EvenRowGrid>
            </div>
          </div>
        </>}
        footer={<InterestButton />}
      />
    </div>
    <ChannelsModal isOpen={!!modalData} close={closeProgramModal} channels={modalData} /> :
  </>
}

DigiTV.propTypes = landingPagePT

export default withResponsivity(DigiTV)