import {
  isObject,
  reactDev,
  fileToBase64,
} from 'scripts/helpers'
import Indexable from 'types/Indexable'

export function FileToSCH(file: File) {
  return new Promise((resolve, reject) => {
    fileToBase64(file)
      .then(b64 => {
        if (typeof b64 === 'string')
          resolve({
            filename: file.name,
            b64content: b64.split(';base64,')[1]
          })
        else
          reject(b64)
      })
  })
}

export interface SCH_response {
  err: string,
  data: Indexable,
}

const APIpath_overwrites = [
  // {
  //   pattern: /.*\/tv\/brief/,
  //   replace: '/tv/offers'
  // },
  {
    pattern: /^\//,
    replace: ''
  }
]

/**
 * Makes fetch requests to the Setri Chytre API
 * 
 * @param {String} APIpath API path to access
 * @param {Object|null} [data] Undefined for GET, Null for post without body. 
 * @param {Boolean} [verbose=false] Whether to print out url and data on request.
 * 
 * @returns {Promise} Resolve on success without errors, reject otherwise with relevant data (body or response).
 */
export default function setriChytre_API(
  APIpath: String,
  data?: Indexable | null,
  options?: { verbose: boolean }
): Promise<SCH_response> {
  const opts =
  {
    verbose: false,
    ...options
  }

  const sanitised_APIpath = APIpath_overwrites.reduce(
    (APIpath, { pattern, replace }) => APIpath.replace(pattern, replace)
    , APIpath)

  const url = 'https://api.' +
    (reactDev() ? 'beta.setrichytre.cz' : window.location.hostname) +
    `/${sanitised_APIpath}`

  let APIcall: Promise<Response>

  switch (data) {
    case undefined:
      APIcall = fetch(url, {
        method: 'get',
        credentials: 'include'
      })
      opts.verbose && console.log('GET', url)
      break
    case null:
      APIcall = fetch(url, {
        method: 'post',
        credentials: 'include'
      })
      opts.verbose && console.log('POST', url)
      break
    default:
      APIcall = fetch(url, {
        method: 'post',
        credentials: 'include',
        headers: new Headers({
          'Content-type': 'application/json'
        }),
        body: JSON.stringify(data)
      })
      opts.verbose && console.log('POST', url, data)
  }

  return new Promise((resolve, reject) => {
    APIcall
      .then(response => {
        opts.verbose && console.log({ response })
        if (response.ok)
          return response.json()
        else
          reject(response)
      })
      .then(body => {
        opts.verbose && console.log({ body })
        if (isObject(body) && body.err === null)
          resolve(body)
        reject(body)
      })
      .catch(reject)
  })
}