import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';
import AllProgramsLink from '../ProgramsLink';

function DigiTVPackage(props) {
  return <div className='Component-DigiTVPackage'>
    <div className='price-wrapper'>
      <div className='price'>
        +&nbsp;{props.price}&nbsp;Kč
      </div>
    </div>
    <div className='name-wrapper'>
      <div className='name'>
        {props.name}
      </div>
      <AllProgramsLink onClick={props.openProgramModal} />
    </div>
  </div>;
}

DigiTVPackage.propTypes = {
  price: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  openProgramModal: PropTypes.func.isRequired
}

export default DigiTVPackage