import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import 'styles/style.scss'
import Main from './Main'
import { BasketProvider } from 'contexts/Basket'
import { CookiesProvider } from 'react-cookie'
import { ResponsivityProvider } from 'contexts/Responsivity'
import { TitleProvider } from 'contexts/Title'

const
  breakpoints = [
    {
      name: 'large',
      breakpoint: 1366
    },
    {
      name: 'menu',
      breakpoint: 1160
    },
    {
      name: 'section',
      breakpoint: 1000
    },
    {
      name: 'medium',
      breakpoint: 800
    },
    {
      name: 'mobile',
      breakpoint: 600
    },
    {
      name: 'small',
      breakpoint: 400
    }
  ],
  maxBasketAge = 24 * 60 * 60 * 1000 //-- One day

ReactDOM.render(
  <Router>
    <CookiesProvider>
      <TitleProvider title='Šetři chytře'>
        <BasketProvider maxItemAge={maxBasketAge}>
          <ResponsivityProvider breakpoints={breakpoints}>
            <Main />
          </ResponsivityProvider>
        </BasketProvider>
      </TitleProvider>
    </CookiesProvider>
  </Router>
  , document.getElementById('page-content')
)
