import React from 'react';
import PropTypes from 'prop-types'
import ModalBox from 'components/ModalBox'
import './style.scss';
import uuid from 'uuid'
import ChannelList from './ChannelList';
import { hash, isEmpty } from 'scripts/helpers';
import channels from './channels.json'
import { isArray } from 'util';
const key_seed = uuid()

function ChannelsModal(props) {

  if (!isArray(props.channels))
    return null

  return <ModalBox
    className='Component-ChannelsModal'
    {...props}
  >
    <div className='channel-groups-container'>
      {channels
        .map(channel_group => ({
          ...channel_group,
          items: channel_group.items.filter(channel => {
            // console.log({  channel_group, channel })
            return props.channels.includes(channel.label)
          })
        }))
        .filter(channel_group => !isEmpty(channel_group.items))
        .map((channel_group, key_iterator) =>
          <ChannelList
            key={hash(key_seed, key_iterator)}
            title={channel_group.label}
            channels={channel_group.items}
          />
        )}
    </div>
  </ModalBox>;
}

ChannelsModal.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.string),
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default ChannelsModal