import React from 'react';
import PropTypes from 'prop-types'

import './style.scss';
import Offer from 'components/Offer';

const testOffer = {
  productName: "test_product",
  offerType: "public",
  provider: {
    id: 69,
    logoUrl: "https://pbs.twimg.com/profile_images/203659312/joinha_400x400.jpg",
    name: "Test Corp."
  }
}

function InfoReadout(props) {
  return <div className='Component-InfoReadout'>
    <Offer
      offer={{
        ...testOffer,
        fields: [{
          cell: <div className='bonus-container'>
            <div className='bonus-text-wrapper'>
              <span className='bonus-text'>
                Bonus
            </span>
            </div>
            {/* &nbsp; */}
            <div className='bonus-value-wrapper'>
              <span className='bonus-value' style={{
                transform: `scale(${.75 + .00005 * props.bonus})`,
                transition: '.2s'
              }}>
                {props.bonus === undefined ? 'až 5000' :
                  props.bonus} Kč
            </span>
            </div>
          </div>
        }]
      }}
      noButtons={true}
      compact={true}
    />
  </div>;
}

InfoReadout.propTypes = {
  bonus: PropTypes.number,
  saving: PropTypes.number,
  total: PropTypes.number,
}

export default InfoReadout