import React from 'react';
import PropTypes from 'prop-types'

import './style.scss';
import SCH_getVATs from 'scripts/SCH_getVATs';
import makeCancellable from 'make-cancellable-promise'
import { currencyCZ } from 'scripts/helpers';
import useError from 'hooks/useError'
import JumpingEllipsis from 'components/JumpingEllipsis';

function CostWithTax(props) {
  const {
    className,
    product,
    incVAT,
    cost,
  } = props,
    [vat, setVat] = React.useState(null),
    throwError = useError

  React.useEffect(() => {
    const { promise, cancel } = makeCancellable(SCH_getVATs())

    promise.then(vats => setVat(vats[product]), throwError)

    return cancel
  }, [product, throwError])

  return <div className={['CostWithTax-Component', className].toClass()}>
    <span className='amount'>
      {incVAT ?
        vat === null ?
          <JumpingEllipsis /> :
          currencyCZ(cost * (1 + vat)) :
        currencyCZ(cost)}
    </span>
    <span className='tax'>
      ({incVAT ? 'vč.' : 'bez'} DPH)
    </span>
  </div>
}

CostWithTax.propTypes = {
  cost: PropTypes.number.isRequired,
  incVAT: PropTypes.bool.isRequired,
  product: PropTypes.oneOf([
    'electricity',
    'internet',
    'mobile',
    'gas',
    'tv',
  ]).isRequired,
}

export default CostWithTax