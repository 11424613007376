import React from 'react';
import PropTypes from 'prop-types'
import url from 'url-prop-type'
import { FaChevronRight } from 'react-icons/fa'
import OfferBox from '../OfferBox'

import './style.scss';
import { hash } from 'scripts/helpers';
import uuid from 'uuid'
import AllProgramsLink from '../ProgramsLink';
const key_seed = uuid()

function DigiTVOffer(props) {
  return <OfferBox
    className={[props.className, 'Component-DigiTVOffer'].toClass()}
    colour={props.colour}
    header={{
      ...props.header,
      left: <img src={props.header.image} alt='header-img' className='header-image' />,
      right: props.header.content
    }}
    main={<>
      <div className='title' style={{ color: props.colour }}>{props.text.title}</div>
      <div className='content'>
        <div className='paragraph'>{props.text.paragraph}</div>
        <div className='subtitle'>{props.text.subtitle}</div>
      </div>
    </>}
    footer={<>
      <div className='logos-wrapper'>
        {props.logos.map((logo, key_iterator) =>
          <img
            key={hash(key_seed, key_iterator)}
            className='logo'
            src={logo}
            alt='logo'
          />
        )}
      </div>
      <div className='button continue-button' livesupp-call='' >
        Mám zájem
      <FaChevronRight className='icon' />
      </div>
      <AllProgramsLink onClick={props.openProgramModal} />
    </>}
  >
  </OfferBox>;
}

DigiTVOffer.propTypes = {
  header: PropTypes.shape({
    background: url.isRequired,
    image: url.isRequired,
    content: PropTypes.node.isRequired,
  }).isRequired,
  text: PropTypes.shape({
    title: PropTypes.node.isRequired,
    paragraph: PropTypes.node.isRequired,
    subtitle: PropTypes.node.isRequired,
  }).isRequired,
  logos: PropTypes.arrayOf(url.isRequired),
  colour: PropTypes.string.isRequired,
  openProgramModal: PropTypes.func.isRequired,
}

export default DigiTVOffer