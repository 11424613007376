import { FormPageInfo } from '../'

const utilitiesFormInfo: Omit<FormPageInfo, 'path'>[] =
  [
    {
      name: 'internet',
      slug: 'internet',
      label: 'Internet'
    },
    {
      name: 'tv',
      slug: 'televize',
      label: 'Televize'
    },
  ]

export default utilitiesFormInfo
