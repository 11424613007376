import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import offer from 'types/offer'
import { hash, isVoid } from 'scripts/helpers'
import uuid from 'uuid'
import SortButton from './SortButton'
import useResponsivity from 'hooks/useResponsivity'
// import cloneDeep from 'lodash/cloneDeep'
// import EvenRowGrid from 'components/EvenRowGrid'
const key_seed = uuid()

function OffersSorter(props) {
  const {
    className,
    children: offers,
    setSorter,
    unsetSorter,
    modalPoint,
  } = props,
    { isGreaterThan } = useResponsivity(),
    [currentSortCriterion, _setCurrentSortCriterion] = React.useState(null),
    setCurrentSortCriterion = (criterion) => {
      // console.log('setting sort criterion:', criterion)
      return _setCurrentSortCriterion(criterion)
    },
    [currentSortDirection, _setCurrentSortDirection] = React.useState(null),
    setCurrentSortDirection = (direction) => {
      // console.log('setting sort direction:', direction)
      return _setCurrentSortDirection(direction)
    },
    // offers_ref = React.createRef(),
    //-- get sortable fields
    availableSortCriteria = (offers || [])
      .concat() //-- Clone array
      .sort((lhs, rhs) => lhs.id - rhs.id)
      .reduce((all, offer) => {
        const fields = offer.fields
          .filter(({ selector }) => !isVoid(selector))
          .reduce((all, { name, label }) => ({
            ...all,
            [name]: label
          }), {})

        // console.log({ fields })

        return {
          ...all,
          ...fields,
        }
      }, {})

  // //-- Populate local offers
  // React.useEffect(() => {
  //   console.log('creating local copy of', { offers })
  //   offers_ref.current = cloneDeep(offers)
  // }, [offers, offers_ref])

  // console.log({ offers })
  // console.log({ availableSortCriteria })
  // console.log({ currentSortCriterion, currentSortDirection })

  React.useEffect(() => {
    if (currentSortCriterion === null || currentSortDirection === null) {
      // console.log('Unsetting sorter')
      unsetSorter()
    }
    else {
      const sorterId = hash(currentSortCriterion, currentSortDirection)

      function sorterFunction(offers) {
        return offers.sort(({ fields: lhs }, { fields: rhs }) => {
          // console.log(offers, lhs, rhs)
          const
            sortField = {
              lhs: lhs.find(({ name }) => name === currentSortCriterion),
              rhs: rhs.find(({ name }) => name === currentSortCriterion),
            },
            canSortBy = {
              lhs: sortField.lhs && ('selector' in sortField.lhs),
              rhs: sortField.rhs && ('selector' in sortField.rhs),
            }

          // console.log({ canSortBy, sortField })

          let order
          switch (currentSortDirection) {
            case 'asc':
              order = val => val
              break
            case 'dsc':
              order = val => -val
              break
            default:
              order = val => 0
              break
          }

          function difference(lhs, rhs) {
            if (lhs < rhs)
              return -1
            else if (lhs > rhs)
              return 1
            else
              return 0
          }

          const diff = (!canSortBy.lhs || !canSortBy.rhs) ?
            difference(canSortBy.lhs, canSortBy.rhs) :
            difference(sortField.lhs.selector, sortField.rhs.selector)

          // console.log({ lhs, rhs, diff })

          return order(diff)
        })
      }

      // console.log('Setting sorter:', sorterId)
      setSorter(sorterFunction, sorterId)
    }
  }, [currentSortCriterion, currentSortDirection, setSorter, unsetSorter])

  return <div className={['OffersSorter-Component', className, isGreaterThan(modalPoint) ? 'asExpander' : 'asModal'].toClass()}>

    {Object.entries(availableSortCriteria).map(([criterion, label]) =>
      <SortButton className='sort-button'
        key={hash(key_seed, criterion, currentSortCriterion, currentSortDirection)}
        sortDirection={
          (currentSortCriterion === criterion) ?
            currentSortDirection : null}
        onClick={() => {
          if (currentSortCriterion === criterion)
            switch (currentSortDirection) {
              case 'asc':
                setCurrentSortDirection('dsc')
                break
              case 'dsc':
                setCurrentSortDirection('asc')
                break
              default:
                throw Error('Invalid sort direction: ' + String(currentSortDirection))
            }
          else {
            setCurrentSortCriterion(criterion)
            setCurrentSortDirection('dsc')
          }
        }}>
        {label}
      </SortButton>)}

  </div>
}

OffersSorter.propTypes = {
  setSorter: PropTypes.func.isRequired,
  unsetSorter: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(offer),
}

export default React.memo(OffersSorter)