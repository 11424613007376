import Indexable from 'types/Indexable'
import { getSCFormPage } from 'forms'
import { $$offer_product, $$offer_origin } from '.'
import ProductOrigin from './ProductOrigin'

type Product = Indexable

export default async function createOffer(
  origin: ProductOrigin,
  product: Product,
): Promise<{
  [$$offer_product]: Readonly<Product>,
  [$$offer_origin]: Readonly<ProductOrigin>,
} & Indexable> {
  return {
    ...await getSCFormPage(origin.formType, origin.formPage).options.hooks.onOffer(
      Object.freeze(product),
      Object.freeze(origin.formData)
    ),
    [$$offer_product]: Object.freeze(product),
    [$$offer_origin]: Object.freeze(origin),
  }
}