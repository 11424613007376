import React from 'react'

import PropTypes from 'prop-types'
import {
  FaChevronUp as Up,
  FaChevronDown as Down
} from 'react-icons/fa'
import {
  clamp,
  roundToNearest,
  addHundredsGap,
  toNumber,
  isNumber,
  isVoid,
  // isEmpty,
  // hash
} from 'scripts/helpers'
// import Input from '.'
import './style.scss'

function defaultInputParser(val) {
  if (isNumber(val))
    return addHundredsGap(val.toString())
}

function defaultOutputParser(val) {
  return toNumber(val)
}

function InputNumber(props) {

  const {
    min,
    max,
    value,
    className,
  } = props,
    options = {
      default: 0,
      round: 1,
      inputParser: defaultInputParser,
      outputParser: defaultOutputParser,
      ...props.options
    },
    sanitiseVal = React.useCallback(val => {
      // if (!isNumber(val))
      //   return options.default

      const
        clamped = clamp(min, val, max),
        rounded = roundToNearest(clamped, options.round)

      // console.log({ val, clamped, minmax: { min: props.min, max: props.max }, rounded, round: options.round })
      return rounded
    }, [options.round, max, min]),
    parseInput = React.useCallback(val => options.inputParser(val, defaultInputParser), [options]),
    parseOutput = React.useCallback(val => options.outputParser(val, defaultOutputParser), [options]),
    [isFocused, setFocused] = React.useState(false),
    [manualInput, setManualInput] = React.useState(null),
    /**
     * Commits a value into the form
     */
    commit = React.useCallback((passed_value = isVoid(manualInput) ? value : manualInput) => {
      setManualInput(null)
      // console.log({ passed_value })

      let numeric_val
      if (isNumber(passed_value))
        numeric_val = passed_value
      else {
        numeric_val = parseOutput(passed_value)
        if (!isNumber(numeric_val)) {
          // console.log({ numeric_val })
          props.onChange(undefined)
          return
        }
      }

      const sanitised = sanitiseVal(numeric_val)
      // console.log({ numeric_val, sanitised })

      props.onChange(sanitised)
      return sanitised
    }, [manualInput, value, sanitiseVal, props, parseOutput]),
    step = step => {
      // console.log({ step, 'props.value': props.value, default: options.default })

      const
        val = value === undefined ? options.default : value,
        steppedValue = val + step
      // console.log({ value, step, steppedValue })

      commit(steppedValue)
    },
    increment = () => step(props.step),
    decrement = () => step(-props.step)

  //-- sanitize inital unclean value
  React.useEffect(() => {
    // console.log('inital sanitisation', { value })
    if (isNumber(value)) {
      const sanitised = sanitiseVal(value)
      if (value !== sanitised) {
        // console.log('initial sanitisation', { value, sanitised })
        props.onChange(sanitised)
      }
    }
  }, [props, sanitiseVal, value])

  // console.log({ value, manualInput })
  return <div className={['Component-InputNumber', className].toClass()}
    onKeyDown={e => {
      // console.log(e.keyCode)
      if (props.disabled) {
        if (e.keyCode !== 9) { //tab
          e.preventDefault()
          e.stopPropagation()
        }
      }
      else if (e.shiftKey === false) {
        // console.log(e.keyCode)
        switch (e.keyCode) {
          case 13: //enter
            props.onEnter(commit())
            break
          case 38: //up
            e.preventDefault()
            e.stopPropagation()
            increment()
            break;
          case 40: //down
            e.preventDefault()
            e.stopPropagation()
            decrement()
            break;
          default:
          //not a keycode we care about
        }
      }
    }}
  >
    <div className='wrapper'>
      <input type='text' className='form-field' size='1'
        value={isVoid(manualInput) ? isFocused ? value : (parseInput(value) || '') : manualInput}
        placeholder={props.placeholder}
        onFocus={() => { setFocused(true) }}
        onChange={({ target: { value } }) => setManualInput(value)}
        onBlur={() => {
          setFocused(false)
          props.onLeave(commit())
        }}
        controlled=''
      />
      <div className='spinner'>
        <Up onClick={increment} />
        <Down onClick={decrement} />
      </div>
    </div>
  </div>;
}

InputNumber.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  options: PropTypes.object,
}

InputNumber.defaultProps = {
  min: -Infinity,
  max: Infinity,
  step: 1,
  onLeave: () => { },
  onEnter: () => { },
  options: {},
}

export default InputNumber