import React from 'react';
import PropTypes from 'prop-types'
// import getSize from 'get-size'
import OfferTopButton from './OfferTopButton'
import OfferViewSwitchIcon from './OfferViewSwitchIcon'
// import Debounce from 'awesome-debounce-promise'
import {
  FaArrowCircleLeft as BackIcon,
  FaQuestionCircle as HelpIcon,
  FaFilter as FilterIcon,
  FaSort as SortIcon,
} from 'react-icons/fa'
import './style.scss';
// import OperatorOrb from 'components/OperatorOrb'
// import withResponsivity from 'HOCs/withResponsivity'
// import { getDocHeight, isNumber } from 'scripts/helpers'
import { isMobileOnly } from 'react-device-detect'
// import OperatorRibbon from 'components/OperatorRibbon'
import Expander from 'components/Expander'
import renderable from 'types/renderable';
import ModalBox from 'components/ModalBox';
import useResponsivity from 'hooks/useResponsivity';
import { createPortal } from 'react-dom';
// import { useTraceUpdate } from 'hooks/useTraceUpdate';
// import { animated, useSpring } from 'react-spring'
// import offer from 'types/offer'

// const ribbon_snapPoint = 800

function OffersHeader(props) {
  const {
    className,
    children,
    isLoading,
    showTableButtons,
    modalPoint,
  } = props,
    // [toptext_anim, setToptext_anim] = useSpring(() => ({})),
    // [toptextHeight, setToptextHeight] = React.useState(null),
    {
      isLessThanOrEqual,
      isLessThan,
      addResizeListener,
    } = useResponsivity(),
    [manualRibbon, setManualRibbon] = React.useState(isMobileOnly || isLessThanOrEqual('mobile')),
    // [forceRibbonState, setLastScrollDelta] = React.useState(false),
    // [ribbonOpen, setRibbonOpen] = React.useState(true),
    [preventRibbonUpdate, setPreventRibbonUpdate] = React.useState(false),
    [bannerOpen, _setBannerOpen] = React.useState(true),
    setBannerOpen = React.useCallback((new_bannerOpen, force = false) => {
      if ((force || !preventRibbonUpdate) && (new_bannerOpen !== bannerOpen)) {
        // console.log({ preventRibbonUpdate, bannerOpen: new_bannerOpen })
        _setBannerOpen(new_bannerOpen)
        setPreventRibbonUpdate(true)
      }
    }, [bannerOpen, preventRibbonUpdate]),
    closeBanner = React.useCallback(force => {
      setBannerOpen(false, force)
    }, [setBannerOpen]),
    [sorterOpen, _setSorterOpen] = React.useState(false),
    closeSorter = () => _setSorterOpen(false),
    [filterOpen, _setFilterOpen] = React.useState(false),
    closeFilter = () => _setFilterOpen(false),
    openBanner = React.useCallback(no_close_others => {
      // lastScroll_ref.current = (1 - bannerOpen)
      setBannerOpen(true)
      if (!no_close_others) {
        closeSorter()
        closeFilter()
      }
    }, [setBannerOpen]),
    openSorter = () => {
      closeBanner(true)
      closeFilter()
      _setSorterOpen(true)
    },
    openFilter = () => {
      closeBanner(true)
      closeSorter()
      _setFilterOpen(true)
    },
    toggleBanner = no_close_others => {
      if (bannerOpen)
        closeBanner(no_close_others)
      else
        openBanner(no_close_others)
    },
    mobile = isLessThan('section'),
    // lastScroll_ref = React.useRef(0),
    useModals = isLessThanOrEqual(modalPoint)//,
  // { current: lastScroll } = lastScroll_ref,
  // forceBannerState = React.useRef(null)

  //-- Resize detector for manual ribbon
  React.useEffect(() => {
    return addResizeListener(() => {
      const newManualRibbon = isMobileOnly || isLessThanOrEqual('mobile')
      if (newManualRibbon !== manualRibbon) {
        // console.log({newManualRibbon})
        setManualRibbon(newManualRibbon)
      }
    })
  }, [addResizeListener, isLessThanOrEqual, manualRibbon])

  // //-- Monitor scroll for banner
  // React.useEffect(() => {
  //   if (isNumber(toptextHeight)) {

  //     let prevScroll = {
  //       percentage: 0,
  //       pageTop: window.pageYOffset,
  //     }

  //     const onScrollFunc = () => {
  //       const
  //         newPageTop = window.pageYOffset,
  //         newPercentage = newPageTop / (getDocHeight() - window.innerHeight),
  //         pageTop_delta = prevScroll.pageTop - newPageTop,
  //         percentage_delta = prevScroll.percentage - newPercentage,
  //         haveScrolled = pageTop_delta * percentage_delta > 0

  //       if (newPercentage === 1 ||            //-- Close banner on page bottom
  //         sorterOpen || filterOpen ||         //-- Close banner if another scrollout item is open
  //         (haveScrolled && pageTop_delta < 0))//-- Close banner manual control is on and we scrolled down
  //         closeBanner()

  //       else if (newPercentage === 0 ||                         //-- Open banner on page top
  //         (!manualRibbon && haveScrolled && pageTop_delta > 0)) //-- Open banner on scroll up
  //         openBanner()

  //       prevScroll = {
  //         percentage: newPercentage,
  //         pageTop: newPageTop,
  //       }
  //     }

  //     window.addEventListener('scroll', onScrollFunc)
  //     return () => window.removeEventListener('scroll', onScrollFunc)
  //     // }
  //   }
  // }, [closeBanner, filterOpen, manualRibbon, openBanner, sorterOpen, toptextHeight])

  // useTraceUpdate(props, {
  //   toptextHeight,
  //   manualRibbon,
  //   bannerOpen,
  //   sorterOpen,
  //   filterOpen,
  // })

  return createPortal(
    <div className={['Component-OffersHeader', className].toClass()}>

      {/* <Expander className='toptext-container' isOpen={bannerOpen} snapTolerance={5}
        onRest={() => { setPreventRibbonUpdate(false) }}>
        <OperatorRibbon className='toptext' ref={ref => ref && setToptextHeight(ref.scrollHeight)}>
          {children.toptext}
        </OperatorRibbon>
      </Expander> */}

      <div className='buttons-container'>
        <div className='buttons-wrapper-left buttons-wrapper'>
          <OfferTopButton onClick={props.goBack} iconPos='before' icon={BackIcon} animation='bounce-left' />
        </div>
        <div className='buttons-wrapper-right buttons-wrapper'>
          {showTableButtons ? <>
            <OfferTopButton className='sorter-button' onClick={() => {
              if (sorterOpen)
                closeSorter()
              else
                openSorter()
            }}>
              <SortIcon className='sorter-icon' />
            </OfferTopButton>
            <OfferTopButton className='filter-button' onClick={() => {
              if (filterOpen)
                closeFilter()
              else
                openFilter()
            }}>
              <FilterIcon className='filter-icon' />
            </OfferTopButton>
            {mobile ? null :
              <OfferViewSwitchIcon
                className={isLoading ? 'disabled' : undefined}
                onClick={() => props.setForceMoile(!props.forceMobile)}
                compact={props.forceMobile} />}
            {manualRibbon ?
              <OfferTopButton className='help-button' onClick={() => { toggleBanner() }}>
                <HelpIcon className='help-icon' />
              </OfferTopButton> : null}
          </> : null}
        </div>
      </div>

      {useModals ?
        <>
          <ModalBox isOpen={filterOpen} close={closeFilter} persistentContent={true}>
            {children.filter}
          </ModalBox>
          <ModalBox isOpen={sorterOpen} close={closeSorter} persistentContent={true}>
            {children.sorter}
          </ModalBox>
        </> :
        <>
          <Expander className='filter-container' isOpen={filterOpen} close={closeFilter}>
            {children.filter}
          </Expander>
          <Expander className='sorter-container' isOpen={sorterOpen} close={closeSorter}>
            {children.sorter}
          </Expander>
        </>}


    </div>,
    document.getElementById('page-header'))
}

OffersHeader.propTypes = {
  showTableButtons: PropTypes.bool.isRequired,
  setForceMoile: PropTypes.func.isRequired,
  forceMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  children: PropTypes.shape({
    toptext: renderable.node,
    filter: renderable.node,
    sorter: renderable.node,
  })
}
OffersHeader.defaultProps = {
  // onResize: () => { }
}

export default React.memo(OffersHeader)