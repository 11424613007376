import getPageAPI from "./LS_PageAPI"

let pageAPI = null
function closeActionMenu() {
  if (pageAPI)
    pageAPI.setActionMenuActive(false)
}

const actionBanner_symbol = Symbol('LiveSupp ActionBanners')

export default function () {
  const actionMenuCloseListeners = window[actionBanner_symbol] || 0

  if (!pageAPI)
    getPageAPI().then(LS_PageAPI => pageAPI = LS_PageAPI)

  if (!actionMenuCloseListeners)
    window.addEventListener('click', closeActionMenu)

  window[actionBanner_symbol] = actionMenuCloseListeners + 1

  return function () {
    if (!--window[actionBanner_symbol])
      window.removeEventListener('click', closeActionMenu)
  }
}
