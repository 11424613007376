import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { rawBoon_proptype } from 'components/BoonLabel';
import { hash, isEmpty } from 'scripts/helpers';
import uuid from 'uuid'
import { getCellBoons } from 'components/BoonLabel';
import { getBoonDetails } from 'components/BoonLabel';
const key_seed = uuid()

/**
 * Appends a boon cell to the end of the fields array (inplce).
 * @param {product} product
 * @param {any[]} fields
 * @param {Object} [field_details] Detials to use when creating field
 * @param {String} [field_details.name='boons']
 * @param {String} [field_details.label='Výhody']
 */
export function appendBoonCell(product, fields, field_details = null) {

  if (!isEmpty(getCellBoons(product.boons.map(getBoonDetails))))
    fields.push({
      name: 'boons',
      label: 'Výhody',
      ...field_details,
      cell: passProps =>
        <BoonsCell {...passProps}>
          {product.boons}
        </BoonsCell>
    })
}

const BoonsCell = props => {
  const {
    className,
    label,
    compact,
    children: boons
  } = props

  return <div className={['BoonsCell-Component', className, compact ? 'compact' : null].toClass()}>
    <div className='boonsCell-title-wrapper'>
      <span className='boonsCell-title'>
        {label}
      </span>
    </div>
    <div className='boonsCell-boons-container'>
      {getCellBoons(boons.map(getBoonDetails))
        .map(({ label }, key_index) =>
          <div key={hash(key_seed, key_index)} className='boonsCell-boon'>
            {label}
          </div>)}
    </div>
  </div>
}

BoonsCell.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape(rawBoon_proptype)).isRequired,
  label: PropTypes.node.isRequired,
  compact: PropTypes.bool.isRequired
}

export default BoonsCell