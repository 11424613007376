import React from 'react';

import PropTypes from 'prop-types'
import './style.scss';
import Form from 'components/Form';
import { useEffect } from 'react';
import { generateDefaultData } from 'components/Form';
import { SketchPicker } from 'react-color'
import {
  mergeDeep,
  index,
  someKeys,
  // reactDev,
} from 'scripts/helpers';
import { FaAlignLeft, FaAlignRight, FaAlignCenter, FaAlignJustify, FaDownload, FaUpload } from 'react-icons/fa';
import fileDownload from 'js-file-download'
import Files from 'react-files'

const
  textPrototype = [
    {
      type: 'text',
      name: 'content',
      options: {
        placeholder: 'Text'
      },
    },
    {
      type: 'number',
      name: 'fontSize',
      options: {
        placeholder: 'Velikost textu',
        label: 'px'
      },
    },
    {
      type: 'number',
      name: 'marginTop',
      options: {
        placeholder: 'Mezera nad textem',
        label: 'px'
      },
    },
    {
      type: 'number',
      name: 'marginBottom',
      options: {
        placeholder: 'Mezera pod textem',
        label: 'px'
      },
    },
    {
      type: 'number',
      name: 'letterSpacing',
      options: {
        placeholder: 'Mezera mezi písmeny',
        label: 'px'
      },
    },
    {
      type: 'number',
      name: 'lineHeight',
      options: {
        placeholder: 'Mezera mezi řádky',
        label: 'px'
      },
    },
    {
      type: 'switch',
      name: 'alignment',
      options: {
        default: 'right'
      },
      data: [
        {
          label: (props) => <div {...props}><FaAlignLeft /></div>,
          value: 'left',
        },
        {
          label: (props) => <div {...props}><FaAlignCenter /></div>,
          value: 'center',
        },
        {
          label: (props) => <div {...props}><FaAlignRight /></div>,
          value: 'right',
        },
        {
          label: (props) => <div {...props}><FaAlignJustify /></div>,
          value: 'justify',
        }
      ]
    }
  ],
  cloneableTexts = [{
    type: 'cloneable',
    name: 'texts',
    options: {
      button: 'Přidat text'
    },
    data: textPrototype
  }]

const ToolSection = ({ title, children }) => <div className={`tool-section tool-${encodeURI(title)}`}>
  <div className='tool-name'>{title}:</div>
  {children}
</div>

function ToolPanel(props) {
  const
    setState = state => {
      const newState = mergeDeep(props.state, state, { overwrite_undefined: true })
      // console.log({ oldState: props.state, state, newState })
      localStorage.setItem('az customiser state', JSON.stringify(newState))
      props.setState(newState)
    },
    setFormData = (key, val) => {
      let newData = {}
      index(newData, key, val, { force: true }) //assign new value
      setState(newData)
    },
    getFormData = key => index(props.state, key)

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('az customiser state'))

    if (stored === null)
      setState(generateDefaultData(cloneableTexts))
    else
      setState(stored)
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return <div className='Component-ToolPanel'>
    <div className='panel-wrapper'>
      <div className='panel'>
        <ToolSection title='Barva chatu'>
          <SketchPicker
            color={{
              ...props.state.colour,
              a: props.state['bg-opacity']
            }}
            onChange={colour => {
              // console.log({ colour })
              setState({
                colour: someKeys(['r', 'g', 'b'], colour.rgb),
                'bg-opacity': colour.rgb.a
              })
            }}
          />
        </ToolSection>
        <ToolSection title='Pozadí'>
          <Form {...{ setFormData, getFormData }}
            formFields={[
              {
                type: 'text',
                name: 'bg-url',
                options: {
                  placeholder: 'URL pozadí',
                  default: '',
                },
              }
            ]}
          />
        </ToolSection>
        <ToolSection title='Akce'>
          <Form {...{ setFormData, getFormData }}
            formFields={
              Object.keys(props.actions)
                .map(action => ({
                  type: 'check',
                  name: `actions.${action}`,
                  data: props.actions[action],
                  options: {
                    placeholder: action,
                    // label: props.actions[action] ? 'on' : 'off'
                  }
                }))
            }
          />
        </ToolSection>
        <ToolSection title='Texty'>
          <Form {...{ setFormData, getFormData }}
            formFields={cloneableTexts}
          />
        </ToolSection>
      </div>
      <Files clickable
        accepts={['application/json', '.json']}
        onChange={files => {
          const fileReader = new FileReader()
          fileReader.onload = event =>
            setState(JSON.parse(event.target.result))
          fileReader.readAsText(files[0])
        }}
        maxFiles={1}
        className='button import-button file-button'>
        Import<FaUpload />
      </Files>
      <div className='button export-button file-button'
        onClick={() => {
          const date = new Date(),
            dateStr = `${date.getFullYear()}.${date.getMonth().toString().padStart(2, '0')}.${date.getDay().toString().padStart(2, '0')}-${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
          // console.log({ dateStr })
          fileDownload(JSON.stringify(props.state), `customAz-${dateStr}.json`, 'application/json')
        }}
      >
        Export <FaDownload />
      </div>
    </div>
  </div >;
}

ToolPanel.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.bool).isRequired
}

export default ToolPanel