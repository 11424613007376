import React from 'react'
import {
  FaLightbulb as UtilitiesIcon,
  FaWifi as CommsIcon,
} from 'react-icons/fa'
import Indexable from 'types/Indexable'
import { isEmpty } from 'scripts/helpers'
import { offersBasePath } from 'pages/Offers'

interface FormInfoBase extends Indexable {
  name: string,
  slug: string,
  label: string,
}
interface FormPath extends String {
  base: string,
  form: string,
  offers: string,
}
export interface FormPageInfo extends FormInfoBase {
  path: FormPath
}
interface FormTypeInfoFormless extends FormInfoBase {
  icon: JSX.Element,
}
interface FormTypeInfo extends FormTypeInfoFormless {
  forms: FormPageInfo[]
}

export const formBasePath = '/form'

function makePathInjectionMapper(formTypeInfo: FormTypeInfoFormless) {
  return (formItem: FormInfoBase) => {
    const path = `${formTypeInfo.slug}/${formItem.slug}`

    return {
      ...formItem,
      path: {
        base: path,
        form: `${formBasePath}/${path}`,
        offers: `${offersBasePath}/${path}`,
      }
    }
  }
}

const
  formTypesInfo: FormTypeInfo[] =
    [
      {
        name: 'utilities',
        slug: 'energie',
        label: 'Elektřina a plyn',
        icon: <UtilitiesIcon className='icon' />,
      },
      {
        name: 'comms',
        slug: 'volani-internet-tv',
        label: 'Internet',
        icon: <CommsIcon className='icon' />,
      },
    ].map(
      formTypeInfo => ({
        ...formTypeInfo,
        forms: require(`./${formTypeInfo.name}`).default
          .map(makePathInjectionMapper(formTypeInfo))
      })
    )

/**
 * Searches in for headers in order by the following properties:
 * - name
 * - slug
 * - label
 * @param formTypeQuery Query to search for form by.
 */
export function getSCFormTypeInfo(formTypeQuery: string) {

  for (const searchProp of ['name', 'slug', 'label']) {
    const formTypeInfo = formTypesInfo.find(
      formInfo => formInfo[searchProp] === formTypeQuery
    )
    if (formTypeInfo)
      return formTypeInfo
  }

  throw new Error(`Can't find form type \`${formTypeQuery}\``)
}

export function getSCFormPageInfo(formTypeQuery: string, formQuery: string) {

  const formTypeInfo = getSCFormTypeInfo(formTypeQuery)

  for (const searchProp of ['name', 'slug']) {
    const formPageInfo = formTypeInfo.forms.find(
      formPageInfo => formPageInfo[searchProp] === formQuery
    )
    if (formPageInfo)
      return formPageInfo
  }

  console.error({ formTypeInfo, formTypeQuery, formQuery })
  throw new Error(`Can't find form page \`${formQuery}\` in \`${formTypeInfo.name}\``)
}

export function getSCFormPage(formTypeQuery: string, formQuery: string) {
  return require(`./${
    getSCFormTypeInfo(formTypeQuery).name
    }/${
    getSCFormPageInfo(formTypeQuery, formQuery).name
    }`).default
}

export function getSCForm(formTypeQuery: string) {
  return getSCFormTypeInfo(formTypeQuery).forms
    .map(({ name }) => getSCFormPage(formTypeQuery, name))
    .filter(formPage => !formPage.options.disabled)
}

export function getSCFormLink(formTypeQuery: string, formQuery: string) {
  return `/form/${getSCFormTypeInfo(formTypeQuery).slug}/${getSCFormPageInfo(formTypeQuery, formQuery).slug}`
}

export function getSCNavItems() {
  return formTypesInfo
    .map(form_header => getSCForm(form_header.name))
    // .map(form => { console.log(form); return form })
    .reduce(formToNavitem_reducer, [])
}

function formToNavitem_reducer(all: Indexable[], form: Indexable) {
  const layout = form.filter((field: Indexable) => !field.options.disabled)

  // console.log(layout)

  if (!isEmpty(layout))
    all.push(...layout.map((item: Indexable) => item.navItem))

  return all
}

export default formTypesInfo