import React from 'react';
import PropTypes from 'prop-types'
import {
  compareDeep,
  hash,
  getLocalItem,
  setLocalItem,
  isPureObject,
} from 'scripts/helpers'
import uuid from 'uuid'
import useError from 'hooks/useError';
import { isArray } from 'util';

const
  VERBOSE = false,
  symbols = {
    id: Symbol('ID'),
    ts: Symbol('Timestamp'),
  },
  keys = {
    id: `ID[[${hash('basket item id', getLocalItem('machineId'))}]]`,
    ts: `timestamp[[${hash('basket item ts', getLocalItem('machineId'))}]]`,
  }

const BasketContext = React.createContext();
export default BasketContext
export const BasketConsumer = BasketContext.Consumer
export function BasketProvider(props) {

  const {
    maxItemAge,
    children,
  } = props,
    [_basket, _setBasket] = React.useState(getLocalItem('basket', 'json') || {}),
    throwError = useError(),

    basket = React.useCallback(
      Object.entries(_basket)
        .reduce((basket, [, item]) => {
          const now = new Date().getTime()
          if (now - item[keys.ts] < maxItemAge) {

            const newItem = {
              ...item,
              [symbols.id]: item[keys.id],
              [symbols.ts]: item[keys.ts],
            }

            delete newItem[keys.id]
            delete newItem[keys.ts]

            basket.push(newItem)
          }

          return basket
        }, [])
        .sort((lhs, rhs) => lhs[keys.ts] - rhs[keys.ts])
      , [_basket]),

    setBasket = React.useCallback(
      function set_basket(items) {
        if (!isArray(items)) {
          console.error({ 'First setBasket argument': items })
          throwError(new Error(`First argument of setBasket must be array`))
        }
        else if (items.some(item => !isPureObject(item))) {
          console.error({ 'Non object items': items.filter(item => !isPureObject(item)) })
          throwError(new Error('All items in setBasket array must be pure objects'))
        }

        VERBOSE && console.log('Called setBasket with', { items })

        const newBasket = items.reduce((newBasket, item) => {

          if (!_basket[item[symbols.id]] && !item[symbols.id])
            item[symbols.id] = uuid()

          if (!compareDeep(_basket[item[symbols.id]], item))
            item[symbols.ts] = new Date().getTime()

          newBasket[item[symbols.id]] = {
            ...item,
            [keys.id]: item[symbols.id],
            [keys.ts]: item[symbols.ts],
          }

          // console.log({ newBasket })

          return newBasket
        }, {})

        VERBOSE && console.log('Setting basket to', { newBasket })

        _setBasket(newBasket)
      }, [_basket, throwError])

  React.useEffect(() => {
    setLocalItem('basket', _basket)
  }, [_basket])

  return <BasketContext.Provider value={[basket, setBasket]}>
    {children}
  </BasketContext.Provider>
}

BasketProvider.propTypes = {
  maxItemAge: PropTypes.number.isRequired
}