import ProductOrigin from "./ProductOrigin"
import setriChytre_API from "scripts/SCH_API"
import createOffer from "./createOffer"
import Indexable from "types/Indexable"

export default function fetchOffers(
  origin: Omit<ProductOrigin, 'searchId'> | ProductOrigin,
  options: {
    verbose?: boolean
  } | null = null,
): Promise<{ offers: Indexable[] } & Indexable> {
  const {
    verbose
  } = {
    verbose: false,
    ...options
  }

  return new Promise((resolve, reject) => {

    setriChytre_API(`${origin.formPage}/${origin.formData ? 'offers' : 'brief'}`, origin.formData, { verbose })
      //-- offers fetch successful
      .then(response => {
        verbose && console.log({ 'response.data': response.data })
        verbose && console.log('running onOffers');

        (Promise.all(
          response.data.products.map(
            async (product: Indexable) =>
              await Promise.resolve(createOffer({
                searchId: response.data.searchId,
                ...origin,
              }, product))
          )
        ) as Promise<Indexable[]>)
          .then((offers: Indexable[]) => {
            verbose && console.log({ offers })

            resolve({
              ...response.data,
              offers
            })
          })

      })
      .catch(reject)
  })
}