import React from 'react';

import PropTypes from 'prop-types'

import './style.scss';
import JumpingEllipsis from 'components/JumpingEllipsis'
import lockOpen from 'assets/fontawesome/svgs/solid/lock-open.svg'
import lockClosed from 'assets/fontawesome/svgs/solid/lock.svg'
// import {
//   enableBodyScroll,
//   disableBodyScroll,
// } from 'body-scroll-lock'
import { createPortal } from 'react-dom';

function LoadingPage(props) {
  const {
    loading,
    retry,
    className,
    staticContext,
    ...passProps
  } = props

  // React.useEffect(() => {
  //   if (loading) {
  //     disableBodyScroll()
  //     document.body.classList.add('loading')
  //   }
  //   else {
  //     enableBodyScroll()
  //     document.body.classList.remove('loading')
  //   }
  // }, [loading])

  return createPortal(
    <div className={['Component-LoadingPage', className].toClass()} {...passProps}>
      <div className='wrapper'>
        <div className='icon-wrapper'>
          <div className='icon' style={{
            mask: `url(${props.loading ? lockOpen : lockClosed}) center no-repeat`,
            WebkitMask: `url(${props.loading ? lockOpen : lockClosed}) center no-repeat`,
          }}>
          </div>
        </div>
        <span className='text'>
          Zajišťujeme bezpečnou komunikaci
      </span>
        <JumpingEllipsis />
      </div>
    </div>,
    document.body
  )
}

LoadingPage.propTypes = {
  loading: PropTypes.bool
}

export default LoadingPage