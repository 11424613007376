import {
  shape,
  string,
  oneOfType,
} from 'prop-types'

export default oneOfType([
  string.isRequired,
  shape({
    pathname: string.isRequired,
    hash: string,
  }).isRequired
])