import React from 'react';

import useError from 'hooks/useError';

export default WrappedComponent =>
  React.forwardRef((props, ref) => {
    return <WrappedComponent
      throwError={useError()}
      {...props}
      ref={ref}
    />
  })