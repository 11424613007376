import React from 'react'

import PropTypes from 'prop-types'
// import {
//   FaChevronUp as Up,
//   FaChevronDown as Down
// } from 'react-icons/fa'
// import { clamp } from '../../scripts/helpers'
import Slider from 'react-input-slider'

import './style.scss'
// import InputNumber from './InputNumber'
import InputDelimeter from '../Delimeter'

function InputSlider(props) {

  const
    increment = () => {
      props.onChange(
        Math.min(
          (props.value ? Number(props.value) : 0)
          + Number(props.step),
          Number(props.max)
        )
      )
    },
    decrement = () => {
      props.onChange(
        Math.max(
          (props.value ? Number(props.value) : 0)
          - Number(props.step),
          Number(props.min)
        )
      )
    }

  return <div className='Component-InputSlider'
    onKeyDown={e => {
      if (props.disabled) {
        if (e.keyCode !== 9) //tab
          e.preventDefault()
      }
      else if (e.shiftKey === false) {
        // console.log(e.keyCode)
        switch (e.keyCode) {
          case 38: //up
            e.preventDefault()
            increment()
            break;
          case 40: //down
            e.preventDefault()
            decrement()
            break;
          default:
          //not a keycode we care about
        }
      }
    }
    }
  >
    {props.placeholder === undefined ? null :
      <InputDelimeter placeholder={props.placeholder} />
    }
    <Slider
      axis='x'
      x={props.value === undefined ? undefined : props.value}
      onChange={({ x }) => props.onChange(x)}
      xmax={props.max}
      xmin={props.min}
      xstep={props.step}
      disabled={props.options.disabled}
    />
  </div>;
}

InputSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onLeave: PropTypes.func
}

InputSlider.defaultProps = {
  step: 1,
  onLeave: () => { }
}

export default InputSlider