import React from 'react'

import './style.scss'
import proptypes from '../pageProptypes'

import {
  FaPiggyBank as SavingIcon,
  FaMoneyBillWave as SubsidyIcon,
} from 'react-icons/fa'
import {
} from 'react-icons/gi'

export const initialState = {
  useSubsidy: undefined
}

function payment_choice(props) {
  const { state, setState, goToPage } = props

  return <div className='Component-payment_choice'>
    <h2>Chcete využít dotaci na nový boiler?</h2>

    <div className='tiles-container'>
      <div className={'tile' + (state.electricity === true ? ' active' : '')}
        onClick={() =>
          setState({ useSubsidy: true },
            () => alert('redirect to no subsidy page'))
        }>
        <SavingIcon className='icon' />
        <div className='text'>
          Ne, chci jen ušetřit
    </div>
      </div>
      <div className={'tile' + (state.gas === true ? ' active' : '')}
        onClick={() =>
          setState({ useSubsidy: false },
            () => goToPage('subsidy_display'))
        }>
        <SubsidyIcon className='icon' />
        <div className='text'>
          Ano, chci i dotaci
    </div>
      </div>
    </div>

    <div className='button-wrapper'>
      <div className='button'
        onClick={() => goToPage('util_choice')}>
        Zpět
      </div>
    </div>
  </div >;
}

payment_choice.propTypes = proptypes

export default payment_choice