import React from 'react';

// import './style.scss';
import { animated, useSpring } from 'react-spring'
// import LoadingIcon from 'components/LoadingIcon';
import JumpingEllipsis from 'components/JumpingEllipsis';

function SpringNumber(props: {
  children: number | null,
  className?: string,
}) {
  const {
    children,
    className,
  } = props,
    { number } = useSpring({ number: children ? children : 0 }),
    Container = children === null ?
      JumpingEllipsis :
      animated.span

  return <Container className={['SpringNumber-Component', className].toClass()}>
    {number.interpolate(x => x.toFixed(0))}
  </Container>
}

export default SpringNumber