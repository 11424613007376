import React from 'react';

import PropTypes from 'prop-types';

import {
  FaRegCheckSquare as CheckedIco,
  FaRegSquare as UncheckedIco
} from 'react-icons/fa'

import './style.scss'
import { syntheticRender } from 'scripts/helpers';
import field_label from 'types/field_label';

function InputCheck(props) {
  const {
    className,
    children,
    value,
    options,
  } = props,
    label = React.useCallback(
      syntheticRender(children, { value }),
      [children, value]
    ),
    checkbox = React.useCallback(options.hideCheckbox === true ?
      null : value ?
        <CheckedIco className='checkbox' /> :
        <UncheckedIco className='checkbox' />,
      [value, options.hideCheckbox])

  return <div className={['Component-InputCheck', className].toClass()}
    onClick={() => props.onChange(!value)}>
    {options.swap_checkbox ?
      <>{label}{checkbox}</> :
      <>{checkbox}{label}</>}
  </div>
}

InputCheck.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  onLeave: PropTypes.func,
  children: field_label,
}

InputCheck.defaultProps = {
  onLeave: () => { }
}

export default InputCheck