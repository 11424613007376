import React from 'react';

import './style.scss'
import useTitle from 'hooks/useTitle';

function KlubChytrych(props) {
  const Title = useTitle()

  return <>
    <Title>Klub chytrých</Title>
    <div className='Component-KlubChytrych'>
      <div className='title emphasise'>
        <h1>Klub chytrých spořivců</h1>
      </div>
      <p>
        Zbavte se <b>zdarma</b> starostí s hlídáním smluv a nechte nás šetřit své peníze.
    </p>
      <div className='point-wrapper'>
        <ol className='list emphasise'>
          <li>
            <p>Hlídáme konec závazků a výpovědních lhůt – už žádné automatické prodlužování nevýhodných smluv</p>
          </li>
          <li>
            <p>S předstihem dodáme aktuální a nejvýhodnější nabídky (včetně neveřejných) od ověřených dodavatelů</p>
          </li>
          <li>
            <p>Mnoho výhod od našich partnerů, např. slevy na další produkty nebo vyplacení pokut za předčasné ukončení
        nevýhodných závazků</p>
          </li>
          <li>
            <p>Stojíme na Vaší straně, naprostá mlčenlivost a maximální ochrana osobních údajů je u nás samozřejmostí</p>
          </li>
        </ol>
      </div>
      <div className='timeline-wrapper'>
        {/* <Timeline
        from='today'
        to='t + 1 year'
        events={[
          {
            date: 'today',
            // cell: ''
            cell: {
              html: 'Kontakt s Šetži chytře',
              style: 'background: white; color: black; border: 1px solid black; border-radius: 6px; padding: 4px 3px;'
            }
          },
          {
            date: 't + 7 days',
            // cell: ''
            cell: {
              html: 'Analýza smluv',
              style: 'background: white; color: black; border: 1px solid black; border-radius: 6px; padding: 4px 3px;'
            }
          },
          {
            date: 't + 1 month',
            // cell: ''
            cell: {
              html: 'Nejlepší nabídky an trhu',
              style: 'background: white; color: black; border: 1px solid black; border-radius: 6px; padding: 4px 3px;'
            }
          },
          {
            date: 't + 2 months',
            // cell: ''
            cell: {
              html: 'Výpovědní lhůta',
              style: 'background: white; color: black; border: 1px solid black; border-radius: 6px; padding: 4px 3px;'
            }
          },
          {
            date: 't + 10 months',
            // cell: ''
            cell: {
              html: 'Vyřešení papírování',
              style: 'background: white; color: black; border: 1px solid black; border-radius: 6px; padding: 4px 3px;'
            }
          },
          {
            date: 't + 1 year',
            // cell: ''
            cell: {
              html: 'Konec závazku a nová smlouva',
              style: 'background: white; color: black; border: 1px solid black; border-radius: 6px; padding: 4px 3px;'
            }
          }
        ]}
      /> */}
      </div>
      <div className='button' livesupp-call=''>Chci do klubu</div>
    </div>
  </>
}

export default KlubChytrych