import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';
import withResponsivity from 'HOCs/withResponsivity'
import uuid from 'uuid'
import toPx from 'to-px'
import getSize from 'get-size'
import {
  constructArray,
  hash,
  compareDeep,
} from 'scripts/helpers';
import ResizeDetector from 'react-resize-detector'
// import Debounce from 'awesome-debounce-promise'
const key_seed = uuid()

class EvenRowGrid extends React.Component {
  state = {
    renderState: null,
    nextStateButton: false,
  }
  measurerRef = React.createRef()
  selfRef = React.createRef()

  forceRecalc = (nextStateButton = false) => {
    if (this.state.renderState !== null)
      this.setState({ renderState: null, nextStateButton })
    else
      this.forceUpdate()
  }

  shouldComponentUpdate(props, state) {
    const self_width = getSize(this.selfRef.current).width

    if (this.self_width !== self_width) {
      // props.verbose && console.log({ width: self_width }, '!==', { oldWidth: this.self_width })
      // props.verbose && console.log('EvenRowGrid updating')
      this.self_width = self_width
      return true
    }

    if (!compareDeep(this.state, state)) {
      // props.verbose && console.log({ state }, '!==', { oldState: this.state })
      // props.verbose && console.log('EvenRowGrid updating')
      return true
    }
    else
      // props.verbose && console.log({ state }, '===', { oldState: this.state })

      if (!compareDeep(this.props, props)) {
        // props.verbose && console.log({ props }, '!==', { oldProps: this.props })
        props.verbose && console.log('children changed; recalcing')
        this.forceRecalc()
      }

    return false
  }
  componentDidUpdate() {
    const nextStateButton = document.createElement('div') // NEXT STEP BUTTON
    nextStateButton.innerHTML = 'Click me' // NEXT STEP BUTTON
    nextStateButton.style.position = 'fixed' // NEXT STEP BUTTON
    nextStateButton.style.background = '#222' // NEXT STEP BUTTON
    nextStateButton.style.color = '#fff' // NEXT STEP BUTTON
    nextStateButton.style.bottom = '10px' // NEXT STEP BUTTON
    nextStateButton.style.right = '10px' // NEXT STEP BUTTON
    nextStateButton.style.borderRadius = '2px' // NEXT STEP BUTTON
    nextStateButton.style.padding = '2px 5px' // NEXT STEP BUTTON
    nextStateButton.style.cursor = 'pointer' // NEXT STEP BUTTON
    const { current: measurerRef } = this.measurerRef

    // this.maxWidth = toPx('100vw')
    this.props.verbose &&
      console.log({ renderState: this.state.renderState, measurerRef: measurerRef })

    switch (this.state.renderState) {
      case null:
        this.props.verbose &&
          console.log('EvenRowGrid rendering')

        this.setState({ renderState: 'minwidth' })
        break

      case 'minwidth':
        let minwidthStyle = document.createElement('style')
        minwidthStyle.innerHTML = '* {min-width: min-content;}'

        document.head.appendChild(minwidthStyle)

        const min_widestChild = this.getWidestChild(measurerRef)
        this.min_width = min_widestChild ? getSize(min_widestChild).width : 0
        this.props.verbose &&
          console.log({ min_width: this.min_width })

        if (this.props.nextStateButton || this.state.nextStateButton) {
          nextStateButton.onclick = () => { // NEXT STEP BUTTON
            if (document.head.contains(minwidthStyle))
              document.head.removeChild(minwidthStyle)
            this.setState({ renderState: 'maxwidth' }
              , () => document.body.removeChild(nextStateButton) // NEXT STEP BUTTON
            )
          } // NEXT STEP BUTTON
          document.body.appendChild(nextStateButton) // NEXT STEP BUTTON
        }
        else {
          // nextStateButton.onclick = () => { // NEXT STEP BUTTON
          document.head.removeChild(minwidthStyle)
          this.setState({ renderState: 'maxwidth' }
            // , () => document.body.removeChild(nextStateButton) // NEXT STEP BUTTON
          )
          // } // NEXT STEP BUTTON
          // document.body.appendChild(nextStateButton) // NEXT STEP BUTTON
        }
        break

      case 'maxwidth':
        const max_widestChild = this.getWidestChild(measurerRef)
        this.max_width = max_widestChild ? getSize(max_widestChild).width : 0
        this.props.verbose &&
          console.log({ max_width: this.max_width })

        if (this.props.nextStateButton || this.state.nextStateButton) {
          nextStateButton.onclick = () => // NEXT STEP BUTTON
            this.setState({ renderState: 'render' }
              , () => document.body.removeChild(nextStateButton) // NEXT STEP BUTTON
            )
          document.body.appendChild(nextStateButton) // NEXT STEP BUTTON
        }
        else {
          // nextStateButton.onclick = () => // NEXT STEP BUTTON
          this.setState({ renderState: 'render', nextStateButton: false }
            // , () => document.body.removeChild(nextStateButton) // NEXT STEP BUTTON
          )
          // document.body.appendChild(nextStateButton) // NEXT STEP BUTTON
        }
        break

      default:
      // console.log('EvenRowGrid rendering')
    }
  }

  getWidestChild(ref) {
    if (ref === null)
      return null

    let widest = null
    for (const child of ref.childNodes)
      if (widest === null || getSize(child).width > getSize(widest).width)
        widest = child

    return widest
  }

  render() {
    const {
      className,
      children,
      rowGap,
      columnGap,
      justifyContent,
      verbose,
      getBreakpoint,
      isEqual,
      isGreaterThan,
      isGreaterThanOrEqual,
      isLessThan,
      isLessThanOrEqual,
      addResizeListener,
      sizeProps,
      style,
      nextStateButton,
      ...passProps
    } = this.props,
      gross_item_width = justifyContent === 'stretch' ? this.max_width : this.min_width,
      gaps = {
        rowGap: toPx(rowGap),
        columnGap: toPx(columnGap),
      },
      item_count = children.length,
      gapless_row_capacity = (gross_item_width && this.self_width) ? Math.floor(this.self_width / gross_item_width) : item_count,
      row_capacity = gapless_row_capacity - Math.max(0, Math.ceil(((gapless_row_capacity * gross_item_width + (gapless_row_capacity - 1) * gaps.columnGap) - this.self_width) / (gross_item_width + gaps.columnGap))),
      row_count = row_capacity ? Math.floor(item_count / row_capacity) : null,
      overflow_count = item_count - (row_count * row_capacity),
      move_to_lastrow = overflow_count ? Math.max(0, Math.floor((row_capacity - overflow_count) / (row_count + 1))) : 0,
      lastrow_count = overflow_count + move_to_lastrow * row_count,
      grid_count = item_count - lastrow_count,
      column_count = row_capacity - move_to_lastrow,
      // spare_row_space = this.self_width - gross_item_width * column_count,
      // spare_item_space = spare_row_space / column_count,
      item_width = (this.self_width + gaps.columnGap) / column_count - gaps.columnGap,
      positioning = {
        margin: `${justifyContent === 'center' ? 'auto' : '0'}`,
        width: `${justifyContent === 'center' ? 'auto' : '100%'}`,
      }

    //-- logging
    // console.log(passProps)
    if (verbose && this.state.renderState === 'render') {
      console.log({
        item_count,
        self_width: this.self_width,
        gross_item_width,
        ...gaps
      })
      console.log({
        item_width,
        // spare_row_space,
      })
      console.log({
        gapless_row_capacity,
        row_capacity,
        overflow_count,
        move_to_lastrow,
        lastrow_count,
      })
      console.log({
        row_count,
        column_count,
      })
    }

    return <ResizeDetector handleWidth onResize={() => {
      verbose && console.log('EvenRowGrid resized')
      this.forceRecalc()
    }}>
      <div className={['Component-EvenRowGrid', className].toClass()}
        style={{ ...gaps, ...style }}
        ref={this.selfRef}
        {...passProps}>

        {this.state.renderState === 'render' ?
          <>
            {grid_count === 0 ? null :
              constructArray(row_count, index =>
                <div role='row' key={hash(key_seed, index)}
                  style={{
                    ...gaps,
                    ...positioning,
                    gridTemplateColumns: `repeat(${column_count}, ${item_width}px)`,
                  }}>
                  {children
                    .filter((_, i) =>
                      (i >= index * column_count) &&
                      (i < (index + 1) * column_count))
                  }
                </div>
              )}

            {lastrow_count === 0 ? null :
              <div role='row'
                style={{
                  ...gaps,
                  ...positioning,
                  gridTemplateColumns: `repeat(${column_count ? lastrow_count : 1}, ${item_width}px)`,
                  gridTemplateRows: `repeat(${column_count ? 1 : item_count}, 1fr)`,
                }}>
                {children.slice(grid_count)}
              </div>}
          </> :
          <div className={['measurer', this.state.renderState].toClass()} ref={this.measurerRef}>
            {children}
          </div>}
      </div>
    </ResizeDetector>
  }
}

EvenRowGrid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  // className: PropTypes.string,
  rowGap: PropTypes.string,
  columnGap: PropTypes.string,
  justifyContent: PropTypes.oneOf(['stretch', 'center']),
  verbose: PropTypes.bool,
  nextStateButton: PropTypes.bool,
}
EvenRowGrid.defaultProps = {
  // className: '',
  rowGap: '0',
  columnGap: '0',
  justifyContent: 'stretch',
  verbose: false,
  nextStateButton: false,
}

export default withResponsivity(EvenRowGrid)