import React from 'react'
import BasketContext from 'contexts/Basket'
import { $$offer_origin, $$offer_product } from 'pages/Offers'
import Indexable from 'types/Indexable'
import ProductOrigin from 'pages/Offers/ProductOrigin'
import Product from 'types/Product'

type BasketItem = {
  [$$offer_origin]: ProductOrigin,
  [$$offer_product]: Product
} & Indexable
export default function useBasket() {
  return React.useContext(BasketContext) as [BasketItem[], (newBasket: BasketItem[]) => void]
}