import React from 'react';

import AzBanner from './AzBanner'
// import azBackground from '../assets/money.jpg'
// import Title from 'components/Title'
import './style.scss';
import ToolPanel from './ToolPanel';
import useTitle from 'hooks/useTitle'

function AzCustomiser(props) {
  const [state, setState] = React.useState({
    actions: {
      offline: true,
      chat: true,
      voice: true,
      video: true,
      deny: true,
    },
    texts: [],
    colour: { r: 0, b: 0, g: 0 },
    'bg-opacity': .5
  }),
    Title = useTitle()

  return <>
    <Title>AZ customiser</Title>
    <div className='Component-AzCustomiser'>
      <AzBanner
        actions={state.actions}
        bgUrl={state['bg-url']}
        bgOpacity={state['bg-opacity']}
        colour={state['colour']}
        texts={state.texts}
      />
      <ToolPanel
        actions={state.actions}
        state={Object.freeze(state)}
        {...{ setState }}
      />
    </div>
  </>
}

export default AzCustomiser