import React from 'react';
import PropTypes from 'prop-types';
import field_label from 'types/field_label'
import {
  isFunction,
  isRegExp,
} from 'scripts/helpers'
import {
  isArray,
} from 'util'
import Cloneable from 'components/Form/Cloneable'
import InputCheck from './Check'
import InputDate from './Date'
import InputDelimeter from './Delimeter'
import InputFile from './File'
// import InputHintedText from './DEPRACATED_HintedText'
import InputLoading from './Loading'
import InputNumber from './Number'
import InputSelect from './Select'
import InputSlider from './Slider'
import InputSwitch from './Switch'
import InputText from './Text'
// import InputTextArea from './DEPRACATED_TextArea'
import InputUnitLabel from './UnitLabel'
import InputWrapper from './Wrapper'
import HelpTooltip from 'components/Input/HelpTooltip'

import './style.scss'

const
  InputTypes = {
    'check': InputCheck,
    'cloneable': Cloneable,
    'date': InputDate,
    // 'hinted-text': InputHintedText,
    'file': InputFile,
    'delimeter': InputDelimeter,
    'loading': InputLoading,
    'number': InputNumber,
    'select': InputSelect,
    'slider': InputSlider,
    'switch': InputSwitch,
    'text': InputText,
    // 'text-area': InputTextArea,
    'unit-label': InputUnitLabel,
    'wrapper': InputWrapper
  },
  // highlightables = [
  //   'hinted-text',
  //   'number',
  //   'select',
  //   'text',
  //   'text-area'
  // ],
  labelables = [
    'number',
    'text',
  ]

export function validateField(field, getFormData, verbose = false) {
  const {
    options,
    name,
    data
  } = field,
    value = getFormData(field.name)
  verbose && console.log('validating field', { field })

  if (options.mandatory === true) {
    if (value === undefined) {
      verbose && console.log({ name }, 'data is undef, this field is invalid')
      return false
    }
    if (isArray(data)) {
      const some = data.some(d => d.value === value)
      verbose && console.log({ name }, 'data is an array, valid field is' + (some ? '' : "'nt") + ' selected')
      return some
    }
    return true
  }

  if (isRegExp(options.mandatory)) {
    verbose && console.log({ name }, 'has a regex mandatory')
    return options.mandatory.test(value)
  }

  if (isFunction(options.mandatory)) {
    verbose && console.log({ name }, 'has a functional mandatory')
    return options.mandatory(value, getFormData)
  }

  return true
}

export default function Input(props) {

  const
    {
      type,
      disabled,
      options: _options,
      label,
      highlight,
      highlightState: _highlightState,
      getFormData,
      setFormData,
      className,
      name,
      ...other //onChange, onLeave, value, data etc.
    } = props,
    {
      placeholder,
      // label,
      tooltip,
      min,
      max,
      maxRows,
      step,
      mandatory,
      filter,
      inputTooltip,
      ...options
    } = _options,
    passProps = {
      placeholder,
      // label,
      children: label,
      min,
      max,
      maxRows,
      step,
      mandatory,
      options,
      disabled,
      tooltip: inputTooltip,
      ...other
    },
    ProplessInput =
      type === 'custom' ?
        () => other.data :
        InputTypes[type]

  // console.log({ passProps })

  if (ProplessInput === undefined)
    throw Error(`Bad input type: ${type}`)

  // apply filter option if present
  if (isFunction(filter) && isArray(passProps.data))
    passProps.data =
      passProps.data.filter(val => filter(val, getFormData))

  //todo get state
  const
    highlightState = highlight === false ? null : _highlightState,
    hasUnitLabel = !!(labelables.includes(type) && label),
    hasTooltip = !!tooltip,
    classes = [
      'Component-Input',
      className,
      highlightState ? highlightState : '',
      hasUnitLabel ? '' : 'with-label',
      // disabled ? 'disabled' : null
    ]

  // console.log(props.name, { hasLabel: hasUnitLabel, hasTooltip, passProps, props })

  switch (hasTooltip) {

    // Render without tooltip
    case false:

      switch (hasUnitLabel) {
        // Render without tooltip or label
        case false:
          return <div className={classes.toClass()}>
            <ProplessInput {...passProps} />
          </div>

        // Render with label but withou tooltip
        case true:
          if (isArray(label))
            return <InputUnitLabel
              labels={label}
              className={classes.toClass()}
              value={getFormData(name + "::label")}
              onChange={label => setFormData(name + "::label", label)}
            >
              <ProplessInput {...passProps} />
            </InputUnitLabel>
          else
            return <InputUnitLabel labels={label} className={classes.toClass()}>
              <ProplessInput {...passProps} />
            </InputUnitLabel>

        default:
          throw new Error(`Bad hasLabel value: ${hasUnitLabel}`)
      }

    // Render with tooltip
    case true:
      const TooltipComp = () => <HelpTooltip text={tooltip} />

      switch (hasUnitLabel) {

        // Render with tooltip but without label
        case false:
          return <>
            <div className={classes.toClass()}>
              <ProplessInput {...passProps} />
            </div>
            <TooltipComp />
          </>

        // Render with both tooltip and label
        case true:
          return <InputUnitLabel
            labels={label}
            className={classes}
            value={getFormData(name + "::label")}
            onChange={label => setFormData(name + "::label", label)}>
            <ProplessInput {...passProps} />
            <TooltipComp />
          </InputUnitLabel>

        default:
          throw new Error(`Bad hasLabel value: ${hasUnitLabel}`)
      }

    default:
      throw new Error(`Bad hasTooltip value: ${hasUnitLabel}`)
  }
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.object,
  label: field_label,
  highlightState: PropTypes.oneOf(['required', 'valid', 'invalid', null]),
  className: PropTypes.string,
  highlight: PropTypes.bool
}
Input.defaultProps = {
  options: {},
  className: '',
  highlight: false,
  highlightState: null,
}