import React from 'react';

import PropTypes from 'prop-types';
import uuid from 'uuid';

import './style.scss'
import { hash } from 'scripts/helpers';

const key_seed = uuid()

export default function InputUnitLabel(props) {
  let key_iterator = 0

  const {
    className
  } = props,
    label = <div className='Component-InputUnitLabel unit-label-wrapper'>
      {Array.isArray(props.labels) ?
        props.labels.map(label =>
          <div key={hash(key_seed + key_iterator++)} className={`unit-label${label === props.value ? ' active' : ''}`}
            onClick={() => props.onChange(label)}>
            {label}
          </div>) :
        <div className='unit-label'>
          {props.labels}
        </div>
      }</div>

  return <>
    <div className={[className].toClass()}>
      {Array.isArray(props.children) ? props.children[0] : props.children}
      {label}
    </div>
    {Array.isArray(props.children) ? props.children[1] : null}
  </>;
}

InputUnitLabel.propTypes = {
  labels: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
}