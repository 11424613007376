import PropTypes from 'prop-types';

import uuid from 'uuid';
import { hash } from 'scripts/helpers';
const key_seed = uuid(),
  generateRows = props => {
    // console.log('generating rows')
    return props.data.map((subfield, key_iterator) =>
      props.generateFormRow({
        ...subfield,
        name: props.name ? props.name + '.' + subfield.name : subfield.name
      },
        hash(key_seed + key_iterator))
    )
  }

export default function Multifield(props) {
  return generateRows(props)
}

Multifield.propTypes = {
  options: PropTypes.object,
  data: PropTypes.array,
  generateFormRow: PropTypes.func.isRequired
}

Multifield.defaultProps = {
  data: []
}