import React, { PureComponent } from 'react';

import PropTypes from 'prop-types'

import uuid from 'uuid'
import {
  mod,
  hash,
  isFunction,
  isObject,
  isEmpty,
  isNumber,
  isVoid
} from 'scripts/helpers';
import {
  FaChevronDown as DropdownIcon,
  FaChevronUp as PullUpIcon
} from 'react-icons/fa'
import { findBestMatch } from 'string-similarity'
import onClickOutside from 'react-onclickoutside'
import Debounce from 'debounce-promise'
import './style.scss'
import InputText from '../Text';
import Tooltip from 'components/Tooltip';

const key_seed = uuid()

class InputSelect extends PureComponent {
  state = {
    opened: false,
    highlighted: null,
    hintValue: undefined,
  }

  onScroll = Debounce(() => {
    // console.log('forcing poscheck')
    if (this.state.opened)
      // call open to recalculate dropdown positioning
      this.open()
  }, 20)

  componentDidUpdate(oldProps) {
    this.scrollListener = window.addEventListener('scroll', this.onScroll)

    if (oldProps.data !== this.props.data && !isEmpty(this.props.data)) {
      let searchBy
      if (this.state.hintValue !== undefined)
        searchBy = this.state.hintValue
      else {
        const currentVal = this.props.data.find(item => item.value === this.props.value)
        if (currentVal)
          searchBy = currentVal.label
        else
          return
      }

      this.setHighlight(this.bestMatch(searchBy).bestMatchIndex)
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  wrapRef = React.createRef()
  highlightedRef = React.createRef()

  handleClickOutside = () => this.close()

  isRefSet = () => this.wrapRef.current !== null

  open = () => {
    if (this.locked)
      return

    if (!isEmpty(this.props.data) && typeof this.state.hintValue === 'string')
      this.setHighlight(this.bestMatch(this.state.hintValue))

    if (!this.isRefSet())
      this.setState({ opened: 'bot' })

    const
      rect = this.wrapRef.current.getBoundingClientRect(),
      bodyRect = document.documentElement.getBoundingClientRect(),
      dist = {
        toTop: rect.bottom - rect.height,
        toBot: bodyRect.height - rect.bottom
      }

    // console.log(this.wrapRef.current, document.documentElement, dist)

    this.setState({ opened: dist.toTop > dist.toBot ? 'top' : 'bot' })
  }
  close = () => {
    this.locked = true
    this.setState({ opened: false },
      () => setTimeout(() => this.locked = false),
      100)
  }
  toggle = () => this.state.opened ? this.close() : this.open()

  runHook = (name, ...args) => {
    if (isObject(this.props.options) &&
      isObject(this.props.options.hooks) &&
      isFunction(this.props.options.hooks[name]))
      this.props.options.hooks[name](...args)
  }

  submitVal = val => {
    this.runHook('onChange', val)

    // console.log('submitting', val)
    this.setState({ hintValue: undefined })
    if (isFunction(this.props.onChange))
      this.props.onChange(val)
  }

  submitHighlighted = (toSubmit = this.state.highlighted) => {
    if (typeof toSubmit === 'number' &&
      toSubmit < this.props.data.length)
      this.submitVal(this.props.data[toSubmit].value)
  }

  setHighlight = index => {
    // console.log('setting highlight to', index, this.props.data.length)
    this.setState({
      highlighted: mod(index, this.props.data.length)
    }, () => {
      if (this.highlightedRef.current !== null)
        this.highlightedRef.current
          .scrollIntoView({ block: 'nearest' })
    })
  }

  bestMatch = query => {
    const labels = this.props.data.map(({ label }) => label)
    // console.log({ query, labels, data: this.props.data })

    if (isEmpty(labels))
      throw Error("Can't find best match in an empty array")

    const match = findBestMatch(query, labels)
    return match
  }

  render() {
    // console.log('select data', this.props.data)
    const
      { highlighted } = this.state,
      currentValue =
        (this.props.data.find(item => item.value === this.props.value) ||
          { label: undefined }).label,
      textValue = this.state.hintValue === undefined ? currentValue : this.state.hintValue,
      hinting = textValue &&
        (this.props.data.every(item => item.label.trim() !== textValue.trim())),
      noSearch = !this.props.options.allowSearch,
      {
        options,
        tooltip,
      } = this.props,
      {
        hooks,
      } = options,
      {
        onHintChange,
        onChange,
        onEnter,
        ...passHooks
      } = (hooks || {})

    return <Tooltip ref={this.wrapRef}
      text={isFunction(tooltip) ? tooltip({
        value: this.props.value,
        hintValue: this.state.hintValue,
        options: this.props.data
      }) : tooltip}
      className={['Component-InputSelect',
        this.props.disabled ? 'disabled' : null,
        noSearch ? 'no-search' : null,
        hinting ? 'hinting' : null,
      ].toClass()}
      onClick={this.open}
      onMouseLeave={() => this.setState({ highlighted: null })}>
      <InputText
        // className='test'
        placeholder={this.props.placeholder}
        value={textValue}
        onChange={noSearch ? undefined : newHintValue => {
          // console.log({ newHintValue })
          this.open()
          this.runHook('onHintChange', newHintValue)

          const found = this.props.data.find(item => item.label === newHintValue)
          if (found === undefined) {
            // console.log('no exact match to', { newHintValue })
            if (!isEmpty(this.props.data))
              this.setHighlight(this.bestMatch(newHintValue).bestMatchIndex)
            this.setState({ hintValue: newHintValue })
          }
          else {
            this.setState({ hintValue: undefined })
            this.submitVal(found.value)
          }
        }}
        onEnter={() => {
          // console.log('enter pressed', this.state.tmpVal)
          if (this.state.hintValue === '')
            this.submitVal('')
          else {

            //-- If nothing is highlighted, submit first option
            if (isVoid(this.state.highlighted))
              this.submitHighlighted(0)
            else
              this.submitHighlighted()
          }
          this.close()
          this.setState({ hintValue: undefined })
        }}
        onBlur={() => {
          // console.log({ hintValue: this.state.tmpVal })
          if (this.state.hintValue === '')
            this.submitVal(undefined)
          else if (!isEmpty(this.props.data) && this.state.hintValue !== undefined) {
            const bestMatch = this.bestMatch(this.state.hintValue)
            // console.log({ bestMatch })
            this.submitVal(bestMatch.bestMatch.value)
          }
        }}
        onKeyDown={e => {
          if (this.props.disabled) {
            if (e.keyCode !== 9) //tab
              e.preventDefault()
          }
          else if (e.shiftKey === false) {
            if (e.keyCode !== 13)
              this.open()
            switch (e.keyCode) {
              case 9:
                this.close()
                break;
              case 38: //up
                e.preventDefault();
                this.setHighlight(highlighted - 1)
                break;
              case 40: //down
                e.preventDefault();
                this.setHighlight(
                  isNumber(highlighted) ?
                    highlighted + 1 :
                    null)
                break;
              default:
              //not a keycode we care about
            }
          }
        }}
        maxRows={1}
        disabled={this.props.disabled}
        unselectable={noSearch ? 'on' : undefined}
        readOnly={noSearch ? 'readonly' : undefined}
        {...passHooks}
      />
      {(this.state.opened && this.props.data.length !== 0) ?
        <PullUpIcon className='select-icon pullup-icon' onClick={this.close} /> :
        <DropdownIcon className='select-icon dropdown-icon' onClick={this.open} />}
      {this.state.opened === false || this.props.data.length === 0 ? null :
        <ul className='hint-dropdown' style={
          (() => {
            switch (this.state.opened) {
              case 'top':
                return {
                  bottom: 'calc(100% - 4px)',
                  borderTopLeftRadius: 'inherit',
                  borderTopRightRadius: 'inherit',
                  borderBottom: 0,
                }
              case 'bot':
                return {
                  top: 'calc(100% - 4px)',
                  borderBottomLeftRadius: 'inherit',
                  borderBottomRightRadius: 'inherit',
                  borderTop: 0,
                }
              default:
                throw new Error(this.state.opened, 'is not a valid open state in', this)
            }
          })()
        }>
          {this.props.data.map((option, index) =>
            <li key={hash(key_seed, index)}
              ref={highlighted === index ? this.highlightedRef : undefined}
              className={['option', option.disabled ? 'disabled' : highlighted === index ? 'hovered' : null].toClass()}
              onMouseEnter={option.disabled ? null : () => this.setHighlight(index)}
              onClick={option.disabled ? null : () => {
                if (this.props.mandatory === false &&
                  option.value === this.props.value)
                  this.submitVal(undefined)
                else {
                  // console.log('Select option clicked:', option)
                  this.submitVal(option.value)
                }
                this.close()
              }}
            >
              {option.label}
            </li>)}
        </ul>}
    </Tooltip >
  }
}

InputSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  })),
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  onHintChange: PropTypes.func,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool,
  options: PropTypes.object,
}
InputSelect.defaultProps = {
  onEnter: () => { },
  onLeave: () => { },
  disabled: false,
  mandatory: false,
  data: [],
  options: { hooks: {} },
}

export default onClickOutside(InputSelect)