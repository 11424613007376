import {
  validateEmail,
  validatePhone,
} from 'scripts/helpers'

const formFields = [
  {
    name: "first-name",
    type: "text",
    options: {
      placeholder: "Jméno",
      mandatory: true,
    }
  },
  {
    name: "last-name",
    type: "text",
    options: {
      placeholder: "Přímení",
      mandatory: true,
    }
  },
  {
    name: "email",
    type: "text",
    options: {
      placeholder: "Email",
      mandatory: (email, getFormData) => validateEmail(email) || validatePhone(getFormData('phone'))
    }
  },
  {
    name: "phone",
    type: "text",
    options: {
      placeholder: "Telefon",
      mandatory: (phone, getFormData) => validatePhone(phone) || validateEmail(getFormData('email'))
    }
  },
]

export default {
  layout: formFields
}