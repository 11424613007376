import {
  number,
  oneOfType,
  string,
  shape,
} from 'prop-types'
import renderable from './renderable';

export default shape({
  name: string,
  selector: oneOfType([string, number]),
  cell: renderable
})

