import React from 'react';
import Countdown from 'react-countdown'
import styled from 'styled-components'
import mainImg from 'assets/Energie.svg'
import waveClip from 'assets/wave.svg'
import './style.scss';
import { currencyCZ } from 'scripts/helpers';

function intro(props) {
  return <div className={'Component-intro ' + props.className}>
    <header className='header-wrapper'>
      <h1 className='header'>
        Exkluzivní nabídka
      </h1>
    </header>
    <div className='spacing-container'>
      <div className='countdown-section-wrapper'>
        <div className='countdown-container'>
          <div className='countdown-text-wrapper'>
            <div className='countdown-text'>
              končí za
        </div>
          </div>
          <div className='countdown-wrapper'>
            <Countdown date={new Date(props.specialOffer.data.offerEnds)} />
          </div>
        </div>
      </div>
      <div className='img-text-container'>
        <div className='img-wrapper'>
          <img src={mainImg} alt='main' />
        </div>
        <div className='img-text-wrapper'>
          <div className='img-text'>
            Převeďte své energie ke spolehlivému dodavateli
          </div>
        </div>
      </div>
      <div className='salespitch-section-wrapper'>
        <div className='salespitch-wrapper'>
          <div className='salespitch-toptext'>
            Kromě úspory získáte navíc
          </div>
          <div className='salespitch-bottext'>
            <span className='sum'>{currencyCZ(props.specialOffer.data.reward)}</span> na účet
          </div>
        </div>
      </div>
    </div>
  </div>;
}

export default styled(intro)`
::after {
  mask: url('${() => waveClip}');
}
`
