import setriChytreAPI from 'scripts/SCH_API'

export default function (verbose = false) {
  return new Promise((passed_resolve, passed_reject) => {

    function resolve(...data: any[]) {
      verbose && console.log(...data)
      passed_resolve(...data)
      // passed_reject(...data)
    }

    function reject(...data: any[]) {
      verbose && console.warn(...data)
      passed_reject(...data)
    }

    setriChytreAPI('/customers/self', undefined, { verbose })
      .then(({ data }) => resolve(data)) //-- already a customer
      .catch((...err) => {
        if (err[0].err === 'notCustomer') //-- establish new customer
          setriChytreAPI('/customers/self', null, { verbose })
            .then(response => {
              if (response.err)
                reject(response)
              resolve(response.data)
            })
            .catch(reject)

        else //-- real error
          reject(...err)
      })
  })
}