import React from 'react'
import {
  currencyCZ,
  isNumber
} from 'scripts/helpers'

export function DPHspan({ incVat, children }:
  {
    incVat: boolean,
    children?: React.ReactNode
  }) {
  return <span style={{ fontSize: '80%', opacity: '.5' }}>
    ({incVat ? 'vč.' : 'bez'} DPH{children})
  </span>
}

function CostRow({ children, before, after, vat }: {
  children: number,
  before?: JSX.Element,
  after?: JSX.Element,
  vat: number | null
}) {
  return <>
    {before}
    {currencyCZ(children * (isNumber(vat) ? 1 + (vat as number) : 1))}
    &nbsp;<DPHspan incVat={isNumber(vat)} />
    {after}
  </>
}

CostRow.defaultProps = {
  before: null,
  after: null,
}

export default CostRow
