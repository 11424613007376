import React from 'react';

import './style.scss'
import PlaceholderPage from 'pages/PlaceholderPage'

function ActionOffers(props) {
  const {
    className,
  } = props

  return <div className={['ActionOffers-Component', className].toClass()}>
    <PlaceholderPage />
  </div>
}

export default ActionOffers