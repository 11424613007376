import React from 'react';

import {
  FaTv as TvIcon,
} from 'react-icons/fa'
import {
  daySuffixCZ,
  monthSuffixCZ,
} from 'scripts/helpers'
import InfoElement from 'forms/InfoElement'
import MidBold from 'forms/cells/MidBold'
import CostCell from 'forms/cells/CostCell'
import SCH_getVATs from 'scripts/SCH_getVATs'
import CostRow from 'forms/inforows/CostRow'
import cloneDeep from 'lodash/cloneDeep'
import { appendBoonCell } from 'forms/cells/BoonsCell'
import { getSCFormPageInfo } from 'forms'
import Indexable from 'types/Indexable'

const
  tvInfo = getSCFormPageInfo('comms', 'tv'),
  tvState = {
    from: {
      pathname: getSCFormPageInfo('comms', 'internet').path.form,
    },
    formData: {}
  },
  form = {
    label: tvInfo.label,
    value: tvInfo.slug,
    navItem: {
      label: tvInfo.label,
      path: tvInfo.path.form,
      icon: <TvIcon className='icon' />,
    },
    title: 'Kalkulačka ceny televize',
    tagline: 'Kalkulátory cen zatím připravujeme. Pro indiviuální nabídky k ušetření nás neváhejte kontaktovat.',
    submit: 'Porovnat',
    options: {
      showHelpButtom: true,
      // disabled: true,
      state: tvState,
      hooks: {

        onPageLoad: ({ props }: { props: Indexable }) => {
          props.history.push({
            pathname: tvInfo.path.offers,
            state: tvState,
          })
        },

        onOffer: async (_product: any) => {

          const
            product = cloneDeep(_product),
            vat = (await Promise.resolve(SCH_getVATs())).tv

          product.fields = [
            {
              name: 'companyName',
              label: 'Název poskytovatele',
              selector: product.provider.name.toLowerCase(),
              cell: null
            },
            {
              name: 'numChannels',
              label: 'Celkový počet kanálů',
              selector: product.numChannels,
              cell: ({ compact }: { compact: boolean }) =>
                <MidBold {...{ compact }}>
                  Celkový počet kanálů
                    {product.numChannels}
                </MidBold>
            },
            {
              name: 'numHDChannels',
              label: 'Počet HD kanálů',
              selector: product.numHDChannels,
              cell: ({ compact }: { compact: boolean }) =>
                <MidBold {...{ compact }}>
                  Počet HD kanálů
                    {product.numHDChannels}
                </MidBold>
            },
            {
              name: 'watchableHistory',
              label: 'Zpětné shlédnutí',
              selector: product.watchableHistory,
              cell: ({ compact }: { compact: boolean }) =>
                <MidBold {...{ compact }}>
                  Zpětné shlédnutí
                    {daySuffixCZ(product.watchableHistory)}
                </MidBold>
            },
            {
              name: 'contractDuration',
              label: 'Délka smlouvy',
              selector: product.contractDuration,
              cell: ({ compact }: { compact: boolean }) =>
                <MidBold {...{ compact }}>
                  Délka smlouvy
                  {product.contractDuration ?
                    monthSuffixCZ(product.contractDuration) :
                    'Bez závazku'}
                </MidBold>
            },
            {
              name: 'cost',
              label: 'Cena',
              selector: product.costs.totalCostPerMonth,
              cell: (passProps: Object) =>
                <CostCell {...passProps} cost={product.costs.totalCostPerMonth} vat={vat}>
                  Cena za měsíc
                </CostCell>
            }
          ]

          appendBoonCell(product, product.fields)

          product.info = <InfoElement info={
            [
              [
                {
                  label: 'Celková měsíční platba',
                  value: <CostRow vat={vat}>
                    {product.costs.totalCostPerMonth}
                  </CostRow>
                },
              ],
              [
                {
                  label: 'Počet kanálů / HD kanály',
                  value: `${product.numChannels} / ${product.numHDChannels}`,
                },
                {
                  label: 'Zpětné shlédnutí',
                  value: daySuffixCZ(product.watchableHistory),
                },
              ],
              [
                {
                  label: 'Závazek',
                  value: product.contractDuration ?
                    monthSuffixCZ(product.contractDuration) :
                    'Bez závazku',
                }
              ]
            ]
          } />

          return product
        },
      }
    },
    fields: []
  }

export default form