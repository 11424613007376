import React from 'react'
import test from './DisplayFitting_test'
import { syntheticRender } from 'scripts/helpers'
import useTitle from 'hooks/useTitle'

function TestingPage() {
  const Title = useTitle()

  return <>
    <Title>Test</Title>
    <div style={{
      flex: '1', alignSelf: 'stretch', display: 'flex', alignItems: 'center', justifyContent: 'center',
      color: 'white'
    }}>
      {syntheticRender(test)}
    </div>
  </>
}

export default TestingPage