import {
  arrayOf,
  number,
  // oneOf,
  shape,
  string,
} from "prop-types"

import provider from './provider'
import field from './offer_field'
import info from './offer_info'
import { rawBoon_proptype } from 'components/BoonLabel'

export default shape({
  contractDuration: number,
  cost: number,
  fields: arrayOf(field).isRequired,
  info: info.isRequired,
  // offerType: oneOf(['private', 'public', 'brief']).isRequired,
  id: number.isRequired,
  name: string.isRequired,
  provider: provider.isRequired,
  warnings: arrayOf(string),
  boons: arrayOf(shape(rawBoon_proptype)).isRequired
});
