import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';
import { FaInfoCircle as Icon } from 'react-icons/fa';

function AllProgramsLink(props) {
  return <div className='Component-AllProgramsLink'>
    <div className='programs-link' {...props}>
      <Icon className='icon' />
      <div className='text'>zobrazit programy</div>
    </div>
  </div>;
}

AllProgramsLink.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default AllProgramsLink