import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import OffersTable from './OffersTable'
import InfoModal from 'components/Offer/InfoModal'
// import ModalBox from 'components/ModalBox'
import {
  isFunction,
  isObject,
  // syntheticRender,
  setSessionItem,
  getSessionItem,
  isEmpty,
  hash,
  // makeCallable,
  cancellablePromise,
  // clone,
  // serialiseError,
  // pathToStr,
} from 'scripts/helpers'
// import cloneDeep from 'lodash/cloneDeep'
import OffersHeader from './OffersHeader'
import withResponsivity from 'HOCs/withResponsivity'
import withBasket from 'HOCs/withBasket'
// import ReactResizeDetector from 'react-resize-detector'
import defaultSorter from './OffersSorter/defaultSorter'
import OffersSorter from './OffersSorter'
import OffersFilter from './OffersFilter'
// import Debounce from 'awesome-debounce-promise''
// import getSize from 'get-size'
import uuid from 'uuid'
import './style.scss'
import memoizeOne from 'memoize-one'
import withError from 'HOCs/withError'
// import Scroller from 'components/Scroller'
// import ResizeObserver from 'components/ResizeObserver'
import { getSCFormTypeInfo, getSCFormPageInfo, getSCFormPage } from 'forms'
import fetchOffers from './fetchOffers'
// import JumpingEllipsis from 'components/JumpingEllipsis'
// import LoadingIcon from 'components/LoadingIcon'
import SpringNumber from 'components/SpringNumber'
import withTitle from 'HOCs/withTitle'
const key_seed = uuid()

export const offersBasePath = '/nabidky'
export const $$offer_product = Symbol('Offer source product')
export const $$offer_origin = Symbol('Offer origin')

const verbose = false

class Offers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isInfoModalOpen: false,
      infoModalData: {},
      forceMobile: !!getSessionItem('Force offers compact'),
      // headerHeight: null,
      // tableHeight: null,
      topGap: 0,
      self: null,
      sorter: defaultSorter,
      filter: null,
      offersTable_key: hash(uuid()),
    }

    // console.log(props)

    const
      {
        formTypeSlug,
        formPageSlug,
      } = props.match.params

    this.formTypeInfo = getSCFormTypeInfo(formTypeSlug)
    this.formPageInfo = getSCFormPageInfo(formTypeSlug, formPageSlug)
    this.state.offers = null
    this.formData = (props.location.state && props.location.state.formData) ? props.location.state.formData : undefined
    this.form = getSCFormPage(formTypeSlug, formPageSlug)

    if (!isObject(this.form)) {
      props.throwError(new Error('Invalid form: ' + this.form))
      return
    }
  }

  returnToForm = () => {
    this.props.history.push((
      this.props.location.state &&
      this.props.location.state.from
    ) ?
      this.props.location.state.from :
      this.formPageInfo.path.form
    )
  }

  componentDidMount() {
    // console.log('offers mounted', this)
    this.unblockHistory = this.props.history.block(
      (_, action) => {
        if (action === 'POP') {
          this.returnToForm()
          return false
        }
        return true
      }
    )

    //-- Page was loaded, fetch offers
    const [fetchOffersPromise, cancelFetchOffers] = cancellablePromise(fetchOffers({
      formType: this.formTypeInfo.name,
      formPage: this.formPageInfo.name,
      formData: this.formData,
    },
      { verbose: false }
    ))

    this.cancelFetchOffers = cancelFetchOffers

    fetchOffersPromise.then(
      async ({ offers, searchId }) => {
        //-- publish offers
        // console.log({ form: this.formPageInfo.name, searchId })
        this.setState({
          offers,
          searchId,
          isLoading: false,
        }, this.storeOffers)
      })
      .catch(this.props.throwError)
      .finally(() => { delete this.cancelFetchOffers })
  }
  componentWillUnmount() {
    this.unblockHistory()

    if (isFunction(this.cancelFetchOffers))
      this.cancelFetchOffers()
  }

  setForceMoile = (val) => {
    if (val !== this.state.forceMobile) {
      this.setState({ forceMobile: val }, setSessionItem('Force offers compact', val))
    }
  }

  wrapper_ref = React.createRef()
  heights = {}
  setHeight = (newHeight, heightName) => {
    const currentHeight = this.heights[heightName]

    if (newHeight !== currentHeight) {
      this.heights[heightName] = newHeight

      const height = Object.values(this.heights).reduce((total, value) => total + value, 0)

      if (this.wrapper_ref.current) {
        verbose && console.log('OffersWrapper', { height })
        this.wrapper_ref.current.style.height = `${height}px`
      }
    }
  }

  // //-- Catch height updates to avoid rerender
  // shouldComponentUpdate = (newProps, newState) => {
  //   if (new)
  //   console.log({ newState, newProps })
  //   return true
  // }

  setStateAndUpdateTable = (newState) => {
    // console.log('setStateAndUpdateTable')
    this.setState({
      ...newState,
      offersTable_key: hash(key_seed, newState),
    }
      // , () => console.log('set state and update table', { newState })
    )
  }
  setSorter = (sorter) => this.setStateAndUpdateTable({ sorter: isFunction(sorter) ? memoizeOne(sorter) : sorter })
  setFilter = (filter) => this.setStateAndUpdateTable({ filter: isFunction(filter) ? memoizeOne(filter) : filter })

  offersSorter_funcs = {
    setSorter: (sorter, sorterId) => {
      if (this.currentSorterId !== sorterId) {
        // console.log({ sorterId, currentSorterId: this.currentSorterId })
        this.setSorter(sorter)
        this.currentSorterId = sorterId
        // this.forceUpdate()
      }
    },
    unsetSorter: () => this.setSorter(defaultSorter),
  }
  offersFilter_funcs = {
    setFilter: (filter) => this.setFilter(filter),
    unsetFilter: () => this.setFilter(null),
  }
  offersTable_funcs = {
    openModal: (productId) => {
      const offer = this.state.offers.find(({ id }) => id === productId)
      // console.log('opening modal for', { modalOffer: offer })

      if (!offer) {
        console.error({ productId }, { offers: this.state.offers })
        this.props.throwError(new Error("Couldn't find offer to open modal"))
        return
      }

      this.setState({
        isInfoModalOpen: true,
        infoModalData: offer
      })
    },
    continue: () => this.props.history.push('/objednavka'),
  }
  onResize_funcs = {
    onResize: (_, height) => this.setTableHeight(height)
  }
  closeSpecialOffer = () => {
    // console.log('closeSpecialOffer')
    this.setState({ showSpecialOffer: false })
  }

  render() {
    verbose && console.log('Offers', { state: this.state, props: this.props })

    let offers = this.state.offers

    if (!isEmpty(offers)) {
      // console.log({ offers: JSON.parse(JSON.stringify(offers)) })

      //-- filter offers
      if (isFunction(this.state.filter)) {
        offers = this.state.filter(offers)
        // console.log({ filtered: JSON.parse(JSON.stringify(offers)) })
      }
      //-- sort offers
      if (isFunction(this.state.sorter)) {
        offers = this.state.sorter(offers)
        // console.log({ sorted: JSON.parse(JSON.stringify(offers)) })
      }
    }
    // console.log({ final: JSON.parse(JSON.stringify(offers)) })

    const modalPoint = 'menu'
    return <>
      <this.props.Title>Nabídky</this.props.Title>

      <div className='Offers-Component'>

        <OffersHeader
          setForceMoile={this.setForceMoile}
          goBack={this.returnToForm}
          forceMobile={this.state.forceMobile}
          isLoading={this.state.isLoading}
          showTableButtons={!isEmpty(offers)}
          modalPoint={modalPoint}
        >{{
          toptext: <>
            <div className='toptext-heading'>{
              (isEmpty(offers) && !this.state.isLoading) ?
                'Bohužel pro vás nemáme žádné veřejné nabídky' :
                <div className='toptext-title'>
                  Máme pro Vás {
                    <SpringNumber className={['toptext-title-number', (offers && offers.length) ? null : 'loading'].toClass()}>
                      {offers ? offers.length : 0}
                    </SpringNumber>
                  } {
                    `nabíd${
                    offers && offers.length < 5 ?
                      `k${offers.length > 1 ? 'y' : 'u'}` :
                      'ek'
                    }`
                  } od stabilních a férových dodavatelů
                  </div>
            }</div>
            <div className='toptext-text'>
              {(isEmpty(offers) && !this.state.isLoading) ?
                'Pro neveřejné a individuální nabídky klikněte na obrázek asistenta' :
                'Pro pomoc s výběrem nebo pro neveřejné a individuální nabídky klikněte na obrázek asistenta'
              }
            </div>
          </>,
          filter: <OffersFilter key={hash(key_seed, 'filter')}
            {...this.offersFilter_funcs}
            modalPoint={modalPoint}
          >{this.state.offers}</OffersFilter>,
          sorter: <OffersSorter key={hash(key_seed, 'sorter')}
            {...this.offersSorter_funcs}
            modalPoint={modalPoint}
          >
            {offers}
          </OffersSorter>,
        }}</OffersHeader>

        <OffersTable
          isLoading={this.state.isLoading}
          forceMobile={this.state.forceMobile}
          {...this.offersTable_funcs}>
          {offers}
        </OffersTable>

        <InfoModal
          open={this.state.isInfoModalOpen}
          onClose={() => { this.setState({ isInfoModalOpen: false }) }}
          info={this.state.infoModalData.info}
        />
      </div>
    </>
  }
}

export default withRouter(withResponsivity(withBasket(withError(withTitle(Offers)))))