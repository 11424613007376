import UtilityProduct, { BriefProduct } from '../Product.d.ts'
import { BriefProduct as BriefProductBase } from 'types/Product'

export interface BriefProduct extends BriefProductBase {
  costs: {
    highTariffCostPerMWh: number,
    fixedCostPerMonth: number,
  }
}

export interface FullProduct extends UtilityProduct {
  distributionTariff: string,
  costs: {
    totalCostPerMonth: number,
    fixedCostPerMonth: number,
    usageCostPerMonth: number,
    ecologyCostPerMonth: number,
  },
  costCalculation: {
    fixedCostPerMonth: {
      baseProduct: number,
      distribution: number,
      operatorServicesFee: number,
    },
    usageCostPerKwh: {
      productHighTariff: number,
      distributorHighTariff: number,
      systemServicesFee: number,
      tax: number,
    },
    ecologyCostPerYear:
    {
      perMWh: number,
    } |
    {
      feePerAmperePerPhasePerMonth: number,
      phaseCount: number,
      currentPerPhase: number,
    }
  }
}

type Product = FullProduct | BriefProduct

export default Product