import React from 'react';
import PropTypes from 'prop-types'
import toPx from 'to-px'
import { animated } from 'react-spring'

const
  bounceDist = toPx('.3em'),
  bounce = {
    left: val => Math.abs(1 - val) - 1,
    right: val => 1 - Math.abs(1 - val),
  }

function Bounce(props) {
  const {
    animationValue,
    direction,
    ...passProps
  } = props

  return <animated.div
    style={{
      display: 'flex',
      alignItems: 'center',
      transform: animationValue.interpolate(val =>
        `translateX(${bounce[direction](val) * bounceDist}px)`
      )
    }}
    {...passProps} />
}

Bounce.propTypes = {
  animationValue: PropTypes.object.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
}

export default Bounce