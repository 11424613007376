import React from 'react'

import Indexable from "types/Indexable"
// import cloneDeep from 'lodash/cloneDeep'
import MidBold from 'forms/cells/MidBold'
import {
  monthSuffixCZ,
  currencyCZ,
  isNumber
} from 'scripts/helpers'
import { getBoon } from 'components/Offer'
import CostRow, {
  DPHspan
} from 'forms/inforows/CostRow'
import { SetFormData } from 'types/FormAPI'
import styled from 'styled-components'
import {
  FaBuilding,
  FaHome,
  FaUser,
  FaUserFriends,
  FaUsers,
  FaTint,
  FaFireAlt,
  FaTemperatureLow,
} from 'react-icons/fa'
import CostCell from 'forms/cells/CostCell'
import ElectricityFormData from './electricity/FormData'
import ElectricityProduct, { FullProduct } from './electricity/Product'
import GasFormData from './gas/FormData'
import GasProduct from './gas/Product'
import { Info } from 'forms/InfoElement'

export const
  SwitchStylewrap = styled.div`
    @media(min-width: 501px) {
      display: flex;
      flex-direction: column;
    };

    @media(max-width: 500px) {
      display: grid;
      grid-template-columns: 1fr 3fr;
      column-gap: 10px;
      padding-inline-start: .75em;
      padding-inline-end: .75em;
      
      .icon-wrapper,
      .text-wrapper {
        align-content: center;
        display: flex;
        width: 100%;
      };
      
      .icon-wrapper {
        justify-content: left!important;
        margin-inline-start: .5em;
      };
        
      .text-wrapper {
        justify-content: center;
      };
    };
  
  .icon {
    font-size: 2em;
  }; `

export const CheckStylewrap = styled.div`
    align-self: stretch;
    background: ${ (props: any) => props.value ? '#ba2653' : 'transparent'};
    /* border-radius: 4px; */
    color: ${ (props: any) => props.value ? 'white' : 'unset'};
    display: flex;
    flex: 1;
    padding: 5px 20px;
    ${ (props: any) => props.value && 'font-weight: bolder;'}

    &:hover {
      font-weight: bolder;
    }

    .text-wrapper,
    .icon-wrapper {
      align-content: center;
      justify-content: center;
      display: flex;
    }

    .text-wrapper {
      flex: 1;
    }
      
    .icon-wrapper {
      .icon {
        box-sizing: border-box;
        font-size: 2em;

        &.padleft {
          padding-inline-start: .1em;
        }
      }
    }
`

export const commonTop = [{
  name: "type",
  type: "switch",
  options: {
    hooks: {
      onChange: ({ setFormData }: { setFormData: SetFormData }, value: string) => {
        if (value === 'business')
          setFormData('usage_known', 'known')
      }
    }
  },
  data: [
    {
      label: "Domácnost",
      value: "household"
    },
    {
      label: "Podnikatel",
      value: "business"
    }
  ]
},
{
  name: "usage_known",
  type: "switch",
  options: {
    conditional: [
      [
        {
          key: "type",
          value: "household"
        }
      ]
    ],
    mandatory: true
  },
  data: [
    {
      label: "Znám spotřebu",
      value: "known"
    },
    {
      label: "Neznám spotřebu",
      value: "unknown"
    },
    {
      label: "Rychlé srovnání",
      value: "quick_compare"
    },
  ]
},
{
  name: "usage_estimate_accomedation",
  type: "switch",
  options: {
    conditional: [
      [
        {
          key: "usage_known",
          value: "unknown"
        },
        {
          key: "type",
          value: "household"
        },
        {
          key: "usage_known",
          value: "!quick_compare"
        }
      ]
    ],
    mandatory: true
  },
  data: [
    {
      label: (props: Indexable) =>
        <SwitchStylewrap {...props}>
          <div className='icon-wrapper'>
            <FaBuilding className='icon' />
          </div>
          <div className='text-wrapper'>
            <span className='text'>Byt</span>
          </div>
        </SwitchStylewrap>,
      value: "apartment"
    },
    {
      label: (props: Indexable) =>
        <SwitchStylewrap {...props}>
          <div className='icon-wrapper'>
            <FaHome className='icon' />
          </div>
          <div className='text-wrapper'>
            <span className='text'>Dům</span>
          </div>
        </SwitchStylewrap>,
      value: "house"
    }
  ]
},
{
  name: "usage_estimate_persons",
  label: "Bude využívat",
  type: "switch",
  options: {
    conditional: [
      [
        {
          key: "usage_known",
          value: "unknown"
        },
        {
          key: "type",
          value: "household"
        },
        {
          key: "usage_estimate_persons",
          value: "1"
        },
        {
          key: "usage_known",
          value: "!quick_compare"
        }
      ]
    ],
    mandatory: true
  },
  data: [
    {
      label: (props: Indexable) =>
        <SwitchStylewrap {...props}>
          <div className='icon-wrapper'>
            <FaUser className='icon' />
          </div>
          <div className='text-wrapper'>
            <span className='text'>1 osoba</span>
          </div>
        </SwitchStylewrap>,
      value: "1"
    },
    {
      label: (props: Indexable) =>
        <SwitchStylewrap {...props}>
          <div className='icon-wrapper'>
            <FaUserFriends className='icon' />
          </div>
          <div className='text-wrapper'>
            <span className='text'>2-3 osoby</span>
          </div>
        </SwitchStylewrap>,
      value: "2-3"
    },
    {
      label: (props: Indexable) =>
        <SwitchStylewrap {...props}>
          <div className='icon-wrapper'>
            <FaUsers className='icon' />
          </div>
          <div className='text-wrapper'>
            <span className='text'>4+ osoby</span>
          </div>
        </SwitchStylewrap>,
      value: "4+"
    }
  ]
},
{
  name: "usage_estimate_persons",
  label: "Budou využívat",
  type: "switch",
  options: {
    conditional: [
      [
        {
          key: "usage_known",
          value: "unknown"
        },
        {
          key: "type",
          value: "household"
        },
        {
          key: "usage_estimate_persons",
          value: "!1"
        },
        {
          key: "usage_known",
          value: "!quick_compare"
        }
      ],
    ],
    mandatory: true
  },
  data: [
    {
      label: (props: Indexable) =>
        <SwitchStylewrap {...props}>
          <div className='icon-wrapper'>
            <FaUser className='icon' />
          </div>
          <div className='text-wrapper'>
            <span className='text'>1 osoba</span>
          </div>
        </SwitchStylewrap>,
      value: "1"
    },
    {
      label: (props: Indexable) =>
        <SwitchStylewrap {...props}>
          <div className='icon-wrapper'>
            <FaUserFriends className='icon' />
          </div>
          <div className='text-wrapper'>
            <span className='text'>2-3 osoby</span>
          </div>
        </SwitchStylewrap>,
      value: "2-3"
    },
    {
      label: (props: Indexable) =>
        <SwitchStylewrap {...props}>
          <div className='icon-wrapper'>
            <FaUsers className='icon' />
          </div>
          <div className='text-wrapper'>
            <span className='text'>4+ osoby</span>
          </div>
        </SwitchStylewrap>,
      value: "4+"
    }
  ]
}],
  usageEstimatePurpose = {
    type: "wrapper",
    name: "",
    options: {
      conditional: [
        [
          {
            key: "usage_known",
            value: "unknown"
          },
          {
            key: "type",
            value: "household"
          },
          {
            key: "usage_known",
            value: "!quick_compare"
          }
        ],
      ],
      wrap_type: "vertical"
    },
    data: [
      {
        name: "usage_estimate_purpose::water",
        label: (props: Indexable) =>
          <CheckStylewrap {...props}>
            <div className='icon-wrapper'>
              <FaTint className='icon' />
            </div>
            <div className='text-wrapper'>
              <span className='text'>Ohřev vody</span>
            </div>
          </CheckStylewrap>,
        type: "check",
        options: {
          hideCheckbox: true,
          mandatory: true
        },
      },
      {
        name: "usage_estimate_purpose::cooking",
        label: (props: Indexable) =>
          <CheckStylewrap {...props}>
            <div className='icon-wrapper'>
              <FaFireAlt className='icon' />
            </div>
            <div className='text-wrapper'>
              <span className='text'>Vaření</span>
            </div>
          </CheckStylewrap>,
        type: "check",
        options: {
          hideCheckbox: true,
          mandatory: true
        },
      },
      {
        name: "usage_estimate_purpose::heating",
        label: (props: Indexable) =>
          <CheckStylewrap {...props}>
            <div className='icon-wrapper'>
              <FaTemperatureLow className='icon padleft' />
            </div>
            <div className='text-wrapper'>
              <span className='text'>Vytápění</span>
            </div>
          </CheckStylewrap>,
        type: "check",
        options: {
          hideCheckbox: true,
          mandatory: true
        }
      }
    ]
  }

export type Savings = {
  absolute: number,
  percentage: number,
}

export function getType(formData: ElectricityFormData | GasFormData) {
  return formData === undefined ? 'brief' : formData.type
}

export async function onOffer_rawInfo(
  product: ElectricityProduct | GasProduct,
  formData: ElectricityFormData | GasFormData,
  vat: number | null
) {

  const
    type = getType(formData),
    fields = [
      {
        name: 'companyName',
        label: 'Název poskytovatele',
        selector: product.provider.name.toLowerCase(),
        cell: null
      },
      {
        name: 'contractDuration',
        label: 'Délka smlouvy',
        selector: product.contractDuration || null,
        cell: (passProps: Indexable) => <MidBold
          {...passProps}>
          Délka smlouvy
        {product.contractDuration ?
            monthSuffixCZ(product.contractDuration) :
            'Bez závazku'}
        </MidBold>
      },
    ],
    info: Info = [
      [
        {
          label: 'Závazek',
          value: product.contractDuration ? monthSuffixCZ(product.contractDuration) : 'bez závazku'
        },
        {
          label: 'Garance ceny',
          value: getBoon(product, 'priceGuarantee') ? 'ano' : 'ne'
        }
      ]
    ],
    costs: typeof product.costs & Partial<{ savingsPerMonth: Savings }> = product.costs

  if (type !== 'brief') {
    fields.splice(1, 0, {
      name: 'yearly',
      label: 'Cena za rok',
      selector: (costs as FullProduct['costs']).totalCostPerMonth * 12,
      cell: (passProps: Indexable) => <MidBold {...passProps}>
        Cena za rok{
          currencyCZ(
            (costs as FullProduct['costs']).totalCostPerMonth * 12 * (typeof vat === 'number' ? 1 + vat : 1)
          )
        }
        <DPHspan incVat={isNumber(vat)} />
      </MidBold>
    })

    info.splice(0, 0,
      [
        {
          label: 'Měsíční poplatky',
          value: <CostRow {...{ vat }}>
            {(costs as FullProduct['costs']).fixedCostPerMonth}
          </CostRow>,
        }
      ],
      [
        {
          label: 'Celková měsíční záloha',
          value: <CostRow {...{ vat }}>
            {(costs as FullProduct['costs']).totalCostPerMonth}
          </CostRow>,
        }
      ])

    if (formData && typeof formData.currentMonthlyCost === 'number') {

      costs.savingsPerMonth = {
        absolute: formData.currentMonthlyCost - (costs as FullProduct['costs']).totalCostPerMonth * (
          typeof vat === 'number' ? 1 + vat : 1
        ),
        percentage: 100 * (
          formData.currentMonthlyCost - (costs as FullProduct['costs']).totalCostPerMonth * (
            typeof vat === 'number' ? 1 + vat : 1
          )
        ) / formData.currentMonthlyCost
      }

      const
        yr_saving = costs.savingsPerMonth.absolute * 12

      if (yr_saving >= 0) {

        info[1].push(
          {
            label: 'Ušetříte',
            value: <>
              {Math.round(costs.savingsPerMonth.percentage)}% ({currencyCZ(yr_saving)} za rok)
              &nbsp;<DPHspan incVat={isNumber(vat)} />
            </>
          }
        )

        fields.splice(1, 0, {
          name: 'saving',
          label: 'Ušetříte',
          selector: yr_saving,
          cell: (passProps: Indexable) => <MidBold {...passProps}>
            Ušetříte / rok
            <span>{Math.round((costs.savingsPerMonth as Savings).percentage)}%</span>
            <b>{currencyCZ(yr_saving)}</b>
          </MidBold>
        })
      }
    }
  }

  return {
    fields,
    info,
    costs: costs as typeof product.costs & Savings
  }
}

// export default async function onOffers_rawInfo(
//   { products, ...responseData }: { products: any[], responseData: any[] },
//   { currentMonthlyCost, ...formData }: { currentMonthlyCost?: number, formData: Indexable },
//   vat: number | null
// ) {

//   const processed_products = await Promise.all(
//     products.map(async product => await onOffer_rawInfo(product, formData, vat))
//   )

//   // console.log({ products })

//   return processed_products
// }

export function create_depositCell(cost: number, vat: number | null) {

  return {
    name: 'deposit',
    label: 'Měsíční záloha',
    selector: cost,
    cell: (passProps: Indexable) =>
      <CostCell {...{ ...passProps, cost, vat }}>
        Měsíční záloha
      </CostCell>
  }
}