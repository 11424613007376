import React, { ReactChildren } from 'react';

import PropTypes from 'prop-types';

import './style.scss'
import {
  FaFile as FileIcon,
  FaArrowUp as DropIcon,
} from 'react-icons/fa'
import { GiHandOk as HandIcon } from 'react-icons/gi'

import FileDrop from 'react-file-drop'
import FileRow from './FileRow'

import uuid from 'uuid'
import { hash, isEmpty } from 'scripts/helpers'
import AnimatedUnderline from 'components/AnimatedUnderline';
import maybe_file from 'types/maybe_file';
const key_seed = uuid()

function InputFile(props: {
  value: Array<Object>;
  onChange: Function;
  placeholder?: string;
  name: string;
  onLeave?: Function;
  onEnter?: Function;
  className?: string;
  children?: ReactChildren
}) {

  const {
    value,
    onChange,
    placeholder,
    className,
    name,
    children: label,
  } = props,
    files = (value.filter(maybeFile => maybeFile instanceof File) as Array<File>),
    inputRef = React.useRef<HTMLInputElement>(null),
    [fileBeingDragged, setFileBeingDragged] = React.useState(false),
    openFileBrowser = () => {
      if (inputRef.current)
        inputRef.current.click()
    },
    addFiles = (_newFiles: FileList) => {

      let newFiles = Array.prototype.slice.call(_newFiles)
      // console.log('converted', _newFiles, 'to', newFiles)
      // console.log(isPureObject(newFiles[0]))

      onChange(files.concat(newFiles))
    },
    removeFile = (index: number) => {
      // console.log('Removing file', files[index])
      onChange(null) // zero out the file array, otherwise it will merge
      onChange(files.filter((_, i) => i !== index))
    },
    browseLink = isEmpty(files) ? placeholder || label : label

  return <div className={(['Component-InputFile', className] as any).toClass()}>
    <FileDrop
      onDrop={(files: FileList | null) => {
        if (files instanceof FileList)
          addFiles(files)
        setFileBeingDragged(false)
      }}
      onDragOver={() => {
        // console.log('File dragged')
        setFileBeingDragged(true)
      }}
      onDragLeave={() => {
        // console.log('File undragged')
        setFileBeingDragged(false)
      }}
    >
      <div className={(['file-list-container', isEmpty(files) ? 'open-file-browser' : null] as any).toClass()}
        onClick={() => isEmpty(files) && openFileBrowser()}>
        {(files.length === 0 || fileBeingDragged) ?
          <div className='icon-wrapper'>
            {fileBeingDragged ?
              <div className='icon-drop-container'>
                {[...Array(4)].map((_, i) => <DropIcon key={`file drop animated arrow ${i}`} className='icon icon-drop' />)}
              </div> :
              <>
                <HandIcon className='icon icon-hand' />
                <FileIcon className='icon icon-file' />
              </>}
          </div>
          : <div className='file-list-wrapper'>
            {/* {console.log(files)} */}
            {files.map((file, file_index) =>
              <FileRow
                file={file}
                key={hash(key_seed, file_index)}
                remove={() => removeFile(file_index)}
              />)}
          </div>}
      </div>
      {/* <span className='browse'
        onClick={openFileBrowser}
      >Procházet</span> */}
      {browseLink ?
        <div className='browse-link' onClick={openFileBrowser}>
          <AnimatedUnderline>
            {browseLink}
          </AnimatedUnderline>
        </div>
        : null}
    </FileDrop>
    <input ref={inputRef}
      type="file"
      name={name}
      multiple={true}
      style={{ display: 'none' }}
      onChange={({ target }) => {
        if (target.files)
          addFiles(target.files)
        target.value = ''
      }}
    />
  </div>;
}

InputFile.propTypes = {
  value: PropTypes.arrayOf(maybe_file).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func
}
InputFile.defaultProps = {
  onEnter: () => { },
  onLeave: () => { },
}

export default InputFile