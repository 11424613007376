import React from 'react'

export default ({ onAccept, onDeny }) => <div style={{
  background: 'white',
  borderRadius: '6px',
  color: '#222',
  maxWidth: '500px',
}}>
  <div style={{ display: 'grid', gridTemplateAreas: '"header" "main"' }}>
    <header style={{
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      columnGap: '2.5em',
      padding: '25px 25px 0',
    }}>
      <div style={{ background: '#1c2f67', padding: '10px 15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img width='100' src='https://www.pre.cz/assets/images/logo_PRE-color.svg' alt='PRE logo' />
      </div>
      <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Speciální nabídka
      </h2>
    </header>
    <main style={{
      marginBlockStart: '20px',
      maxWidth: '100%',
      textAlign: 'left',
      padding: '25px',
      background: 'linear-gradient(#0002, transparent 2px)'
    }}>
      <span>
        Dobrý den,<br />
        získejte slevu u svého současného dodavatele (nic nemusíte měnit). Stačí vyplnit kontaktní formulář a dodavatel se Vám brzy ozve.
      </span>
      <div style={{ marginBlockStart: '50px' }}>
        <h3 className='button'
          style={{ textTransform: 'uppercase' }}
          onClick={() => onAccept('redirect', '/PRE/gas')}>
          Využít
        </h3>
      </div>
    </main>
  </div>
</div>