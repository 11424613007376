export default [{
  price: 99,
  name: 'Extra',
  channels: [
    "CS Mini",
    "Disney Junior",
    "Nick Jr CZ",
    "Filmbox Family",
    "AXN Black",
    "AXN White",
    "FilmBox Premium HD",
    "CS Film",
    "Horor Film",
    "Discovery Science",
    "DTX HD",
    "Crime &amp; Investigation",
    "Travel Channel HD",
    "History Channel HD",
    "MTV Rocks",
    "MTV Hits",
    "Fine Living Network HD",
    "Food Network HD",
    "Auto Motor Sport HD",
    "Eroxxx HD"
  ]
},
{
  price: 199,
  name: 'HBO',
  channels: [
    "HBO HD",
    "HBO 2 HD",
    "HBO 3 HD"
  ]
},
{
  price: 89,
  name: 'Cinemax',
  channels: [
    "Cinemax",
    "Cinemax 2"
  ]
},
{
  price: 149,
  name: 'Sport',
  channels: [
    "Premier Sport HD",
    "Digi Sport 1 HD",
    "Digi Sport 2 HD",
    "Digi Sport 3 HD",
    "Digi Sport 6 HD",
    "Digi Sport 7 HD",
    "Digi Sport 8 HD",
    "Digi Sport 9 HD",
    "Arena Sport 1 HD",
    "Golf Channel",
    "Nova Sport 2 HD"
  ]
},]