import React from 'react';

import { FaUserShield } from 'react-icons/fa'

export default {
  path: '/form/pojisteni',
  name: 'insurance',
  label: 'Pojištění',
  class: '',
  icon: <FaUserShield className='icon' />,
  hooks: {
    offerHooks: {},
    formHooks: {}
  },
  layout: [
    {
      label: "Cestovní",
      value: "travel",
      title: "Kalkulačka cestovního pojištění",
      tagline: "Kalkulátory cen zatím připravujeme. Pro indiviuální nabídky k ušetření nás neváhejte kontaktovat.",
      submit: "Spočítat pojištění",
      options: {
        disabled: true,
        showHelpButtom: true,
      },
      fields: [
        {
          type: "select",
          name: "destination",
          options: {
            mandatory: true,
            placeholder: "Destinace"
          },
          data: [
            {
              label: "Afghánistán",
              value: "af"
            },
            {
              label: "Alžírsko",
              value: "dz"
            },
            {
              label: "Americká Samoa",
              value: "as"
            },
            {
              label: "Angola",
              value: "ao"
            },
            {
              label: "Anguilla",
              value: "ai"
            },
            {
              label: "Antarktida",
              value: "aq"
            },
            {
              label: "Antigua a Barbuda",
              value: "ag"
            },
            {
              label: "Argentina",
              value: "ar"
            },
            {
              label: "Aruba",
              value: "aw"
            },
            {
              label: "Austrálie",
              value: "au"
            },
            {
              label: "Bahamy",
              value: "bs"
            },
            {
              label: "Bahrajn",
              value: "bh"
            },
            {
              label: "Bangladéš",
              value: "bd"
            },
            {
              label: "Barbados",
              value: "bb"
            },
            {
              label: "Belize",
              value: "bz"
            },
            {
              label: "Benine",
              value: "bj"
            },
            {
              label: "Bermudy",
              value: "bm"
            },
            {
              label: "Bhútán",
              value: "bt"
            },
            {
              label: "Bolívie",
              value: "bo"
            },
            {
              label: "Botswana",
              value: "bw"
            },
            {
              label: "Bouvetův ostrov",
              value: "bv"
            },
            {
              label: "Brazílie",
              value: "br"
            },
            {
              label: "Britské území v Indickém oceánu",
              value: "io"
            },
            {
              label: "Brunej Darussalam",
              value: "bn"
            },
            {
              label: "Burkina Faso",
              value: "bf"
            },
            {
              label: "Burundi",
              value: "bi"
            },
            {
              label: "Chile",
              value: "cl"
            },
            {
              label: "Cookovy ostrovy",
              value: "ck"
            },
            {
              label: "Dominika",
              value: "dm"
            },
            {
              label: "Dominikánská republika",
              value: "do"
            },
            {
              label: "Džibutsko",
              value: "dj"
            },
            {
              label: "Ekvádor",
              value: "ec"
            },
            {
              label: "El Salvador",
              value: "sv"
            },
            {
              label: "Eritrea",
              value: "er"
            },
            {
              label: "Etiopie",
              value: "et"
            },
            {
              label: "Falklandské ostrovy (Malvinas)",
              value: "fk"
            },
            {
              label: "Fidži",
              value: "fj"
            },
            {
              label: "Filipíny, Republika",
              value: "ph"
            },
            {
              label: "Francouzská Guyana",
              value: "gf"
            },
            {
              label: "Francouzská Polynésie",
              value: "pf"
            },
            {
              label: "Francouzská jižní území",
              value: "tf"
            },
            {
              label: "Gabon",
              value: "ga"
            },
            {
              label: "Gambie",
              value: "gm"
            },
            {
              label: "Ghana",
              value: "gh"
            },
            {
              label: "Grenada",
              value: "gd"
            },
            {
              label: "Grónsko",
              value: "gl"
            },
            {
              label: "Guadeloupe",
              value: "gp"
            },
            {
              label: "Guam",
              value: "gu"
            },
            {
              label: "Guatemala",
              value: "gt"
            },
            {
              label: "Guernsey",
              value: "gg"
            },
            {
              label: "Guinea",
              value: "gn"
            },
            {
              label: "Guinea-Bissau",
              value: "gw"
            },
            {
              label: "Guyana",
              value: "gy"
            },
            {
              label: "Haiti",
              value: "ht"
            },
            {
              label: "Honduras",
              value: "hn"
            },
            {
              label: "Hongkong",
              value: "hk"
            },
            {
              label: "Indie",
              value: "in"
            },
            {
              label: "Indonésie",
              value: "id"
            },
            {
              label: "Irák",
              value: "iq"
            },
            {
              label: "Izrael",
              value: "il"
            },
            {
              label: "Jamaica",
              value: "jm"
            },
            {
              label: "Japonsko",
              value: "jp"
            },
            {
              label: "Jemen",
              value: "ye"
            },
            {
              label: "Jižní Afrika",
              value: "za"
            },
            {
              label: "Jižní Georgie a Jižní Sandwichovy ostrovy",
              value: "gs"
            },
            {
              label: "Jordán",
              value: "jo"
            },
            {
              label: "Jít",
              value: "tg"
            },
            {
              label: "Kajmanské ostrovy",
              value: "ky"
            },
            {
              label: "Kambodža",
              value: "kh"
            },
            {
              label: "Kamerun",
              value: "cm"
            },
            {
              label: "Kanada",
              value: "ca"
            },
            {
              label: "Kapverdy",
              value: "cv"
            },
            {
              label: "Katar",
              value: "qa"
            },
            {
              label: "Kazachstán",
              value: "kz"
            },
            {
              label: "Keňa",
              value: "ke"
            },
            {
              label: "Kiribati",
              value: "ki"
            },
            {
              label: "Kokosové (Keelingovy) ostrovy",
              value: "cc"
            },
            {
              label: "Kolumbie",
              value: "co"
            },
            {
              label: "Komory",
              value: "km"
            },
            {
              label: "Kongo (Demokratická republika)",
              value: "cd"
            },
            {
              label: "Kongo (Republika)",
              value: "cg"
            },
            {
              label: "Korejská republika",
              value: "kr"
            },
            {
              label: "Kostarika",
              value: "cr"
            },
            {
              label: "Kuba",
              value: "cu"
            },
            {
              label: "Kuvajt",
              value: "kw"
            },
            {
              label: "Kyrgyzstán",
              value: "kg"
            },
            {
              label: "Laoská lidově demokratická republika",
              value: "la"
            },
            {
              label: "Lesotho",
              value: "ls"
            },
            {
              label: "Libanon",
              value: "lb"
            },
            {
              label: "Libyjská arabská Jamahiriya",
              value: "ly"
            },
            {
              label: "Libérie",
              value: "lr"
            },
            {
              label: "Lichtenštejnsko",
              value: "li"
            },
            {
              label: "Macau",
              value: "mo"
            },
            {
              label: "Madagaskar",
              value: "mg"
            },
            {
              label: "Malajsie",
              value: "my"
            },
            {
              label: "Malawi",
              value: "mw"
            },
            {
              label: "Maledivy",
              value: "mv"
            },
            {
              label: "Mali",
              value: "ml"
            },
            {
              label: "Marshallovy ostrovy",
              value: "mh"
            },
            {
              label: "Martinik",
              value: "mq"
            },
            {
              label: "Mauricius",
              value: "mu"
            },
            {
              label: "Mauritánie",
              value: "mr"
            },
            {
              label: "Mayotte",
              value: "yt"
            },
            {
              label: "Menší odlehlé ostrovy Spojených států",
              value: "um"
            },
            {
              label: "Mexiko",
              value: "mx"
            },
            {
              label: "Mikronésie, Federativní státy",
              value: "fm"
            },
            {
              label: "Moldavská republika",
              value: "md"
            },
            {
              label: "Mongolsko",
              value: "mn"
            },
            {
              label: "Montserrat",
              value: "ms"
            },
            {
              label: "Mozambik",
              value: "mz"
            },
            {
              label: "Myanmar",
              value: "mm"
            },
            {
              label: "Namibie",
              value: "na"
            },
            {
              label: "Nauru",
              value: "nr"
            },
            {
              label: "Nepál",
              value: "np"
            },
            {
              label: "Niger",
              value: "ne"
            },
            {
              label: "Nigérie",
              value: "ng"
            },
            {
              label: "Nikaragua",
              value: "ni"
            },
            {
              label: "Niue",
              value: "nu"
            },
            {
              label: "Nizozemské Antily",
              value: "an"
            },
            {
              label: "Norfolk Island",
              value: "nf"
            },
            {
              label: "Nová Kaledonie",
              value: "nc"
            },
            {
              label: "Nový Zéland",
              value: "nz"
            },
            {
              label: "Omán",
              value: "om"
            },
            {
              label: "Ostrov Man",
              value: "im"
            },
            {
              label: "Ostrov Nanebevstoupení Páně",
              value: "ac"
            },
            {
              label: "Ostrovy Svalbard a Jan Mayen",
              value: "sj"
            },
            {
              label: "Ostrovy Turks a Cacois",
              value: "tc"
            },
            {
              label: "Palau",
              value: "pw"
            },
            {
              label: "Palestina",
              value: "ps"
            },
            {
              label: "Panama",
              value: "pa"
            },
            {
              label: "Panenské ostrovy (USA)",
              value: "vi"
            },
            {
              label: "Panenské ostrovy (britské)",
              value: "vg"
            },
            {
              label: "Papua-Nová Guinea",
              value: "pg"
            },
            {
              label: "Paraguay",
              value: "py"
            },
            {
              label: "Peru",
              value: "pe"
            },
            {
              label: "Pitcairn",
              value: "pn"
            },
            {
              label: "Pobřeží slonoviny",
              value: "ci"
            },
            {
              label: "Polsko",
              value: "pl"
            },
            {
              label: "Portoriko",
              value: "pr"
            },
            {
              label: "Pákistán",
              value: "pk"
            },
            {
              label: "Rovníková Guinea",
              value: "gq"
            },
            {
              label: "Rwandě",
              value: "rw"
            },
            {
              label: "Samoa",
              value: "ws"
            },
            {
              label: "Saudská arábie",
              value: "sa"
            },
            {
              label: "Senegal",
              value: "sn"
            },
            {
              label: "Severní Mariany",
              value: "mp"
            },
            {
              label: "Seychely",
              value: "sc"
            },
            {
              label: "Shledání",
              value: "re"
            },
            {
              label: "Sierra Leone",
              value: "sl"
            },
            {
              label: "Singapur",
              value: "sg"
            },
            {
              label: "Skotsko",
              value: "uk"
            },
            {
              label: "Slyšel a Mc Donaldovy ostrovy",
              value: "hm"
            },
            {
              label: "Solomonovy ostrovy",
              value: "sb"
            },
            {
              label: "Somálsko",
              value: "so"
            },
            {
              label: "Sovětský svaz",
              value: "su"
            },
            {
              label: "Spojené arabské emiráty",
              value: "ae"
            },
            {
              label: "Spojené království",
              value: "uk"
            },
            {
              label: "Spojené státy",
              value: "us"
            },
            {
              label: "Srbsko",
              value: "rs"
            },
            {
              label: "Srí Lanka",
              value: "lk"
            },
            {
              label: "St. Helena",
              value: "sh"
            },
            {
              label: "St. Pierre a Miquelon",
              value: "pm"
            },
            {
              label: "Středoafrická republika",
              value: "cf"
            },
            {
              label: "Surinam",
              value: "sr"
            },
            {
              label: "Svatá Lucia",
              value: "lc"
            },
            {
              label: "Svatý Kryštof a Nevis",
              value: "kn"
            },
            {
              label: "Svatý Tomáš a Princip",
              value: "st"
            },
            {
              label: "Svatý Vincenc a Grenadiny",
              value: "vc"
            },
            {
              label: "Svazijsko",
              value: "sz"
            },
            {
              label: "Syrská Arabská republika",
              value: "sy"
            },
            {
              label: "Súdán",
              value: "sd"
            },
            {
              label: "Tanzanie, Spojené království",
              value: "tz"
            },
            {
              label: "Tchaj-wan",
              value: "tw"
            },
            {
              label: "Thajsko",
              value: "th"
            },
            {
              label: "Tokelau",
              value: "tk"
            },
            {
              label: "Tonga",
              value: "to"
            },
            {
              label: "Trikot",
              value: "je"
            },
            {
              label: "Trinidad a Tobago",
              value: "tt"
            },
            {
              label: "Turkmenistán",
              value: "tm"
            },
            {
              label: "Tuvalu",
              value: "tv"
            },
            {
              label: "Tádžikistán",
              value: "tj"
            },
            {
              label: "Ugandě",
              value: "ug"
            },
            {
              label: "Uruguay",
              value: "uy"
            },
            {
              label: "Uzbekistán",
              value: "uz"
            },
            {
              label: "Vanuatu",
              value: "vu"
            },
            {
              label: "Venezuela",
              value: "ve"
            },
            {
              label: "Vietnam",
              value: "vn"
            },
            {
              label: "Vánoční ostrov",
              value: "cx"
            },
            {
              label: "Východní Timor (nový kód)",
              value: "ti"
            },
            {
              label: "Východní Timor (starý kód)",
              value: "tp"
            },
            {
              label: "Wallis a Futuna ostrovy",
              value: "wf"
            },
            {
              label: "Zambie",
              value: "zm"
            },
            {
              label: "Země",
              value: "ax"
            },
            {
              label: "Zimbabwe",
              value: "zw"
            },
            {
              label: "Ázerbajdžán",
              value: "az"
            },
            {
              label: "Írán (Islámská republika)",
              value: "ir"
            },
            {
              label: "Čadu",
              value: "td"
            },
            {
              label: "Černá Hora",
              value: "me"
            },
            {
              label: "Čínská lidová republika",
              value: "cn"
            }
          ]
        },
        {
          type: "select",
          name: "purpose",
          options: {
            mandatory: true,
            placeholder: "Typ cesty"
          },
          data: [
            {
              label: "Turistická",
              value: "tourism"
            },
            {
              label: "Pracovně administrativní",
              value: "administration"
            },
            {
              label: "Pracovně manuální",
              value: "labour"
            }
          ]
        },
        {
          type: "date",
          name: "departure",
          options: {
            placeholder: "Datum odjezdu",
            min: "today",
            max_src: "return"
          }
        },
        {
          type: "date",
          name: "return",
          options: {
            placeholder: "Datum návratu",
            min_src: "departure",
            max: "t + 10 years"
          }
        },
        {
          type: "number",
          name: "num-of-persons",
          options: {
            placeholder: "Počet pojišťovaných osob",
            mandatory: true,
            min: 1
          }
        },
        {
          type: "select",
          name: "group-type",
          options: {
            placeholder: "Typ skupiny",
            filter_by: "num-of-persons",
            mandatory: true,
            conditional: [
              [
                {
                  key: "num-of-persons",
                  value: "!1"
                },
                {
                  key: "num-of-persons",
                  value: "!"
                }
              ]
            ]
          },
          data: [
            {
              label: "Pár",
              value: "couple",
              'num-of-persons': 2
            },
            {
              label: "Rodina",
              value: "family"
            },
            {
              label: "Skupina",
              value: "group"
            }
          ]
        },
        {
          type: "cloneable",
          name: "person-info",
          options: {
            controlled: true,
            num_src: "num-of-persons",
            placeholder: "Údaje o osobě"
          },
          data: [
            {
              type: "wrapper",
              name: "",
              options: {
                wrap_type: "horizontal"
              },
              data: [
                {
                  type: "number",
                  name: "age",
                  options: {
                    placeholder: "Věk v den odjezdu",
                    mandatory: true,
                    min: 0,
                    max: 120,
                    label: "let"
                  }
                },
                {
                  type: "check",
                  name: "national",
                  options: {
                    placeholder: "Cizinec"
                  }
                }
              ]
            }
          ]
        },
        {
          type: "delimeter",
          name: "",
          options: {
            placeholder: "Na co chete pojištění?"
          }
        },
        {
          type: "wrapper",
          name: "",
          options: {
            wrap_type: "unwrap",
            placeholder: "Sporty"
          },
          data: [
            {
              type: "wrapper",
              name: "",
              options: {
                wrap_type: "vertical"
              },
              data: [
                {
                  type: "check",
                  name: "recreation_sports",
                  options: {
                    placeholder: "Rekreační sporty"
                  }
                },
                {
                  type: "check",
                  name: "winter_sports",
                  options: {
                    placeholder: "Zimní sporty"
                  }
                },
                {
                  type: "check",
                  name: "high_risk_sports",
                  options: {
                    placeholder: "Rizikové sporty"
                  }
                },
                {
                  type: "check",
                  name: "extreme_sports",
                  options: {
                    placeholder: "Extrémní sporty"
                  }
                },
                {
                  type: "check",
                  name: "organised_sports",
                  options: {
                    placeholder: "Organizované sporty"
                  }
                }
              ]
            }
          ]
        },
        {
          type: "wrapper",
          name: "",
          options: {
            wrap_type: "unwrap",
            placeholder: "Jiné"
          },
          data: [
            {
              type: "wrapper",
              name: "",
              options: {
                wrap_type: "vertical"
              },
              data: [
                {
                  type: "check",
                  name: "medical_expenses",
                  options: {
                    placeholder: "Léčebné výlohy"
                  }
                },
                {
                  type: "check",
                  name: "assistance",
                  options: {
                    placeholder: "Aistenční služby"
                  }
                },
                {
                  type: "check",
                  name: "injury_insurance",
                  options: {
                    placeholder: "Úrazové pojištění"
                  }
                },
                {
                  type: "check",
                  name: "liability_insurance",
                  options: {
                    placeholder: "Pojištění odpovědnosti"
                  }
                },
                {
                  type: "check",
                  name: "luggage_insurance",
                  options: {
                    placeholder: "Pojištění zavazadel"
                  }
                },
                {
                  type: "check",
                  name: "cancellation_insurance",
                  options: {
                    placeholder: "Připojištění storna cesty"
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      label: "Bydlení",
      value: "property",
      title: "Kalkulačka pojištění majetku",
      tagline: "Kalkulátory cen zatím připravujeme. Pro indiviuální nabídky k ušetření nás neváhejte kontaktovat.",
      submit: "Spočítat pojištění",
      options: {
        disabled: true,
        showHelpButtom: true,
      },
      fields: [
        {
          type: "switch",
          name: "type",
          options: {},
          data: [
            {
              label: "Nemovitost a domácnost",
              value: "both"
            },
            {
              label: "Nemovitost",
              value: "property"
            },
            {
              label: "Domácnost",
              value: "household"
            }
          ]
        },
        {
          type: "switch",
          name: "household_type",
          options: {},
          data: [
            {
              label: "Byt",
              value: "apartment"
            },
            {
              label: "Dům",
              value: "house"
            }
          ]
        },
        {
          type: "wrapper",
          name: "",
          options: {
            wrap_type: "horizontal"
          },
          data: [
            {
              type: "select",
              name: "apartment_location",
              options: {
                mandatory: true,
                placeholder: "Umístění",
                conditional: [
                  [
                    {
                      key: "household_type",
                      value: "apartment"
                    }
                  ]
                ]
              },
              data: [
                {
                  label: "V přízení",
                  value: "ground_floor"
                },
                {
                  label: "V patře",
                  value: "other_floor"
                },
                {
                  label: "V posledním patře",
                  value: "top_floor"
                }
              ]
            },
            {
              type: "select",
              name: "house_location",
              options: {
                mandatory: true,
                placeholder: "Umístění",
                conditional: [
                  [
                    {
                      key: "household_type",
                      value: "house"
                    }
                  ]
                ]
              },
              data: [
                {
                  label: "V zástavbě",
                  value: "development"
                },
                {
                  label: "Na samotě",
                  value: "solitary"
                }
              ]
            },
            {
              type: "check",
              name: "occupied",
              options: {
                placeholder: "Trvale obývaný",
                default: true
              }
            }
          ]
        },
        {
          type: "wrapper",
          name: "",
          options: {
            wrap_type: "vertical"
          },
          data: [
            {
              type: "check",
              name: "security_lock",
              options: {
                placeholder: "Bezpečnostní zámek"
              }
            },
            {
              type: "check",
              name: "locking_system",
              options: {
                placeholder: "Bezpečnostní uzamykací systém"
              }
            },
            {
              type: "check",
              name: "extra_security_lock",
              options: {
                placeholder: "Přídavný bezpečnostní zámek"
              }
            },
            {
              type: "check",
              name: "security_door",
              options: {
                placeholder: "Bezpečnostní dveře"
              }
            },
            {
              type: "check",
              name: "security_bar",
              options: {
                placeholder: "Bezpečnosntí závora"
              }
            },
            {
              type: "check",
              name: "secured_windows",
              options: {
                placeholder: "Zabezpečení oken"
              }
            },
            {
              type: "check",
              name: "security_doorframes",
              options: {
                placeholder: "Zárubně proti roztažení"
              }
            },
            {
              type: "check",
              name: "ezs",
              options: {
                placeholder: "EZS"
              }
            },
            {
              type: "check",
              name: "security_desk",
              options: {
                placeholder: "Pult centrální ochrany"
              }
            }
          ]
        },
        {
          type: "select",
          name: "furnishing",
          options: {
            placeholder: "Vybavení",
            mandatory: true
          },
          data: [
            {
              label: "Účelné",
              value: "utilitarian"
            },
            {
              label: "Standardní",
              value: "standard"
            },
            {
              label: "Nadstandardní",
              value: "above-standard"
            },
            {
              label: "Luxusní",
              value: "luxury"
            }
          ]
        },
        {
          type: "number",
          name: "floorspace",
          options: {
            placeholder: "Velikost domácnosti",
            min: 0,
            label: "m²",
            conditional: [
              [
                {
                  key: "type",
                  value: "household"
                }
              ]
            ]
          }
        },
        {
          type: "number",
          name: "insurance_sum",
          options: {
            placeholder: "Pojistná částka",
            min: 0,
            step: 1000,
            label: "Kč"
          }
        },
        {
          type: "wrapper",
          name: "",
          options: {
            wrap_type: "vertical"
          },
          data: [
            {
              type: "check",
              name: "flooding",
              options: {
                placeholder: "Za poslendích 20 let potopa či záplava domácnosti"
              }
            },
            {
              type: "check",
              name: "flooding-more-than-once",
              options: {
                placeholder: "Více než 1x",
                conditional: [
                  [
                    {
                      key: "flooding",
                      value: true
                    }
                  ]
                ]
              }
            }
          ]
        },
        {
          type: "wrapper",
          name: "",
          options: {
            wrap_type: "vertical"
          },
          data: [
            {
              type: "check",
              name: "incident",
              options: {
                placeholder: "Za posledních 5 let pojistné události v domácnosti"
              }
            },
            {
              type: "check",
              name: "incident-more-than-once",
              options: {
                placeholder: "Více než 1x",
                conditional: [
                  [
                    {
                      key: "incident",
                      value: true
                    }
                  ]
                ]
              }
            }
          ]
        },
        {
          type: "date",
          name: "insurance_start",
          options: {
            placeholder: "Počátek pojištění",
            min: "today"
          }
        }
      ]
    },
    {
      label: "Automobil",
      value: "automotive",
      title: "Kalkulačka pojištění automobilu",
      tagline: "Kalkulátory cen zatím připravujeme. Pro indiviuální nabídky k ušetření nás neváhejte kontaktovat.",
      submit: "Spočítat pojištění",
      options: {
        disabled: true,
        showHelpButtom: true,
      },
      fields: [
        {
          name: "type",
          type: "switch",
          options: {},
          data: [
            {
              label: "Fyzická osoba",
              value: "home"
            },
            {
              label: "OSVČ",
              value: "private"
            },
            {
              label: "Právnická osoba",
              value: "business"
            }
          ]
        },
        {
          name: "use",
          type: "select",
          options: {
            placeholder: "Užití vozidla",
            mandatory: true
          },
          data: [
            {
              label: "Běžný provoz",
              value: "normal"
            },
            {
              label: "Taxislužba",
              value: "taxi"
            },
            {
              label: "Půjčování",
              value: "hire"
            },
            {
              label: "Autoškola",
              value: "school"
            },
            {
              label: "Přednostní",
              value: "priority"
            },
            {
              label: "Nebezpeční náklady",
              value: "danger"
            },
            {
              label: "Historické (do roku 1952)",
              value: "historical"
            },
            {
              label: "Historické se zvláštní RZ",
              value: "historical-rz"
            }
          ]
        },
        {
          name: "manufacturer",
          type: "select",
          options: {
            placeholder: "Výrobce",
            mandatory: true
          },
          data: [
            {
              label: "Abarth",
              value: "Abarth"
            },
            {
              label: "Acura",
              value: "Acura"
            },
            {
              label: "Aixam",
              value: "Aixam"
            },
            {
              label: "Alfa Romeo",
              value: "Alfa_Romeo"
            },
            {
              label: "Alpina",
              value: "Alpina"
            },
            {
              label: "Amarok",
              value: "Amarok"
            },
            {
              label: "Aston Martin",
              value: "Aston_Martin"
            },
            {
              label: "BMW",
              value: "BMW"
            },
            {
              label: "Bentley",
              value: "Bentley"
            },
            {
              label: "Buick",
              value: "Buick"
            },
            {
              label: "Cadillac",
              value: "Cadillac"
            },
            {
              label: "Casalini",
              value: "Casalini"
            },
            {
              label: "Chevrolet",
              value: "Chevrolet"
            },
            {
              label: "Chrysler",
              value: "Chrysler"
            },
            {
              label: "Citroën",
              value: "Citroen"
            },
            {
              label: "DR",
              value: "DR"
            },
            {
              label: "DS",
              value: "DS"
            },
            {
              label: "Dacia",
              value: "Dacia"
            },
            {
              label: "Daewoo",
              value: "Daewoo"
            },
            {
              label: "Daihatsu",
              value: "Daihatsu"
            },
            {
              label: "Dodge",
              value: "Dodge"
            },
            {
              label: "Ferrari",
              value: "Ferrari"
            },
            {
              label: "Fiat",
              value: "Fiat"
            },
            {
              label: "Ford",
              value: "Ford"
            },
            {
              label: "GMC",
              value: "GMC"
            },
            {
              label: "Gordon",
              value: "Gordon"
            },
            {
              label: "Honda",
              value: "Honda"
            },
            {
              label: "Hummer",
              value: "Hummer"
            },
            {
              label: "Hyundai",
              value: "Hyundai"
            },
            {
              label: "Infiniti",
              value: "Infiniti"
            },
            {
              label: "Isuzu",
              value: "Isuzu"
            },
            {
              label: "Iveco",
              value: "Iveco"
            },
            {
              label: "JAC",
              value: "JAC"
            },
            {
              label: "Jaguar",
              value: "Jaguar"
            },
            {
              label: "Jeep",
              value: "Jeep"
            },
            {
              label: "Kia",
              value: "Kia"
            },
            {
              label: "Lada",
              value: "Lada"
            },
            {
              label: "Lamborghini",
              value: "Lamborghini"
            },
            {
              label: "Lancia",
              value: "Lancia"
            },
            {
              label: "Land Rover",
              value: "Land_Rover"
            },
            {
              label: "Lexus",
              value: "Lexus"
            },
            {
              label: "Ligier",
              value: "Ligier"
            },
            {
              label: "Lincoln",
              value: "Lincoln"
            },
            {
              label: "Lotus",
              value: "Lotus"
            },
            {
              label: "MCC",
              value: "MCC"
            },
            {
              label: "MG",
              value: "MG"
            },
            {
              label: "Maserati",
              value: "Maserati"
            },
            {
              label: "Mazda",
              value: "Mazda"
            },
            {
              label: "McLaren",
              value: "McLaren"
            },
            {
              label: "Mercedes-Benz",
              value: "Mercedes-Benz"
            },
            {
              label: "Microcar",
              value: "Microcar"
            },
            {
              label: "Mini",
              value: "Mini"
            },
            {
              label: "Mitsubishi",
              value: "Mitsubishi"
            },
            {
              label: "Nissan",
              value: "Nissan"
            },
            {
              label: "Opel",
              value: "Opel"
            },
            {
              label: "Peugeot",
              value: "Peugeot"
            },
            {
              label: "Plymouth",
              value: "Plymouth"
            },
            {
              label: "Pontiac",
              value: "Pontiac"
            },
            {
              label: "Porsche",
              value: "Porsche"
            },
            {
              label: "Rayton Fissore",
              value: "Rayton_Fissore"
            },
            {
              label: "Renault",
              value: "Renault"
            },
            {
              label: "Rolls Royce",
              value: "Rolls_Royce"
            },
            {
              label: "Rover",
              value: "Rover"
            },
            {
              label: "Saab",
              value: "Saab"
            },
            {
              label: "Santana",
              value: "Santana"
            },
            {
              label: "Seat",
              value: "Seat"
            },
            {
              label: "Smart",
              value: "Smart"
            },
            {
              label: "SsangYong",
              value: "SsangYong"
            },
            {
              label: "Subaru",
              value: "Subaru"
            },
            {
              label: "Suzuki",
              value: "Suzuki"
            },
            {
              label: "TVR",
              value: "TVR"
            },
            {
              label: "Tata",
              value: "Tata"
            },
            {
              label: "Tatra",
              value: "Tatra"
            },
            {
              label: "Tesla",
              value: "Tesla"
            },
            {
              label: "Toyota",
              value: "Toyota"
            },
            {
              label: "Trabant",
              value: "Trabant"
            },
            {
              label: "Volvo",
              value: "Volvo"
            },
            {
              label: "Wartburg",
              value: "Wartburg"
            },
            {
              label: "ZhiDou",
              value: "ZhiDou"
            },
            {
              label: "Škoda",
              value: "Skoda"
            }
          ]
        },
        {
          name: "model",
          type: "select",
          options: {
            placeholder: "Model",
            mandatory: true,
            filter_by: "manufacturer",
            sequential: "manufacturer"
          },
          data: [
            {
              label: "124 Spider",
              value: "124_Spider",
              manufacturer: "Abarth"
            },
            {
              label: "500",
              value: "500",
              manufacturer: "Abarth"
            },
            {
              label: "500 C",
              value: "500_C",
              manufacturer: "Abarth"
            },
            {
              label: "595",
              value: "595",
              manufacturer: "Abarth"
            },
            {
              label: "595 50TH",
              value: "595_50TH",
              manufacturer: "Abarth"
            },
            {
              label: "595 C",
              value: "595_C",
              manufacturer: "Abarth"
            },
            {
              label: "695",
              value: "695",
              manufacturer: "Abarth"
            },
            {
              label: "Punto",
              value: "Punto",
              manufacturer: "Abarth"
            },
            {
              label: "CL",
              value: "CL",
              manufacturer: "Acura"
            },
            {
              label: "EL",
              value: "EL",
              manufacturer: "Acura"
            },
            {
              label: "Integra",
              value: "Integra",
              manufacturer: "Acura"
            },
            {
              label: "MDX",
              value: "MDX",
              manufacturer: "Acura"
            },
            {
              label: "NSX-T",
              value: "NSX-T",
              manufacturer: "Acura"
            },
            {
              label: "RDX",
              value: "RDX",
              manufacturer: "Acura"
            },
            {
              label: "RL",
              value: "RL",
              manufacturer: "Acura"
            },
            {
              label: "RSX",
              value: "RSX",
              manufacturer: "Acura"
            },
            {
              label: "TL",
              value: "TL",
              manufacturer: "Acura"
            },
            {
              label: "TSX",
              value: "TSX",
              manufacturer: "Acura"
            },
            {
              label: "ZDX",
              value: "ZDX",
              manufacturer: "Acura"
            },
            {
              label: "A400",
              value: "A400",
              manufacturer: "Aixam"
            },
            {
              label: "A500",
              value: "A500",
              manufacturer: "Aixam"
            },
            {
              label: "A721",
              value: "A721",
              manufacturer: "Aixam"
            },
            {
              label: "A741",
              value: "A741",
              manufacturer: "Aixam"
            },
            {
              label: "A751",
              value: "A751",
              manufacturer: "Aixam"
            },
            {
              label: "CITY",
              value: "CITY",
              manufacturer: "Aixam"
            },
            {
              label: "CROSSLINE",
              value: "CROSSLINE",
              manufacturer: "Aixam"
            },
            {
              label: "CROSSOVER",
              value: "CROSSOVER",
              manufacturer: "Aixam"
            },
            {
              label: "Coupé",
              value: "Coupe",
              manufacturer: "Aixam"
            },
            {
              label: "GTO",
              value: "GTO",
              manufacturer: "Aixam"
            },
            {
              label: "Minauto",
              value: "Minauto",
              manufacturer: "Aixam"
            },
            {
              label: "ROADLINE",
              value: "ROADLINE",
              manufacturer: "Aixam"
            },
            {
              label: "SCOUTY R",
              value: "SCOUTY_R",
              manufacturer: "Aixam"
            },
            {
              label: "e-City",
              value: "e-City",
              manufacturer: "Aixam"
            },
            {
              label: "e-Coupé",
              value: "e-Coupe",
              manufacturer: "Aixam"
            },
            {
              label: "145",
              value: "145",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "146",
              value: "146",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "147",
              value: "147",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "155",
              value: "155",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "156",
              value: "156",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "159",
              value: "159",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "164",
              value: "164",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "166",
              value: "166",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "33",
              value: "33",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "4C",
              value: "4C",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "75",
              value: "75",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "Brera",
              value: "Brera",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "Crosswagon Q4",
              value: "Crosswagon_Q4",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "GT",
              value: "GT",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "GTV",
              value: "GTV",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "Giulia",
              value: "Giulia",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "Giulietta",
              value: "Giulietta",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "MiTo",
              value: "MiTo",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "Spider",
              value: "Spider",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "Sportwagon Q4",
              value: "Sportwagon_Q4",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "Stelvio",
              value: "Stelvio",
              manufacturer: "Alfa_Romeo"
            },
            {
              label: "B3 S",
              value: "B3_S",
              manufacturer: "Alpina"
            },
            {
              label: "B5",
              value: "B5",
              manufacturer: "Alpina"
            },
            {
              label: "B6",
              value: "B6",
              manufacturer: "Alpina"
            },
            {
              label: "B7",
              value: "B7",
              manufacturer: "Alpina"
            },
            {
              label: "D3",
              value: "D3",
              manufacturer: "Alpina"
            },
            {
              label: "D5",
              value: "D5",
              manufacturer: "Alpina"
            },
            {
              label: "Arteon",
              value: "Arteon",
              manufacturer: "Amarok"
            },
            {
              label: "Atlas",
              value: "Atlas",
              manufacturer: "Amarok"
            },
            {
              label: "Beetle",
              value: "Beetle",
              manufacturer: "Amarok"
            },
            {
              label: "Bora",
              value: "Bora",
              manufacturer: "Amarok"
            },
            {
              label: "CC",
              value: "CC",
              manufacturer: "Amarok"
            },
            {
              label: "Caddy",
              value: "Caddy",
              manufacturer: "Amarok"
            },
            {
              label: "California",
              value: "California",
              manufacturer: "Amarok"
            },
            {
              label: "Caravelle",
              value: "Caravelle",
              manufacturer: "Amarok"
            },
            {
              label: "Carman Ghia",
              value: "Carman_Ghia",
              manufacturer: "Amarok"
            },
            {
              label: "Corrado",
              value: "Corrado",
              manufacturer: "Amarok"
            },
            {
              label: "Eos",
              value: "Eos",
              manufacturer: "Amarok"
            },
            {
              label: "Fox",
              value: "Fox",
              manufacturer: "Amarok"
            },
            {
              label: "Golf",
              value: "Golf",
              manufacturer: "Amarok"
            },
            {
              label: "Golf Plus",
              value: "Golf_Plus",
              manufacturer: "Amarok"
            },
            {
              label: "Golf Sportsvan",
              value: "Golf_Sportsvan",
              manufacturer: "Amarok"
            },
            {
              label: "Jetta",
              value: "Jetta",
              manufacturer: "Amarok"
            },
            {
              label: "Karmann-Ghia",
              value: "Karmann-Ghia",
              manufacturer: "Amarok"
            },
            {
              label: "Lupo",
              value: "Lupo",
              manufacturer: "Amarok"
            },
            {
              label: "Multivan",
              value: "Multivan",
              manufacturer: "Amarok"
            },
            {
              label: "New Beetle",
              value: "New_Beetle",
              manufacturer: "Amarok"
            },
            {
              label: "Passat",
              value: "Passat",
              manufacturer: "Amarok"
            },
            {
              label: "Passat CC",
              value: "Passat_CC",
              manufacturer: "Amarok"
            },
            {
              label: "Phaeton",
              value: "Phaeton",
              manufacturer: "Amarok"
            },
            {
              label: "Polo",
              value: "Polo",
              manufacturer: "Amarok"
            },
            {
              label: "Routan",
              value: "Routan",
              manufacturer: "Amarok"
            },
            {
              label: "Scirocco",
              value: "Scirocco",
              manufacturer: "Amarok"
            },
            {
              label: "Sharan",
              value: "Sharan",
              manufacturer: "Amarok"
            },
            {
              label: "T-Cross",
              value: "T-Cross",
              manufacturer: "Amarok"
            },
            {
              label: "T-Roc",
              value: "T-Roc",
              manufacturer: "Amarok"
            },
            {
              label: "Tiguan",
              value: "Tiguan",
              manufacturer: "Amarok"
            },
            {
              label: "Touareg",
              value: "Touareg",
              manufacturer: "Amarok"
            },
            {
              label: "Touran",
              value: "Touran",
              manufacturer: "Amarok"
            },
            {
              label: "Transporter",
              value: "Transporter",
              manufacturer: "Amarok"
            },
            {
              label: "Up!",
              value: "Up",
              manufacturer: "Amarok"
            },
            {
              label: "Vento",
              value: "Vento",
              manufacturer: "Amarok"
            },
            {
              label: "DB7",
              value: "DB7",
              manufacturer: "Aston_Martin"
            },
            {
              label: "DB9",
              value: "DB9",
              manufacturer: "Aston_Martin"
            },
            {
              label: "DB9 Volante",
              value: "DB9_Volante",
              manufacturer: "Aston_Martin"
            },
            {
              label: "DBS",
              value: "DBS",
              manufacturer: "Aston_Martin"
            },
            {
              label: "DBS Volante",
              value: "DBS_Volante",
              manufacturer: "Aston_Martin"
            },
            {
              label: "Rapide",
              value: "Rapide",
              manufacturer: "Aston_Martin"
            },
            {
              label: "V12",
              value: "V12",
              manufacturer: "Aston_Martin"
            },
            {
              label: "V12 Vantage",
              value: "V12_Vantage",
              manufacturer: "Aston_Martin"
            },
            {
              label: "V8 Vantage",
              value: "V8_Vantage",
              manufacturer: "Aston_Martin"
            },
            {
              label: "V8 Vantage Roadster",
              value: "V8_Vantage_Roadster",
              manufacturer: "Aston_Martin"
            },
            {
              label: "Vanquish",
              value: "Vanquish",
              manufacturer: "Aston_Martin"
            },
            {
              label: "Alpina",
              value: "Alpina",
              manufacturer: "BMW"
            },
            {
              label: "M2",
              value: "M2",
              manufacturer: "BMW"
            },
            {
              label: "M3",
              value: "M3",
              manufacturer: "BMW"
            },
            {
              label: "M4",
              value: "M4",
              manufacturer: "BMW"
            },
            {
              label: "M5",
              value: "M5",
              manufacturer: "BMW"
            },
            {
              label: "M6",
              value: "M6",
              manufacturer: "BMW"
            },
            {
              label: "X1",
              value: "X1",
              manufacturer: "BMW"
            },
            {
              label: "X2",
              value: "X2",
              manufacturer: "BMW"
            },
            {
              label: "X3",
              value: "X3",
              manufacturer: "BMW"
            },
            {
              label: "X4",
              value: "X4",
              manufacturer: "BMW"
            },
            {
              label: "X5",
              value: "X5",
              manufacturer: "BMW"
            },
            {
              label: "X6",
              value: "X6",
              manufacturer: "BMW"
            },
            {
              label: "X7",
              value: "X7",
              manufacturer: "BMW"
            },
            {
              label: "Z1",
              value: "Z1",
              manufacturer: "BMW"
            },
            {
              label: "Z3",
              value: "Z3",
              manufacturer: "BMW"
            },
            {
              label: "Z4",
              value: "Z4",
              manufacturer: "BMW"
            },
            {
              label: "Z8",
              value: "Z8",
              manufacturer: "BMW"
            },
            {
              label: "i3",
              value: "i3",
              manufacturer: "BMW"
            },
            {
              label: "i8",
              value: "i8",
              manufacturer: "BMW"
            },
            {
              label: "Řada 1",
              value: "Rada_1",
              manufacturer: "BMW"
            },
            {
              label: "Řada 2",
              value: "Rada_2",
              manufacturer: "BMW"
            },
            {
              label: "Řada 3",
              value: "Rada_3",
              manufacturer: "BMW"
            },
            {
              label: "Řada 4",
              value: "Rada_4",
              manufacturer: "BMW"
            },
            {
              label: "Řada 5",
              value: "Rada_5",
              manufacturer: "BMW"
            },
            {
              label: "Řada 6",
              value: "Rada_6",
              manufacturer: "BMW"
            },
            {
              label: "Řada 7",
              value: "Rada_7",
              manufacturer: "BMW"
            },
            {
              label: "Řada 8",
              value: "Rada_8",
              manufacturer: "BMW"
            },
            {
              label: "Arnage",
              value: "Arnage",
              manufacturer: "Bentley"
            },
            {
              label: "Azure Convertible",
              value: "Azure_Convertible",
              manufacturer: "Bentley"
            },
            {
              label: "Bentayga",
              value: "Bentayga",
              manufacturer: "Bentley"
            },
            {
              label: "Brooklands",
              value: "Brooklands",
              manufacturer: "Bentley"
            },
            {
              label: "Continental Flying Spur",
              value: "Continental_Flying_Spur",
              manufacturer: "Bentley"
            },
            {
              label: "Continental GT",
              value: "Continental_GT",
              manufacturer: "Bentley"
            },
            {
              label: "Continental GTC",
              value: "Continental_GTC",
              manufacturer: "Bentley"
            },
            {
              label: "Continental R",
              value: "Continental_R",
              manufacturer: "Bentley"
            },
            {
              label: "Continental T",
              value: "Continental_T",
              manufacturer: "Bentley"
            },
            {
              label: "Mulsanne",
              value: "Mulsanne",
              manufacturer: "Bentley"
            },
            {
              label: "Turbo R",
              value: "Turbo_R",
              manufacturer: "Bentley"
            },
            {
              label: "Century",
              value: "Century",
              manufacturer: "Buick"
            },
            {
              label: "Electra",
              value: "Electra",
              manufacturer: "Buick"
            },
            {
              label: "Enclave",
              value: "Enclave",
              manufacturer: "Buick"
            },
            {
              label: "LaCrosse",
              value: "LaCrosse",
              manufacturer: "Buick"
            },
            {
              label: "LeSabre",
              value: "LeSabre",
              manufacturer: "Buick"
            },
            {
              label: "Park Avenue",
              value: "Park_Avenue",
              manufacturer: "Buick"
            },
            {
              label: "Rainer",
              value: "Rainer",
              manufacturer: "Buick"
            },
            {
              label: "Rainier",
              value: "Rainier",
              manufacturer: "Buick"
            },
            {
              label: "Randezvous",
              value: "Randezvous",
              manufacturer: "Buick"
            },
            {
              label: "Regal",
              value: "Regal",
              manufacturer: "Buick"
            },
            {
              label: "Riviera",
              value: "Riviera",
              manufacturer: "Buick"
            },
            {
              label: "Skylark",
              value: "Skylark",
              manufacturer: "Buick"
            },
            {
              label: "ATS",
              value: "ATS",
              manufacturer: "Cadillac"
            },
            {
              label: "BLS",
              value: "BLS",
              manufacturer: "Cadillac"
            },
            {
              label: "CT6",
              value: "CT6",
              manufacturer: "Cadillac"
            },
            {
              label: "CTS",
              value: "CTS",
              manufacturer: "Cadillac"
            },
            {
              label: "Catera",
              value: "Catera",
              manufacturer: "Cadillac"
            },
            {
              label: "DeVille",
              value: "DeVille",
              manufacturer: "Cadillac"
            },
            {
              label: "Eldorado",
              value: "Eldorado",
              manufacturer: "Cadillac"
            },
            {
              label: "Escalade",
              value: "Escalade",
              manufacturer: "Cadillac"
            },
            {
              label: "Fleetwood",
              value: "Fleetwood",
              manufacturer: "Cadillac"
            },
            {
              label: "SRX",
              value: "SRX",
              manufacturer: "Cadillac"
            },
            {
              label: "STS",
              value: "STS",
              manufacturer: "Cadillac"
            },
            {
              label: "Seville",
              value: "Seville",
              manufacturer: "Cadillac"
            },
            {
              label: "XLR",
              value: "XLR",
              manufacturer: "Cadillac"
            },
            {
              label: "XT4",
              value: "XT4",
              manufacturer: "Cadillac"
            },
            {
              label: "XT5",
              value: "XT5",
              manufacturer: "Cadillac"
            },
            {
              label: "XTS",
              value: "XTS",
              manufacturer: "Cadillac"
            },
            {
              label: "Sulky",
              value: "Sulky",
              manufacturer: "Casalini"
            },
            {
              label: "Ydea",
              value: "Ydea",
              manufacturer: "Casalini"
            },
            {
              label: "Alero",
              value: "Alero",
              manufacturer: "Chevrolet"
            },
            {
              label: "Astro",
              value: "Astro",
              manufacturer: "Chevrolet"
            },
            {
              label: "Astro Van",
              value: "Astro_Van",
              manufacturer: "Chevrolet"
            },
            {
              label: "Avalanche",
              value: "Avalanche",
              manufacturer: "Chevrolet"
            },
            {
              label: "Aveo",
              value: "Aveo",
              manufacturer: "Chevrolet"
            },
            {
              label: "Beretta",
              value: "Beretta",
              manufacturer: "Chevrolet"
            },
            {
              label: "Blazer",
              value: "Blazer",
              manufacturer: "Chevrolet"
            },
            {
              label: "Camaro",
              value: "Camaro",
              manufacturer: "Chevrolet"
            },
            {
              label: "Caprice",
              value: "Caprice",
              manufacturer: "Chevrolet"
            },
            {
              label: "Captiva",
              value: "Captiva",
              manufacturer: "Chevrolet"
            },
            {
              label: "Cavalier",
              value: "Cavalier",
              manufacturer: "Chevrolet"
            },
            {
              label: "Cobalt",
              value: "Cobalt",
              manufacturer: "Chevrolet"
            },
            {
              label: "Colorado",
              value: "Colorado",
              manufacturer: "Chevrolet"
            },
            {
              label: "Corvette",
              value: "Corvette",
              manufacturer: "Chevrolet"
            },
            {
              label: "Cruze",
              value: "Cruze",
              manufacturer: "Chevrolet"
            },
            {
              label: "El Camino",
              value: "El_Camino",
              manufacturer: "Chevrolet"
            },
            {
              label: "Epica",
              value: "Epica",
              manufacturer: "Chevrolet"
            },
            {
              label: "Equinox",
              value: "Equinox",
              manufacturer: "Chevrolet"
            },
            {
              label: "Evanda",
              value: "Evanda",
              manufacturer: "Chevrolet"
            },
            {
              label: "HHR",
              value: "HHR",
              manufacturer: "Chevrolet"
            },
            {
              label: "Impala",
              value: "Impala",
              manufacturer: "Chevrolet"
            },
            {
              label: "Kalos",
              value: "Kalos",
              manufacturer: "Chevrolet"
            },
            {
              label: "Lacetti",
              value: "Lacetti",
              manufacturer: "Chevrolet"
            },
            {
              label: "Lumina",
              value: "Lumina",
              manufacturer: "Chevrolet"
            },
            {
              label: "Malibu",
              value: "Malibu",
              manufacturer: "Chevrolet"
            },
            {
              label: "Matiz",
              value: "Matiz",
              manufacturer: "Chevrolet"
            },
            {
              label: "Metro",
              value: "Metro",
              manufacturer: "Chevrolet"
            },
            {
              label: "Monte Carlo",
              value: "Monte_Carlo",
              manufacturer: "Chevrolet"
            },
            {
              label: "Niva",
              value: "Niva",
              manufacturer: "Chevrolet"
            },
            {
              label: "Nubira",
              value: "Nubira",
              manufacturer: "Chevrolet"
            },
            {
              label: "Optra",
              value: "Optra",
              manufacturer: "Chevrolet"
            },
            {
              label: "Orlando",
              value: "Orlando",
              manufacturer: "Chevrolet"
            },
            {
              label: "Prizm",
              value: "Prizm",
              manufacturer: "Chevrolet"
            },
            {
              label: "Rezzo",
              value: "Rezzo",
              manufacturer: "Chevrolet"
            },
            {
              label: "S10",
              value: "S10",
              manufacturer: "Chevrolet"
            },
            {
              label: "Silverado",
              value: "Silverado",
              manufacturer: "Chevrolet"
            },
            {
              label: "Spark",
              value: "Spark",
              manufacturer: "Chevrolet"
            },
            {
              label: "Suburban",
              value: "Suburban",
              manufacturer: "Chevrolet"
            },
            {
              label: "Tacuma",
              value: "Tacuma",
              manufacturer: "Chevrolet"
            },
            {
              label: "Tahoe",
              value: "Tahoe",
              manufacturer: "Chevrolet"
            },
            {
              label: "Tracker",
              value: "Tracker",
              manufacturer: "Chevrolet"
            },
            {
              label: "Trail",
              value: "Trail",
              manufacturer: "Chevrolet"
            },
            {
              label: "Trailblazer",
              value: "Trailblazer",
              manufacturer: "Chevrolet"
            },
            {
              label: "Trans Sport",
              value: "Trans_Sport",
              manufacturer: "Chevrolet"
            },
            {
              label: "Trax",
              value: "Trax",
              manufacturer: "Chevrolet"
            },
            {
              label: "Venture",
              value: "Venture",
              manufacturer: "Chevrolet"
            },
            {
              label: "200",
              value: "200",
              manufacturer: "Chrysler"
            },
            {
              label: "300C",
              value: "300C",
              manufacturer: "Chrysler"
            },
            {
              label: "300M",
              value: "300M",
              manufacturer: "Chrysler"
            },
            {
              label: "Cirrus",
              value: "Cirrus",
              manufacturer: "Chrysler"
            },
            {
              label: "Concorde",
              value: "Concorde",
              manufacturer: "Chrysler"
            },
            {
              label: "Crossfire",
              value: "Crossfire",
              manufacturer: "Chrysler"
            },
            {
              label: "ES",
              value: "ES",
              manufacturer: "Chrysler"
            },
            {
              label: "GTS",
              value: "GTS",
              manufacturer: "Chrysler"
            },
            {
              label: "Grand Voyager",
              value: "Grand_Voyager",
              manufacturer: "Chrysler"
            },
            {
              label: "Intrepid",
              value: "Intrepid",
              manufacturer: "Chrysler"
            },
            {
              label: "LHS",
              value: "LHS",
              manufacturer: "Chrysler"
            },
            {
              label: "Le Baron Cabrio",
              value: "Le_Baron_Cabrio",
              manufacturer: "Chrysler"
            },
            {
              label: "Neon",
              value: "Neon",
              manufacturer: "Chrysler"
            },
            {
              label: "New Yorker",
              value: "New_Yorker",
              manufacturer: "Chrysler"
            },
            {
              label: "PT Cruiser",
              value: "PT_Cruiser",
              manufacturer: "Chrysler"
            },
            {
              label: "Pacifica",
              value: "Pacifica",
              manufacturer: "Chrysler"
            },
            {
              label: "Saratoga",
              value: "Saratoga",
              manufacturer: "Chrysler"
            },
            {
              label: "Sebring",
              value: "Sebring",
              manufacturer: "Chrysler"
            },
            {
              label: "Stratus",
              value: "Stratus",
              manufacturer: "Chrysler"
            },
            {
              label: "Town & Country",
              value: "Town__Country",
              manufacturer: "Chrysler"
            },
            {
              label: "Vision",
              value: "Vision",
              manufacturer: "Chrysler"
            },
            {
              label: "Voyager",
              value: "Voyager",
              manufacturer: "Chrysler"
            },
            {
              label: "AX",
              value: "AX",
              manufacturer: "Citroen"
            },
            {
              label: "BX",
              value: "BX",
              manufacturer: "Citroen"
            },
            {
              label: "Berlingo",
              value: "Berlingo",
              manufacturer: "Citroen"
            },
            {
              label: "C-Crosser",
              value: "C-Crosser",
              manufacturer: "Citroen"
            },
            {
              label: "C-Elysée",
              value: "C-Elysee",
              manufacturer: "Citroen"
            },
            {
              label: "C-Zero",
              value: "C-Zero",
              manufacturer: "Citroen"
            },
            {
              label: "C1",
              value: "C1",
              manufacturer: "Citroen"
            },
            {
              label: "C2",
              value: "C2",
              manufacturer: "Citroen"
            },
            {
              label: "C3",
              value: "C3",
              manufacturer: "Citroen"
            },
            {
              label: "C3 Aircross",
              value: "C3_Aircross",
              manufacturer: "Citroen"
            },
            {
              label: "C3 Picasso",
              value: "C3_Picasso",
              manufacturer: "Citroen"
            },
            {
              label: "C4",
              value: "C4",
              manufacturer: "Citroen"
            },
            {
              label: "C4 Aircross",
              value: "C4_Aircross",
              manufacturer: "Citroen"
            },
            {
              label: "C4 Cactus",
              value: "C4_Cactus",
              manufacturer: "Citroen"
            },
            {
              label: "C4 Picasso",
              value: "C4_Picasso",
              manufacturer: "Citroen"
            },
            {
              label: "C4 SpaceTourer",
              value: "C4_SpaceTourer",
              manufacturer: "Citroen"
            },
            {
              label: "C5",
              value: "C5",
              manufacturer: "Citroen"
            },
            {
              label: "C5 Aircross",
              value: "C5_Aircross",
              manufacturer: "Citroen"
            },
            {
              label: "C6",
              value: "C6",
              manufacturer: "Citroen"
            },
            {
              label: "C8",
              value: "C8",
              manufacturer: "Citroen"
            },
            {
              label: "CX",
              value: "CX",
              manufacturer: "Citroen"
            },
            {
              label: "DS3",
              value: "DS3",
              manufacturer: "Citroen"
            },
            {
              label: "DS3 Cabrio",
              value: "DS3_Cabrio",
              manufacturer: "Citroen"
            },
            {
              label: "DS4",
              value: "DS4",
              manufacturer: "Citroen"
            },
            {
              label: "DS5",
              value: "DS5",
              manufacturer: "Citroen"
            },
            {
              label: "Grand C4 Picasso",
              value: "Grand_C4_Picasso",
              manufacturer: "Citroen"
            },
            {
              label: "Grand C4 SpaceTourer",
              value: "Grand_C4_SpaceTourer",
              manufacturer: "Citroen"
            },
            {
              label: "Jumper",
              value: "Jumper",
              manufacturer: "Citroen"
            },
            {
              label: "Jumpy",
              value: "Jumpy",
              manufacturer: "Citroen"
            },
            {
              label: "Nemo",
              value: "Nemo",
              manufacturer: "Citroen"
            },
            {
              label: "Saxo",
              value: "Saxo",
              manufacturer: "Citroen"
            },
            {
              label: "SpaceTourer",
              value: "SpaceTourer",
              manufacturer: "Citroen"
            },
            {
              label: "Visa",
              value: "Visa",
              manufacturer: "Citroen"
            },
            {
              label: "XM",
              value: "XM",
              manufacturer: "Citroen"
            },
            {
              label: "Xantia",
              value: "Xantia",
              manufacturer: "Citroen"
            },
            {
              label: "Xsara",
              value: "Xsara",
              manufacturer: "Citroen"
            },
            {
              label: "Xsara Picasso",
              value: "Xsara_Picasso",
              manufacturer: "Citroen"
            },
            {
              label: "ZX",
              value: "ZX",
              manufacturer: "Citroen"
            },
            {
              label: "Évasion",
              value: "Evasion",
              manufacturer: "Citroen"
            },
            {
              label: "DR5",
              value: "DR5",
              manufacturer: "DR"
            },
            {
              label: "3",
              value: "3",
              manufacturer: "DS"
            },
            {
              label: "4",
              value: "4",
              manufacturer: "DS"
            },
            {
              label: "4 Crossback",
              value: "4_Crossback",
              manufacturer: "DS"
            },
            {
              label: "5",
              value: "5",
              manufacturer: "DS"
            },
            {
              label: "1300",
              value: "1300",
              manufacturer: "Dacia"
            },
            {
              label: "1310",
              value: "1310",
              manufacturer: "Dacia"
            },
            {
              label: "1410",
              value: "1410",
              manufacturer: "Dacia"
            },
            {
              label: "Dokker",
              value: "Dokker",
              manufacturer: "Dacia"
            },
            {
              label: "Double",
              value: "Double",
              manufacturer: "Dacia"
            },
            {
              label: "Duster",
              value: "Duster",
              manufacturer: "Dacia"
            },
            {
              label: "Lodgy",
              value: "Lodgy",
              manufacturer: "Dacia"
            },
            {
              label: "Logan",
              value: "Logan",
              manufacturer: "Dacia"
            },
            {
              label: "Pick Up",
              value: "Pick_Up",
              manufacturer: "Dacia"
            },
            {
              label: "Sandero",
              value: "Sandero",
              manufacturer: "Dacia"
            },
            {
              label: "Solenza",
              value: "Solenza",
              manufacturer: "Dacia"
            },
            {
              label: "Chairman",
              value: "Chairman",
              manufacturer: "Daewoo"
            },
            {
              label: "Espero",
              value: "Espero",
              manufacturer: "Daewoo"
            },
            {
              label: "Evanda",
              value: "Evanda",
              manufacturer: "Daewoo"
            },
            {
              label: "Kalos",
              value: "Kalos",
              manufacturer: "Daewoo"
            },
            {
              label: "Lanos",
              value: "Lanos",
              manufacturer: "Daewoo"
            },
            {
              label: "Leganza",
              value: "Leganza",
              manufacturer: "Daewoo"
            },
            {
              label: "Matiz",
              value: "Matiz",
              manufacturer: "Daewoo"
            },
            {
              label: "Nexia",
              value: "Nexia",
              manufacturer: "Daewoo"
            },
            {
              label: "Nubira",
              value: "Nubira",
              manufacturer: "Daewoo"
            },
            {
              label: "Racer",
              value: "Racer",
              manufacturer: "Daewoo"
            },
            {
              label: "Tacuma",
              value: "Tacuma",
              manufacturer: "Daewoo"
            },
            {
              label: "Tico",
              value: "Tico",
              manufacturer: "Daewoo"
            },
            {
              label: "Applause",
              value: "Applause",
              manufacturer: "Daihatsu"
            },
            {
              label: "Charade",
              value: "Charade",
              manufacturer: "Daihatsu"
            },
            {
              label: "Copen",
              value: "Copen",
              manufacturer: "Daihatsu"
            },
            {
              label: "Cuore",
              value: "Cuore",
              manufacturer: "Daihatsu"
            },
            {
              label: "Feroza",
              value: "Feroza",
              manufacturer: "Daihatsu"
            },
            {
              label: "Grand Movie",
              value: "Grand_Movie",
              manufacturer: "Daihatsu"
            },
            {
              label: "Materia",
              value: "Materia",
              manufacturer: "Daihatsu"
            },
            {
              label: "Move",
              value: "Move",
              manufacturer: "Daihatsu"
            },
            {
              label: "Rocky",
              value: "Rocky",
              manufacturer: "Daihatsu"
            },
            {
              label: "Sirion",
              value: "Sirion",
              manufacturer: "Daihatsu"
            },
            {
              label: "Terios",
              value: "Terios",
              manufacturer: "Daihatsu"
            },
            {
              label: "Trevis",
              value: "Trevis",
              manufacturer: "Daihatsu"
            },
            {
              label: "Avenger",
              value: "Avenger",
              manufacturer: "Dodge"
            },
            {
              label: "Caliber",
              value: "Caliber",
              manufacturer: "Dodge"
            },
            {
              label: "Caravan",
              value: "Caravan",
              manufacturer: "Dodge"
            },
            {
              label: "Challenger",
              value: "Challenger",
              manufacturer: "Dodge"
            },
            {
              label: "Charger",
              value: "Charger",
              manufacturer: "Dodge"
            },
            {
              label: "Dakota",
              value: "Dakota",
              manufacturer: "Dodge"
            },
            {
              label: "Durango",
              value: "Durango",
              manufacturer: "Dodge"
            },
            {
              label: "Grand Caravan",
              value: "Grand_Caravan",
              manufacturer: "Dodge"
            },
            {
              label: "Intrepid",
              value: "Intrepid",
              manufacturer: "Dodge"
            },
            {
              label: "Journey",
              value: "Journey",
              manufacturer: "Dodge"
            },
            {
              label: "Magnum",
              value: "Magnum",
              manufacturer: "Dodge"
            },
            {
              label: "Neon",
              value: "Neon",
              manufacturer: "Dodge"
            },
            {
              label: "Nitro",
              value: "Nitro",
              manufacturer: "Dodge"
            },
            {
              label: "Ram",
              value: "Ram",
              manufacturer: "Dodge"
            },
            {
              label: "Ram 1500",
              value: "Ram_1500",
              manufacturer: "Dodge"
            },
            {
              label: "SRT-4",
              value: "SRT-4",
              manufacturer: "Dodge"
            },
            {
              label: "SX",
              value: "SX",
              manufacturer: "Dodge"
            },
            {
              label: "Spirit",
              value: "Spirit",
              manufacturer: "Dodge"
            },
            {
              label: "Stealth",
              value: "Stealth",
              manufacturer: "Dodge"
            },
            {
              label: "Stratus",
              value: "Stratus",
              manufacturer: "Dodge"
            },
            {
              label: "Viper",
              value: "Viper",
              manufacturer: "Dodge"
            },
            {
              label: "246",
              value: "246",
              manufacturer: "Ferrari"
            },
            {
              label: "250",
              value: "250",
              manufacturer: "Ferrari"
            },
            {
              label: "330",
              value: "330",
              manufacturer: "Ferrari"
            },
            {
              label: "348",
              value: "348",
              manufacturer: "Ferrari"
            },
            {
              label: "360",
              value: "360",
              manufacturer: "Ferrari"
            },
            {
              label: "456",
              value: "456",
              manufacturer: "Ferrari"
            },
            {
              label: "458",
              value: "458",
              manufacturer: "Ferrari"
            },
            {
              label: "512",
              value: "512",
              manufacturer: "Ferrari"
            },
            {
              label: "550",
              value: "550",
              manufacturer: "Ferrari"
            },
            {
              label: "550 Maranello",
              value: "550_Maranello",
              manufacturer: "Ferrari"
            },
            {
              label: "599",
              value: "599",
              manufacturer: "Ferrari"
            },
            {
              label: "612",
              value: "612",
              manufacturer: "Ferrari"
            },
            {
              label: "California",
              value: "California",
              manufacturer: "Ferrari"
            },
            {
              label: "Enzo",
              value: "Enzo",
              manufacturer: "Ferrari"
            },
            {
              label: "F 355",
              value: "F_355",
              manufacturer: "Ferrari"
            },
            {
              label: "F 430",
              value: "F_430",
              manufacturer: "Ferrari"
            },
            {
              label: "F12",
              value: "F12",
              manufacturer: "Ferrari"
            },
            {
              label: "FF",
              value: "FF",
              manufacturer: "Ferrari"
            },
            {
              label: "Mondial",
              value: "Mondial",
              manufacturer: "Ferrari"
            },
            {
              label: "Testarossa",
              value: "Testarossa",
              manufacturer: "Ferrari"
            },
            {
              label: "124 Spider",
              value: "124_Spider",
              manufacturer: "Fiat"
            },
            {
              label: "126",
              value: "126",
              manufacturer: "Fiat"
            },
            {
              label: "127",
              value: "127",
              manufacturer: "Fiat"
            },
            {
              label: "128",
              value: "128",
              manufacturer: "Fiat"
            },
            {
              label: "131",
              value: "131",
              manufacturer: "Fiat"
            },
            {
              label: "500",
              value: "500",
              manufacturer: "Fiat"
            },
            {
              label: "500 Abarth",
              value: "500_Abarth",
              manufacturer: "Fiat"
            },
            {
              label: "500E",
              value: "500E",
              manufacturer: "Fiat"
            },
            {
              label: "500L",
              value: "500L",
              manufacturer: "Fiat"
            },
            {
              label: "500X",
              value: "500X",
              manufacturer: "Fiat"
            },
            {
              label: "600",
              value: "600",
              manufacturer: "Fiat"
            },
            {
              label: "850",
              value: "850",
              manufacturer: "Fiat"
            },
            {
              label: "Barchetta",
              value: "Barchetta",
              manufacturer: "Fiat"
            },
            {
              label: "Brava",
              value: "Brava",
              manufacturer: "Fiat"
            },
            {
              label: "Bravo",
              value: "Bravo",
              manufacturer: "Fiat"
            },
            {
              label: "Cinquecento",
              value: "Cinquecento",
              manufacturer: "Fiat"
            },
            {
              label: "Coupé",
              value: "Coupe",
              manufacturer: "Fiat"
            },
            {
              label: "Croma",
              value: "Croma",
              manufacturer: "Fiat"
            },
            {
              label: "Dobló",
              value: "Doblo",
              manufacturer: "Fiat"
            },
            {
              label: "Ducato",
              value: "Ducato",
              manufacturer: "Fiat"
            },
            {
              label: "Fiorino",
              value: "Fiorino",
              manufacturer: "Fiat"
            },
            {
              label: "Fiorino Combi",
              value: "Fiorino_Combi",
              manufacturer: "Fiat"
            },
            {
              label: "Freemont",
              value: "Freemont",
              manufacturer: "Fiat"
            },
            {
              label: "Fullback",
              value: "Fullback",
              manufacturer: "Fiat"
            },
            {
              label: "Grande Punto",
              value: "Grande_Punto",
              manufacturer: "Fiat"
            },
            {
              label: "Idea",
              value: "Idea",
              manufacturer: "Fiat"
            },
            {
              label: "Linea",
              value: "Linea",
              manufacturer: "Fiat"
            },
            {
              label: "Marea",
              value: "Marea",
              manufacturer: "Fiat"
            },
            {
              label: "Multipla",
              value: "Multipla",
              manufacturer: "Fiat"
            },
            {
              label: "Palio",
              value: "Palio",
              manufacturer: "Fiat"
            },
            {
              label: "Panda",
              value: "Panda",
              manufacturer: "Fiat"
            },
            {
              label: "Punto",
              value: "Punto",
              manufacturer: "Fiat"
            },
            {
              label: "Punto EVO",
              value: "Punto_EVO",
              manufacturer: "Fiat"
            },
            {
              label: "Qubo",
              value: "Qubo",
              manufacturer: "Fiat"
            },
            {
              label: "Regata",
              value: "Regata",
              manufacturer: "Fiat"
            },
            {
              label: "Ritmo",
              value: "Ritmo",
              manufacturer: "Fiat"
            },
            {
              label: "Scudo",
              value: "Scudo",
              manufacturer: "Fiat"
            },
            {
              label: "Sedici",
              value: "Sedici",
              manufacturer: "Fiat"
            },
            {
              label: "Seicento",
              value: "Seicento",
              manufacturer: "Fiat"
            },
            {
              label: "Siena",
              value: "Siena",
              manufacturer: "Fiat"
            },
            {
              label: "Stilo",
              value: "Stilo",
              manufacturer: "Fiat"
            },
            {
              label: "Talento",
              value: "Talento",
              manufacturer: "Fiat"
            },
            {
              label: "Tempra",
              value: "Tempra",
              manufacturer: "Fiat"
            },
            {
              label: "Tipo",
              value: "Tipo",
              manufacturer: "Fiat"
            },
            {
              label: "Ulysse",
              value: "Ulysse",
              manufacturer: "Fiat"
            },
            {
              label: "Uno",
              value: "Uno",
              manufacturer: "Fiat"
            },
            {
              label: "X 1/9",
              value: "X_19",
              manufacturer: "Fiat"
            },
            {
              label: "B-MAX",
              value: "B-MAX",
              manufacturer: "Ford"
            },
            {
              label: "C-MAX",
              value: "C-MAX",
              manufacturer: "Ford"
            },
            {
              label: "Capri",
              value: "Capri",
              manufacturer: "Ford"
            },
            {
              label: "Connect",
              value: "Connect",
              manufacturer: "Ford"
            },
            {
              label: "Contour",
              value: "Contour",
              manufacturer: "Ford"
            },
            {
              label: "Cougar",
              value: "Cougar",
              manufacturer: "Ford"
            },
            {
              label: "Crown Victoria",
              value: "Crown_Victoria",
              manufacturer: "Ford"
            },
            {
              label: "Ecosport",
              value: "Ecosport",
              manufacturer: "Ford"
            },
            {
              label: "Edge",
              value: "Edge",
              manufacturer: "Ford"
            },
            {
              label: "Escape",
              value: "Escape",
              manufacturer: "Ford"
            },
            {
              label: "Escort",
              value: "Escort",
              manufacturer: "Ford"
            },
            {
              label: "Excursion",
              value: "Excursion",
              manufacturer: "Ford"
            },
            {
              label: "Expedition",
              value: "Expedition",
              manufacturer: "Ford"
            },
            {
              label: "Explorer",
              value: "Explorer",
              manufacturer: "Ford"
            },
            {
              label: "F-150",
              value: "F-150",
              manufacturer: "Ford"
            },
            {
              label: "Fiesta",
              value: "Fiesta",
              manufacturer: "Ford"
            },
            {
              label: "Flex",
              value: "Flex",
              manufacturer: "Ford"
            },
            {
              label: "Focus",
              value: "Focus",
              manufacturer: "Ford"
            },
            {
              label: "Freestar",
              value: "Freestar",
              manufacturer: "Ford"
            },
            {
              label: "Freestyle",
              value: "Freestyle",
              manufacturer: "Ford"
            },
            {
              label: "Fusion",
              value: "Fusion",
              manufacturer: "Ford"
            },
            {
              label: "GT",
              value: "GT",
              manufacturer: "Ford"
            },
            {
              label: "Galaxy",
              value: "Galaxy",
              manufacturer: "Ford"
            },
            {
              label: "Granada",
              value: "Granada",
              manufacturer: "Ford"
            },
            {
              label: "Grand C-MAX",
              value: "Grand_C-MAX",
              manufacturer: "Ford"
            },
            {
              label: "Grand Marquis",
              value: "Grand_Marquis",
              manufacturer: "Ford"
            },
            {
              label: "KA+",
              value: "KA",
              manufacturer: "Ford"
            },
            {
              label: "Ka",
              value: "Ka",
              manufacturer: "Ford"
            },
            {
              label: "Kuga",
              value: "Kuga",
              manufacturer: "Ford"
            },
            {
              label: "Marauder",
              value: "Marauder",
              manufacturer: "Ford"
            },
            {
              label: "Maverick",
              value: "Maverick",
              manufacturer: "Ford"
            },
            {
              label: "Mondeo",
              value: "Mondeo",
              manufacturer: "Ford"
            },
            {
              label: "Mustang",
              value: "Mustang",
              manufacturer: "Ford"
            },
            {
              label: "Orion",
              value: "Orion",
              manufacturer: "Ford"
            },
            {
              label: "Probe",
              value: "Probe",
              manufacturer: "Ford"
            },
            {
              label: "Puma",
              value: "Puma",
              manufacturer: "Ford"
            },
            {
              label: "Ranger",
              value: "Ranger",
              manufacturer: "Ford"
            },
            {
              label: "S-MAX",
              value: "S-MAX",
              manufacturer: "Ford"
            },
            {
              label: "Scorpio",
              value: "Scorpio",
              manufacturer: "Ford"
            },
            {
              label: "Sierra",
              value: "Sierra",
              manufacturer: "Ford"
            },
            {
              label: "Streetka",
              value: "Streetka",
              manufacturer: "Ford"
            },
            {
              label: "Taunus",
              value: "Taunus",
              manufacturer: "Ford"
            },
            {
              label: "Taurus",
              value: "Taurus",
              manufacturer: "Ford"
            },
            {
              label: "Thunderbird",
              value: "Thunderbird",
              manufacturer: "Ford"
            },
            {
              label: "Tourneo Connect",
              value: "Tourneo_Connect",
              manufacturer: "Ford"
            },
            {
              label: "Tourneo Courier",
              value: "Tourneo_Courier",
              manufacturer: "Ford"
            },
            {
              label: "Tourneo Custom",
              value: "Tourneo_Custom",
              manufacturer: "Ford"
            },
            {
              label: "Transit",
              value: "Transit",
              manufacturer: "Ford"
            },
            {
              label: "Windstar",
              value: "Windstar",
              manufacturer: "Ford"
            },
            {
              label: "Canyon",
              value: "Canyon",
              manufacturer: "GMC"
            },
            {
              label: "Envoy",
              value: "Envoy",
              manufacturer: "GMC"
            },
            {
              label: "Jimmy",
              value: "Jimmy",
              manufacturer: "GMC"
            },
            {
              label: "Safari",
              value: "Safari",
              manufacturer: "GMC"
            },
            {
              label: "Savana",
              value: "Savana",
              manufacturer: "GMC"
            },
            {
              label: "Sierra",
              value: "Sierra",
              manufacturer: "GMC"
            },
            {
              label: "Sonoma",
              value: "Sonoma",
              manufacturer: "GMC"
            },
            {
              label: "Suburban",
              value: "Suburban",
              manufacturer: "GMC"
            },
            {
              label: "Yukon",
              value: "Yukon",
              manufacturer: "GMC"
            },
            {
              label: "Roadster",
              value: "Roadster",
              manufacturer: "Gordon"
            },
            {
              label: "Accord",
              value: "Accord",
              manufacturer: "Honda"
            },
            {
              label: "CR-V",
              value: "CR-V",
              manufacturer: "Honda"
            },
            {
              label: "CR-Z",
              value: "CR-Z",
              manufacturer: "Honda"
            },
            {
              label: "CRX",
              value: "CRX",
              manufacturer: "Honda"
            },
            {
              label: "City",
              value: "City",
              manufacturer: "Honda"
            },
            {
              label: "Civic",
              value: "Civic",
              manufacturer: "Honda"
            },
            {
              label: "Concerto",
              value: "Concerto",
              manufacturer: "Honda"
            },
            {
              label: "Element",
              value: "Element",
              manufacturer: "Honda"
            },
            {
              label: "FR-V",
              value: "FR-V",
              manufacturer: "Honda"
            },
            {
              label: "HR-V",
              value: "HR-V",
              manufacturer: "Honda"
            },
            {
              label: "Insight",
              value: "Insight",
              manufacturer: "Honda"
            },
            {
              label: "Integra",
              value: "Integra",
              manufacturer: "Honda"
            },
            {
              label: "Jazz",
              value: "Jazz",
              manufacturer: "Honda"
            },
            {
              label: "Legend",
              value: "Legend",
              manufacturer: "Honda"
            },
            {
              label: "NSX",
              value: "NSX",
              manufacturer: "Honda"
            },
            {
              label: "Odyssey",
              value: "Odyssey",
              manufacturer: "Honda"
            },
            {
              label: "Pilot",
              value: "Pilot",
              manufacturer: "Honda"
            },
            {
              label: "Prelude",
              value: "Prelude",
              manufacturer: "Honda"
            },
            {
              label: "Ridgeline",
              value: "Ridgeline",
              manufacturer: "Honda"
            },
            {
              label: "S2000",
              value: "S2000",
              manufacturer: "Honda"
            },
            {
              label: "Shuttle",
              value: "Shuttle",
              manufacturer: "Honda"
            },
            {
              label: "Stream",
              value: "Stream",
              manufacturer: "Honda"
            },
            {
              label: "H1",
              value: "H1",
              manufacturer: "Hummer"
            },
            {
              label: "H1 Alpha",
              value: "H1_Alpha",
              manufacturer: "Hummer"
            },
            {
              label: "H2",
              value: "H2",
              manufacturer: "Hummer"
            },
            {
              label: "H2 SUT",
              value: "H2_SUT",
              manufacturer: "Hummer"
            },
            {
              label: "H3",
              value: "H3",
              manufacturer: "Hummer"
            },
            {
              label: "H3 Alpha",
              value: "H3_Alpha",
              manufacturer: "Hummer"
            },
            {
              label: "H3T",
              value: "H3T",
              manufacturer: "Hummer"
            },
            {
              label: "H3x",
              value: "H3x",
              manufacturer: "Hummer"
            },
            {
              label: "Accent",
              value: "Accent",
              manufacturer: "Hyundai"
            },
            {
              label: "Atos",
              value: "Atos",
              manufacturer: "Hyundai"
            },
            {
              label: "Coupé",
              value: "Coupe",
              manufacturer: "Hyundai"
            },
            {
              label: "Elantra",
              value: "Elantra",
              manufacturer: "Hyundai"
            },
            {
              label: "Equus",
              value: "Equus",
              manufacturer: "Hyundai"
            },
            {
              label: "Galloper",
              value: "Galloper",
              manufacturer: "Hyundai"
            },
            {
              label: "Genesis",
              value: "Genesis",
              manufacturer: "Hyundai"
            },
            {
              label: "Getz",
              value: "Getz",
              manufacturer: "Hyundai"
            },
            {
              label: "Grandeur",
              value: "Grandeur",
              manufacturer: "Hyundai"
            },
            {
              label: "IONIQ",
              value: "IONIQ",
              manufacturer: "Hyundai"
            },
            {
              label: "Kona",
              value: "Kona",
              manufacturer: "Hyundai"
            },
            {
              label: "Lantra",
              value: "Lantra",
              manufacturer: "Hyundai"
            },
            {
              label: "Matrix",
              value: "Matrix",
              manufacturer: "Hyundai"
            },
            {
              label: "Pony",
              value: "Pony",
              manufacturer: "Hyundai"
            },
            {
              label: "Santa Fe",
              value: "Santa_Fe",
              manufacturer: "Hyundai"
            },
            {
              label: "Santamo",
              value: "Santamo",
              manufacturer: "Hyundai"
            },
            {
              label: "Scoupé",
              value: "Scoupe",
              manufacturer: "Hyundai"
            },
            {
              label: "Sonata",
              value: "Sonata",
              manufacturer: "Hyundai"
            },
            {
              label: "Terracan",
              value: "Terracan",
              manufacturer: "Hyundai"
            },
            {
              label: "Trajet",
              value: "Trajet",
              manufacturer: "Hyundai"
            },
            {
              label: "Tucson",
              value: "Tucson",
              manufacturer: "Hyundai"
            },
            {
              label: "Veloster",
              value: "Veloster",
              manufacturer: "Hyundai"
            },
            {
              label: "XG",
              value: "XG",
              manufacturer: "Hyundai"
            },
            {
              label: "i10",
              value: "i10",
              manufacturer: "Hyundai"
            },
            {
              label: "i20",
              value: "i20",
              manufacturer: "Hyundai"
            },
            {
              label: "i30",
              value: "i30",
              manufacturer: "Hyundai"
            },
            {
              label: "i40",
              value: "i40",
              manufacturer: "Hyundai"
            },
            {
              label: "ix20",
              value: "ix20",
              manufacturer: "Hyundai"
            },
            {
              label: "ix35",
              value: "ix35",
              manufacturer: "Hyundai"
            },
            {
              label: "ix55",
              value: "ix55",
              manufacturer: "Hyundai"
            },
            {
              label: "EX30",
              value: "EX30",
              manufacturer: "Infiniti"
            },
            {
              label: "EX35",
              value: "EX35",
              manufacturer: "Infiniti"
            },
            {
              label: "EX37",
              value: "EX37",
              manufacturer: "Infiniti"
            },
            {
              label: "FX30",
              value: "FX30",
              manufacturer: "Infiniti"
            },
            {
              label: "FX35",
              value: "FX35",
              manufacturer: "Infiniti"
            },
            {
              label: "FX37",
              value: "FX37",
              manufacturer: "Infiniti"
            },
            {
              label: "FX45",
              value: "FX45",
              manufacturer: "Infiniti"
            },
            {
              label: "FX50",
              value: "FX50",
              manufacturer: "Infiniti"
            },
            {
              label: "G",
              value: "G",
              manufacturer: "Infiniti"
            },
            {
              label: "G20",
              value: "G20",
              manufacturer: "Infiniti"
            },
            {
              label: "G35",
              value: "G35",
              manufacturer: "Infiniti"
            },
            {
              label: "G37",
              value: "G37",
              manufacturer: "Infiniti"
            },
            {
              label: "I30",
              value: "I30",
              manufacturer: "Infiniti"
            },
            {
              label: "I35",
              value: "I35",
              manufacturer: "Infiniti"
            },
            {
              label: "M30",
              value: "M30",
              manufacturer: "Infiniti"
            },
            {
              label: "M35",
              value: "M35",
              manufacturer: "Infiniti"
            },
            {
              label: "M37",
              value: "M37",
              manufacturer: "Infiniti"
            },
            {
              label: "M45",
              value: "M45",
              manufacturer: "Infiniti"
            },
            {
              label: "Q30",
              value: "Q30",
              manufacturer: "Infiniti"
            },
            {
              label: "Q45",
              value: "Q45",
              manufacturer: "Infiniti"
            },
            {
              label: "Q50",
              value: "Q50",
              manufacturer: "Infiniti"
            },
            {
              label: "Q60",
              value: "Q60",
              manufacturer: "Infiniti"
            },
            {
              label: "Q70",
              value: "Q70",
              manufacturer: "Infiniti"
            },
            {
              label: "QX30",
              value: "QX30",
              manufacturer: "Infiniti"
            },
            {
              label: "QX4",
              value: "QX4",
              manufacturer: "Infiniti"
            },
            {
              label: "QX50",
              value: "QX50",
              manufacturer: "Infiniti"
            },
            {
              label: "QX56",
              value: "QX56",
              manufacturer: "Infiniti"
            },
            {
              label: "QX70",
              value: "QX70",
              manufacturer: "Infiniti"
            },
            {
              label: "QX80",
              value: "QX80",
              manufacturer: "Infiniti"
            },
            {
              label: "D-Max",
              value: "D-Max",
              manufacturer: "Isuzu"
            },
            {
              label: "Gemini",
              value: "Gemini",
              manufacturer: "Isuzu"
            },
            {
              label: "Piazza",
              value: "Piazza",
              manufacturer: "Isuzu"
            },
            {
              label: "Trooper",
              value: "Trooper",
              manufacturer: "Isuzu"
            },
            {
              label: "Massif",
              value: "Massif",
              manufacturer: "Iveco"
            },
            {
              label: "IEV7S",
              value: "IEV7S",
              manufacturer: "JAC"
            },
            {
              label: "Daimler",
              value: "Daimler",
              manufacturer: "Jaguar"
            },
            {
              label: "E-Pace",
              value: "E-Pace",
              manufacturer: "Jaguar"
            },
            {
              label: "F-Pace",
              value: "F-Pace",
              manufacturer: "Jaguar"
            },
            {
              label: "F-Type",
              value: "F-Type",
              manufacturer: "Jaguar"
            },
            {
              label: "I-Pace",
              value: "I-Pace",
              manufacturer: "Jaguar"
            },
            {
              label: "S-Type",
              value: "S-Type",
              manufacturer: "Jaguar"
            },
            {
              label: "X-Type",
              value: "X-Type",
              manufacturer: "Jaguar"
            },
            {
              label: "XE",
              value: "XE",
              manufacturer: "Jaguar"
            },
            {
              label: "XF",
              value: "XF",
              manufacturer: "Jaguar"
            },
            {
              label: "XJ",
              value: "XJ",
              manufacturer: "Jaguar"
            },
            {
              label: "XJ6",
              value: "XJ6",
              manufacturer: "Jaguar"
            },
            {
              label: "XJ8",
              value: "XJ8",
              manufacturer: "Jaguar"
            },
            {
              label: "XJR",
              value: "XJR",
              manufacturer: "Jaguar"
            },
            {
              label: "XJS",
              value: "XJS",
              manufacturer: "Jaguar"
            },
            {
              label: "XK",
              value: "XK",
              manufacturer: "Jaguar"
            },
            {
              label: "XK8",
              value: "XK8",
              manufacturer: "Jaguar"
            },
            {
              label: "XKR",
              value: "XKR",
              manufacturer: "Jaguar"
            },
            {
              label: "Cherokee",
              value: "Cherokee",
              manufacturer: "Jeep"
            },
            {
              label: "Commander",
              value: "Commander",
              manufacturer: "Jeep"
            },
            {
              label: "Compass",
              value: "Compass",
              manufacturer: "Jeep"
            },
            {
              label: "Grand Cherokee",
              value: "Grand_Cherokee",
              manufacturer: "Jeep"
            },
            {
              label: "Liberty",
              value: "Liberty",
              manufacturer: "Jeep"
            },
            {
              label: "Patriot",
              value: "Patriot",
              manufacturer: "Jeep"
            },
            {
              label: "Renegade",
              value: "Renegade",
              manufacturer: "Jeep"
            },
            {
              label: "Scrambler",
              value: "Scrambler",
              manufacturer: "Jeep"
            },
            {
              label: "Wrangler",
              value: "Wrangler",
              manufacturer: "Jeep"
            },
            {
              label: "Wrangler Rubicon",
              value: "Wrangler_Rubicon",
              manufacturer: "Jeep"
            },
            {
              label: "Wrangler Sahara",
              value: "Wrangler_Sahara",
              manufacturer: "Jeep"
            },
            {
              label: "Carens",
              value: "Carens",
              manufacturer: "Kia"
            },
            {
              label: "Carnival",
              value: "Carnival",
              manufacturer: "Kia"
            },
            {
              label: "Cee´d",
              value: "Ceed",
              manufacturer: "Kia"
            },
            {
              label: "Cerato",
              value: "Cerato",
              manufacturer: "Kia"
            },
            {
              label: "Clarus",
              value: "Clarus",
              manufacturer: "Kia"
            },
            {
              label: "Joice",
              value: "Joice",
              manufacturer: "Kia"
            },
            {
              label: "Magentis",
              value: "Magentis",
              manufacturer: "Kia"
            },
            {
              label: "Niro",
              value: "Niro",
              manufacturer: "Kia"
            },
            {
              label: "Opirus",
              value: "Opirus",
              manufacturer: "Kia"
            },
            {
              label: "Optima",
              value: "Optima",
              manufacturer: "Kia"
            },
            {
              label: "Picanto",
              value: "Picanto",
              manufacturer: "Kia"
            },
            {
              label: "Pride",
              value: "Pride",
              manufacturer: "Kia"
            },
            {
              label: "ProCeed",
              value: "ProCeed",
              manufacturer: "Kia"
            },
            {
              label: "Pro_cee´d",
              value: "Pro_ceed",
              manufacturer: "Kia"
            },
            {
              label: "Retona",
              value: "Retona",
              manufacturer: "Kia"
            },
            {
              label: "Rio",
              value: "Rio",
              manufacturer: "Kia"
            },
            {
              label: "Sephia",
              value: "Sephia",
              manufacturer: "Kia"
            },
            {
              label: "Shuma",
              value: "Shuma",
              manufacturer: "Kia"
            },
            {
              label: "Sorento",
              value: "Sorento",
              manufacturer: "Kia"
            },
            {
              label: "Soul",
              value: "Soul",
              manufacturer: "Kia"
            },
            {
              label: "Sportage",
              value: "Sportage",
              manufacturer: "Kia"
            },
            {
              label: "Stinger",
              value: "Stinger",
              manufacturer: "Kia"
            },
            {
              label: "Stonic",
              value: "Stonic",
              manufacturer: "Kia"
            },
            {
              label: "Venga",
              value: "Venga",
              manufacturer: "Kia"
            },
            {
              label: "XCEED",
              value: "XCEED",
              manufacturer: "Kia"
            },
            {
              label: "1200",
              value: "1200",
              manufacturer: "Lada"
            },
            {
              label: "1500",
              value: "1500",
              manufacturer: "Lada"
            },
            {
              label: "2101",
              value: "2101",
              manufacturer: "Lada"
            },
            {
              label: "2102",
              value: "2102",
              manufacturer: "Lada"
            },
            {
              label: "2103",
              value: "2103",
              manufacturer: "Lada"
            },
            {
              label: "2104",
              value: "2104",
              manufacturer: "Lada"
            },
            {
              label: "21044",
              value: "21044",
              manufacturer: "Lada"
            },
            {
              label: "2105",
              value: "2105",
              manufacturer: "Lada"
            },
            {
              label: "2106",
              value: "2106",
              manufacturer: "Lada"
            },
            {
              label: "2107",
              value: "2107",
              manufacturer: "Lada"
            },
            {
              label: "2110",
              value: "2110",
              manufacturer: "Lada"
            },
            {
              label: "2111",
              value: "2111",
              manufacturer: "Lada"
            },
            {
              label: "2112",
              value: "2112",
              manufacturer: "Lada"
            },
            {
              label: "Kalina",
              value: "Kalina",
              manufacturer: "Lada"
            },
            {
              label: "Niva",
              value: "Niva",
              manufacturer: "Lada"
            },
            {
              label: "Nova",
              value: "Nova",
              manufacturer: "Lada"
            },
            {
              label: "Samara",
              value: "Samara",
              manufacturer: "Lada"
            },
            {
              label: "Aventador",
              value: "Aventador",
              manufacturer: "Lamborghini"
            },
            {
              label: "Countach",
              value: "Countach",
              manufacturer: "Lamborghini"
            },
            {
              label: "Gallardo",
              value: "Gallardo",
              manufacturer: "Lamborghini"
            },
            {
              label: "Huracán",
              value: "Huracan",
              manufacturer: "Lamborghini"
            },
            {
              label: "Miura",
              value: "Miura",
              manufacturer: "Lamborghini"
            },
            {
              label: "Murciélago",
              value: "Murcielago",
              manufacturer: "Lamborghini"
            },
            {
              label: "Urus",
              value: "Urus",
              manufacturer: "Lamborghini"
            },
            {
              label: "Dedra",
              value: "Dedra",
              manufacturer: "Lancia"
            },
            {
              label: "Delta",
              value: "Delta",
              manufacturer: "Lancia"
            },
            {
              label: "Flavia",
              value: "Flavia",
              manufacturer: "Lancia"
            },
            {
              label: "Kappa",
              value: "Kappa",
              manufacturer: "Lancia"
            },
            {
              label: "Lybra",
              value: "Lybra",
              manufacturer: "Lancia"
            },
            {
              label: "Musa",
              value: "Musa",
              manufacturer: "Lancia"
            },
            {
              label: "Phedra",
              value: "Phedra",
              manufacturer: "Lancia"
            },
            {
              label: "Prisma",
              value: "Prisma",
              manufacturer: "Lancia"
            },
            {
              label: "Thema",
              value: "Thema",
              manufacturer: "Lancia"
            },
            {
              label: "Thesis",
              value: "Thesis",
              manufacturer: "Lancia"
            },
            {
              label: "Voyager",
              value: "Voyager",
              manufacturer: "Lancia"
            },
            {
              label: "Y",
              value: "Y",
              manufacturer: "Lancia"
            },
            {
              label: "Z",
              value: "Z",
              manufacturer: "Lancia"
            },
            {
              label: "Defender",
              value: "Defender",
              manufacturer: "Land_Rover"
            },
            {
              label: "Discovery",
              value: "Discovery",
              manufacturer: "Land_Rover"
            },
            {
              label: "Discovery Sport",
              value: "Discovery_Sport",
              manufacturer: "Land_Rover"
            },
            {
              label: "Freelander",
              value: "Freelander",
              manufacturer: "Land_Rover"
            },
            {
              label: "Range Rover",
              value: "Range_Rover",
              manufacturer: "Land_Rover"
            },
            {
              label: "Range Rover Evoque",
              value: "Range_Rover_Evoque",
              manufacturer: "Land_Rover"
            },
            {
              label: "Range Rover Sport",
              value: "Range_Rover_Sport",
              manufacturer: "Land_Rover"
            },
            {
              label: "Range Rover Velar",
              value: "Range_Rover_Velar",
              manufacturer: "Land_Rover"
            },
            {
              label: "200",
              value: "200",
              manufacturer: "Lexus"
            },
            {
              label: "300",
              value: "300",
              manufacturer: "Lexus"
            },
            {
              label: "400",
              value: "400",
              manufacturer: "Lexus"
            },
            {
              label: "CT 200h",
              value: "CT_200h",
              manufacturer: "Lexus"
            },
            {
              label: "ES",
              value: "ES",
              manufacturer: "Lexus"
            },
            {
              label: "ES 300h",
              value: "ES_300h",
              manufacturer: "Lexus"
            },
            {
              label: "ES 330",
              value: "ES_330",
              manufacturer: "Lexus"
            },
            {
              label: "GS",
              value: "GS",
              manufacturer: "Lexus"
            },
            {
              label: "GS 300",
              value: "GS_300",
              manufacturer: "Lexus"
            },
            {
              label: "GS 450h",
              value: "GS_450h",
              manufacturer: "Lexus"
            },
            {
              label: "GX 460",
              value: "GX_460",
              manufacturer: "Lexus"
            },
            {
              label: "GX 470",
              value: "GX_470",
              manufacturer: "Lexus"
            },
            {
              label: "IS",
              value: "IS",
              manufacturer: "Lexus"
            },
            {
              label: "IS 200",
              value: "IS_200",
              manufacturer: "Lexus"
            },
            {
              label: "IS 220d",
              value: "IS_220d",
              manufacturer: "Lexus"
            },
            {
              label: "IS 250",
              value: "IS_250",
              manufacturer: "Lexus"
            },
            {
              label: "IS 250C",
              value: "IS_250C",
              manufacturer: "Lexus"
            },
            {
              label: "IS 350",
              value: "IS_350",
              manufacturer: "Lexus"
            },
            {
              label: "IS F",
              value: "IS_F",
              manufacturer: "Lexus"
            },
            {
              label: "LC 500",
              value: "LC_500",
              manufacturer: "Lexus"
            },
            {
              label: "LS",
              value: "LS",
              manufacturer: "Lexus"
            },
            {
              label: "LS 430",
              value: "LS_430",
              manufacturer: "Lexus"
            },
            {
              label: "LS 460",
              value: "LS_460",
              manufacturer: "Lexus"
            },
            {
              label: "LS 460 AWD",
              value: "LS_460_AWD",
              manufacturer: "Lexus"
            },
            {
              label: "LS 600h",
              value: "LS_600h",
              manufacturer: "Lexus"
            },
            {
              label: "LS 600h L",
              value: "LS_600h_L",
              manufacturer: "Lexus"
            },
            {
              label: "LX 470",
              value: "LX_470",
              manufacturer: "Lexus"
            },
            {
              label: "LX 570",
              value: "LX_570",
              manufacturer: "Lexus"
            },
            {
              label: "NX 300h",
              value: "NX_300h",
              manufacturer: "Lexus"
            },
            {
              label: "RC",
              value: "RC",
              manufacturer: "Lexus"
            },
            {
              label: "RX",
              value: "RX",
              manufacturer: "Lexus"
            },
            {
              label: "RX 300",
              value: "RX_300",
              manufacturer: "Lexus"
            },
            {
              label: "RX 330",
              value: "RX_330",
              manufacturer: "Lexus"
            },
            {
              label: "RX 350",
              value: "RX_350",
              manufacturer: "Lexus"
            },
            {
              label: "RX 400",
              value: "RX_400",
              manufacturer: "Lexus"
            },
            {
              label: "RX 400h",
              value: "RX_400h",
              manufacturer: "Lexus"
            },
            {
              label: "RX 450 FWD",
              value: "RX_450_FWD",
              manufacturer: "Lexus"
            },
            {
              label: "RX 450h",
              value: "RX_450h",
              manufacturer: "Lexus"
            },
            {
              label: "RX 450h L",
              value: "RX_450h_L",
              manufacturer: "Lexus"
            },
            {
              label: "RX Hybrid",
              value: "RX_Hybrid",
              manufacturer: "Lexus"
            },
            {
              label: "SC",
              value: "SC",
              manufacturer: "Lexus"
            },
            {
              label: "UX 200",
              value: "UX_200",
              manufacturer: "Lexus"
            },
            {
              label: "UX 250h",
              value: "UX_250h",
              manufacturer: "Lexus"
            },
            {
              label: "Ambra",
              value: "Ambra",
              manufacturer: "Ligier"
            },
            {
              label: "JS 50",
              value: "JS_50",
              manufacturer: "Ligier"
            },
            {
              label: "JS 50 L",
              value: "JS_50_L",
              manufacturer: "Ligier"
            },
            {
              label: "JS RC",
              value: "JS_RC",
              manufacturer: "Ligier"
            },
            {
              label: "Nova",
              value: "Nova",
              manufacturer: "Ligier"
            },
            {
              label: "X Too Max",
              value: "X_Too_Max",
              manufacturer: "Ligier"
            },
            {
              label: "Aviator",
              value: "Aviator",
              manufacturer: "Lincoln"
            },
            {
              label: "Continental",
              value: "Continental",
              manufacturer: "Lincoln"
            },
            {
              label: "LS",
              value: "LS",
              manufacturer: "Lincoln"
            },
            {
              label: "MKS",
              value: "MKS",
              manufacturer: "Lincoln"
            },
            {
              label: "MKT",
              value: "MKT",
              manufacturer: "Lincoln"
            },
            {
              label: "MKX",
              value: "MKX",
              manufacturer: "Lincoln"
            },
            {
              label: "MKZ",
              value: "MKZ",
              manufacturer: "Lincoln"
            },
            {
              label: "Navigator",
              value: "Navigator",
              manufacturer: "Lincoln"
            },
            {
              label: "Town Car",
              value: "Town_Car",
              manufacturer: "Lincoln"
            },
            {
              label: "Elise",
              value: "Elise",
              manufacturer: "Lotus"
            },
            {
              label: "Esprit",
              value: "Esprit",
              manufacturer: "Lotus"
            },
            {
              label: "Smart",
              value: "Smart",
              manufacturer: "MCC"
            },
            {
              label: "F",
              value: "F",
              manufacturer: "MG"
            },
            {
              label: "TF",
              value: "TF",
              manufacturer: "MG"
            },
            {
              label: "ZR",
              value: "ZR",
              manufacturer: "MG"
            },
            {
              label: "ZS",
              value: "ZS",
              manufacturer: "MG"
            },
            {
              label: "ZT",
              value: "ZT",
              manufacturer: "MG"
            },
            {
              label: "3200",
              value: "3200",
              manufacturer: "Maserati"
            },
            {
              label: "Coupe",
              value: "Coupe",
              manufacturer: "Maserati"
            },
            {
              label: "Ghibli",
              value: "Ghibli",
              manufacturer: "Maserati"
            },
            {
              label: "GranCabrio",
              value: "GranCabrio",
              manufacturer: "Maserati"
            },
            {
              label: "GranTurismo",
              value: "GranTurismo",
              manufacturer: "Maserati"
            },
            {
              label: "Levante",
              value: "Levante",
              manufacturer: "Maserati"
            },
            {
              label: "Maybach",
              value: "Maybach",
              manufacturer: "Maserati"
            },
            {
              label: "Quattroporte",
              value: "Quattroporte",
              manufacturer: "Maserati"
            },
            {
              label: "121",
              value: "121",
              manufacturer: "Mazda"
            },
            {
              label: "2",
              value: "2",
              manufacturer: "Mazda"
            },
            {
              label: "3",
              value: "3",
              manufacturer: "Mazda"
            },
            {
              label: "323",
              value: "323",
              manufacturer: "Mazda"
            },
            {
              label: "5",
              value: "5",
              manufacturer: "Mazda"
            },
            {
              label: "6",
              value: "6",
              manufacturer: "Mazda"
            },
            {
              label: "626",
              value: "626",
              manufacturer: "Mazda"
            },
            {
              label: "929",
              value: "929",
              manufacturer: "Mazda"
            },
            {
              label: "BT",
              value: "BT",
              manufacturer: "Mazda"
            },
            {
              label: "CX",
              value: "CX",
              manufacturer: "Mazda"
            },
            {
              label: "CX-3",
              value: "CX-3",
              manufacturer: "Mazda"
            },
            {
              label: "CX-30",
              value: "CX-30",
              manufacturer: "Mazda"
            },
            {
              label: "CX-5",
              value: "CX-5",
              manufacturer: "Mazda"
            },
            {
              label: "CX-7",
              value: "CX-7",
              manufacturer: "Mazda"
            },
            {
              label: "CX-9",
              value: "CX-9",
              manufacturer: "Mazda"
            },
            {
              label: "Demio",
              value: "Demio",
              manufacturer: "Mazda"
            },
            {
              label: "MPV",
              value: "MPV",
              manufacturer: "Mazda"
            },
            {
              label: "MX",
              value: "MX",
              manufacturer: "Mazda"
            },
            {
              label: "MX-3",
              value: "MX-3",
              manufacturer: "Mazda"
            },
            {
              label: "MX-5",
              value: "MX-5",
              manufacturer: "Mazda"
            },
            {
              label: "MX-6",
              value: "MX-6",
              manufacturer: "Mazda"
            },
            {
              label: "Premacy",
              value: "Premacy",
              manufacturer: "Mazda"
            },
            {
              label: "RX-7",
              value: "RX-7",
              manufacturer: "Mazda"
            },
            {
              label: "RX-8",
              value: "RX-8",
              manufacturer: "Mazda"
            },
            {
              label: "Tribute",
              value: "Tribute",
              manufacturer: "Mazda"
            },
            {
              label: "Xedos",
              value: "Xedos",
              manufacturer: "Mazda"
            },
            {
              label: "650S Coupé",
              value: "650S_Coupe",
              manufacturer: "McLaren"
            },
            {
              label: "650S Spider",
              value: "650S_Spider",
              manufacturer: "McLaren"
            },
            {
              label: "MP4-12C",
              value: "MP4-12C",
              manufacturer: "McLaren"
            },
            {
              label: "115",
              value: "115",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "116",
              value: "116",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "123",
              value: "123",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "124",
              value: "124",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "126",
              value: "126",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "140",
              value: "140",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "170",
              value: "170",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "190",
              value: "190",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "20",
              value: "20",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "208",
              value: "208",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "209",
              value: "209",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "210",
              value: "210",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "211",
              value: "211",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "215",
              value: "215",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "220",
              value: "220",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "AMG GT",
              value: "AMG_GT",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "CL",
              value: "CL",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "CLA",
              value: "CLA",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "CLC",
              value: "CLC",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "CLK",
              value: "CLK",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "CLS",
              value: "CLS",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Citan",
              value: "Citan",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "GL",
              value: "GL",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "GL 320",
              value: "GL_320",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "GL 350",
              value: "GL_350",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "GLA",
              value: "GLA",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "GLC",
              value: "GLC",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "GLE",
              value: "GLE",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "GLK",
              value: "GLK",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "GLS",
              value: "GLS",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Maybach",
              value: "Maybach",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "SL",
              value: "SL",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "SLC",
              value: "SLC",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "SLK",
              value: "SLK",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "SLR",
              value: "SLR",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "SLS AMG",
              value: "SLS_AMG",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Sprinter",
              value: "Sprinter",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Třídy A",
              value: "Tridy_A",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Třídy B",
              value: "Tridy_B",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Třídy C",
              value: "Tridy_C",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Třídy E",
              value: "Tridy_E",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Třídy G",
              value: "Tridy_G",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Třídy M",
              value: "Tridy_M",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Třídy R",
              value: "Tridy_R",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Třídy S",
              value: "Tridy_S",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Třídy V",
              value: "Tridy_V",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Třídy X",
              value: "Tridy_X",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Vaneo",
              value: "Vaneo",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Viano",
              value: "Viano",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "Vito",
              value: "Vito",
              manufacturer: "Mercedes-Benz"
            },
            {
              label: "DUE",
              value: "DUE",
              manufacturer: "Microcar"
            },
            {
              label: "Lyra",
              value: "Lyra",
              manufacturer: "Microcar"
            },
            {
              label: "M:GO",
              value: "MGO",
              manufacturer: "Microcar"
            },
            {
              label: "MC1",
              value: "MC1",
              manufacturer: "Microcar"
            },
            {
              label: "MC2",
              value: "MC2",
              manufacturer: "Microcar"
            },
            {
              label: "MGO",
              value: "MGO",
              manufacturer: "Microcar"
            },
            {
              label: "Virgo",
              value: "Virgo",
              manufacturer: "Microcar"
            },
            {
              label: "Clubman",
              value: "Clubman",
              manufacturer: "Mini"
            },
            {
              label: "Cooper",
              value: "Cooper",
              manufacturer: "Mini"
            },
            {
              label: "Cooper S",
              value: "Cooper_S",
              manufacturer: "Mini"
            },
            {
              label: "Countryman",
              value: "Countryman",
              manufacturer: "Mini"
            },
            {
              label: "New Mini",
              value: "New_Mini",
              manufacturer: "Mini"
            },
            {
              label: "One",
              value: "One",
              manufacturer: "Mini"
            },
            {
              label: "Paceman",
              value: "Paceman",
              manufacturer: "Mini"
            },
            {
              label: "3000 GT",
              value: "3000_GT",
              manufacturer: "Mitsubishi"
            },
            {
              label: "ASX",
              value: "ASX",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Carisma",
              value: "Carisma",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Colt",
              value: "Colt",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Eclipse",
              value: "Eclipse",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Eclipse Cross",
              value: "Eclipse_Cross",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Galant",
              value: "Galant",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Grandis",
              value: "Grandis",
              manufacturer: "Mitsubishi"
            },
            {
              label: "L200",
              value: "L200",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Lancer",
              value: "Lancer",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Outlander",
              value: "Outlander",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Pajero",
              value: "Pajero",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Pajero Pinin",
              value: "Pajero_Pinin",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Pajero Sport",
              value: "Pajero_Sport",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Sigma",
              value: "Sigma",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Space",
              value: "Space",
              manufacturer: "Mitsubishi"
            },
            {
              label: "Space Star",
              value: "Space_Star",
              manufacturer: "Mitsubishi"
            },
            {
              label: "SpaceWagon",
              value: "SpaceWagon",
              manufacturer: "Mitsubishi"
            },
            {
              label: "i-MiEV",
              value: "i-MiEV",
              manufacturer: "Mitsubishi"
            },
            {
              label: "100 NX",
              value: "100_NX",
              manufacturer: "Nissan"
            },
            {
              label: "200 SX",
              value: "200_SX",
              manufacturer: "Nissan"
            },
            {
              label: "240 SX",
              value: "240_SX",
              manufacturer: "Nissan"
            },
            {
              label: "280 ZX",
              value: "280_ZX",
              manufacturer: "Nissan"
            },
            {
              label: "300 ZX",
              value: "300_ZX",
              manufacturer: "Nissan"
            },
            {
              label: "350 Z",
              value: "350_Z",
              manufacturer: "Nissan"
            },
            {
              label: "370 Z",
              value: "370_Z",
              manufacturer: "Nissan"
            },
            {
              label: "Almera",
              value: "Almera",
              manufacturer: "Nissan"
            },
            {
              label: "Almera Tino",
              value: "Almera_Tino",
              manufacturer: "Nissan"
            },
            {
              label: "Altima",
              value: "Altima",
              manufacturer: "Nissan"
            },
            {
              label: "Armada",
              value: "Armada",
              manufacturer: "Nissan"
            },
            {
              label: "Bluebird",
              value: "Bluebird",
              manufacturer: "Nissan"
            },
            {
              label: "Cherry",
              value: "Cherry",
              manufacturer: "Nissan"
            },
            {
              label: "Cube",
              value: "Cube",
              manufacturer: "Nissan"
            },
            {
              label: "Double cab",
              value: "Double_cab",
              manufacturer: "Nissan"
            },
            {
              label: "Evalia",
              value: "Evalia",
              manufacturer: "Nissan"
            },
            {
              label: "Frontier",
              value: "Frontier",
              manufacturer: "Nissan"
            },
            {
              label: "GT-R",
              value: "GT-R",
              manufacturer: "Nissan"
            },
            {
              label: "Juke",
              value: "Juke",
              manufacturer: "Nissan"
            },
            {
              label: "King Cab",
              value: "King_Cab",
              manufacturer: "Nissan"
            },
            {
              label: "LEAF",
              value: "LEAF",
              manufacturer: "Nissan"
            },
            {
              label: "Laurel",
              value: "Laurel",
              manufacturer: "Nissan"
            },
            {
              label: "Maxima",
              value: "Maxima",
              manufacturer: "Nissan"
            },
            {
              label: "Micra",
              value: "Micra",
              manufacturer: "Nissan"
            },
            {
              label: "Murano",
              value: "Murano",
              manufacturer: "Nissan"
            },
            {
              label: "NV200",
              value: "NV200",
              manufacturer: "Nissan"
            },
            {
              label: "Navara",
              value: "Navara",
              manufacturer: "Nissan"
            },
            {
              label: "Note",
              value: "Note",
              manufacturer: "Nissan"
            },
            {
              label: "Pathfinder",
              value: "Pathfinder",
              manufacturer: "Nissan"
            },
            {
              label: "Pathfinder Armada",
              value: "Pathfinder_Armada",
              manufacturer: "Nissan"
            },
            {
              label: "Patrol",
              value: "Patrol",
              manufacturer: "Nissan"
            },
            {
              label: "Pixo",
              value: "Pixo",
              manufacturer: "Nissan"
            },
            {
              label: "Prairie",
              value: "Prairie",
              manufacturer: "Nissan"
            },
            {
              label: "Primastar",
              value: "Primastar",
              manufacturer: "Nissan"
            },
            {
              label: "Primera",
              value: "Primera",
              manufacturer: "Nissan"
            },
            {
              label: "Pulsar",
              value: "Pulsar",
              manufacturer: "Nissan"
            },
            {
              label: "Qashqai",
              value: "Qashqai",
              manufacturer: "Nissan"
            },
            {
              label: "Quest",
              value: "Quest",
              manufacturer: "Nissan"
            },
            {
              label: "Rogue",
              value: "Rogue",
              manufacturer: "Nissan"
            },
            {
              label: "Sentra",
              value: "Sentra",
              manufacturer: "Nissan"
            },
            {
              label: "Serena",
              value: "Serena",
              manufacturer: "Nissan"
            },
            {
              label: "Silvia",
              value: "Silvia",
              manufacturer: "Nissan"
            },
            {
              label: "Stanza",
              value: "Stanza",
              manufacturer: "Nissan"
            },
            {
              label: "Sunny",
              value: "Sunny",
              manufacturer: "Nissan"
            },
            {
              label: "Terrano",
              value: "Terrano",
              manufacturer: "Nissan"
            },
            {
              label: "Terrano II",
              value: "Terrano_II",
              manufacturer: "Nissan"
            },
            {
              label: "Tiida",
              value: "Tiida",
              manufacturer: "Nissan"
            },
            {
              label: "Titan",
              value: "Titan",
              manufacturer: "Nissan"
            },
            {
              label: "X-Trail",
              value: "X-Trail",
              manufacturer: "Nissan"
            },
            {
              label: "Xterra",
              value: "Xterra",
              manufacturer: "Nissan"
            },
            {
              label: "e-NV200",
              value: "e-NV200",
              manufacturer: "Nissan"
            },
            {
              label: "Adam",
              value: "Adam",
              manufacturer: "Opel"
            },
            {
              label: "Agila",
              value: "Agila",
              manufacturer: "Opel"
            },
            {
              label: "Ampera",
              value: "Ampera",
              manufacturer: "Opel"
            },
            {
              label: "Antara",
              value: "Antara",
              manufacturer: "Opel"
            },
            {
              label: "Ascona",
              value: "Ascona",
              manufacturer: "Opel"
            },
            {
              label: "Astra",
              value: "Astra",
              manufacturer: "Opel"
            },
            {
              label: "Calibra",
              value: "Calibra",
              manufacturer: "Opel"
            },
            {
              label: "Cascada",
              value: "Cascada",
              manufacturer: "Opel"
            },
            {
              label: "Combo",
              value: "Combo",
              manufacturer: "Opel"
            },
            {
              label: "Corsa",
              value: "Corsa",
              manufacturer: "Opel"
            },
            {
              label: "Crossland X",
              value: "Crossland_X",
              manufacturer: "Opel"
            },
            {
              label: "Frontera",
              value: "Frontera",
              manufacturer: "Opel"
            },
            {
              label: "GT",
              value: "GT",
              manufacturer: "Opel"
            },
            {
              label: "Grandland X",
              value: "Grandland_X",
              manufacturer: "Opel"
            },
            {
              label: "Insignia",
              value: "Insignia",
              manufacturer: "Opel"
            },
            {
              label: "Kadett",
              value: "Kadett",
              manufacturer: "Opel"
            },
            {
              label: "Manta",
              value: "Manta",
              manufacturer: "Opel"
            },
            {
              label: "Meriva",
              value: "Meriva",
              manufacturer: "Opel"
            },
            {
              label: "Mokka",
              value: "Mokka",
              manufacturer: "Opel"
            },
            {
              label: "Monterey",
              value: "Monterey",
              manufacturer: "Opel"
            },
            {
              label: "Monza",
              value: "Monza",
              manufacturer: "Opel"
            },
            {
              label: "Omega",
              value: "Omega",
              manufacturer: "Opel"
            },
            {
              label: "Rekord",
              value: "Rekord",
              manufacturer: "Opel"
            },
            {
              label: "Senator",
              value: "Senator",
              manufacturer: "Opel"
            },
            {
              label: "Signum",
              value: "Signum",
              manufacturer: "Opel"
            },
            {
              label: "Sintra",
              value: "Sintra",
              manufacturer: "Opel"
            },
            {
              label: "Speedster",
              value: "Speedster",
              manufacturer: "Opel"
            },
            {
              label: "Tigra",
              value: "Tigra",
              manufacturer: "Opel"
            },
            {
              label: "Vectra",
              value: "Vectra",
              manufacturer: "Opel"
            },
            {
              label: "Vivaro",
              value: "Vivaro",
              manufacturer: "Opel"
            },
            {
              label: "Zafira",
              value: "Zafira",
              manufacturer: "Opel"
            },
            {
              label: "1007",
              value: "1007",
              manufacturer: "Peugeot"
            },
            {
              label: "106",
              value: "106",
              manufacturer: "Peugeot"
            },
            {
              label: "107",
              value: "107",
              manufacturer: "Peugeot"
            },
            {
              label: "108",
              value: "108",
              manufacturer: "Peugeot"
            },
            {
              label: "2008",
              value: "2008",
              manufacturer: "Peugeot"
            },
            {
              label: "205",
              value: "205",
              manufacturer: "Peugeot"
            },
            {
              label: "206",
              value: "206",
              manufacturer: "Peugeot"
            },
            {
              label: "207",
              value: "207",
              manufacturer: "Peugeot"
            },
            {
              label: "208",
              value: "208",
              manufacturer: "Peugeot"
            },
            {
              label: "3008",
              value: "3008",
              manufacturer: "Peugeot"
            },
            {
              label: "301",
              value: "301",
              manufacturer: "Peugeot"
            },
            {
              label: "305",
              value: "305",
              manufacturer: "Peugeot"
            },
            {
              label: "306",
              value: "306",
              manufacturer: "Peugeot"
            },
            {
              label: "307",
              value: "307",
              manufacturer: "Peugeot"
            },
            {
              label: "308",
              value: "308",
              manufacturer: "Peugeot"
            },
            {
              label: "309",
              value: "309",
              manufacturer: "Peugeot"
            },
            {
              label: "4007",
              value: "4007",
              manufacturer: "Peugeot"
            },
            {
              label: "4008",
              value: "4008",
              manufacturer: "Peugeot"
            },
            {
              label: "405",
              value: "405",
              manufacturer: "Peugeot"
            },
            {
              label: "406",
              value: "406",
              manufacturer: "Peugeot"
            },
            {
              label: "407",
              value: "407",
              manufacturer: "Peugeot"
            },
            {
              label: "5008",
              value: "5008",
              manufacturer: "Peugeot"
            },
            {
              label: "505",
              value: "505",
              manufacturer: "Peugeot"
            },
            {
              label: "508",
              value: "508",
              manufacturer: "Peugeot"
            },
            {
              label: "605",
              value: "605",
              manufacturer: "Peugeot"
            },
            {
              label: "607",
              value: "607",
              manufacturer: "Peugeot"
            },
            {
              label: "608",
              value: "608",
              manufacturer: "Peugeot"
            },
            {
              label: "806",
              value: "806",
              manufacturer: "Peugeot"
            },
            {
              label: "807",
              value: "807",
              manufacturer: "Peugeot"
            },
            {
              label: "Bipper Tepee",
              value: "Bipper_Tepee",
              manufacturer: "Peugeot"
            },
            {
              label: "Boxer",
              value: "Boxer",
              manufacturer: "Peugeot"
            },
            {
              label: "Expert",
              value: "Expert",
              manufacturer: "Peugeot"
            },
            {
              label: "Expert Tepee",
              value: "Expert_Tepee",
              manufacturer: "Peugeot"
            },
            {
              label: "Ion",
              value: "Ion",
              manufacturer: "Peugeot"
            },
            {
              label: "Partner",
              value: "Partner",
              manufacturer: "Peugeot"
            },
            {
              label: "Partner Tepee",
              value: "Partner_Tepee",
              manufacturer: "Peugeot"
            },
            {
              label: "RCZ",
              value: "RCZ",
              manufacturer: "Peugeot"
            },
            {
              label: "Rifter",
              value: "Rifter",
              manufacturer: "Peugeot"
            },
            {
              label: "Traveller",
              value: "Traveller",
              manufacturer: "Peugeot"
            },
            {
              label: "Breeze",
              value: "Breeze",
              manufacturer: "Plymouth"
            },
            {
              label: "Morgan",
              value: "Morgan",
              manufacturer: "Plymouth"
            },
            {
              label: "Prowler",
              value: "Prowler",
              manufacturer: "Plymouth"
            },
            {
              label: "Voyager",
              value: "Voyager",
              manufacturer: "Plymouth"
            },
            {
              label: "Aztek",
              value: "Aztek",
              manufacturer: "Pontiac"
            },
            {
              label: "Bonneville",
              value: "Bonneville",
              manufacturer: "Pontiac"
            },
            {
              label: "Firebird",
              value: "Firebird",
              manufacturer: "Pontiac"
            },
            {
              label: "Firefly",
              value: "Firefly",
              manufacturer: "Pontiac"
            },
            {
              label: "G6",
              value: "G6",
              manufacturer: "Pontiac"
            },
            {
              label: "GTO",
              value: "GTO",
              manufacturer: "Pontiac"
            },
            {
              label: "Grand Am",
              value: "Grand_Am",
              manufacturer: "Pontiac"
            },
            {
              label: "Grand Prix",
              value: "Grand_Prix",
              manufacturer: "Pontiac"
            },
            {
              label: "Montana",
              value: "Montana",
              manufacturer: "Pontiac"
            },
            {
              label: "Sunfire",
              value: "Sunfire",
              manufacturer: "Pontiac"
            },
            {
              label: "Trans Sport",
              value: "Trans_Sport",
              manufacturer: "Pontiac"
            },
            {
              label: "Vibe",
              value: "Vibe",
              manufacturer: "Pontiac"
            },
            {
              label: "911",
              value: "911",
              manufacturer: "Porsche"
            },
            {
              label: "924",
              value: "924",
              manufacturer: "Porsche"
            },
            {
              label: "928",
              value: "928",
              manufacturer: "Porsche"
            },
            {
              label: "944",
              value: "944",
              manufacturer: "Porsche"
            },
            {
              label: "968",
              value: "968",
              manufacturer: "Porsche"
            },
            {
              label: "981 Boxster S",
              value: "981_Boxster_S",
              manufacturer: "Porsche"
            },
            {
              label: "986 Boxster",
              value: "986_Boxster",
              manufacturer: "Porsche"
            },
            {
              label: "987 Boxster",
              value: "987_Boxster",
              manufacturer: "Porsche"
            },
            {
              label: "Carrera GT",
              value: "Carrera_GT",
              manufacturer: "Porsche"
            },
            {
              label: "Cayenne",
              value: "Cayenne",
              manufacturer: "Porsche"
            },
            {
              label: "Cayman",
              value: "Cayman",
              manufacturer: "Porsche"
            },
            {
              label: "Macan",
              value: "Macan",
              manufacturer: "Porsche"
            },
            {
              label: "Panamera",
              value: "Panamera",
              manufacturer: "Porsche"
            },
            {
              label: "Magnum",
              value: "Magnum",
              manufacturer: "Rayton_Fissore"
            },
            {
              label: "Alaskan",
              value: "Alaskan",
              manufacturer: "Renault"
            },
            {
              label: "Alpine",
              value: "Alpine",
              manufacturer: "Renault"
            },
            {
              label: "Avantime",
              value: "Avantime",
              manufacturer: "Renault"
            },
            {
              label: "Captur",
              value: "Captur",
              manufacturer: "Renault"
            },
            {
              label: "Clio",
              value: "Clio",
              manufacturer: "Renault"
            },
            {
              label: "Espace",
              value: "Espace",
              manufacturer: "Renault"
            },
            {
              label: "Fluence",
              value: "Fluence",
              manufacturer: "Renault"
            },
            {
              label: "Fuego",
              value: "Fuego",
              manufacturer: "Renault"
            },
            {
              label: "Grand Espace",
              value: "Grand_Espace",
              manufacturer: "Renault"
            },
            {
              label: "Grand Scénic",
              value: "Grand_Scenic",
              manufacturer: "Renault"
            },
            {
              label: "Kadjar",
              value: "Kadjar",
              manufacturer: "Renault"
            },
            {
              label: "Kangoo",
              value: "Kangoo",
              manufacturer: "Renault"
            },
            {
              label: "Koleos",
              value: "Koleos",
              manufacturer: "Renault"
            },
            {
              label: "Laguna",
              value: "Laguna",
              manufacturer: "Renault"
            },
            {
              label: "Latitude",
              value: "Latitude",
              manufacturer: "Renault"
            },
            {
              label: "Master",
              value: "Master",
              manufacturer: "Renault"
            },
            {
              label: "Modus",
              value: "Modus",
              manufacturer: "Renault"
            },
            {
              label: "Mégane",
              value: "Megane",
              manufacturer: "Renault"
            },
            {
              label: "R11",
              value: "R11",
              manufacturer: "Renault"
            },
            {
              label: "R18",
              value: "R18",
              manufacturer: "Renault"
            },
            {
              label: "R19",
              value: "R19",
              manufacturer: "Renault"
            },
            {
              label: "R20",
              value: "R20",
              manufacturer: "Renault"
            },
            {
              label: "R21",
              value: "R21",
              manufacturer: "Renault"
            },
            {
              label: "R25",
              value: "R25",
              manufacturer: "Renault"
            },
            {
              label: "R4",
              value: "R4",
              manufacturer: "Renault"
            },
            {
              label: "R5",
              value: "R5",
              manufacturer: "Renault"
            },
            {
              label: "R9",
              value: "R9",
              manufacturer: "Renault"
            },
            {
              label: "Safrane",
              value: "Safrane",
              manufacturer: "Renault"
            },
            {
              label: "Scénic",
              value: "Scenic",
              manufacturer: "Renault"
            },
            {
              label: "Spider",
              value: "Spider",
              manufacturer: "Renault"
            },
            {
              label: "Talisman",
              value: "Talisman",
              manufacturer: "Renault"
            },
            {
              label: "Thalia",
              value: "Thalia",
              manufacturer: "Renault"
            },
            {
              label: "Trafic",
              value: "Trafic",
              manufacturer: "Renault"
            },
            {
              label: "Twingo",
              value: "Twingo",
              manufacturer: "Renault"
            },
            {
              label: "Twizy",
              value: "Twizy",
              manufacturer: "Renault"
            },
            {
              label: "Vel Satis",
              value: "Vel_Satis",
              manufacturer: "Renault"
            },
            {
              label: "Wind",
              value: "Wind",
              manufacturer: "Renault"
            },
            {
              label: "ZOE",
              value: "ZOE",
              manufacturer: "Renault"
            },
            {
              label: "Ghost",
              value: "Ghost",
              manufacturer: "Rolls_Royce"
            },
            {
              label: "Park",
              value: "Park",
              manufacturer: "Rolls_Royce"
            },
            {
              label: "Phantom",
              value: "Phantom",
              manufacturer: "Rolls_Royce"
            },
            {
              label: "Silver Dawn",
              value: "Silver_Dawn",
              manufacturer: "Rolls_Royce"
            },
            {
              label: "Silver Spirit",
              value: "Silver_Spirit",
              manufacturer: "Rolls_Royce"
            },
            {
              label: "Touring Limousine",
              value: "Touring_Limousine",
              manufacturer: "Rolls_Royce"
            },
            {
              label: "Wraith",
              value: "Wraith",
              manufacturer: "Rolls_Royce"
            },
            {
              label: "100",
              value: "100",
              manufacturer: "Rover"
            },
            {
              label: "200",
              value: "200",
              manufacturer: "Rover"
            },
            {
              label: "25",
              value: "25",
              manufacturer: "Rover"
            },
            {
              label: "400",
              value: "400",
              manufacturer: "Rover"
            },
            {
              label: "45",
              value: "45",
              manufacturer: "Rover"
            },
            {
              label: "600",
              value: "600",
              manufacturer: "Rover"
            },
            {
              label: "75",
              value: "75",
              manufacturer: "Rover"
            },
            {
              label: "800",
              value: "800",
              manufacturer: "Rover"
            },
            {
              label: "Mini",
              value: "Mini",
              manufacturer: "Rover"
            },
            {
              label: "Streetwise",
              value: "Streetwise",
              manufacturer: "Rover"
            },
            {
              label: "9-3",
              value: "9-3",
              manufacturer: "Saab"
            },
            {
              label: "9-5",
              value: "9-5",
              manufacturer: "Saab"
            },
            {
              label: "90",
              value: "90",
              manufacturer: "Saab"
            },
            {
              label: "900",
              value: "900",
              manufacturer: "Saab"
            },
            {
              label: "9000",
              value: "9000",
              manufacturer: "Saab"
            },
            {
              label: "99",
              value: "99",
              manufacturer: "Saab"
            },
            {
              label: "PS-10",
              value: "PS-10",
              manufacturer: "Santana"
            },
            {
              label: "PS300",
              value: "PS300",
              manufacturer: "Santana"
            },
            {
              label: "PS350",
              value: "PS350",
              manufacturer: "Santana"
            },
            {
              label: "Alhambra",
              value: "Alhambra",
              manufacturer: "Seat"
            },
            {
              label: "Altea",
              value: "Altea",
              manufacturer: "Seat"
            },
            {
              label: "Arona",
              value: "Arona",
              manufacturer: "Seat"
            },
            {
              label: "Arosa",
              value: "Arosa",
              manufacturer: "Seat"
            },
            {
              label: "Ateca",
              value: "Ateca",
              manufacturer: "Seat"
            },
            {
              label: "Cordoba",
              value: "Cordoba",
              manufacturer: "Seat"
            },
            {
              label: "Exeo",
              value: "Exeo",
              manufacturer: "Seat"
            },
            {
              label: "Ibiza",
              value: "Ibiza",
              manufacturer: "Seat"
            },
            {
              label: "Inca",
              value: "Inca",
              manufacturer: "Seat"
            },
            {
              label: "Leon",
              value: "Leon",
              manufacturer: "Seat"
            },
            {
              label: "Malaga",
              value: "Malaga",
              manufacturer: "Seat"
            },
            {
              label: "Marbella",
              value: "Marbella",
              manufacturer: "Seat"
            },
            {
              label: "Mii",
              value: "Mii",
              manufacturer: "Seat"
            },
            {
              label: "Tarraco",
              value: "Tarraco",
              manufacturer: "Seat"
            },
            {
              label: "Toledo",
              value: "Toledo",
              manufacturer: "Seat"
            },
            {
              label: "Electric Drive",
              value: "Electric_Drive",
              manufacturer: "Smart"
            },
            {
              label: "Forfour",
              value: "Forfour",
              manufacturer: "Smart"
            },
            {
              label: "Fortwo",
              value: "Fortwo",
              manufacturer: "Smart"
            },
            {
              label: "Micro compact",
              value: "Micro_compact",
              manufacturer: "Smart"
            },
            {
              label: "Roadster",
              value: "Roadster",
              manufacturer: "Smart"
            },
            {
              label: "Actyon",
              value: "Actyon",
              manufacturer: "SsangYong"
            },
            {
              label: "Korando",
              value: "Korando",
              manufacturer: "SsangYong"
            },
            {
              label: "Kyron",
              value: "Kyron",
              manufacturer: "SsangYong"
            },
            {
              label: "Musso",
              value: "Musso",
              manufacturer: "SsangYong"
            },
            {
              label: "Rexton",
              value: "Rexton",
              manufacturer: "SsangYong"
            },
            {
              label: "Tivoli",
              value: "Tivoli",
              manufacturer: "SsangYong"
            },
            {
              label: "XLV",
              value: "XLV",
              manufacturer: "SsangYong"
            },
            {
              label: "1800",
              value: "1800",
              manufacturer: "Subaru"
            },
            {
              label: "Ascent",
              value: "Ascent",
              manufacturer: "Subaru"
            },
            {
              label: "BRZ",
              value: "BRZ",
              manufacturer: "Subaru"
            },
            {
              label: "Baja",
              value: "Baja",
              manufacturer: "Subaru"
            },
            {
              label: "Forester",
              value: "Forester",
              manufacturer: "Subaru"
            },
            {
              label: "Impreza",
              value: "Impreza",
              manufacturer: "Subaru"
            },
            {
              label: "Justy",
              value: "Justy",
              manufacturer: "Subaru"
            },
            {
              label: "Legacy",
              value: "Legacy",
              manufacturer: "Subaru"
            },
            {
              label: "Levorg",
              value: "Levorg",
              manufacturer: "Subaru"
            },
            {
              label: "Outback",
              value: "Outback",
              manufacturer: "Subaru"
            },
            {
              label: "SVX",
              value: "SVX",
              manufacturer: "Subaru"
            },
            {
              label: "Stationwagon",
              value: "Stationwagon",
              manufacturer: "Subaru"
            },
            {
              label: "Trezia",
              value: "Trezia",
              manufacturer: "Subaru"
            },
            {
              label: "Tribeca",
              value: "Tribeca",
              manufacturer: "Subaru"
            },
            {
              label: "Vivio",
              value: "Vivio",
              manufacturer: "Subaru"
            },
            {
              label: "WRX STI",
              value: "WRX_STI",
              manufacturer: "Subaru"
            },
            {
              label: "XV",
              value: "XV",
              manufacturer: "Subaru"
            },
            {
              label: "750 S",
              value: "750_S",
              manufacturer: "Suzuki"
            },
            {
              label: "Alto",
              value: "Alto",
              manufacturer: "Suzuki"
            },
            {
              label: "Baleno",
              value: "Baleno",
              manufacturer: "Suzuki"
            },
            {
              label: "Carry",
              value: "Carry",
              manufacturer: "Suzuki"
            },
            {
              label: "Celerio",
              value: "Celerio",
              manufacturer: "Suzuki"
            },
            {
              label: "Grand Vitara",
              value: "Grand_Vitara",
              manufacturer: "Suzuki"
            },
            {
              label: "Ignis",
              value: "Ignis",
              manufacturer: "Suzuki"
            },
            {
              label: "Jimny",
              value: "Jimny",
              manufacturer: "Suzuki"
            },
            {
              label: "Kizashi",
              value: "Kizashi",
              manufacturer: "Suzuki"
            },
            {
              label: "Liana",
              value: "Liana",
              manufacturer: "Suzuki"
            },
            {
              label: "S-Cross",
              value: "S-Cross",
              manufacturer: "Suzuki"
            },
            {
              label: "SX4",
              value: "SX4",
              manufacturer: "Suzuki"
            },
            {
              label: "SX4 S-Cross",
              value: "SX4_S-Cross",
              manufacturer: "Suzuki"
            },
            {
              label: "Samurai",
              value: "Samurai",
              manufacturer: "Suzuki"
            },
            {
              label: "Santana",
              value: "Santana",
              manufacturer: "Suzuki"
            },
            {
              label: "Splash",
              value: "Splash",
              manufacturer: "Suzuki"
            },
            {
              label: "Supper Carry",
              value: "Supper_Carry",
              manufacturer: "Suzuki"
            },
            {
              label: "Swift",
              value: "Swift",
              manufacturer: "Suzuki"
            },
            {
              label: "Vitara",
              value: "Vitara",
              manufacturer: "Suzuki"
            },
            {
              label: "Wagon R",
              value: "Wagon_R",
              manufacturer: "Suzuki"
            },
            {
              label: "Cerbera",
              value: "Cerbera",
              manufacturer: "TVR"
            },
            {
              label: "Safari",
              value: "Safari",
              manufacturer: "Tata"
            },
            {
              label: "T613",
              value: "T613",
              manufacturer: "Tatra"
            },
            {
              label: "T700",
              value: "T700",
              manufacturer: "Tatra"
            },
            {
              label: "Model 3",
              value: "Model_3",
              manufacturer: "Tesla"
            },
            {
              label: "Model S",
              value: "Model_S",
              manufacturer: "Tesla"
            },
            {
              label: "Model X",
              value: "Model_X",
              manufacturer: "Tesla"
            },
            {
              label: "Roadster",
              value: "Roadster",
              manufacturer: "Tesla"
            },
            {
              label: "4Runner",
              value: "4Runner",
              manufacturer: "Toyota"
            },
            {
              label: "Auris",
              value: "Auris",
              manufacturer: "Toyota"
            },
            {
              label: "Avalon",
              value: "Avalon",
              manufacturer: "Toyota"
            },
            {
              label: "Avensis",
              value: "Avensis",
              manufacturer: "Toyota"
            },
            {
              label: "Avensis Verso",
              value: "Avensis_Verso",
              manufacturer: "Toyota"
            },
            {
              label: "Aygo",
              value: "Aygo",
              manufacturer: "Toyota"
            },
            {
              label: "C-HR",
              value: "C-HR",
              manufacturer: "Toyota"
            },
            {
              label: "Camry",
              value: "Camry",
              manufacturer: "Toyota"
            },
            {
              label: "Carina",
              value: "Carina",
              manufacturer: "Toyota"
            },
            {
              label: "Celica",
              value: "Celica",
              manufacturer: "Toyota"
            },
            {
              label: "Corolla",
              value: "Corolla",
              manufacturer: "Toyota"
            },
            {
              label: "Corolla Verso",
              value: "Corolla_Verso",
              manufacturer: "Toyota"
            },
            {
              label: "Echo",
              value: "Echo",
              manufacturer: "Toyota"
            },
            {
              label: "FJ Cruiser",
              value: "FJ_Cruiser",
              manufacturer: "Toyota"
            },
            {
              label: "Fortuner",
              value: "Fortuner",
              manufacturer: "Toyota"
            },
            {
              label: "GT86",
              value: "GT86",
              manufacturer: "Toyota"
            },
            {
              label: "Highlander",
              value: "Highlander",
              manufacturer: "Toyota"
            },
            {
              label: "Hilux",
              value: "Hilux",
              manufacturer: "Toyota"
            },
            {
              label: "Land Cruiser",
              value: "Land_Cruiser",
              manufacturer: "Toyota"
            },
            {
              label: "MR2",
              value: "MR2",
              manufacturer: "Toyota"
            },
            {
              label: "Matrix",
              value: "Matrix",
              manufacturer: "Toyota"
            },
            {
              label: "Paseo",
              value: "Paseo",
              manufacturer: "Toyota"
            },
            {
              label: "Picnic",
              value: "Picnic",
              manufacturer: "Toyota"
            },
            {
              label: "Previa",
              value: "Previa",
              manufacturer: "Toyota"
            },
            {
              label: "Prius",
              value: "Prius",
              manufacturer: "Toyota"
            },
            {
              label: "Proace",
              value: "Proace",
              manufacturer: "Toyota"
            },
            {
              label: "Rav4",
              value: "Rav4",
              manufacturer: "Toyota"
            },
            {
              label: "Runner",
              value: "Runner",
              manufacturer: "Toyota"
            },
            {
              label: "Sequoia",
              value: "Sequoia",
              manufacturer: "Toyota"
            },
            {
              label: "Sienna",
              value: "Sienna",
              manufacturer: "Toyota"
            },
            {
              label: "Solara",
              value: "Solara",
              manufacturer: "Toyota"
            },
            {
              label: "Starlet",
              value: "Starlet",
              manufacturer: "Toyota"
            },
            {
              label: "Supra",
              value: "Supra",
              manufacturer: "Toyota"
            },
            {
              label: "Tacoma",
              value: "Tacoma",
              manufacturer: "Toyota"
            },
            {
              label: "Tundra",
              value: "Tundra",
              manufacturer: "Toyota"
            },
            {
              label: "Urban Cruiser",
              value: "Urban_Cruiser",
              manufacturer: "Toyota"
            },
            {
              label: "Venza",
              value: "Venza",
              manufacturer: "Toyota"
            },
            {
              label: "Verso",
              value: "Verso",
              manufacturer: "Toyota"
            },
            {
              label: "Yaris",
              value: "Yaris",
              manufacturer: "Toyota"
            },
            {
              label: "iQ",
              value: "iQ",
              manufacturer: "Toyota"
            },
            {
              label: "601S",
              value: "601S",
              manufacturer: "Trabant"
            },
            {
              label: "Kombi",
              value: "Kombi",
              manufacturer: "Trabant"
            },
            {
              label: "240",
              value: "240",
              manufacturer: "Volvo"
            },
            {
              label: "241",
              value: "241",
              manufacturer: "Volvo"
            },
            {
              label: "242",
              value: "242",
              manufacturer: "Volvo"
            },
            {
              label: "245",
              value: "245",
              manufacturer: "Volvo"
            },
            {
              label: "360",
              value: "360",
              manufacturer: "Volvo"
            },
            {
              label: "440",
              value: "440",
              manufacturer: "Volvo"
            },
            {
              label: "460",
              value: "460",
              manufacturer: "Volvo"
            },
            {
              label: "480",
              value: "480",
              manufacturer: "Volvo"
            },
            {
              label: "740",
              value: "740",
              manufacturer: "Volvo"
            },
            {
              label: "850",
              value: "850",
              manufacturer: "Volvo"
            },
            {
              label: "940",
              value: "940",
              manufacturer: "Volvo"
            },
            {
              label: "944",
              value: "944",
              manufacturer: "Volvo"
            },
            {
              label: "960",
              value: "960",
              manufacturer: "Volvo"
            },
            {
              label: "C30",
              value: "C30",
              manufacturer: "Volvo"
            },
            {
              label: "C70",
              value: "C70",
              manufacturer: "Volvo"
            },
            {
              label: "S40",
              value: "S40",
              manufacturer: "Volvo"
            },
            {
              label: "S60",
              value: "S60",
              manufacturer: "Volvo"
            },
            {
              label: "S70",
              value: "S70",
              manufacturer: "Volvo"
            },
            {
              label: "S80",
              value: "S80",
              manufacturer: "Volvo"
            },
            {
              label: "S90",
              value: "S90",
              manufacturer: "Volvo"
            },
            {
              label: "V40",
              value: "V40",
              manufacturer: "Volvo"
            },
            {
              label: "V50",
              value: "V50",
              manufacturer: "Volvo"
            },
            {
              label: "V60",
              value: "V60",
              manufacturer: "Volvo"
            },
            {
              label: "V70",
              value: "V70",
              manufacturer: "Volvo"
            },
            {
              label: "V90",
              value: "V90",
              manufacturer: "Volvo"
            },
            {
              label: "XC40",
              value: "XC40",
              manufacturer: "Volvo"
            },
            {
              label: "XC60",
              value: "XC60",
              manufacturer: "Volvo"
            },
            {
              label: "XC70",
              value: "XC70",
              manufacturer: "Volvo"
            },
            {
              label: "XC90",
              value: "XC90",
              manufacturer: "Volvo"
            },
            {
              label: "353",
              value: "353",
              manufacturer: "Wartburg"
            },
            {
              label: "100",
              value: "100",
              manufacturer: "ZhiDou"
            },
            {
              label: "200",
              value: "200",
              manufacturer: "ZhiDou"
            },
            {
              label: "80",
              value: "80",
              manufacturer: "ZhiDou"
            },
            {
              label: "90",
              value: "90",
              manufacturer: "ZhiDou"
            },
            {
              label: "A1",
              value: "A1",
              manufacturer: "ZhiDou"
            },
            {
              label: "A2",
              value: "A2",
              manufacturer: "ZhiDou"
            },
            {
              label: "A3",
              value: "A3",
              manufacturer: "ZhiDou"
            },
            {
              label: "A4",
              value: "A4",
              manufacturer: "ZhiDou"
            },
            {
              label: "A4 Avant",
              value: "A4_Avant",
              manufacturer: "ZhiDou"
            },
            {
              label: "A4 allroad",
              value: "A4_allroad",
              manufacturer: "ZhiDou"
            },
            {
              label: "A5",
              value: "A5",
              manufacturer: "ZhiDou"
            },
            {
              label: "A6",
              value: "A6",
              manufacturer: "ZhiDou"
            },
            {
              label: "A6 Avant",
              value: "A6_Avant",
              manufacturer: "ZhiDou"
            },
            {
              label: "A6 allroad",
              value: "A6_allroad",
              manufacturer: "ZhiDou"
            },
            {
              label: "A7",
              value: "A7",
              manufacturer: "ZhiDou"
            },
            {
              label: "A8",
              value: "A8",
              manufacturer: "ZhiDou"
            },
            {
              label: "Coupé",
              value: "Coupe",
              manufacturer: "ZhiDou"
            },
            {
              label: "D1",
              value: "D1",
              manufacturer: "ZhiDou"
            },
            {
              label: "D2",
              value: "D2",
              manufacturer: "ZhiDou"
            },
            {
              label: "D2S",
              value: "D2S",
              manufacturer: "ZhiDou"
            },
            {
              label: "Q2",
              value: "Q2",
              manufacturer: "ZhiDou"
            },
            {
              label: "Q3",
              value: "Q3",
              manufacturer: "ZhiDou"
            },
            {
              label: "Q5",
              value: "Q5",
              manufacturer: "ZhiDou"
            },
            {
              label: "Q7",
              value: "Q7",
              manufacturer: "ZhiDou"
            },
            {
              label: "Q8",
              value: "Q8",
              manufacturer: "ZhiDou"
            },
            {
              label: "R8",
              value: "R8",
              manufacturer: "ZhiDou"
            },
            {
              label: "RS2",
              value: "RS2",
              manufacturer: "ZhiDou"
            },
            {
              label: "RS3",
              value: "RS3",
              manufacturer: "ZhiDou"
            },
            {
              label: "RS4",
              value: "RS4",
              manufacturer: "ZhiDou"
            },
            {
              label: "RS4 Avant",
              value: "RS4_Avant",
              manufacturer: "ZhiDou"
            },
            {
              label: "RS5",
              value: "RS5",
              manufacturer: "ZhiDou"
            },
            {
              label: "RS6",
              value: "RS6",
              manufacturer: "ZhiDou"
            },
            {
              label: "RS7",
              value: "RS7",
              manufacturer: "ZhiDou"
            },
            {
              label: "S3",
              value: "S3",
              manufacturer: "ZhiDou"
            },
            {
              label: "S4",
              value: "S4",
              manufacturer: "ZhiDou"
            },
            {
              label: "S5",
              value: "S5",
              manufacturer: "ZhiDou"
            },
            {
              label: "S6",
              value: "S6",
              manufacturer: "ZhiDou"
            },
            {
              label: "S7",
              value: "S7",
              manufacturer: "ZhiDou"
            },
            {
              label: "S8",
              value: "S8",
              manufacturer: "ZhiDou"
            },
            {
              label: "SQ5",
              value: "SQ5",
              manufacturer: "ZhiDou"
            },
            {
              label: "SQ7",
              value: "SQ7",
              manufacturer: "ZhiDou"
            },
            {
              label: "SQ8",
              value: "SQ8",
              manufacturer: "ZhiDou"
            },
            {
              label: "TT",
              value: "TT",
              manufacturer: "ZhiDou"
            },
            {
              label: "TTS",
              value: "TTS",
              manufacturer: "ZhiDou"
            },
            {
              label: "V8",
              value: "V8",
              manufacturer: "ZhiDou"
            },
            {
              label: "e-tron",
              value: "e-tron",
              manufacturer: "ZhiDou"
            },
            {
              label: "udi",
              value: "udi",
              manufacturer: "ZhiDou"
            },
            {
              label: "1000 MB",
              value: "1000_MB",
              manufacturer: "Skoda"
            },
            {
              label: "105",
              value: "105",
              manufacturer: "Skoda"
            },
            {
              label: "110",
              value: "110",
              manufacturer: "Skoda"
            },
            {
              label: "120",
              value: "120",
              manufacturer: "Skoda"
            },
            {
              label: "125",
              value: "125",
              manufacturer: "Skoda"
            },
            {
              label: "130",
              value: "130",
              manufacturer: "Skoda"
            },
            {
              label: "450 Roadster",
              value: "450_Roadster",
              manufacturer: "Skoda"
            },
            {
              label: "860",
              value: "860",
              manufacturer: "Skoda"
            },
            {
              label: "Citigo",
              value: "Citigo",
              manufacturer: "Skoda"
            },
            {
              label: "Fabia",
              value: "Fabia",
              manufacturer: "Skoda"
            },
            {
              label: "Favorit",
              value: "Favorit",
              manufacturer: "Skoda"
            },
            {
              label: "Felicia",
              value: "Felicia",
              manufacturer: "Skoda"
            },
            {
              label: "Forman",
              value: "Forman",
              manufacturer: "Skoda"
            },
            {
              label: "Kamiq",
              value: "Kamiq",
              manufacturer: "Skoda"
            },
            {
              label: "Karoq",
              value: "Karoq",
              manufacturer: "Skoda"
            },
            {
              label: "Kodiaq",
              value: "Kodiaq",
              manufacturer: "Skoda"
            },
            {
              label: "Octavia",
              value: "Octavia",
              manufacturer: "Skoda"
            },
            {
              label: "Rapid",
              value: "Rapid",
              manufacturer: "Skoda"
            },
            {
              label: "Roomster",
              value: "Roomster",
              manufacturer: "Skoda"
            },
            {
              label: "Scala",
              value: "Scala",
              manufacturer: "Skoda"
            },
            {
              label: "Superb",
              value: "Superb",
              manufacturer: "Skoda"
            },
            {
              label: "Volkswagen",
              value: "Volkswagen",
              manufacturer: "Skoda"
            },
            {
              label: "Yeti",
              value: "Yeti",
              manufacturer: "Skoda"
            },
            {
              label: "Jiný",
              value: "other"
            }
          ]
        },
        {
          name: "motor-volume",
          type: "number",
          options: {
            placeholder: "Objem motoru",
            mandatory: true,
            label: "cm³",
            min: 0
          }
        },
        {
          name: "motor-power",
          type: "number",
          options: {
            placeholder: "Výkon motoru",
            mandatory: true,
            label: "kW",
            min: 0
          }
        },
        {
          name: "weight",
          type: "number",
          options: {
            placeholder: "Hmotnost vozidla",
            mandatory: true,
            label: "kg",
            min: 0
          }
        },
        {
          name: "fuel",
          type: "switch",
          options: {},
          data: [
            {
              label: "Benzín",
              value: "petrol"
            },
            {
              label: "Nafta",
              value: "diesel"
            },
            {
              label: "LPG/CNG",
              value: "gas"
            },
            {
              label: "Elektrika",
              value: "electric"
            },
            {
              label: "Hybridní",
              value: "hybrid"
            }
          ]
        },
        {
          name: "year",
          type: "number",
          options: {
            placeholder: "Rok výroby",
            mandatory: true,
            min: 1900
          }
        },
        {
          name: "active-from",
          type: "date",
          options: {
            placeholder: "První uvedení do provozu"
          }
        },
        {
          name: "age",
          type: "number",
          options: {
            placeholder: "Věk vlastníka",
            mandatory: true,
            min: 16,
            max: 116
          }
        },
        {
          name: "previous",
          type: "number",
          options: {
            placeholder: "Délka předchozích pojištění (bonus)",
            label: "Měsíce",
            min: 1
          }
        },
        {
          name: "incidents",
          type: "number",
          options: {
            placeholder: "Počet pojistných událostí",
            min: 0
          }
        }
      ]
    },
    {
      label: "Další",
      value: "other",
      title: "Jiné pojištění",
      tagline: "Kalkulátory cen zatím připravujeme. Pro indiviuální nabídky k ušetření nás neváhejte kontaktovat.",
      submit: "Zobrazit nabídky",
      options: {
        disabled: true,
        showHelpButtom: true,
      },
      fields: [
        {
          type: "switch",
          name: "type",
          options: {},
          data: [
            {
              label: "Pojištění odpovědnosti",
              value: "liability"
            },
            {
              label: "Úrazové pojištění",
              value: "injury"
            },
            {
              label: "Životní pojištění",
              value: "life"
            },
            {
              label: "Nemocenské pojištění",
              value: "sickness"
            },
            {
              label: "Penzijní připojištění",
              value: "pension"
            }
          ]
        }
      ]
    }
  ]
}