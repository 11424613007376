import React from 'react';
import PropTypes from 'prop-types'
import {
  constructArray,
  hash,
} from 'scripts/helpers'
import './style.scss'
import uuid from 'uuid'
const key_seed = uuid()

function JumpingEllipsis(props) {

  return <div className='Component-JumpingEllipsis' style={props.style}>
    {constructArray(props.count, index =>
      <span key={hash(key_seed, index)}
        className='dot'
        style={{
          animationDelay: (props.animationDelay * index) + 's',
        }}
      >.</span>
    )}
  </div>;
}

JumpingEllipsis.propTypes = {
  animationDuration: PropTypes.number,
  animationDelay: PropTypes.number,
  count: PropTypes.number,
  animationOverlap: PropTypes.number,
  style: PropTypes.object,
}
JumpingEllipsis.defaultProps = {
  animationDuration: 2,
  animationDelay: .3,
  count: 3,
  animationOverlap: .1,
  style: {}
}

export default JumpingEllipsis 