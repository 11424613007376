import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import boon_proptype from 'types/boon'
import boons from 'pages/Offers/boons'
import { someKeys, isFunction } from 'scripts/helpers'
import Tooltip from 'components/Tooltip'

export const rawBoon_proptype = someKeys(['type', 'source', 'params'], boon_proptype)

export function getBoonDetails(boon) {
  return boons.find(({ type }) => type === boon.type)
}

export function getCellBoons(boons) {
  return boons.filter(({ options }) => options.displayInCell)
}

export function getHeaderBoons(boons) {
  return boons.filter(({ options }) => options.displayInHeader)
}

// //-- Boon type components
// const boonTypeComponents =
//   boons.reduce((all, { type }) => {
//     console.log({ all, type })
//     return {
//       ...all,
//       [type]: require(`./${type}`).default
//     }
//   }, null)

// console.log(boonTypeComponents)
function BoonLabel(props) {
  const {
    className,
    children: raw_boon,
    ...passProps
  } = props,
    boon = {
      ...raw_boon,
      ...boons.find(boon => boon.type === raw_boon.type)
    }
  // Boon = boonTypeComponents[props.children.type]

  return <Tooltip className={['BoonLabel-Component', `BoonLabel-${raw_boon.type}-Component`, className].toClass()}
    text={boon.description}
    {...passProps}>
    {isFunction(boon.labelParser) ? boon.labelParser(boon.params) : boon.label}
  </Tooltip>
}

BoonLabel.propTypes = {
  children: PropTypes.shape(rawBoon_proptype)
}

export default BoonLabel