import {
  FormPageInfo
} from '..'

const utilitiesFormInfo: Omit<FormPageInfo, 'path'>[] =
  [
    {
      name: 'electricity',
      slug: 'elektrina',
      label: 'Elektřina'
    },
    {
      name: 'gas',
      slug: 'plyn',
      label: 'Plyn'
    },
  ]

export default utilitiesFormInfo
