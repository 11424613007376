import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import toPx from 'to-px'
import TextArea from 'react-textarea-autosize'

import './style.scss'
import { someKeys, isFunction } from 'scripts/helpers';

class InputTextArea extends PureComponent {
  state = {
    multirow: false
  }

  render() {
    const {
      value,
      onChange,
      onEnter,
      onKeyDown,
      ...other
    } = this.props,
      passProps = someKeys([
        'onClick',
        'onBlur',
        'onFocus',
        'onMouseEnter',
        'onMouseLeave',
        'dirname',
        'disabled',
        'form',
        'maxLength',
        'readOnly',
        'placeholder',
        'require',
        'wrap',
        'minRows',
        'maxRows',
        'unselectable'
      ], other)

    // console.log('InputText value:', value)

    return <div className='Component-InputText' onClick={() => { if (this.inputRef !== undefined) this.inputRef.focus() }}>
      <div className='wrapper'>
        <TextArea className={'form-field' + (this.state.multirow ? ' multirow' : '')}
          // size='1'
          {...passProps}
          value={value || ''}
          useCacheForDOMMeasurements={true}
          inputRef={ref => this.inputRef = ref}
          onHeightChange={height => {
            const
              lineHeight = toPx(window.getComputedStyle(this.inputRef, null).getPropertyValue('line-height')),
              rows = Math.ceil(height / lineHeight)

            // console.log({ rows })

            this.setState({
              multirow: rows > 1
            })
          }}
          onChange={({ target: input }) => onChange(input.value === '' ? undefined : input.value)}
          onKeyDown={e => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              e.preventDefault();
              onEnter(e.target.value === '' ? undefined : e.target.value)
            }
            if (isFunction(onKeyDown))
              onKeyDown(e)
          }} />
      </div>
    </div>
  }
}

InputTextArea.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  options: PropTypes.object,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
}
InputTextArea.defaultProps = {
  onChange: () => { },
  onEnter: () => { },
  onLeave: () => { },
  options: {},
  maxRows: Infinity,
  minRows: 1,
}

export default InputTextArea