import React from 'react';

import './style.scss';
import useTitle from 'hooks/useTitle';
import SCButton from 'components/SCButton';

function Kontakt(props) {
  const Title = useTitle()

  return <>
    <Title>Kontakt</Title>
    <div className='Component-Kontakt'>
      <div className='whitebox'>
        <h2>Kontakt</h2>
        <SCButton className='contact-button' liveSupp>
          Online chat / Osobní asistence
        </SCButton>
        <div className='contact-table'>

          {/* <p className='hr'>Zákaznická linka:</p>
      <b >+420 222 111 222</b>

      <p className='hr'>Email pro zákazníky:</p>
      <b >zakaznici@setrichytre.cz</b>

      <p className='hr'>Email pro reklamace:</p>
      <b >reklamace@setrichytre.cz</b>

      <p className='hr'>Email vedení:</p>
      <b >vedeni@setrichytre.cz</b> */}

          <p className='hr'>Email:</p>
          <b >info@setrichytre.cz</b>

          <div className='fullwidth'>&nbsp;</div>

          <p className='hr'>Společnost:</p>
          <p >Šetři chytře s.r.o.</p>

          <p className='hr'>Sídlo a kanceláře:</p>
          <p>Jeremiášova 947/16<br />
            Praha 13 - 155 00</p>

          <div className='fullwidth'>&nbsp;</div>

          <p className='hr ico'>IČO: <span>08427691</span></p>

          <p>DIČ: <span>CZ08427691</span></p>

          <p className='hr'>Číslo účtu - Raiffeisenbank:</p>
          <p>66442223 / 5500</p>
        </div>
      </div>
    </div>
  </>
}

export default Kontakt

