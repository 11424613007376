import React, { useEffect } from 'react'

import './style.scss'
import proptypes from '../pageProptypes'
import setriChytreAPI from 'scripts/SCH_API'
import {
  isObject,
  constructArray,
  awaitAll,
  mergeDeep
} from 'scripts/helpers'
import {
  isArray,
} from 'util'
import Form from 'components/Form'
// import utilities from 'forms/utilities'
import { validateForm } from 'components/Form'
import { generateDefaultData } from 'components/Form'
import { getSCForm } from 'forms'

export const initialState = {
  electricitySupplier: undefined,
  electricitySuppliers: undefined,
  formData: undefined,
}

const utilities = getSCForm('utilities')

const usageFields = (() => {
  let out = {
    gas: undefined,
    electricity: undefined,
  }

  for (const util in out)
    out[util] = {
      ...utilities.layout
        .find(f => f.value === util).fields
        .find(f => f.name === 'usage'),
      name: `${util}Usage`
    }

  return out
})()

function SupplierForm(props) {
  const { state, setState, getState, goToPage } = props,
    utilities = getState('util_choice')

  useEffect(() => {
    (async () => {
      if (isObject(utilities)) {

        let
          suppliers = {},
          apiCalls = ['electricity', 'gas']
            // Only the ones we selected
            .filter(util => utilities[util])
            .map(util =>
              setriChytreAPI(`/${util}/formData`)
                .then(({ data }) =>
                  suppliers[`${util}Suppliers`] = data.productProviders
                    .map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))
                )
            )

        await awaitAll(apiCalls)
        setState(suppliers)
      }
    })()
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const fields = [],
    setFormData = (key, data) => setState({
      formData: mergeDeep(state.formData, { [key]: data }, { overwrite_undefined: true })
    }),
    getFormData = key => {
      if (state.formData === undefined)
        return undefined
      return state.formData[key]
    }

  let loading = false
  for (const util of [
    ['electricity', 'Dodavatel elektřiny'],
    ['gas', 'Dodavatel plynu']
  ])
    if (isObject(utilities) && utilities[util[0]] === true) {
      if (isArray(state[util[0] + 'Suppliers']))
        fields.push({
          type: 'select',
          name: util[0] + 'Supplier',
          data: state[util[0] + 'Suppliers'],
          options: {
            mandatory: true,
          },
          placeholder: util[1],
        }, usageFields[util[0]])
      else {
        fields.push(...constructArray(2, () => ({
          name: '',
          type: 'loading',
          opations: {},
        })))
        loading = true
      }
    }

  let canContinue = false
  if (!loading) {
    if (state.formData === undefined) {
      const defaultData = generateDefaultData(fields)
      // console.log({ defaultData })
      setState({ formData: defaultData },
        // () => console.log(props.state.formData)
      )
    }
    else {
      // console.log({ formData: props.state.formData })
      const invalidFields = validateForm(fields, getFormData)
      // console.log({ fields, invalidFields })
      canContinue = invalidFields.length === 0
    }
  }

  return <div className='Component-supplier_form whitebox'>
    <h2>Současný dodavatel</h2>

    <Form
      name='provider_details'
      setFormData={setFormData}
      getFormData={getFormData}
      formFields={fields}
    />

    <div className='button-container'>
      <div className='button-wrapper'>
        <div className='button' onClick={() => goToPage('payment_choice')}>
          Zpět
          </div>
      </div>
      <div className='button-wrapper'>
        <div className={'button' + (canContinue ? '' : ' disabled')}
          onClick={() => canContinue && goToPage('supplier_form')}>
          Dále
          </div>
      </div>
    </div>
  </div >;
}

SupplierForm.propTypes = proptypes

export default SupplierForm