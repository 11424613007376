import React from 'react'
import getSize from 'get-size'
import './style.scss'
// import { useSpring, animated } from 'react-spring'
import toPx from 'to-px'
import './style.scss'

function Spinner(props) {
  const {
    size,
    speed,
    bounce,
    config,
    weight,
    reverse,
    className,
    borderWidth,
    ...passProps
  } = props//,
  // [selfRef, setSelfRef] = React.useState(null),
  // [phase, setPhase] = React.useState(1),
  // { val } = useSpring({
  //   val: phase,
  //   config: {
  //     ...{
  //       ...config,
  //       mass: 6 * size,
  //       friction: 10,
  //       tension: 200 * speed,
  //     },
  //     clamp: true
  //   },
  //   onRest: () => setPhase(1 - phase)
  // })

  // React.useEffect(() => {
  //   console.log('setting anim value to', { phase })
  //   setBounce_anim({ val: phase })
  // }, [phase, setBounce_anim])

  return <div className='spinner-wrapper'
    // ref={setSelfRef}
    style={{
      height: `${size * 100}%`,
      width: `${size * 100}%`,
    }}>
    <div className='spinner-animator'
    // style={{
    //   // transform: val.interpolate(val => `scale(${(1 - bounce) + bounce * val})`)
    // }}
    >
      <div className={['spinner', className].toClass()} {...passProps}
        style={{
          animationDuration: `${1 / speed}s`,
          borderWidth,
          // paddingTop: borderWidth,
          // marginBottom: borderWidth / 2,
          // marginLeft: borderWidth / 2,
          paddingBottom: borderWidth,
          paddingLeft: borderWidth,
          // paddingRight: borderWidth,
        }} />
    </div >
  </div>
}

Spinner.defaultProps = {
  config: {},
  size: 1,
  speed: 1,
  reverse: false,
  bounce: .2,
  weight: .05,
}

function LoadingIcon(props) {
  const {
    className
  } = props,
    [selfRef, setSelfRef] = React.useState(null),
    size = selfRef ? Math.max(
      Math.min(getSize(selfRef).width, getSize(selfRef).height),
      toPx(window.getComputedStyle(selfRef).getPropertyValue('font-size')),
    ) : null,
    stroke = size * .05

  // console.log({ size })

  return <div className={['Component-LoadingIcon', className].toClass()} ref={setSelfRef}>
    <div className='spinner-container' style={{
      height: `${size}px`,
      width: `${size}px`,
    }}>
      <Spinner size={1 / Math.E} className='reverse' bounce={.1} borderWidth={stroke / Math.E} />
      <Spinner speed={1 - 1 / Math.E} bounce={.05} borderWidth={stroke} />
    </div>
  </div>
}

export default LoadingIcon