import {
  useHistory,
  useLocation,
} from 'react-router-dom'
import {
  pathToStr,
  serialiseError
} from 'scripts/helpers'

export default function () {
  const
    history = useHistory(),
    location = useLocation()

  return function (error) {
    history.replace({
      pathname: '/error',
      state: {
        from: pathToStr(location),
        error: serialiseError(error)
      }
    })
  }
}