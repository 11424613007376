import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';
import BoonLabel, {
  rawBoon_proptype
} from 'components/BoonLabel'
// import EvenRowGrid from 'components/EvenRowGrid';
import boon_types from 'pages/Offers/boons'
import uuid from 'uuid'
import { hash } from 'scripts/helpers'

const key_seed = uuid()

function BoonLabels(props) {
  const {
    className,
    children: all_boons,
  } = props,
    boons = all_boons
      .filter(({ type }) =>
        boon_types.find(({ type: t }) =>
          t === type
        ).options.displayInHeader
      )
      .slice(0, 2) //-- only display first two boons

  return <>
    <div className={['BoonLabels-Component', className].toClass()}>
      {boons.map((boon, key_iterator) =>
        <BoonLabel key={hash(key_seed, key_iterator)}>
          {boon}
        </BoonLabel>
      )}
    </div>
  </>
}

BoonLabels.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape(rawBoon_proptype))
}

export default BoonLabels