import React, { ReactNodeArray } from 'react';

import './style.scss';
import { hash, mergeDeep } from 'scripts/helpers';
import ResizeObserver from 'components/ResizeObserver';
import uuid from 'uuid'
const key_seed = uuid()

function DisplayFitting(props: {
  className?: string;
  children: ReactNodeArray;
  tolerance?: number;
  onChildChange?: (index: number) => unknown;
}) {
  const {
    className,
    children,
    tolerance,
    onChildChange,
  } = {
    onChildChange: () => { },
    tolerance: 1,
    ...props
  },
    [selfWidth, _set_selfWidth] = React.useState<Number>(Infinity),
    set_selfWidth = React.useCallback((new_selfWidth: number) => {
      console.log({ selfWidth: new_selfWidth })
      _set_selfWidth(new_selfWidth)
    }, []),
    [childSizes, setChildSizes] = React.useState<number[]>([]),
    [currentDisplayedChildIndex, set_currentDisplayedChildIndex] = React.useState<number>(),

    // new_selfWidth_ref = React.useRef<number[]>([]),
    calcChildSize = React.useCallback((measurer_ref: HTMLDivElement, index: number) => {
      const new_childSizes = childSizes.slice(0, children.length)
      new_childSizes[index] = (measurer_ref as Element).scrollWidth

      if (new_childSizes.length !== childSizes.length ||
        new_childSizes.some((new_size, index) =>
          Math.abs(new_size - childSizes[index]) > tolerance
        )) {
        console.log({ childSizes: new_childSizes })
        setChildSizes(old_childSizes => mergeDeep(old_childSizes, new_childSizes) as number[])
      }
    }, [childSizes, children.length, tolerance])

  //-- Call onChildChange function
  React.useEffect(() => {

    if (currentDisplayedChildIndex !== undefined)
      onChildChange(currentDisplayedChildIndex)

  }, [onChildChange, currentDisplayedChildIndex])


  let child_to_display = children.find((_, index) => {
    if (childSizes[index] < selfWidth) {
      set_currentDisplayedChildIndex(index)
      return true
    }
    return false
  })

  return <div className={['DisplayFitting-Component', className].toClass()}>
    <ResizeObserver className='DisplayFitting-content' handleWidth
      onResize={({ width }) => { set_selfWidth(width) }}>
      {child_to_display}
    </ResizeObserver>

    {children.map((child, index) =>
      <div className='hidden' key={hash(key_seed, index)} ref={ref => { if (ref) calcChildSize(ref, index) }}>
        {child}
      </div>)}
  </div>
}

export default DisplayFitting