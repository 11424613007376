import React from 'react';

import Form, {
  generateDefaultData,
  validateForm
} from 'components/Form'
import {
  joinArrayCZ,
  isEmpty,
  reactDev
} from 'scripts/helpers';

import form from 'forms/quote'

import './style.scss'
import { SetFormData, GetFormData } from 'types/FormAPI';
import Indexable from 'types/Indexable';
import useTitle from 'hooks/useTitle';

function SCQuote() {
  const
    [formData, _setFormData] = React.useState(generateDefaultData(form.layout) as Indexable),
    formAPI: {
      getFormData: GetFormData,
      setFormData: SetFormData,
    } = {
      getFormData: key => formData[key],
      setFormData: (key, val) => {
        _setFormData({
          ...formData,
          [key]: val
        })
      }
    },
    Title = useTitle()

  return <>
    <Title>Poptávka</Title>
    <div className='Component-SCQuote whitebox'>
      <h2>Nenašli jste co jste hledali?</h2>
      <h3 className='subtitle'>Zázraky děláme ihned a nemožné na počkání</h3>
      <div className="button help-button" livesupp-call=''>Online chat / Osobní asistence</div>
      <Form
        formFields={form.layout}
        {...formAPI}
      />
      <div className='button'
        onClick={() => {
          const invalid = validateForm(form.layout, formAPI.getFormData)

          if (!isEmpty(invalid))
            alert(`Je třeba správně vyplnit ${joinArrayCZ(invalid)}`)
          else {
            reactDev() && console.log(formData)
            alert('Děkujeme za důvěru. Brzy se ozveme s řešením.')
            _setFormData(generateDefaultData(form.layout))
          }
        }}>
        Odeslat
      </div>
    </div>;
  </>
}

export default SCQuote