import {
  arrayOf,
  func,
} from 'prop-types'
import form_field from './form_field.pt'

export default {
  formFields: arrayOf(
    form_field
  ).isRequired,
  setFormData: func.isRequired,
  getFormData: func.isRequired
}