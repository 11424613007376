import React from 'react';

import './style.scss';
import withResponsivity from 'HOCs/withResponsivity'
import {
  hash
} from 'scripts/helpers';
import landingPagePT from '../landingPagePT'
import uuid from 'uuid'
import setriChytreAPI from 'scripts/SCH_API';
import withTitle from 'HOCs/withTitle';
const
  key_seed = uuid(),
  section_names = [
    'intro',
    'operator',
    'contact',
  ],
  sections = section_names.map(name => require('./sections/' + name).default)

class Energie extends React.Component {
  state = {
    specialOffer: null
  }
  headerRef = document.getElementById('page-header')
  // footerRef = document.getElementById('page-footer')

  componentDidMount = () => {
    this.removeReizeListener =
      this.props.addResizeListener(() => { this.forceUpdate() })

    setriChytreAPI('/offers/specialOffers')
      .then(({ data }) => {
        const thisOffer = data.specialOffers.find(offer =>
          offer && offer.data.uuid === 'eedf2f54-773a-4145-a6ab-a4e1e3509f54')
        this.setState({ specialOffer: thisOffer })
      })
  }
  componentWillUnmount = () => {
    this.removeReizeListener()
  }

  render() {
    const sectionHeight = `calc(100vh - ${this.headerRef.clientHeight}px)`
    // console.log({ sectionHeight })

    return <>
      <this.props.Title>Energie</this.props.Title>
      <div className='Component-Energie'>
        {this.state.specialOffer === null ? null :
          sections.map((Section, index) =>
            <section key={hash(key_seed, index)}
              className={['section-wrapper', `${section_names[index]}-section`].toClass()}>
              <Section
                availableHeight={sectionHeight}
                specialOffer={this.state.specialOffer}
              />
            </section>
          )}
      </div>
    </>
  }
  // }
}

Energie.propTypes = landingPagePT

export default withResponsivity(withTitle(Energie))