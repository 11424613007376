import {
  func,
  object,
} from 'prop-types'

export default {
  state: object.isRequired,
  setState: func.isRequired,
  getState: func.isRequired,
  goToPage: func.isRequired,
}