import React from 'react';
import {
  animated,
  useSpring,
} from 'react-spring'
import './style.scss'

function AnimatedUnderline(props) {
  const
    [hovered, setHovered] = React.useState(false),
    { width } = useSpring({
      to: {
        width: hovered ? '100%' : '0%'
      },
      config: {
        tension: 200,
        friction: 20,
        mass: .5,
      },
    })

  return <div className={'Component-AnimatedUnderline ' + (props.className || '')}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)} >
    {props.children}
    <animated.div className='underline'
      style={{ width }} />
  </div>;
}

export default AnimatedUnderline