import React from 'react';

import {
  FaHandPointRight as OperatorCursor,
} from "react-icons/fa";
import OperatorChatBubble from 'components/OperatorOrb'
import operatorImg from 'assets/Etel-orb.jpg'
import './style.scss';

function operator(props) {
  return <div className='Component-operator'>
    <div className='text-container'>
      <div className='operator-text-container'>
        <div className='operator-text'>
          Pro nezávaznou nabídku se spojte s našimi operátory
              </div>
        <OperatorCursor className='operator-cursor-icon' />
      </div>
      {/* <div className='alt-text-container'>
        <div className='alt-text'>
          nebo nahrajte své poslední vyúčtování
              </div>
      </div> */}
    </div>
    <OperatorChatBubble image={operatorImg} size='5cm' />
  </div>;
}

export default operator