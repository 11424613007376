import React from 'react'
import PropTypes from 'prop-types'
// import ColouredTitle from './ColouredTitle'
// import MidBold from './MidBold'
import {
  currencyCZ,
  isNumber,
} from 'scripts/helpers'
import './style.scss'

function CostCell(props: {
  className?: string
  children?: React.ReactNode
  product?: string
  compact?: boolean
  label?: JSX.Element
  vat?: number | null
  cost: number
}) {
  const {
    className,
    children,
    product,
    compact,
    label,
    vat,
    cost,
    ...passProps
  } = props

  return <div className={(['CostCell-Component', compact ? 'compact' : 'full', className] as any).toClass()} {...passProps}>
    <div className='title'>
      {children || label}
    </div>
    <span className='amount'>
      {currencyCZ(
        isNumber(vat) ? cost * (1 + (vat as number)) :
          cost
      )}
    </span>
    <div className='dph-disclaimer'>
      ({isNumber(vat) ? 'vč.' : 'bez'} DPH)
    </div>
  </div>
}

CostCell.propTypes = {
  compact: PropTypes.bool.isRequired,
  cost: PropTypes.number.isRequired,
  vat: PropTypes.number,
}

export default CostCell
