import React, { PureComponent, forwardRef } from 'react';

import PropTypes from 'prop-types';

import moment from 'moment'
import DatePicker from 'react-datepicker'
import { FaCalendarAlt as CalendarIco } from 'react-icons/fa'

import Input from '..'
import {
  daysInMonth,
  toDate,
  monthsInYear
} from '../../../scripts/helpers';

import maybeDatePT from '../../../types/maybe_date'

import './style.scss'

class InputDate extends PureComponent {

  clampDate = date => {
    const
      min = toDate(this.props.min),
      max = toDate(this.props.max)

    // console.log(date, min, max)

    if (date < min)
      return (date = min)
    else if (date > max)
      return (date = max)
    return date
  }

  onChange = raw_date => {
    const date = this.clampDate(raw_date)

    switch (this.props.returnAs) {
      case 'date':
        this.props.onChange(date)
        break
      case 'string':
        this.props.onChange(date.toString())
        break
      case 'timestamp':
        this.props.onChange(date.getTime())
        break
      default:
        console.error('Invalid returnAs prop on', this)

    }
    // console.log(date)
  }

  render() {
    let date = toDate(this.props.value)

    this.clampDate(date)

    // console.log(this.props.value, '->', date)


    moment.locale('cs')
    const
      days = Array(daysInMonth(date.getMonth() - 1, date.getFullYear()))
        .fill(undefined)
        .map((_, i) => ({ label: i + 1, value: i + 1 })),
      months = monthsInYear('cs')
        .map(((m, i) => ({
          label: m,
          value: i
        }))),
      years = Array(toDate(this.props.max).getFullYear() - toDate(this.props.min).getFullYear() + 1)
        .fill(toDate(this.props.min).getFullYear())
        .map((y, i) => ({
          label: (y + i).toString(),
          value: y + i
        }))

    const ForwardableCalendarIco = forwardRef((props, _) => <CalendarIco {...props} />)

    return <div className={this.props.className + ' Component-InputDate'}>
      <div className='field-name'>
        {this.props.placeholder}
      </div>
      <Input className='day-input'
        type='select'
        placeholder='Den'
        mandatory={true}
        data={days}
        value={date.getDate()}
        onChange={day => {
          date.setDate(Number(day))
          this.onChange(date)
        }}
      />
      <Input className='month-input'
        type='select'
        placeholder='Měsíc'
        mandatory={true}
        data={months}
        value={date.getMonth()}
        onChange={month => {
          date.setMonth(Number(month))
          this.onChange(date)
        }}
      />
      <Input className='year-input'
        type='select'
        placeholder='Rok'
        mandatory={true}
        data={years}
        value={date.getFullYear()}
        onChange={year => {
          date.setFullYear(Number(year))
          this.onChange(date)
        }}
      />
      <DatePicker
        // localeString='CS'
        // locale='cs'
        selected={date}
        onChange={this.onChange}
        popperClassName='InputDate-select'
        nextMonthButtonLabel=">>"
        previousMonthButtonLabel="<<"
        customInput={<ForwardableCalendarIco />}
        maxDate={toDate(this.props.max)}
        minDate={toDate(this.props.min)}
      />
    </div>;
  }
}

InputDate.propTypes = {
  value: maybeDatePT.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  min: maybeDatePT,
  max: maybeDatePT,
  returnAs: PropTypes.oneOf(['date', 'string', 'timestamp'])
}
InputDate.defaultProps = {
  onEnter: () => { },
  onLeave: () => { },
  returnAs: 'string',
  min: 0,
  max: new Date()
}

export default InputDate