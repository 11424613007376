import React from 'react'

// import PropTypes from 'prop-types'

import withResponsivity from 'HOCs/withResponsivity'

import {
  hash,
} from 'scripts/helpers'
import {
  getSCNavItems
} from 'forms'
import './style.scss'
import uuid from 'uuid'
import EvenRowGrid from 'components/EvenRowGrid'
import OperatorChatBubble from 'components/OperatorOrb'
import Tile from './Tile'
import operatorImg from 'assets/Etel-orb.jpg'
import withTitle from 'HOCs/withTitle'
const key_seed = uuid()

function Bubble(props) {
  const {
    className,
    children,
    ...passProps
  } = props

  return <div className={['text-bubble-wrapper', className].toClass()} {...passProps}>
    <div className='text-bubble'>
      <div className='text-bubble-text'>
        {children}
      </div>
    </div>
  </div>
}

class Home extends React.Component {

  state = {
    hoverOrb: false,
    startConversation: false,
  }

  formsNavItems = getSCNavItems()

  // componentDidMount = () => {
  //   this.removeResizeListener = this.props.addResizeListener(() => this.forceUpdate)
  // }
  // componentWillUnmount = () => {
  //   this.removeResizeListener()
  // }

  render() {
    const
      hoverEvents = this.props.isGreaterThan('medium') ? {
        onMouseEnter: () => this.setState({ hoverOrb: true }),
        onMouseLeave: () => this.setState({ hoverOrb: false }),
        onClick: () => this.setState({ startConversation: true },
          () => this.setState({ startConversation: false }))
      } : {},
      bubbles = {
        tl: anim_order =>
          <Bubble {...hoverEvents}
            className={`text-bubble-wrapper-top text-bubble-wrapper-left text-bubble-wrapper-top-left anim-order-${anim_order}`}>
            Srovnání akčních nabídek férových a stabilních dodavatelů
          </Bubble>,
        bl: anim_order =>
          <Bubble {...hoverEvents}
            className={`text-bubble-wrapper-bottom text-bubble-wrapper-left text-bubble-wrapper-bottom-left anim-order-${anim_order}`}>
            Odborné poradenství a úspory i u současného dodavatele
          </Bubble>,
        tr: anim_order =>
          <Bubble {...hoverEvents}
            className={`text-bubble-wrapper-top text-bubble-wrapper-right text-bubble-wrapper-top-right anim-order-${anim_order}`}>
            Zabezpečená online komunikace s asistentem jedním klikem
          </Bubble>,
        br: anim_order =>
          <Bubble {...hoverEvents}
            className={`text-bubble-wrapper-bottom text-bubble-wrapper-right text-bubble-wrapper-bottom-right anim-order-${anim_order}`}>
            {/* Vše potřebné vyřešíte online a dokumenty přiveze kurýr */}
              Vše vyřešíte online už do 2 minut a dokumenty přiveze kurýr
          </Bubble>
      },
      operatorBubble = <OperatorChatBubble
        image={operatorImg} size='200px'
        forceHover={this.state.hoverOrb}
        forceConversation={this.state.startConversation}
      />

    return <>
      <this.props.Title />
      <div className='Component-Home'>
        <div id='intro'>
          <div className='title emphasise'>
            <h1>
              Snížíme náklady každé domácnosti a firmě
            </h1>
          </div>
          <div className='chat-section'>
            {this.props.isGreaterThan('section') ?
              <>
                <div className='text-bubble-container text-bubble-container-left'>
                  {bubbles.tl(1)}
                  {bubbles.bl(2)}
                </div>
                {operatorBubble}
                <div className='text-bubble-container text-bubble-container-right'>
                  {bubbles.tr(3)}
                  {bubbles.br(4)}
                </div>
              </> :
              <>
                {operatorBubble}
                <div className='text-bubble-container'>
                  {bubbles.tl(1)}
                  {bubbles.bl(2)}
                  {bubbles.tr(3)}
                  {bubbles.br(4)}
                </div>
              </>}
          </div>
        </div>
        <div id='savings' className='savings'>
          <h2 className='heading emphasise'>
            Kde chcete ušetřit?
          </h2>
          <div className='tiles-container'>
            <EvenRowGrid
              columnGap='40px'
              rowGap={this.props.isGreaterThan('medium') ? '40px' : '10px'}
              // verbose={true}
              justifyContent='center'
            >
              {this.formsNavItems.map((form, key_iterator) =>
                <Tile key={hash(key_seed, key_iterator)} {...form} />
              )}
            </EvenRowGrid>
          </div>
        </div>
      </div >
    </>
  }
}

// Home.propTypes = {
//   forms: PropTypes.arrayOf(PropTypes.object).isRequired
// }

export default withResponsivity(withTitle(Home))