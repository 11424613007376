import React, { useState } from 'react'
import { createPortal } from 'react-dom'

import {
  arrayOf,
  func,
} from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom'
import {
  hash,
  strToPath,
  pathCompare,
  isObject,
} from 'scripts/helpers'
import getSize from 'get-size'
import {
  useSpring,
  animated,
} from 'react-spring'
import {
  GiHamburgerMenu as MenuIcon
} from 'react-icons/gi'
import './style.scss'
import uuid from 'uuid'
import form_navItem from 'types/form_navItem';
import Expander from 'components/Expander';
// import reactComponent from 'types/reactComponent';
const key_seed = uuid()

function MobileMenu(props) {

  const {
    className,
  } = props,
    [isOpen, setOpen] = useState(false),
    header = document.querySelector('#page-header .Header-Component'),
    headerHeight = header ? getSize(header).height : null
  // ,buttonSlidein = useSpring({
  //   to: {
  //     opacity: isOpen ? 1 : 0,
  //     left: isOpen ? '0%' : '100%',
  //   },
  //   from: {
  //     opacity: isOpen ? 0 : 1,
  //     left: isOpen ? '100%' : '0%',
  //   }
  // })

  function open() {
    props.onMenuOpen()
    setOpen(true)
  }
  function close() {
    props.onMenuClose()
    setOpen(false)
  }
  function toggle() {
    switch (isOpen) {
      case true:
        close()
        break
      case false:
        open()
        break
      default:
        console.error('Unrecognised menu state:', isOpen)
    }
  }

  React.useEffect(() => {
    props.history.listen(close)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className={['Component-MobileMenu', className].toClass()}>

    <div className='mobile-menu-icon-wrapper'>
      <MenuIcon onClick={toggle} />
    </div>

    {createPortal(
      <div id='mobile-menu-container' style={{ pointerEvents: isOpen ? null : 'none' }}>
        <animated.div className='backdrop' onClick={close} style={useSpring({ opacity: isOpen ? 1 : 0 })} />
        <Expander className='mobile-menu-expander' content_className='mobile-menu-expander-content' direction='horizontal' {...{ isOpen }}>
          <div className='mobile-menu' style={{ paddingBlockStart: `${headerHeight}px` }}>
            <div className='scroll-wrapper' style={{ maxHeight: `calc(100% - ${headerHeight}px)` }}>
              <div className='menu-button-container'>
                {props.itemGroups.map((itemGroup, key_itterator) =>
                  itemGroup.map((item, item_key_itterator) =>
                    <div className='menu-button-wrapper'
                      key={hash(key_seed, key_itterator, item_key_itterator)}
                    // style={buttonSlidein}
                    >
                      <NavLink
                        to={{
                          ...strToPath(item.path),
                          state: isObject(item.options) ?
                            item.options.state :
                            undefined,
                        }}
                        isActive={() => pathCompare(
                          props.location,
                          item.activePath || item.path
                        )}
                        className={['menu-button', item.class].toClass()}
                        activeClassName='current-page'>
                        {item.icon}
                        <span className='text-container'>
                          {item.label}
                        </span>
                      </NavLink>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Expander>
      </div>,
      document.getElementById('page-header'))}
  </div>
}

MobileMenu.propTypes = {
  // WithColor: reactComponent.isRequired,
  itemGroups: arrayOf(arrayOf(form_navItem)).isRequired,
  onMenuOpen: func.isRequired,
  onMenuClose: func.isRequired,
}

export default withRouter(MobileMenu)