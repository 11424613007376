import React, { Component } from 'react';

import {
  Redirect,
  Route,
  Switch,
  withRouter
} from 'react-router-dom'

import Footer from 'components/SCFooter'
import Header from 'components/SCHeader'
import Checkout from 'pages/Checkout'
import ErrorPage from 'pages/Error'
import Home from 'pages/Home'
import KlubChytrych from 'pages/Club'
import Kontakt from 'pages/Contact'
import Offers, {
  offersBasePath,
} from 'pages/Offers'
import Quote from 'pages/Quote'
import RedirectPage from 'pages/RedirectPage'
import LoadingPage from 'pages/LoadingPage'
import LandingPage from 'pages/LandingPage'
import SpecialOffers from 'pages/SpecialOffers'
import TestingPage from 'pages/TestingPage'
import AzCustomiser from 'pages/AzCustomiser'
import Form from 'pages/Forms'
// import formNames from 'forms'
import {
  // hash,
  reactDev,
  // isObject,
  // isEmpty,
  setLocalItem,
  setSessionItem,
  getSessionItem,
  getLocalItem,
  removeSessionItem,
  // pathToStr,
  hash,
  disableBodyScroll,
  enableBodyScroll,
  isVoid,
  // syntheticRender,
} from 'scripts/helpers'
import getCustomer from 'scripts/SCH_getCustomer'
import pairCustomer from 'scripts/SCH_pairCustomer'
import uuid from 'uuid'
// import Title from 'components/SCTitle'
import GDPR from 'assets/documents/GDPR'
import EULA from 'assets/documents/EULA'
import ActionOffers from 'pages/Offers/ActionOffers'
import {
  formBasePath,
} from 'forms'
import withError from 'HOCs/withError';
// import cloneDeep from 'lodash/cloneDeep'
// const isMobile = true

const lsPairId = uuid()

class Main extends Component {
  constructor(props) {
    super(props)
    this.unlisten = this.props.history.listen(this.onPageChanage)

    // assign machine and session ID
    if (!getLocalItem('machineId'))
      setLocalItem('machineId', uuid())
    if (!getSessionItem('sessionId'))
      setSessionItem('sessionId', uuid())

    // Establish new customer if one isn't already, or is from different session
    this.state = {
      isCustomerValidated: false,
      isCustomerPaired: false,
      isLoading: false,
    }
  }

  getCustomer = (customerGetAttempt = 0) => {
    return new Promise((resolve, reject) => {
      if (!this.state.isCustomerValidated) {
        if (customerGetAttempt < 1) { // MAX ATTEMPTS
          // console.log('Getting customer attempt:', customerGetAttempt + 1)
          getCustomer()
            .then(customer => {
              // console.log({ customer })
              setSessionItem('customerId', customer.id)

              resolve({
                pairing: new Promise((resolvePairing, rejectPairing) => {
                  pairCustomer()
                    .then(
                      (...data) => {
                        setSessionItem('lsPairId', lsPairId)
                        resolvePairing(...data)
                      },
                      (...data) => {
                        removeSessionItem('lsPairId')
                        rejectPairing(...data)
                      }
                    )
                })
              })
            })
            .catch((...err) => {
              console.error({ customerGetAttempt }, ...err)
              this.getCustomer(customerGetAttempt + 1)
                .then(resolve, reject)
            })
        }
        else {
          removeSessionItem('customerId')
          reject('filed to establish customer')
        }
      }
    })
  }

  // componentDidMount() {
  //   // Establish new customer
  //   this.getCustomer().then(
  //     ({ pairing }) => {
  //       // console.log(pairing)
  //       this.setState({ isCustomerValidated: true })
  //       pairing.then(
  //         () => this.setState({ isCustomerPaired: true }, () => {
  //           // console.log('customer paired with LS')
  //         }),
  //         () => this.setState({ isCustomerPaired: false }, () => {
  //           console.warn('customer LS pairing failed')
  //         }),
  //       )
  //     },
  //     (...err) => {
  //       console.error(...err)
  //       this.setState({ isCustomerValidated: false })
  //     })
  //     .finally(() => {
  //       this.setState({ isLoading: false })
  //     })
  // }
  componentWillUnmount() {
    this.unlisten()
    window.clearTimeout(this.customerFetchTimeout)
  }
  componentDidUpdate() {
    if (this.state.redirect !== null)
      this.setState({ redirect: null })
  }

  onPageChanage = () => {
    window.scrollTo(0, 0)
  }

  RequireCustomer = ({ children: toRender }) => {
    if (this.state.isCustomerValidated)
      return toRender;

    if (isVoid(this.customerFetchTimeout))
      this.customerFetchTimeout = setTimeout(() => {
        const load = () => {
          disableBodyScroll()
          document.body.classList.add('loading')
          // console.log('getting customer')
          this.getCustomer()
            .then(pairing => {
              // console.log('customer paired', { pairing })
              // this.props.throwError('test error')
              if (this.state.isCustomerValidated !== true)
                this.setState({ isCustomerValidated: true })

              Promise.resolve(pairing)
                .then(() => {
                  if (this.state.isCustomerPaired !== true)
                    this.setState({ isCustomerPaired: true })
                })
                .catch(() => {
                  if (this.state.isCustomerPaired !== false)
                    this.setState({ isCustomerPaired: false })
                })
            })
            .catch((...err) => {
              console.error('customer failed to pair:', ...err)
              this.props.throwError('Failed to establish customer')
            })
            .finally(() => {
              this.setState({ isLoading: false },
                () => {
                  enableBodyScroll()
                  document.body.classList.remove('loading')
                })
            })
        }

        if (this.state.isLoading !== true)
          this.setState({ isLoading: true }, load)
        else load()

        delete this.customerFetchTimeout
      }, 0)

    return null
  }

  render() {
    const
      redirectMessage = getSessionItem('redirectMessage'),
      renderIfRedirectMessageSet = Comp => {
        if (redirectMessage !== null) {
          removeSessionItem('redirectMessage')
          return Comp
        }
        return null
      }

    // console.log(pathToStr(this.props.location))
    return <>

      <Header />

      <Switch>
        <Route exact path='/error'>
          <ErrorPage />
        </Route>

        <Route>
          <LoadingPage
            loading={this.state.isLoading}
            retry={this.getCustomer}
          />
          <Switch>
            <Route exact path='/gdpr'>
              {() => window.location.replace(GDPR)}
            </Route>
            <Route exact path='/eula'>
              {() => window.location.replace(EULA)}
            </Route>

            <Route strict path='/landing-page' component={LandingPage} />

            <Route strict path='/special-offer' component={SpecialOffers} />

            {renderIfRedirectMessageSet(<Route>
              <RedirectPage message={redirectMessage} />
            </Route>)}

            {reactDev() ? <Route exact path='/_redirectPage'>
              <RedirectPage message='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nec auctor lectus, eu varius nisi. In elit magna, rutrum ac.' />
            </Route> : null}
            {reactDev() ? <Route exact path='/_loadingPage'>
              <LoadingPage />
            </Route> : null}
            {reactDev() ? <Route exact path='/_test'>
              <TestingPage />
            </Route> : null}

            <Route exact path='/az-customiser'>
              <AzCustomiser />
            </Route>

            <Route exact path={`${formBasePath}/:formTypeSlug/:formPageSlug`}>{props => {
              // console.log({ props })
              return <this.RequireCustomer>
                <Form {...props}
                  //-- Key makes sure that a new form is rendered on page change
                  key={hash(props.match.params.formTypeSlug)}
                />
              </this.RequireCustomer>
            }
            }</Route>

            <Route exact path={`${offersBasePath}/akcni`}>
              <this.RequireCustomer>
                <ActionOffers />
              </this.RequireCustomer>
            </Route>

            <Route exact path={`${offersBasePath}/:formTypeSlug/:formPageSlug`}>{
              props => <this.RequireCustomer>
                <Offers {...props} key={hash(...Object.values(props.match.params))} />
              </this.RequireCustomer>
            }</Route>

            <Route exact path='/poptavka'>
              <Quote />
            </Route>

            <Route exact path='/klub-chytrych'>
              <KlubChytrych />
            </Route>

            <Route exact path='/kontakt'>
              <Kontakt />
            </Route>

            <Route exact path='/objednavka'>
              <this.RequireCustomer>
                <Checkout />
              </this.RequireCustomer>
            </Route>

            <Route exact path='/'>
              <Home />
            </Route>

            <Redirect to='/' />
          </Switch>
        </Route>

      </Switch>

      <Footer />

    </>
  }
}

export default withRouter(withError(Main));
