import React from 'react'
import { toNumber } from 'scripts/helpers'
import Provider from 'types/Provider'

export interface mobile_formData {
  phoneUser: any[],
  type: 'household' | 'business',
  productProvider: number,
  cost?: number
}

const form = {
  label: 'Mobilní tarify',
  value: 'mobile',
  title: 'Kalkulačka ceny mobilních tarifů',
  tagline: 'Pokud chcete s nabídkou pomoci, stačí kliknout na ikonu operátora',
  submit: 'Porovnat',
  options: {
    disabled: true,
    showHelpButtom: true,
    hooks: {
      onLoad: ({ productProviders }: { productProviders: Provider[] }) => {
        // console.log({ data })
        return ({
          productProviders: productProviders
            .map(provider => ({
              value: provider.id,
              label: provider.name
            }))
        })
      },
      onSubmit: (formData: mobile_formData) => {

        interface mobile_tariff_info {
          calling: {
            unlimited: boolean,
            atLeast: number
          },
          sms: {
            unlimited: boolean,
            atLeast: number
          },
          data: {
            unlimited: boolean,
            atLeast: number
          }
        }

        const numbers: mobile_tariff_info[] =
          formData.phoneUser.map(number => ({
            calling: {
              unlimited: number['minutes-select'] === 'inf',
              atLeast: number['minutes-select'] === 'exact' ?
                number['minutes-number'] :
                toNumber(number['minutes-select'])
            },
            sms: {
              unlimited: number['sms-select'] === 'inf',
              atLeast: number['sms-select'] === 'exact' ?
                number['sms-number'] :
                toNumber(number['sms-select'])
            },
            data: {
              unlimited: number.data === 'inf',
              atLeast: number.data === 'inf' ? 0 : toNumber(number.data)
            }
          }))

        return {
          type: formData.type,
          numbers: numbers,
          currentProvider: Number(formData.productProvider),
          currentMonthlyCost: Number(formData.cost) || null
        }
      },
      onOffers: ({ products, offers }: { products: any[], offers: any[] }, args: any) => {
        // console.log(args)

        products = products.map(offer => {

          let available_for: number[] = []

          offers.forEach((valid_offers, num_index) => {
            if (valid_offers.includes(offer.id))
              available_for.push(num_index + 1)
          })

          let unavailable_for = args.numbers
            .map((_: any, i: number) => i + 1)
            .filter((x: number) => !available_for.includes(x))

          let numbers_message

          if (available_for.length === args.numbers.length) {
            //   numbers_message = 'Tato nabídka je dostupná pro všechna čísla.'
          }
          else if (available_for.length === 1)
            numbers_message = `Tato nabídka je dostupná jen pro číslo ${available_for[0]}`
          else if (unavailable_for.length === 1)
            numbers_message = `Tato nabídka není dostupná pro číslo ${unavailable_for[0]}`
          else {
            numbers_message = `Tato nabídka je dostupná jen pro čísla ${
              available_for.slice(0, available_for.length - 1).join(', ')
              } a ${available_for[available_for.length - 1]}`
          }

          // console.log(available_for)

          const
            new_cost =
              unavailable_for.length * args.currentMonthlyCost / args.numbers.length +
              available_for.length * offer.costs.totalCostPerMonth

          // console.log(offer.id, savings)

          let
            info = [],
            fields = [],
            warnings = numbers_message === undefined ? (offer.warnings || []) : [
              numbers_message,
              ...(offer.warnings || [])
            ]

          if (args.numbers.length > 1) {
            info.push({
              label: 'Dostupná pro čísl' + (available_for.length === 1 ? 'o' : 'a'),
              value: available_for.length === 1 ?
                available_for[0] :
                `${available_for.slice(0, available_for.length - 1).join(', ')} a ${available_for[available_for.length - 1]}`
            })
          }

          info.push(
            {
              label: 'Poskytovatel',
              value: offer.provider.name
            },
            {
              label: 'Produkt',
              value: offer.productName
            },
            {
              label: 'Minuty',
              value: offer.calling.unlimited ? 'Neomezené' : `${offer.calling.count}`
            },
            {
              label: 'Počet SMS',
              value: offer.sms.unlimited ? 'Neomezený' : `${offer.sms.count}`
            },
            {
              label: 'Data',
              value: offer.data.unlimited ? 'Neomezená' : `${offer.data.count} MB`
            },
            {
              label: 'Typ nabídky',
              value: offer.offerType === 'private' ? 'Neveřejná' : 'Veřejná'
            }
          )

          if (available_for.length > 1)
            info.push({
              label: 'Měsíční cena za jedno číslo',
              value: `${Math.round(offer.costs.totalCostPerMonth)} Kč`
            })

          if (unavailable_for.length)
            info.push({
              label: 'Měsíční cena za čísla pokrytá touto nabídkou',
              value: `${Math.round(offer.costs.totalCostPerMonth * available_for.length)} Kč`
            })

          info.push(
            {
              label: 'Měsíční cena' + (args.numbers.length > 1 ? ' za všechna čísla' : ''),
              value: `${Math.round(new_cost)} Kč`
            }
          )

          fields.push(
            {
              name: 'calls',
              selector: offer.calling.unlimited ? Infinity : offer.calling.count,
              cell: <div>
                {offer.calling.unlimited ?
                  <b>Neomezené volání</b> :
                  offer.calling.count + ' minunt'}
              </div>
            },
            {
              name: 'sms',
              selector: offer.sms.unlimited ? Infinity : offer.sms.count,
              cell: <div>
                {offer.sms.unlimited ?
                  <b>Neomezené SMS</b> :
                  offer.sms.count + ' SMS'}
              </div>
            },
            {
              name: 'data',
              selector: offer.data.unlimited ? Infinity : offer.data.count,
              cell: <div>
                {offer.data.unlimited ?
                  <b>Neomezené data</b> :
                  offer.data.count + ' MB'}
              </div>
            },
            {
              name: 'price',
              selector: '',
              cell: <div>
                {offer.costs.totalCostPerMonth} Kč za měsíc
                </div>
            }
          )

          if (args.currentMonthlyCost) {
            const
              absolute_saving = Math.round(args.currentMonthlyCost - new_cost),
              saving = {
                absolute: absolute_saving,
                percentage: Math.round(100 * absolute_saving / args.currentMonthlyCost),
              }

            info.push({
              label: 'Ušetříte',
              value: `${saving.percentage}% (${saving.absolute * 12} Kč za rok)`
            })

            fields.push({
              name: 'saving',
              selector: saving.absolute,
              cell: <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}>
                <span>Ušetříte&nbsp;
                    <b>{saving.percentage}%</b>
                </span>
                <span>({saving.absolute * 12} Kč za rok)</span>
              </div>
            })
          }

          // console.log(offer, available_for, costs)

          return {
            ...offer,
            cost: offer.costs.totalCostPerMonth,
            info: info,
            warnings: warnings,
            fields: fields
          }
        })

        // console.log(products)

        return products
      }
    }
  },
  fields: [
    {
      name: 'type',
      type: 'switch',
      options: {},
      data: [
        {
          label: 'Osobní',
          value: 'household'
        },
        {
          label: 'Firemní',
          value: 'business'
        }
      ]
    },
    {
      name: 'phoneUser',
      type: 'cloneable',
      options: {
        button: 'Přidat číslo'
      },
      data: [
        {
          name: 'minutes-select',
          type: 'select',
          options: {
            placeholder: 'Počet minut',
            mandatory: true
          },
          data: [
            {
              label: 'Žádné',
              value: 0
            },
            {
              label: '100',
              value: 50
            },
            {
              label: '200',
              value: 250
            },
            {
              label: 'Neomezeně',
              value: 'inf'
            },
            {
              label: 'Zadat přesný počet',
              value: 'exact'
            }
          ]
        },
        {
          name: 'minutes-number',
          type: 'number',
          options: {
            min: 0,
            step: 10,
            label: 'min',
            placeholder: 'Přesný počet minut',
            conditional: [
              [
                {
                  key: '.minutes-select',
                  value: 'exact'
                }
              ]
            ],
            mandatory: true
          }
        },
        {
          name: 'sms-select',
          type: 'select',
          options: {
            placeholder: 'Počet SMS',
            mandatory: true
          },
          data: [
            {
              label: 'Žádné',
              value: 0
            },
            {
              label: '100',
              value: 50
            },
            {
              label: '200',
              value: 250
            },
            {
              label: 'Neomezeně',
              value: 'inf'
            },
            {
              label: 'Zadat přesný počet',
              value: 'exact'
            }
          ]
        },
        {
          name: 'sms-number',
          type: 'number',
          options: {
            min: 0,
            step: 10,
            label: 'SMS',
            placeholder: 'Přesný počet SMS',
            conditional: [
              [
                {
                  key: '.sms-select',
                  value: 'exact'
                }
              ]
            ],
            mandatory: true
          }
        },
        {
          name: 'data',
          type: 'select',
          options: {
            placeholder: 'Data',
            mandatory: true
          },
          data: [
            {
              label: 'Žádná',
              value: 0
            },
            {
              label: '50 MB',
              value: 50
            },
            {
              label: '250 MB',
              value: 250
            },
            {
              label: '500 MB',
              value: 500
            },
            {
              label: '1,5 GB',
              value: 1500
            },
            {
              label: '3 GB',
              value: 3000
            },
            {
              label: '10 GB',
              value: 10000
            },
            {
              label: 'Neomezené',
              value: 'inf'
            }
          ]
        }
      ]
    },
    {
      name: 'productProviders',
      type: 'select',
      options: {
        placeholder: 'Současný dodavatel',
        tooltip: 'Vyberte dodavatele, od kterého teď odebíráte elektřinu. Porovnáme vám jeho ceny s ostatními na trhu.',
        mandatory: true
      }
    },
    // currentMonthlyCost
  ]
}

export default form