import React from 'react';

import PropTypes from 'prop-types';

import LoadingSpinner from 'components/LoadingIcon'
import JumpingEllipsis from 'components/JumpingEllipsis'

import styled from 'styled-components'

import './style.scss'
import SCButton from 'components/SCButton';
import Braille from 'components/ASCIIanimation/Braille';
import { constructArray, hash } from 'scripts/helpers';
import uuid from 'uuid'
const key_seed = uuid()

function OfferLoading(props) {
  const {
    className,
    compact,
    // index,
  } = props

  return <div className={['Component-OfferLoading', className,
    compact ? 'Component-CompactOffer' : 'Component-FullOffer',
  ].toClass()}>
    <div className='fake-header'>
      <JumpingEllipsis />
      {/* {constructArray(5, i => <Braille key={hash(key_seed, 1, i)} />)} */}
    </div>
    <div className='fake-body'>
      <SCButton className='fake-button'
        icon={<LoadingSpinner size={20} weight={2} duration={2} />}
        iconPos='after'>
        {/* <JumpingEllipsis /> */}
        {constructArray(5, i => <Braille key={hash(key_seed, 2, i)} />)}
      </SCButton>
    </div>
  </div>;
}

OfferLoading.propTypes = {
  index: PropTypes.number.isRequired,
  compact: PropTypes.bool.isRequired,
}

export default styled(OfferLoading)`
  &:before {
    animation-delay: ${props => props.index * .4 + 's'};
  }
`