import React, { ReactNode } from 'react'
import { isArray } from 'util'
import Helmet from 'react-helmet'

function createTitleComponent(title?: string) {
  return function (props: {
    children: string | string[]
  }) {
    const
      { children } = props,
      subtitle = isArray(children) ? children : [children]

    // console.log({ title, ...subtitle })
    return <Helmet>
      <title>{[title, ...subtitle].pureJoin(' - ')}</title>
    </Helmet>
  }
}

const TitleContext = React.createContext(createTitleComponent(''))
export default TitleContext
export const TitleConsumer = TitleContext.Consumer
export function TitleProvider(props: {
  title?: string,
  children: ReactNode,
}) {

  const {
    title,
    children,
  } = props

  return <TitleContext.Provider value={createTitleComponent(title)}>
    {children}
  </TitleContext.Provider>
}