import { isReactComponent } from "scripts/helpers"

function reactComponent(props, propName, componentName) {
  const
    prop = props[propName],
    isComponent = isReactComponent(prop)

  // console.log('checking if is reactComponent', prop, { isComponent })

  if (!isComponent)
    return new Error(`\`${componentName}\` expects a react component (not element) as \`${propName}\`, got \`${prop}\``)
}

reactComponent.isRequired = function (props, propName, componentName) {
  if (!(propName in props))
    return new Error(`\`${propName}\` is missing in \`${componentName}\``)
  return reactComponent(props, propName, componentName)
}

export default reactComponent