import React from 'react';
import {
  arrayOf,
  oneOf,
  func,
} from 'prop-types'
import './style.scss';
import FullMenu from './Full';
import MobileMenu from './Mobile';
import form_navItem from 'types/form_navItem';

function Menu(props) {
  // console.log(props)

  switch (props.type) {
    case 'full':
      // props.onMenuClose()
      return <FullMenu {...props} />
    case 'mobile':
      return <MobileMenu {...props} />
    default:
      throw new Error(`Invalid menu type: ${props.type}`)
  }
}

Menu.propTypes = {
  itemGroups: arrayOf(arrayOf(form_navItem)).isRequired,
  type: oneOf(['full', 'mobile']).isRequired,
  onMenuOpen: func,
  onMenuClose: func
}
MobileMenu.defaultProps = {
  onMenuOpen: () => { },
  onMenuClose: () => { }
}

export default Menu