import React from 'react';
// import { hash } from 'scripts/helpers'
import uuid from 'uuid'
const
  child = uuid(),
  innerRef = uuid()

class WithForceUpdate extends React.Component {
  boundForceUpdate = this.forceUpdate.bind(this)

  render() {
    const {
      [child]: Component,
      [innerRef]: ref,
      ...passProps
    } = this.props

    // console.log(this.props, Component)

    return <Component {...passProps}
      forceUpdate={this.boundForceUpdate}
    />
  }
}

export default Component => {
  // console.log({ Component })

  return React.forwardRef((props, ref) =>
    <WithForceUpdate {...{ [child]: Component, ...props, [innerRef]: ref }} />)
}