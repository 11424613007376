import React from 'react'

const childStyle = {
  lineHeight: '1.5em',
}
export default props => <div style={props.compact ?
  {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'center',
  } :
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  }}>
  {props.children[0] === null ? props.label ? props.label : null : <span style={{ ...childStyle }}>{props.children[0]}</span>}
  {props.children[1] === null ? null : <b style={{ ...childStyle, fontSize: '120%' }}>&nbsp;{props.children[1]}&nbsp;</b>}
  {props.children[2] === null ? null : <span style={{ ...childStyle }}>{props.children[2]}</span>}
</div>