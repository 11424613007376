import React from 'react'
import ResponsivityContext from 'contexts/Responsivity'
import useError from './useError'

export default function useResponsivity() {
  const
    responsivity = React.useContext(ResponsivityContext),
    throwError = useError()

  if (responsivity === null)
    throwError('Responsivity is null');

  return responsivity as NonNullable<typeof responsivity>
}