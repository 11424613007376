import React from 'react';
import PropTypes from 'prop-types'
import offerPT from 'types/offer'
// import OfferHeader from '../Header'
import {
  isEmpty,
  hash,
  syntheticRender,
} from 'scripts/helpers'
import './style.scss';
import uuid from 'uuid'
import StarfallBackground from '../StarfallBackground';
import PrivateRibbon from '../PrivateRibbon';
import BoonLabels from '../BoonLabels';
const key_seed = uuid()

function CompactOffer(props) {
  const {
    logo,
    offer,
    noButtons,
    className,
  } = props,
    isPrivate = offer.boons.some(boon =>
      boon.source === 'productProvider' &&
      boon.type === 'vipPartner'),
    headerContent = <>
      <div className='text-container'>
        <div className='companyName'>
          {offer.provider.name}
        </div>
        <div className='productName'>
          {offer.name}
        </div>
      </div>
      <BoonLabels className='compact-boons-wrapper'>
        {offer.boons}
      </BoonLabels>
    </>

  // console.log({ offer })

  return <div className={[className, 'Component-CompactOffer'].toClass()}>
    <div className='logo-wrapper'>
      {logo}
      {(isPrivate && !noButtons) ?
        <div className='private-ribbon-wrapper'>
          <PrivateRibbon angle={25} verticalPadding='8px' />
        </div> :
        null}
    </div>

    {isPrivate ?
      <StarfallBackground className='offer-header private-header'>
        {headerContent}
      </StarfallBackground> :
      <div className='offer-header'>
        {headerContent}
      </div>}

    <div className='fields-container'>
      {isEmpty(offer.warnings) ? null :
        <div className='field notes-field'>
          <ul className='notes-list'>
            {offer.warnings.map((note, key_iterator) =>
              <li key={hash('note', key_seed, key_iterator)} className='note'>
                {note}
              </li>)}
          </ul>
        </div>}

      {offer.fields.filter(({ cell }) => cell).map((field, key_iterator) =>
        <div key={hash('field', key_seed, key_iterator)} className='field offer-field'>
          {syntheticRender(field.cell, { compact: true, label: field.label })}
        </div>)}

      {props.noButtons ? null :
        <div className='field buttons-field'>
          {props.continueButton}
          {props.moreInfoButton}
        </div>}
    </div>
  </div>;
}

CompactOffer.propTypes = {
  offer: offerPT.isRequired,
  noButtons: PropTypes.bool,
  moreInfoButton: PropTypes.node,
  continueButton: PropTypes.node,
  openModal: PropTypes.func.isRequired,
  logo: PropTypes.node.isRequired,
}
CompactOffer.defaultProps = {
  noButtons: true,
}

export default CompactOffer