import contactForm from './contact'

export default {
  name: 'quote',
  class: '',
  layout: [
    ...contactForm.layout,
    {
      name: "message",
      type: "text",
      options: {
        placeholder: "Popiště nám své potřeby",
        mandatory: true
      }
    }
  ]
}