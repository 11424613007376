import React from 'react';
import { syntheticRender } from 'scripts/helpers';
import { Route } from 'react-router-dom'
import NestedSwitch from 'components/NestedSwitch'
import uuid from 'uuid'
import { hash } from 'scripts/helpers'
const
  key_seed = uuid(),
  specialOffers = [
    'PRE',
  ].reduce((offers, offer_name) => ({
    ...offers,
    [offer_name]: require(`./${offer_name}`)
  }),
    {})

function SpecialOffers() {
  return <NestedSwitch basepath='/special-offer'>
    {Object.keys(specialOffers).map((offer_name, key_itterator) => {
      const offer = specialOffers[offer_name]
      return <Route strict
        key={hash(key_seed, key_itterator)}
        path={`/${offer_name}`}>
        {syntheticRender(offer.default)}
      </Route>
    })}
  </NestedSwitch>
}

export default SpecialOffers