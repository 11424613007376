import React from 'react';

import './style.scss';

function cells(props) {
  return <div className={'Component-ColouredTitle' + (props.compact ? ' compact' : '')}>
    <div className='title-container'>
      {props.children[0]}
    </div>
    <div className='text-container'>
      {props.children[1]}
    </div>
    {props.children.slice(2)}
  </div>
}

export default cells