import {
  shape,
  string,
} from 'prop-types'
import path from './path'
import renderable from './renderable'

export default shape({
  label: renderable,
  icon: renderable.isRequired,
  path: path.isRequired,
  class: string,
})