import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import './style.scss';

function FakeRow(props) {
  return <div className={props.className + ' Component-FakeRow'} />;
}

FakeRow.propTypes = {
  index: PropTypes.number
}
FakeRow.defaultProps = {
  index: 0
}

export default styled(FakeRow)`
  &::before {
    animation-delay: ${props => props.index * 0.1}s;
  }
`