import React from 'react';

import './style.scss';
import { Link } from 'react-router-dom';
import {
  hash,
  getLocalItem,
  getSessionItem,
  setSessionItem,
} from 'scripts/helpers';
import Input from 'components/Input';
import withForceUpdate from 'HOCs/withForceUpdate';
import AnimatedUnderline from 'components/AnimatedUnderline'
import {
  animated,
  useSpring,
} from 'react-spring'
import getSize from 'get-size'
import toPx from 'to-px'
import withResponsivity from 'HOCs/withResponsivity'
import { useCookies } from 'react-cookie'

const tncGdprCookie = 'tnc-gdpr-consent'
function AgreementText(props) {
  const {
    addResizeListener,
    forceUpdate,
  } = props,
    machineId = getLocalItem('machineId'),
    customerId = getSessionItem('customerId'),
    sessionId = getSessionItem('sessionId'),
    consentId = hash(machineId, customerId, sessionId, 'consent id'),
    [cookies, setCookie, removeCookie] = useCookies([tncGdprCookie]),
    [consent, setConsent] = React.useState(cookies[tncGdprCookie] === consentId),
    [dontAskAgain, setDontAskAgain] = React.useState(true),
    [showConditions, setShowConditions] = React.useState(!!(getSessionItem('Show agreement text') || !consent)),
    [childOutside, setChildOutside] = React.useState(!showConditions),

    //-- Button states and function
    agreementValue = consent,
    onAgreementChange = setConsent,
    dontAskAgainValue = dontAskAgain,
    onDontAskAgain = setDontAskAgain,
    hideConditionsValue = !showConditions,
    onHideConditions = () => {

      if (showConditions)
        setChildOutside(true)

      setShowConditions(!showConditions)
    },

    //-- Animation management
    [consentCheck_ref, setConsentCheck_ref] = React.useState(null),
    [hide_height, set_hide_height] = React.useState(0),
    { interpol } = useSpring({
      to: {
        interpol: consent ? 1 : 0
      }
    }),
    disable_content_anim = useSpring({
      to: {
        // background: consent ? '#eee0' : '#eeef',
        opacity: consent ? 1 : .5,
      },
      onFrame: () => props.forceUpdate()
    }),
    disabler_anim = useSpring({
      to: {
        opacity: consent ? 0 : 1,
      }
    }),
    consent_anim = useSpring({
      to: {
        background: consent ? '#fff0' : '#ffff',
        // borderWidth: consent ? '0' : '1px',
        top: consent ? 0 : 1,
      }
    }),
    consent_height = consentCheck_ref ? getSize(consentCheck_ref).height : toPx('2em'),
    { showInterpol } = useSpring({
      to: {
        showInterpol: showConditions ? 1 : 0
      },
      onRest: () => {
        if (showConditions)
          setChildOutside(false)
      }
    }),
    showSet = <div className='agreement-text-wrapper showset'>
      <AnimatedUnderline>
        <span onClick={onHideConditions}>
          {hideConditionsValue ? 'Zobrazit podmínky' : 'Skrýt'}
        </span>
      </AnimatedUnderline>
    </div>

  // console.log(hideWrapper_ref, hide_height)

  React.useEffect(() => addResizeListener(() => { forceUpdate() }),
    [addResizeListener, forceUpdate])

  //-- Manage consent cookie state
  React.useEffect(() => {
    if (consent && dontAskAgain)
      setCookie(tncGdprCookie, consentId, { path: '/' })
    else
      removeCookie(tncGdprCookie)
  }, [consent, consentId, dontAskAgain, setCookie, removeCookie])

  //-- Manage agreement text open state
  React.useEffect(() => {
    // console.log({ showConditions, dontAskAgain, storageShow: getSessionItem('Show agreement text', 'json') })
    setSessionItem('Show agreement text', !!(showConditions || !dontAskAgain))
  }, [showConditions, dontAskAgain])

  if (machineId === null ||
    customerId === null ||
    sessionId === null)
    return null

  return <div className='Component-AgreementText'>
    <animated.div className='agreement-hide-wrapper'
      style={{
        maxHeight: showInterpol.interpolate(p => `${hide_height * p}px`)
      }}>
      <div className='agreement-hide-content'
        ref={ref => ref && set_hide_height(getSize(ref).height)}>
        <div className='agreement-text-wrapper terms'>
          Společnost Šetři chytře s.r.o. zpracovává Vaše osobní údaje pro účely zasílaní vyžádaných obchodních sdělení, případně k uzavření a správě smlouvy s Vámi vybranými dodavateli naší společnosti, případně k jiným účelům s Vaším souhlasem. Zpracování osobních údajů probíhá v souladu s nařízením Evropského parlamentu a Rady (EU) č. 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů, souvisejícími českými právními předpisy,&nbsp;
          <AnimatedUnderline>
            <Link to='/eula' target='_blank'>Obchodními podmínkami</Link>
          </AnimatedUnderline>
          &nbsp;a&nbsp;
          <AnimatedUnderline>
            <Link to='/gdpr' target='_blank'>Zásadami ochrany soukromí</Link>
          </AnimatedUnderline>
          &nbsp;společnosti Šetři chytře s. r. o.
        </div>
        <animated.div className='agreement-form-wrapper'
          style={{
            paddingBlockStart: interpol.interpolate(
              m => consent_height * m + 'px'
            )
          }}>
          <animated.div className='check-wrapper consent-check'
            ref={setConsentCheck_ref}
            style={{
              ...consent_anim,
              // background: consent ? '#fff0' : '#ffff',
              boxShadow: consent ? 'unset' : '0 0 40px #ccc',
              color: consent ? 'currentColor' : '#222',
              minWidth: consent ? '100%' : '0%',
              top: consent_anim.top.interpolate(m => `calc((50% - ${consent_height / 2}px) * ${m})`),
              transform: interpol.interpolate(
                m => `scale(${1 + .05 * (1 - m)})`
              )
            }}>
            <Input
              type='check'
              value={agreementValue}
              onChange={onAgreementChange}
              label='Souhlasím s výše uvedenými podmínkami'
            />
          </animated.div>
          <div className='disable-section'
            style={{ pointerEvents: consent ? 'auto' : 'none' }}>
            <animated.div className='disabler'
              style={{
                ...disabler_anim,
                top: interpol.interpolate(
                  slideDown => `calc(100% * ${slideDown})`
                ),
                height: interpol.interpolate(
                  slideDown => `calc(100% * ${1 - slideDown})`
                ),
                pointerEvents: consent ? 'none' : 'auto',
              }}
            />
            <animated.div className='disabled-section-content' style={{
              ...disable_content_anim,
              filter: interpol.interpolate(
                m => `blur(${2 * (1 - m)}px)`
              ),
              transform: interpol.interpolate(
                m => `scale(${1 - .1 * (1 - m)})`
              )
            }}>
              <div className='check-wrapper reshow-check'>
                <Input
                  type='check'
                  value={dontAskAgainValue}
                  onChange={onDontAskAgain}
                  label='Už se neptat'
                />
              </div>
              {childOutside ? null : <>
                {props.children}
                {showSet}
              </>}
            </animated.div>
          </div>
        </animated.div>
      </div>
    </animated.div>
    {childOutside ? <>
      {props.children}
      {showSet}
    </> : null}
  </div>;
}

export default withResponsivity(withForceUpdate(AgreementText))