import React from 'react'
import {
  isObject,
  isRegExp,
  isFunction,
  isEmpty,
  hash,
  someKeys
} from 'scripts/helpers'
import {
  isArray,
} from 'util'
import form from 'types/form.pt'
import Input, {
  validateField
} from 'components/Input'
// import './style.scss'
import uuid from 'uuid'
const key_seed = uuid()

/**
 * @param {Object} field 
 * @param {Function} getFormData 
 * 
 * @returns {Boolean} Whether or not the field is visible in the form.
 */
function isFieldVisible(field, getFormData) {
  if (isObject(field.options) && isArray(field.options.conditional)) {
    // console.log(field)
    return field.options.conditional.some(
      disjunctive_clause =>
        disjunctive_clause.every(
          key_val => {
            const recursive_keyfunc = (field, key, value, getFormData) => {
              // console.log(field, key, value)

              if (value === '')
                value = undefined
              else if (value[0] === '!')
                return !recursive_keyfunc(
                  field,
                  key,
                  value.substr(1, value.length), //remove leading '!' 
                  getFormData
                )

              else if (key[0] === '.') {
                let pathname = field.name.split('.')
                pathname = pathname.slice(0, pathname.length - 1) //remove last entry from name

                return recursive_keyfunc(
                  field,
                  key.substr(1, key.length),
                  value,
                  val => getFormData([...pathname, val].join('.'))
                )
              }


              const data_value = getFormData(key)

              if (isRegExp(value)) //we're dealing with a regexp
                return value.test(data_value)

              // console.log(`${value}`, `${data_value}`)

              //we're dealing with cleartext
              return value === data_value ||
                Number(value) === data_value
            }

            return recursive_keyfunc(field, key_val.key, key_val.value, getFormData)
          })
    )
  }

  return true
}

/**
 * @param {Object} form 
 * @param {Function} getFormData
 * 
 * @returns {Array} List of invalid fields. Empty if all are valid.
 */
export function validateForm(fields, getFormData, verbose = false) {
  if (isEmpty(fields))
    return true

  verbose && console.log('validating', fields)

  //these two field types are recursive, we need to check their contents
  const rec_fields = ['cloneable', 'multifield-row', 'wrapper']

  //scan field for invalid ones
  let invalid_fields = fields
    .map(field => ({ options: {}, ...field }))
    .filter(field => {
      if (rec_fields.includes(field.type)) {
        verbose && console.log({ [field.type]: field.name }, 'is recursive; excluding from invalids')
        return false
      }
      else if (!field.options.mandatory) {
        verbose && console.log({ [field.type]: field.name }, 'is not mandatory; excluding from invalids')
        return false
      }
      else if (!isFieldVisible(field, getFormData)) {
        verbose && console.log({ [field.type]: field.name }, 'is not visible; excluding from invalids')
        return false
      }
      else if (validateField(field, getFormData)) {
        verbose && console.log({ [field.type]: field.name }, 'is validated; excluding from invalids')
        return false
      }
      return true
    })
    .reduce((others, field) => ({
      ...others,
      [field.name]: field.options.invalid || field.options.placeholder || field.name || field.id
    }), {})

  verbose && console.log({ invalid_fields })

  let recursive_fields = []
  fields
    .filter(field =>
      rec_fields.includes(field.type) &&
      isFieldVisible(field, getFormData))
    .forEach(field => {
      verbose && console.log('recursing into', { field })
      const inheritMandatory = (field, subfield) => {
        const withMandatory = {
          ...subfield,
          options: {
            ...subfield.options,
            mandatory: !validateField(field, getFormData, verbose) ?
              subfield.options.mandatory :
              false
          }
        }

        // console.log({ subfield, withMandatory })
        return withMandatory
      }

      // console.log({ type: field.type })
      switch (field.type) {
        case 'cloneable':
          const clones_fields = getFormData(field.name)
          verbose && console.log(clones_fields)
          clones_fields.forEach((_, index) => {
            recursive_fields.push(
              ...field.data
                .map(subfield => inheritMandatory(field, {
                  ...subfield,
                  name: `${field.name}.${index}.${subfield.name}`,
                }))
            )
          })
          break
        case 'multifield-row':
        case 'wrapper':
          recursive_fields.push(
            ...field.data
              .map(subfield => inheritMandatory(field, {
                ...subfield,
                name: [field.name, subfield.name].pureJoin('.')
              })))
          break
        default:
          console.error('No case for', field.type)
          throw Error('unhandled recursive field in form validation (details in console)')
      }
      // verbose && console.log(recursive_fields)
    })

  verbose && console.log({ recursive_fields })

  // check recursively
  const nested_validation_result = validateForm(recursive_fields, getFormData, verbose)
  verbose && console.log({ recursive_fields, nested_validation_result })
  invalid_fields = {
    ...invalid_fields,
    ...(nested_validation_result === true ? {} : nested_validation_result)
  }
  // }
  // verbose && console.log(fields_to_test, 'is empty')

  if (isEmpty(invalid_fields))
    return true
  return invalid_fields
}

/**
 * 
 * @arg {Array} fields  fields to generate data of.
 * @arg {Boolean} [verbose = false] Log things.
 * 
 * @returns {Object} Initial form data.
 */
export function generateDefaultData(fields, verbose = false) {
  const defaultableFields = [
    'check',
    'date',
    'number',
    'select',
    'slider',
    'switch',
    'text',
    'unit-label',
  ]

  let out = {}

  for (const field of fields) {

    if (Array.isArray(field.label)) {
      out[field.name + '::label'] = field.label[0]
      verbose && console.log({ field }, 'hash multiple lables, setting to 1st one')
    }

    if (defaultableFields.includes(field.type) &&
      isObject(field.options) && 'default' in field.options) {
      verbose && console.log({ field }, 'has a default:', field.options.default)
      out[field.name] = field.options.default
    }
    else if (
      out[field.name] === undefined &&
      !['delimeter'].includes(field.type)
    ) {
      // verbose && console.log({ field }, 'is not yet defined, trying to set...')
      switch (field.type) {
        case 'check':
          verbose && console.log({ field }, `is of type ${field.type}, setting to:`, false)
          out[field.name] = false
          break
        case 'switch':
          verbose && console.log({ field }, `is of type ${field.type}, setting to first value:`, field.data[0].value)
          out[field.name] = field.data[0].value
          break
        case 'slider':
          verbose && console.log({ field }, `is of type ${field.type}, setting to minimum:`, field.options.min)
          out[field.name] = field.options.min
          break
        case 'date':
          verbose && console.log({ field }, `is of type ${field.type}, setting to current date`)
          out[field.name] = new Date()
          break
        case 'file':
          verbose && console.log({ field }, `is of type ${field.type}, setting to`, [])
          out[field.name] = []
          break
        case 'cloneable':
          verbose && console.log({ field }, `is of type ${field.type}, recursing...`)
          out[field.name] = [generateDefaultData(field.data)]
          break
        case 'multifield-row':
          verbose && console.log({ field }, `is of type ${field.type}, generating data for children`)
          out = { ...out, ...generateDefaultData(field.data) }
          break
        case 'custom':
          verbose && console.log({ field }, 'is custom, no data to generate')
          break
        default:
          verbose && console.log({ field }, `is of type ${field.type}, setting to`, undefined)
          out[field.name] = undefined
      }
    }
  }

  verbose && console.log({ out })
  return out
}

function Form(props) {
  const
    [injectedFlag] = React.useState(Symbol('Form API injected')),
    validation = validateForm(props.formFields, props.getFormData),
    formAPI = someKeys([
      'getFormData',
      'setFormData',
      'formFields',
    ], props),
    injectHook = (hook, args) => hook(formAPI, ...args)
  // console.log({ validation })

  /**
   * Generates a row of the form.
   * @arg {Object} field Field with criteria to generate row by.
   * @arg {Number} field_index Index inthe form of the field currently being generated.
   * 
   * @returns {Node} A JSX node ready for output.
   */
  const generateFormRow = (field, field_index) => {

    if (!isFieldVisible(field, props.getFormData))
      return

    /**
     * Checks if a field should be enabled
     * @param {String} field
     * @returns {Boolean}
     */
    const is_enabled = field => {
      if (field.options.sequential !== undefined) {
        const
          seq = props.formFields.find(f => f.name === field.options.sequential),
          value = props.getFormData(seq.name)
        const data = seq.data || props.getFormData(seq.name + '::data')

        if (data === undefined)
          return false

        // console.log(seq, data)
        if (isArray(data) && (isEmpty(data) || (
          (isObject(data[0]) && 'label' in data[0] && 'value' in data[0]) ?
            !data.map(d => d.value).includes(value) :
            !data.includes(value))
        ))
          return false

        return is_enabled(seq)
      }

      return true
    }

    //allow for empty options
    if (!isObject(field.options))
      field.options = {}
    else //inject the formAPI object into each hook
      if (isObject(field.options.hooks)) {
        for (const hookName in field.options.hooks) {
          const hook = field.options.hooks[hookName]
          if (!hook[injectedFlag]) {
            if (!field.options._hooks
              || !field.options._hooks[hookName])
              field.options._hooks = {
                ...field.options._hooks,
                [hookName]: hook
              }

            field.options.hooks[hookName] =
              (...args) => injectHook(field.options._hooks[hookName], args)
            field.options.hooks[hookName][injectedFlag] = true
          }
        }
      }

    //assign data
    let data
    if ('data_src' in field.options)
      data = props.getFormData(field.options.data_src)
    else
      data = field.data

    //assign min
    let min
    if ('min_src' in field.options)
      min = props.getFormData(field.options.min_src)
    else
      min = field.options.min

    //assign max
    let max
    if ('max_src' in field.options)
      max = props.getFormData(field.options.max_src)
    else
      max = field.options.max

    const InputToRender =
      <Input
        field={field}
        generateFormRow={generateFormRow}
        generateDefaultData={generateDefaultData}
        getFormData={props.getFormData}
        setFormData={props.setFormData}
        disabled={!is_enabled(field)}
        value={props.getFormData(field.name)}
        onChange={val => {
          props.setFormData(field.name, val)
          if (isObject(field.options.hooks) && isFunction(field.options.hooks.onChange))
            field.options.hooks.onChange(val)
        }}
        // label={{
        //   value: props.getFormData(field.name + "::label"),
        //   onChange: label => props.setFormData(field.name + "::label", label)
        // }}
        highlightState={(() => {
          const value = props.getFormData(field.name)

          // console.log({ name: field.name, value })

          // console.log({ name: field.name })
          if ((validation !== true || value === undefined) &&
            Object.keys(validation).includes(field.name)) {
            if (value === undefined)
              return 'required'
            else
              return 'invalid'
          }

          return 'valid'
        })()
        }
        highlight={props.highlight}
        index={field_index}
        {...{
          ...field,
          data: data,
          options: {
            ...field.options,
            min: min,
            max: max
          }
        }
        }
      />

    const
      withDelim = typeof field.options.placeholder === 'string' && ['switch'].includes(field.type)

    return <div
      className={['form-row', withDelim ? 'with-delimeter' : null, field.type, field.className].toClass()}
      key={hash(key_seed, field_index)}
    >
      {withDelim ? <Input type='delimeter'>
        {field.options.placeholder}
      </Input> : null}
      {InputToRender}
    </div>
  }

  return </*div className='Component-Form'*/>
    {props.formFields.map(generateFormRow)}
  </ /*div*/>
}

Form.propTypes = form

export default Form