import React from 'react';

import {
  FaWifi as InternetIcon,
} from 'react-icons/fa'
import setriChytreAPI from 'scripts/SCH_API'
import {
  getSessionItem,
  setSessionItem,
  monthSuffixCZ,
  isEmpty,
  // toNumber,
} from 'scripts/helpers'
import {
  isArray,
} from 'util'
import currentMonthlyCost from 'forms/fields/currentMonthlyCost'
import InfoElement from 'forms/InfoElement'
import MidBold from 'forms/cells/MidBold'
import CostCell from 'forms/cells/CostCell'
import SCH_getVAT from 'scripts/SCH_getVATs'
import CostRow from 'forms/inforows/CostRow'
import cloneDeep from 'lodash/cloneDeep'
import { appendBoonCell } from 'forms/cells/BoonsCell'
import NonEmptyArray from 'types/NonEmptyArray'
import CustomerType from 'types/CustomerType'
import { SetFormData, GetFormData } from 'types/FormAPI';
import { getSCFormPageInfo } from 'forms';

type Street = {
  name: string,
  city: string,
}

const querySelect_tooltip = ({ hintValue, options }: { hintValue?: string, options: any[] }) => {
  if (isEmpty(options))
    if (!hintValue || hintValue.length < 2)
      return 'napište alespoň první dvě písmena'
    else
      return 'Žádné výsledky'
}

interface FormDataBase {
  type: CustomerType;
  minimumMonthlyData?: number;
  'minimumMonthlyData::label'?: 'MB' | 'GB',
  cost?: number;
}

interface FormDataMobile extends FormDataBase {
  internet: 'mobile'
}

interface WiredBase {
  city: string
  street: string
  streetNumber: string
  'streetNumber::data': NonEmptyArray<{ value: string, label: string }>
}

interface FormDataWired extends FormDataBase, WiredBase {
  internet: 'wired'
}

type FormData = FormDataMobile | FormDataWired

interface data_base extends Omit<FormData, 'cost'> {
  currentMonthlyCost: FormData['cost']
}

interface DataMobile extends data_base {
  dataSIM: true
}

interface DataWired extends data_base, WiredBase {
  dataSIM: false
  uirCode: string
}

type Data = DataMobile | DataWired

// function isFormDataWired(formData: FormData) {
//   return formData.internet === 'wired'
// }

const
  internetInfo = getSCFormPageInfo('comms', 'internet'),
  form = {
    label: internetInfo.label,
    value: internetInfo.name,
    title: 'Srovnání cen internetu',
    tagline: "Pokud chcete s nabídkou pomoci, stačí kliknout na ikonu operátora",
    submit: 'Porovnat',
    navItem: {
      label: internetInfo.label,
      path: internetInfo.path.form,
      icon: <InternetIcon className='icon' />,
    },
    options: {
      showHelpButtom: true,
      hooks: {
        onSubmit: (_: any, formData: FormData): Data => {

          let data = {
            dataSIM: formData.internet === 'mobile',
            minimumMonthlyData: formData.minimumMonthlyData !== undefined ?
              formData['minimumMonthlyData::label'] === 'MB' ?
                Number(formData.minimumMonthlyData) :
                Math.round(Number(formData.minimumMonthlyData) / 1000) :
              undefined,
            type: formData.type,
            currentMonthlyCost: formData.cost,
          }

          let wiredData: any = {}
          if (formData.internet === 'wired') {
            let streetNumber
            const found = formData['streetNumber::data'].find(({ value }) =>
              value === formData.streetNumber
            )

            if (found)
              streetNumber = found.label.split('/')[0]
            else throw new Error(`Failed to find street \`${formData.streetNumber}\` in streetdata`);

            wiredData.uirCode = formData.streetNumber
            wiredData.city = formData.city
            wiredData.street = formData.street
            wiredData.streetNumber = streetNumber
          }

          return { ...data, ...wiredData }
        },
        onOffer: async (_product: any, formData: { type: 'household' | 'product'; dataSIM: boolean; }) => {

          const
            vat = (await Promise.resolve(SCH_getVAT())).internet,
            incVAT = formData.type === 'household',
            product = cloneDeep(_product)

          product.fields = [
            {
              name: 'companyName',
              label: 'Název poskytovatele',
              selector: product.provider.name.toLowerCase(),
              hide: true
            },
            {
              name: 'technology',
              label: 'Technologie',
              selector: product.connectionType,
              cell: ({ compact }: { compact: boolean }) =>
                <MidBold {...{ compact }}>
                  Technologie
                  {product.connectionType}
                </MidBold>
            },
            (formData.dataSIM ?
              {
                name: 'data',
                label: 'Data',
                selector: product.dataLimit,
                cell: ({ compact }: { compact: boolean }) =>
                  <MidBold {...{ compact }}>
                    Data
                    {product.dataLimit === null ? 'neomezené' : (product.dataLimit + ' MB')}
                  </MidBold>
              } :
              {
                name: 'speed',
                label: 'Rychlost',
                selector: product.downloadSpeed,
                cell: ({ compact }: { compact: boolean }) =>
                  <MidBold {...{ compact }}>
                    Rychlost
                    {product.downloadSpeed + ' Mb/s'}
                  </MidBold>
              }
            ),
            {
              name: 'contractDuration',
              label: 'Délka smlouvy',
              selector: product.contractDuration,
              cell: ({ compact }: { compact: boolean }) =>
                <MidBold {...{ compact }}>
                  Délka smlouvy
                  {product.contractDuration ?
                    monthSuffixCZ(product.contractDuration) :
                    'Bez závazku'}
                </MidBold>
            },
            {
              name: 'cost',
              label: 'Cena',
              selector: product.costs.totalCostPerMonth,
              cell: ({ compact }: { compact: boolean }) =>
                <CostCell {...{ compact }} vat={incVAT ? vat : null} cost={product.costs.totalCostPerMonth}>
                  Cena za měsíc
                </CostCell>
            }
          ]
          product.info = [
            [
              {
                label: 'Celková měsíční platba',
                value: <CostRow vat={incVAT ? vat : null}>{product.costs.totalCostPerMonth}</CostRow>
              }
            ],
            [
              {
                label: 'Rychlost stahování',
                value: product.downloadSpeed + ' Mb/s'
              },
              {
                label: 'Rychlost nahrávání',
                value: product.uploadSpeed + ' Mb/s'
              },
              {
                label: 'Typ připojení',
                value: product.connectionType
              }
            ],
            [
              {
                label: 'Závazek',
                value: product.contractDuration ?
                  monthSuffixCZ(product.contractDuration) :
                  'Bez závazku',
              },
            ],
            [
              {
                label: 'Další podmínky',
                value: null,
              }
            ]
          ]

          switch (formData.dataSIM) {
            case true:
              product.info[1].splice(0, 0, {
                label: 'Data',
                value: product.dataLimit + ' Mb'
              })
              break
            case false:
              product.info.splice(3, 0, [
                {
                  label: 'Poplatek za instalaci',
                  value: <CostRow vat={incVAT ? vat : null}>{product.costs.installationCost}</CostRow>
                },
                {
                  label: 'Cena zařízení (jednorázově)',
                  value: <CostRow vat={incVAT ? vat : null}>{product.costCalculation.installationCost.routerPurchaseCost}</CostRow>
                },
                {
                  label: 'Cena zařízení (měsíčně)',
                  value: <CostRow vat={incVAT ? vat : null}>{product.costCalculation.totalCostPerMonth.routerRentalFee}</CostRow>
                },
              ])
              break
            default:
          }

          appendBoonCell(product, product.fields)

          product.info = <InfoElement info={product.info} />

          return product
        }
      }
    },
    fields: [
      {
        name: 'type',
        type: 'switch',
        options: {},
        data: [
          {
            label: 'Domácnost',
            value: 'household'
          },
          {
            label: 'Podnikatel',
            value: 'business'
          }
        ]
      },
      {
        name: 'internet',
        type: 'switch',
        options: {},
        data: [
          {
            label: 'Pevný',
            value: 'wired'
          },
          {
            label: 'Mobilní',
            value: 'mobile'
          }
        ]
      },
      {
        name: 'city',
        type: 'select',
        options: {
          placeholder: 'Město',
          tooltip: 'Nabídky internetu se mění dle adresy.',
          inputTooltip: querySelect_tooltip,
          allowSearch: true,
          mandatory: true,
          conditional: [
            [
              {
                key: 'internet',
                value: 'wired'
              }
            ]
          ],
          data_src: 'city::data',
          hooks: {
            onChange: ({ setFormData }: { setFormData: SetFormData }) => {
              setFormData('street', undefined)
              setFormData('street::data', undefined)
              setFormData('streetNumber', undefined)
              setFormData('streetNumber::data', undefined)
            },
            onHintChange: ({ setFormData }: { setFormData: SetFormData }, city: string) => {
              // console.log({ hintValue: city })
              setFormData('city::hint', city)

              setFormData('city::data', undefined)
              if (city === undefined || city.length < 2) {
                // console.log('resolving with no data')
                return
              }

              let cache = getSessionItem('Internet::city::cache', 'json')
              // console.log({ cache })
              if (cache) {
                const cahcedValue = cache[city]

                if (isArray(cahcedValue)) {
                  // console.log('resolving with cached value:', cahcedValue)
                  setFormData('city::data', cahcedValue)
                  return
                }
              }

              // console.log(city, 'not cached, fetching')
              setriChytreAPI(`/internet/cities?cityQuery=${encodeURI(city)}`)
                .then(body => {
                  const cities = body.data.cities.map((city: { name: string }) => ({
                    label: city.name,
                    value: city.name,
                    // disabled: false,
                  }))

                  // use destructuring in case cache is null
                  cache = {
                    ...cache,
                    [city]: cities,
                  }
                  // console.log({ cities })
                  setSessionItem('Internet::city::cache', cache)
                  // setFormData('city::data', null)
                  setFormData('city::data', cities)
                  setFormData('street', undefined)
                  setFormData('street::data', undefined)
                  setFormData('streetNumber', undefined)
                  setFormData('streetNumber::data', undefined)
                })
                .catch(err => {
                  // console.log('scAPI error')
                  throw err
                })
            }
          }
        }
      },
      {
        name: 'street',
        type: 'select',
        options: {
          placeholder: 'Ulice',
          tooltip: 'Vyberte ulici na které bydlíte',
          inputTooltip: querySelect_tooltip,
          allowSearch: true,
          mandatory: true,
          conditional: [
            [
              {
                key: 'internet',
                value: 'wired'
              }
            ]
          ],
          sequential: 'city',
          data_src: 'street::data',
          hooks: {
            onChange: ({ setFormData, getFormData }: { setFormData: SetFormData, getFormData: GetFormData }, street: string) => {
              const city = getFormData('city')

              setriChytreAPI(`/internet/streetNumbers?city=${encodeURI(city)}&street=${encodeURI(street)}`)
                .then(({ data }) => {

                  const streetNumbers_data = data.streetNumbers.map((numData: {
                    streetNumber: string,
                    entranceNumber: string,
                    entranceLetter: string,
                    uirCode: string
                  }) => ({
                    label: (() => {
                      let out = `${numData.streetNumber}`,
                        entrance = `${numData.entranceNumber}${numData.entranceLetter}`
                      if (entrance !== '')
                        out += `/${entrance}`
                      return out
                    })(),
                    value: numData.uirCode
                  }))

                  // console.log({ body, streetNumbers_data })

                  setFormData('streetNumber::data', streetNumbers_data)
                })
            },
            onHintChange: ({ setFormData, getFormData }: { setFormData: SetFormData, getFormData: GetFormData }, street: string) => {
              // console.log(street)

              if (street === undefined || street.length < 2) {
                setFormData('street::data', undefined)
                setFormData('streetNumber::data', undefined)
                setFormData('streetNumber', undefined)
                return
              }

              const city = getFormData('city')

              setriChytreAPI(`/internet/streets?city=${encodeURI(city)}&streetQuery=${encodeURI(street)}`)
                .then(({ data }) => {
                  // console.log(body)

                  const streetData =
                    data.streets
                      .filter((street: Street) => street.city === city)
                      .map((street: Street) => ({
                        label: street.name,
                        value: street.name
                      }))

                  // console.log(streetData, street)
                  setFormData('street::data', streetData)
                  setFormData('streetNumber', undefined)
                  // setFormData('streetNumber::data', undefined)
                })
            }
          }
        }
      },
      {
        name: 'streetNumber',
        type: 'select',
        options: {
          placeholder: 'Číslo domu',
          tooltip: 'Vyberte číslo Vašeho domu.',
          allowSearch: true,
          mandatory: true,
          conditional: [
            [
              {
                key: 'internet',
                value: 'wired'
              }
            ]
          ],
          sequential: 'street',
          data_src: 'streetNumber::data'
        }
      },
      {
        name: 'minimumMonthlyData',
        type: 'number',
        label: [
          'MB',
          'GB'
        ],
        options: {
          min: 0,
          step: 1,
          placeholder: 'Minimální objem dat (nepovinné)',
          tooltip: 'Vyfiltruje nabídky které mají méně než daný objem dat.',
          conditional: [
            [
              {
                key: 'internet',
                value: 'mobile'
              }
            ]
          ]
        }
      },
      currentMonthlyCost
    ]
  }

export default form