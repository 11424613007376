import React from 'react';

import PropTypes from 'prop-types'
import FileIcon, { defaultStyles } from 'react-file-icon'
import CloseIcon from 'components/CloseIcon';

function FileRow(props) {
  const extension = props.file.name.split('.').filter((_, i, arr) => i === arr.length - 1)[0]

  return <>
    <div className='fileIcon-wrapper'>
      <FileIcon
        extension={extension}
        {...(defaultStyles[extension] | {})}
      />
    </div>
    <div className='fileName-wrapper'>
      <div className='fileName'>
        {props.file.name}
      </div>
    </div>
    <CloseIcon className='remove-icon' onClick={props.remove} />
  </>;
}

FileRow.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  remove: PropTypes.func.isRequired
}

export default FileRow